export interface ErrorWithCode {
    code?: number;
    message: string;
}

export class LinkError extends Error implements ErrorWithCode {
    constructor(public code: number, message?: string) {
        super(message ?? '');
        Object.setPrototypeOf(this, LinkError.prototype);
    }
}

export enum MetaMaskErrorCodes {
    PROCESSING_REQUEST_ACCOUNT = '32002',
}
