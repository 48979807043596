import { css } from '@emotion/css';
import {
    colors,
    FlexLayout,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    StyledLink,
    VerticalSpace,
} from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, messageTypes, Routes } from '@imtbl/imx-sdk';
import React, { useCallback, useContext, useState } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName } from '../../lib/analytics/types';
import { createButtonEvent, createFlowEvent } from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { actionDeniedError } from '../Action';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { RrHyperLink } from '../DsConnectorButtons';
import { CLAIM_CONFIG } from './config';
import { Option } from './Option';
import { ClaimProps } from './types';

export function ClaimUS({ parent, testId }: ClaimProps) {
    const textMessages = useI18nMessaging({});
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [option, setOption] = useState('');
    const [ctaDisabled, setCtaDisabled] = useState(true);

    const flags = useLaunchDarklyFlags();
    const isEnabled = flags[FEATURE_FLAG.CLAIM_US_SECOND_OPTION];

    const onDenyClick = useCallback(() => {
        setErrorLog(actionDeniedError, textMessages.actionDenied);
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
        sendAnalytics(
            createButtonEvent(ButtonEventName.claimUSMainCancelPressed),
            createFlowEvent(FlowEventName.claimUSCancelled),
        );
    }, [parent, setErrorLog, textMessages]);

    const selectOption = (value: Routes) => {
        setOption(value);
        setCtaDisabled(false);
    };

    return (
        <StandardLinkRouteContainer testId={`${testId}__ClaimUSContainer`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">{textMessages.claim.US.detection.title}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.claim.US.detection.text.ipBasedLocation}
                <SimpleText fontSize="small" fontWeight="bold">
                    {textMessages.claim.US.detection.text.cancel}
                </SimpleText>
                {textMessages.claim.US.detection.text.ensure}
                <SimpleText fontSize="small" fontWeight="bold">
                    {textMessages.claim.US.detection.text.noVPN}
                </SimpleText>
                {textMessages.claim.US.detection.text.try}
            </ParagraphText>
            <OuterSpace top={measurements.SpacingTeeShirtAmounts.medium}>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    {textMessages.claim.US.detection.text.regulations}
                    <StyledLink
                        fillColor={colors.light[700]}
                        fontWeight="bold"
                        fontSize="small"
                        onClick={() => {
                            window.open(CLAIM_CONFIG.PLAY_TO_EARN, '_blank');
                        }}
                    >
                        {textMessages.claim.US.detection.text.playToEarn}
                    </StyledLink>
                    {textMessages.claim.US.detection.text.cant}
                </ParagraphText>
            </OuterSpace>

            <VerticalSpace top="large">
                <FlexLayout flexGrow={1} flexDirection="column">
                    <OuterSpace top={measurements.SpacingTeeShirtAmounts.medium}>
                        <Option
                            details={textMessages.claim.US.detection.options.instant}
                            name="claim-type"
                            onClick={() => selectOption(Routes.ClaimUSInstant)}
                        />
                        {isEnabled ? (
                            <Option
                                details={textMessages.claim.US.detection.options.overTime}
                                name="claim-type"
                                onClick={() => selectOption(Routes.ClaimUSOverTime)}
                            />
                        ) : null}
                    </OuterSpace>
                </FlexLayout>
            </VerticalSpace>

            <StandardLinkRouteButtonsFooter>
                <StandardButton testId={`${testId}__beginCta`} buttonKind="ultimate-cta" disabled={ctaDisabled}>
                    <RrHyperLink to={option}>{textMessages.standardUiButtons.next}</RrHyperLink>
                </StandardButton>
                <StyledLink
                    testId={`${testId}__denyCta`}
                    fontSize="small"
                    fontWeight="bold"
                    fillColor={colors.red[300]}
                    onClick={onDenyClick}
                    className={css`
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    {textMessages.standardUiButtons.deny}
                </StyledLink>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
}
