import { colors, ParagraphText, SectionHeading, StandardButton, StyledLink, VerticalSpace } from '@imtbl/design-system';
import React, { useEffect } from 'react';

import { closeWindow, ParentWindow } from '../../../lib';
import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { ScreenEventName } from '../../../lib/analytics/types';
import { createScreenEvent } from '../../../lib/analytics/utils';
import useProviderPreference from '../../../lib/useProviderPreference';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../../common';

export interface MultipleWalletsDetectedProps {
    parent: ParentWindow;
}

export const MultipleWalletsDetected = ({ parent }: MultipleWalletsDetectedProps) => {
    const { providerTitle } = useProviderPreference();
    useEffect(() => {
        sendAnalytics(createScreenEvent(ScreenEventName.setupMultipleWalletsDetected));
    }, []);

    const handleCloseClick = closeWindow(parent);

    return (
        <StandardLinkRouteContainer>
            <VerticalSpace bottom="small">
                <SectionHeading testId="multipleWalletsDetected__heading">
                    Multiple browser wallets detected
                </SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small" testId="multipleWalletsDetected__paragraph">
                Turn off other wallet extensions in your web browser to use {providerTitle}
            </ParagraphText>

            <VerticalSpace top="large" bottom="large">
                <StyledLink
                    fillColor={colors.light[700]}
                    fontWeight="bold"
                    href="https://support.immutable.com/hc/en-us/articles/5160531224079-Managing-multiple-wallet-extensions-for-Immutable"
                    target="_blank"
                >
                    View support.
                </StyledLink>
            </VerticalSpace>

            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large" top="large">
                    <StandardButton
                        testId="close-multiple-wallets-detected"
                        buttonKind="ultimate-cta"
                        onClick={handleCloseClick}
                    >
                        Close
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
