import {
    colors,
    ExplodingCircleWithTick,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../../common/StandardLinkUiContainers';

export function CompletedExchange({
    headingMessage,
    onFinish,
    description,
    buttonTitle,
}: {
    headingMessage: string;
    onFinish: () => void;
    description?: string;
    buttonTitle?: string;
}) {
    const textMessages = useI18nMessaging({});
    const message = (
        <>
            Your transfer is on its way into your <strong>Immutable X Inventory</strong>.
        </>
    );
    return (
        <StandardLinkRouteContainer>
            <ExplodingCircleWithTick />
            <VerticalSpace bottom="small">
                <SectionHeading>{headingMessage}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {description || message}
            </ParagraphText>
            <StandardLinkRouteButtonsFooter>
                <StandardButton testId="close" buttonKind="ultimate-cta" onClick={onFinish}>
                    {buttonTitle || textMessages.standardUiButtons.continue}
                </StandardButton>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
}
