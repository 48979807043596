// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F_i4gwqCShOJi1wI5lJB {\n    min-height: 100vh;\n}\n\n.UPalDbGJEvAnNeP8DvG9 {\n    width: 100%;\n    margin: 0 auto;\n    overflow: hidden;\n    position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".layout {\n    min-height: 100vh;\n}\n\n.app {\n    width: 100%;\n    margin: 0 auto;\n    overflow: hidden;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "F_i4gwqCShOJi1wI5lJB",
	"app": "UPalDbGJEvAnNeP8DvG9"
};
export default ___CSS_LOADER_EXPORT___;
