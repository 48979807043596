import { Web3Provider } from '@ethersproject/providers';
import { colors, measurements, OuterSpace, ParagraphText } from '@imtbl/design-system';
import {
    assertEither,
    ERC20TokenType,
    errorsToError,
    ImmutableMethodResults,
    ImmutableOrderStatus,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LinkParamsCodecs,
    LinkParamsF,
    messageTypes,
} from '@imtbl/imx-sdk';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as t from 'io-ts';
import React, { useCallback, useContext } from 'react';

import { LinkCoreContext } from '../../context/App.context';
import { Messages } from '../../i18n';
import {
    DispatchSetError,
    formatAmount,
    getAddress,
    immutableTokenToTokenWithDetails,
    LinkClientConfig,
    NotifyMethod,
    ParentWindow,
    TokenWithDetails,
} from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../lib/analytics/utils';
import { cancel } from '../../lib/cancel';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../utils/constants';
import { getFeesFromSearchParams } from '../../utils/getFeesFromSearchParams';
import { getProvidersConfig } from '../../utils/getProvidersConfig';
import { Action } from '../Action';
import { HelperMessageType } from '../HelperMessageBox';
import { TokenDetailsDisplayRow } from '../TokenDetailsDisplayRow';

export type CancelProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    setErrorLog: DispatchSetError;
};

export type CancelInput = {
    tokenSell: TokenWithDetails;
    tokenBuy: TokenWithDetails;
    takerFees: ImmutableMethodResults.ImmutableOrderMakerTakerFeesType & {
        amount: string;
    };
    order: ImmutableMethodResults.ImmutableGetOrderV3Result;
    tokenBuyInfo: ImmutableMethodResults.ImmutableGetTokenResult;
};

export const Cancel = ({ config, parent, provider, messages, setErrorLog }: CancelProps) => {
    const { config: linkConfig } = useContext(LinkCoreContext);
    const providerOptions = getProvidersConfig(linkConfig);
    const auxiliaryFees = getFeesFromSearchParams();

    const loadInput = (params: LinkParamsF.Cancel) =>
        pipe(
            TE.bindTo('client')(ImmutableXClient.buildF({ publicApiUrl: config.publicApiUrl })),
            TE.bind('address', () => getAddress(provider)),
            TE.bind('parsedOrderId', () =>
                pipe(t.Int.decode(parseInt(params.orderId)), E.mapLeft(errorsToError), TE.fromEither),
            ),
            TE.bind('order', ({ client, parsedOrderId }) =>
                pipe(
                    client.getOrderV3F({ orderId: parsedOrderId, auxiliaryFees }),
                    TE.mapLeft(() => E.toError(messages.cancel.failedToRetrieveOrderDetails)),
                ),
            ),
            TE.chainFirst(({ order, address }) =>
                pipe(
                    A.sequence(E.either)([
                        assertEither(
                            // we should be able to cancel either active or inactive orders (insufficient balance orders)
                            order.status !== (ImmutableOrderStatus.active || ImmutableOrderStatus.inactive),
                            messages.cancel.orderNotCancellable,
                        ),
                        assertEither(order.user !== address, messages.userNotAssetOwner),
                    ]),
                    TE.fromEither,
                ),
            ),
            TE.bind('tokenSell', ({ order, client }) => immutableTokenToTokenWithDetails(client)(order.sell)),
            TE.bind('tokenBuy', ({ order, client }) => immutableTokenToTokenWithDetails(client)(order.buy)),
            TE.bind('takerFees', ({ order }) =>
                TE.right({
                    ...order.taker_fees,
                    amount: formatAmount(order.taker_fees.quantity_with_fees, order.taker_fees.decimals),
                }),
            ),
            TE.bind('tokenBuyInfo', ({ tokenBuy, client }) =>
                pipe(
                    client.getTokenF(
                        tokenBuy.token.type === ERC20TokenType.ERC20
                            ? { tokenAddress: tokenBuy.token.data.tokenAddress }
                            : {},
                    ),
                    TE.mapLeft(() => E.toError(messages.cancel.failedToRetrieveToken)),
                ),
            ),
            TE.chainFirst(() =>
                TE.fromIO(() => {
                    sendAnalytics(
                        // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                        createScreenEvent(ScreenEventName.cancelSaleNotifiedOpened),
                        createFlowEvent(FlowEventName.cancelSaleStarted),
                    );
                }),
            ),
        );

    const showDetailsStart = ({ tokenSell, takerFees, tokenBuyInfo }: CancelInput) => (
        <>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Please confirm the listing to cancel:
            </ParagraphText>
            <OuterSpace top={measurements.SpacingTeeShirtAmounts.large}>
                <TokenDetailsDisplayRow
                    testId="cancelTokenDetails"
                    tokenType={tokenSell?.token?.type}
                    asset={tokenSell?.asset}
                    transactionCostTokenImage={tokenBuyInfo?.image_url || DEFAULT_ETH_TOKEN_IMAGERY}
                    tokenDisplayAmount={takerFees?.amount}
                />
            </OuterSpace>
        </>
    );

    const showDetailsDone = (_: CancelInput) => (
        <ParagraphText fillColor={colors.light[300]} fontSize="small">
            Your listing has been removed from sale.
        </ParagraphText>
    );

    const execute = (input: CancelInput, notify: NotifyMethod) => {
        // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
        sendAnalytics(createButtonEvent(ButtonEventName.cancelSaleNotifiedConfirmPressed));
        return cancel(config, input.order, notify, providerOptions);
    };

    const onFinish = useCallback(() => {
        // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
        sendAnalytics(createButtonEvent(ButtonEventName.cancelSaleConfirmedFinishPressed));
    }, []);

    const onCancel = useCallback(() => {
        sendAnalytics(
            // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
            createButtonEvent(ButtonEventName.cancelSaleNotifiedCancelPressed),
            createFlowEvent(FlowEventName.cancelSaleFailed),
        );
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, []);

    return (
        <Action
            parent={parent}
            title={messages.cancel.title}
            paramsDecoder={LinkParamsCodecs.Cancel.decode}
            loadInput={loadInput}
            execute={execute}
            helperMessageType={HelperMessageType.IS_CANCEL}
            showDetails={{
                start: showDetailsStart,
                done: showDetailsDone,
            }}
            setErrorLog={setErrorLog}
            onFinishClick={onFinish}
            onDenyClick={onCancel}
        />
    );
};
