import { Web3Provider } from '@ethersproject/providers';
import {
    ERC20TokenType,
    ERC721TokenType,
    EthAddressBrand,
    ETHTokenType,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LinkParams,
    messageTypes,
} from '@imtbl/imx-sdk';
import { Branded } from 'io-ts';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { LinkUiCoreContext } from '../../../context/App.context';
import { useGetBuyOrder } from '../../../hooks/useGetBuyOrder';
import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { useTokensList } from '../../../hooks/useTokensList.hook';
import { LinkClientConfig, ParentWindow } from '../../../lib';
import { sendAnalytics, sendAnalyticsAsync } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../../lib/analytics/utils';
import { cancelOffer } from '../../../lib/offers/cancelOffer';
import { getLocationSearch } from '../../../utils/location';
import { LoadingUi } from '../../LoadingUi/LoadingUi.component';
import { OfferFlowComplete } from '../OfferFlowComplete.component';
import {
    CancelOfferAssetError,
    CancelOfferInvalidParametersError,
    CancelOfferOrderError,
    CancelOfferTokensListError,
} from './CancelOfferError';
import { CancelOfferPrompt } from './CancelOfferPrompt.component';

export enum CancelOfferStages {
    Loading = 'loading',
    Prompt = 'prompt',
    Complete = 'complete',
}

export interface CancelOfferProps {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
}

export const CancelOffer = ({ config, parent, provider }: CancelOfferProps) => {
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [offerStage, setOfferStage] = useState(CancelOfferStages.Loading);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const textMessages = useI18nMessaging({});
    const [asset, setAsset] = useState<any>();

    const cancelOfferParamsRaw = queryString.parse(getLocationSearch());

    // Validation for cancel should have orderId and possibly fees
    if (!cancelOfferParamsRaw?.orderId && !parseInt(cancelOfferParamsRaw.orderId as string)) {
        setErrorLog(CancelOfferInvalidParametersError, textMessages.offers.cancelOffer.invalidParameters);
        return null;
    }

    const cancelOfferParams = {
        orderId: cancelOfferParamsRaw?.orderId,
    } as LinkParams.CancelOffer;

    // Get all token currencies so we can get the details of the offer currency
    const { tokens: availableTokens, error: getTokensError } = useTokensList({ config });
    const hasTokensList = useMemo(() => availableTokens.length > 0, [availableTokens]);

    // Get order details from the new client.getOrderV3 in order to retrieve the offer to cancel
    const { order: buyOrder, error: getOrderError } = useGetBuyOrder({
        config,
        orderId: parseInt(cancelOfferParams.orderId),
    });

    // Get the details of the NFT so we can show the image preview
    useEffect(() => {
        const getAssetAsync = async () => {
            try {
                const client = await ImmutableXClient.build({
                    publicApiUrl: config.publicApiUrl,
                });

                const assetResult = await client.getAsset({
                    id: buyOrder?.buy.type === ERC721TokenType.ERC721 ? buyOrder?.buy.data.token_id : '0',
                    address:
                        buyOrder?.buy.type === ERC721TokenType.ERC721
                            ? (buyOrder?.buy.data.token_address as Branded<string, EthAddressBrand>)
                            : ('' as Branded<string, EthAddressBrand>),
                });
                setAsset(assetResult);
            } catch {
                setErrorLog(CancelOfferAssetError, textMessages.offers.apiError);
            }
        };
        if (buyOrder) {
            getAssetAsync();
        }
    }, [buyOrder]);

    useEffect(() => {
        if (getTokensError) {
            setErrorLog(CancelOfferTokensListError, textMessages.offers.apiError);
        }
    }, [getTokensError]);

    useEffect(() => {
        if (getOrderError) {
            setErrorLog(CancelOfferOrderError, textMessages.offers.apiError);
        }
    }, [getOrderError]);

    useEffect(() => {
        if (hasTokensList && buyOrder) {
            sendAnalytics(
                createScreenEvent(ScreenEventName.cancelOfferPromptScreenOpened),
                createFlowEvent(FlowEventName.cancelOfferStarted),
            );
            setOfferStage(CancelOfferStages.Prompt);
        }
    }, [hasTokensList, buyOrder]);

    // Get token sell details (currency) from list of tokens
    const offerCurrencyToken = useMemo(
        () =>
            availableTokens.find(
                (token) =>
                    token.token_address ===
                    (buyOrder?.sell?.type === (ERC20TokenType.ERC20 || ETHTokenType.ETH)
                        ? buyOrder?.sell.data.token_address
                        : ''),
            ),
        [availableTokens, buyOrder],
    );

    const cancelOfferTransaction = useCallback(async () => {
        if (buyOrder) {
            setTransactionLoading(true);
            sendAnalytics(createButtonEvent(ButtonEventName.cancelOfferPromptConfirmBtnPressed));
            await cancelOffer({ config, orderId: buyOrder.order_id, provider, parent, setErrorLog });
            setOfferStage(CancelOfferStages.Complete);
            setTransactionLoading(false);
        }
    }, [buyOrder, setErrorLog, setTransactionLoading, parent]);

    const onCancelOfferCompleteClick = useCallback(async () => {
        await sendAnalyticsAsync(createButtonEvent(ButtonEventName.cancelOfferCompleteContinueBtnPressed));
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent]);

    switch (offerStage) {
        case CancelOfferStages.Complete:
            return (
                <OfferFlowComplete
                    testId="cancelOfferComplete"
                    offerMessages={textMessages.offers.cancelOffer}
                    onContinueClick={onCancelOfferCompleteClick}
                />
            );
        case CancelOfferStages.Prompt:
            return (
                <>
                    {transactionLoading && <LoadingUi showLoading testId="cancelOfferLoadingUi" />}
                    <CancelOfferPrompt
                        testId="cancelOfferPrompt"
                        parent={parent}
                        cancelOfferTransaction={cancelOfferTransaction}
                        buyOrder={buyOrder}
                        offerCurrencyToken={offerCurrencyToken}
                        asset={asset}
                        loading={transactionLoading}
                    />
                </>
            );
        case CancelOfferStages.Loading:
        default:
            return <LoadingUi showLoading testId="cancelOfferLoadingUi" />;
    }
};
