import { Web3Provider } from '@ethersproject/providers';
import {
    errorsToError,
    EthAddress,
    HexadecimalString,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LocalStorageKeys,
    messageTypes,
    ProviderPreference,
    taskEitherWithError,
} from '@imtbl/imx-sdk';
import * as E from 'fp-ts/Either';
import { constant, pipe } from 'fp-ts/function';
import * as IO from 'fp-ts/IO';
import * as IOE from 'fp-ts/IOEither';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { useCallback, useEffect, useState } from 'react';

import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { useI18nMessaging as i18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { DispatchSetError, EthNetwork, waitForTransaction, wasRequestRejected } from '../../lib';
import { sendAnalytics, sendAnalyticsAsync } from '../../lib/analytics/send-analytics';
import { FlowEventName, ScreenEventName, WalletEventName } from '../../lib/analytics/types';
import {
    createFlowEvent,
    createScreenEvent,
    createWalletEvent,
    setWalletAddressInLocalStorage,
} from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { SetupProps } from '.';
import { GamestopExternalProvider, useGamestopConnector } from './useGamestopConnector';
import { useMetamaskConnector } from './useMetamaskConnector';
// import { useWalletConnectConnector } from './useWalletConnectConnector';

export type SetupStep =
    | 'loading'
    | 'selectWallet'
    | 'signIn'
    | 'connectWallet'
    | 'setupImx'
    | 'register'
    | 'complete'
    | 'finish';

export type WalletState = {
    address: EthAddress;
};

export type RegisterState = {
    client: ImmutableXClient;
    starkPublicKey: HexadecimalString;
};

function getConnector(provider: Web3Provider, network: EthNetwork, setErrorLog: DispatchSetError) {
    if (provider.provider.isMetaMask) {
        return useMetamaskConnector();
    }

    // if ((provider.provider as GamestopExternalProvider).isGamestop) {
    return useGamestopConnector(provider.provider);
    // }

    // return useWalletConnectConnector(provider, network, setErrorLog);
}

export function useSetup({ config, parent, provider, setErrorLog, network, providerPreference }: SetupProps) {
    const [step, setStep] = useState<SetupStep>('loading');
    const [wallet, setWallet] = useState<WalletState>();
    const [registration, setRegistration] = useState<RegisterState>();
    const textMessages = i18nMessaging({});
    const [agreeToTnC, changeAgreeToTnc] = useState(false);
    const connector = getConnector(provider, network, setErrorLog);

    const flags = useLaunchDarklyFlags();
    const enableAsyncSendAnalytics = flags[FEATURE_FLAG.ENABLE_ASYNC_SEND_ANALYTICS];
    const selectWallet = useCallback(() => {
        // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
        sendAnalytics(createScreenEvent(ScreenEventName.setupUnlockOpened));
        if (window.localStorage.getItem(LocalStorageKeys.PROVIDER_PREFERENCE) === ProviderPreference.METAMASK) {
            sendAnalytics(createScreenEvent(ScreenEventName.linkProviderMetaMaskOpened));
        }
        setStep('selectWallet');
    }, []);

    const signIn = useCallback(
        () =>
            pipe(
                T.fromIO(() => setStep('signIn')),
                TE.fromTask,
                TE.bind('address', () =>
                    pipe(
                        taskEitherWithError(() => provider.getSigner().getAddress()),
                        TE.chain((address) =>
                            pipe(address.toLowerCase(), EthAddress.decode, E.mapLeft(errorsToError), TE.fromEither),
                        ),
                    ),
                ),
                TE.fold(
                    (e) => T.fromIO(() => setErrorLog(e)),
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    (wallet) =>
                        T.fromIO(() => {
                            setWallet(wallet);

                            setWalletAddressInLocalStorage(wallet.address);

                            sendAnalytics(
                                // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                                createScreenEvent(ScreenEventName.setupSignInOpened),
                            );
                        }),
                ),
            )(),
        [provider, setErrorLog],
    );

    const changeWallet = (onComplete: T.Task<void> = signIn) =>
        pipe(
            taskEitherWithError(() => connector.changeWallet()),
            TE.fold(
                (e) =>
                    T.fromIO(() => {
                        // eslint-disable-next-line no-empty
                        if (wasRequestRejected(e)) {
                        } else {
                            setErrorLog(e);
                        }
                    }),
                () => onComplete,
            ),
        )();

    const connectWallet = () =>
        pipe(
            TE.bindTo('client')(
                pipe(
                    ImmutableXClient.buildF({
                        ...config.client,
                        signer: provider.getSigner(),
                    }),
                    TE.mapLeft(() => E.toError(textMessages.failedToInstantiateSdkImxClient)),
                ),
            ),
            TE.bind('isRegistered', ({ client, client: { address } }) => client.isRegisteredF({ user: address })),
            TE.fold(
                (e) =>
                    T.fromIO(() => {
                        // Signature rejected
                        if (wasRequestRejected(e)) {
                            selectWallet();
                        } else {
                            setErrorLog(e, textMessages.generalErrorMessage([e.message]));
                        }
                    }),
                ({ client, client: { starkPublicKey, address }, isRegistered }) =>
                    isRegistered
                        ? T.fromIO(() => {
                              sendAnalytics(createScreenEvent(ScreenEventName.setupCompleteOpened));
                              finish(address, starkPublicKey);
                          })
                        : T.fromIO(() => {
                              setRegistration({
                                  client,
                                  starkPublicKey,
                              });
                              // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                              sendAnalytics(
                                  createScreenEvent(ScreenEventName.setupKeyOpened),
                                  createFlowEvent(FlowEventName.keySetupStarted),
                              );
                              setStep('setupImx');
                          }),
            ),
        )();

    const register = ({ client, starkPublicKey }: RegisterState, { address }: WalletState) =>
        pipe(
            T.fromIO(() => {
                // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                sendAnalytics(createScreenEvent(ScreenEventName.setupPendingOpened));
                setStep('register');
            }),
            TE.fromTask,
            TE.bind('registerResult', () =>
                client.registerImxF({
                    etherKey: address,
                    starkPublicKey,
                }),
            ),
            TE.chainFirst(({ registerResult }) =>
                registerResult.tx_hash === '' ? TE.of(undefined) : waitForTransaction(provider)(registerResult.tx_hash),
            ),
            TE.fold(
                (e) =>
                    T.fromIO(() => {
                        // Signature rejected
                        if (wasRequestRejected(e)) {
                            // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                            sendAnalytics(
                                createScreenEvent(ScreenEventName.setupKeyOpened),
                                createWalletEvent(WalletEventName.walletCancelBtnPressed),
                            );
                            setStep('setupImx');
                        } else {
                            setErrorLog(e);
                        }
                    }),
                () => T.fromIO(() => setStep('complete')),
            ),
        )();

    const finish = (address: EthAddress, starkPublicKey: HexadecimalString) =>
        pipe(
            IOE.tryCatch(async () => {
                if (enableAsyncSendAnalytics) {
                    await sendAnalyticsAsync(createFlowEvent(FlowEventName.keySetupSucceeded));
                } else {
                    sendAnalytics(createFlowEvent(FlowEventName.keySetupSucceeded));
                }

                parent.postMessage(
                    {
                        type: LINK_MESSAGE_TYPE,
                        message: messageTypes.result,
                        data: {
                            address,
                            starkPublicKey,
                            providerPreference,
                            ethNetwork: config.ethNetwork,
                        },
                    },
                    '*',
                );
                setStep('finish');
            }, E.toError),
            IOE.fold((e) => IO.fromIO(() => setErrorLog(e)), constant),
        )();

    useEffect(() => {
        selectWallet();
    }, [selectWallet]);

    return {
        step,
        wallet,
        registration,
        changeWallet,
        signIn,
        connectWallet,
        register,
        finish,
        agreeToTnC,
        changeAgreeToTnc,
    };
}
