import { ImmutableMethodResults, ImmutableXClient } from '@imtbl/imx-sdk';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { getFeesFromSearchParams } from '../utils/getFeesFromSearchParams';
import { useI18nMessaging } from './useI18nMessaging.hook';
import { usePrevious } from './usePrevious.hook';

export const useGetOrderDetails = ({ orderIds, client }: { orderIds?: string[]; client?: ImmutableXClient }) => {
    const [ordersDetailsOnly, setOrdersDetailsOnly] = useState<ImmutableMethodResults.ImmutableGetOrderV3Result[]>();
    const cachedProps = usePrevious({ client, orderIds });
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const buyTextMessaging = useI18nMessaging().buy;
    const generalErrorMessage = useI18nMessaging();
    const auxiliaryFees = getFeesFromSearchParams();

    useEffect(() => {
        async function getAllOrdersDetails() {
            // @NOTE: simple diff client and orderIds props to avoid many unnecessary renders:
            if (!!client && client !== cachedProps?.client && !!orderIds && orderIds !== cachedProps?.orderIds) {
                try {
                    const ordersDetailsResponse = await Promise.all(
                        orderIds.map((id: string) => {
                            const parsedOrderId = parseInt(id);
                            if (Number.isNaN(parsedOrderId)) {
                                throw new Error(buyTextMessaging.badOrderId(id));
                            }
                            return client.getOrderV3({ orderId: parseInt(id), auxiliaryFees });
                        }),
                    );
                    setOrdersDetailsOnly(ordersDetailsResponse);
                } catch (err) {
                    setErrorLog(
                        err,
                        generalErrorMessage.generalErrorMessage([
                            buyTextMessaging.failedRetrieveOrderDetails(err.message),
                        ]),
                    );
                }
            }
        }

        getAllOrdersDetails();
    }, [orderIds, cachedProps, client, buyTextMessaging, setErrorLog]);

    return ordersDetailsOnly;
};
