import { Web3Provider } from '@ethersproject/providers';
import { LINK_MESSAGE_TYPE } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../../../context/App.context';
import { useGetEthAddress } from '../../../hooks/useGetAddress.hook';
import { LinkClientConfig, ParentWindow } from '../../../lib';
import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { FlowEventName } from '../../../lib/analytics/types';
import { createFlowEvent } from '../../../lib/analytics/utils';
import { calculateNumbers, getClaim } from '../../../lib/claimImxTokens';
import { CLAIM_CONFIG } from '../config';
import { ListClaimsResponse } from '../types';

type UseGetImxFinancialNumbersProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
};

export const useGetImxFinancialNumbers = ({ provider, parent, config }: UseGetImxFinancialNumbersProps) => {
    const ethAddress = useGetEthAddress(provider);
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [imxFinancials, setImxFinancials] = useState({
        points: '0',
        percent: '0',
        imxTotal: '0',
        imxRetro: '0',
        usdcTotal: '0',
    });

    async function getFinancialNumbers() {
        if (ethAddress && provider && parent && config) {
            try {
                const { publicApiUrl } = config;
                const claims: ListClaimsResponse = await getClaim(
                    `${CLAIM_CONFIG.ENDPOINT(publicApiUrl)}/${ethAddress}`,
                );
                const { status, amount, points } = claims.result;
                const unquantizedImxAmount = new BigNumber(amount);
                const userPoints = new BigNumber(points);
                const calculatedNumbersResponse = await calculateNumbers(config, userPoints, unquantizedImxAmount);
                // Zero values updated only when status is "unclaimed".
                // Statuses "claimed" and "paid" will not update zero values.
                CLAIM_CONFIG.CHECK_STATUS(status) &&
                    setImxFinancials({ points: points.toString(), ...calculatedNumbersResponse });
            } catch (e) {
                setErrorLog(FlowEventName.claimNotFound);
                parent.postMessage({ type: LINK_MESSAGE_TYPE, message: FlowEventName.claimNotFound }, '*');
                sendAnalytics(createFlowEvent(FlowEventName.claimNotFound));
            }
        }
    }

    useEffect(() => {
        getFinancialNumbers();
    }, [ethAddress, provider, parent, config]);

    return imxFinancials;
};
