// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RmKTLQ6KdxBnJjYYcGdK {\n    border-top: 1px solid var(--colors_dark_500);\n}\n\n.uOsdSWxOBg1i9EZxtQN6.uOsdSWxOBg1i9EZxtQN6 {\n    margin-left: 6px;\n    align-self: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PrepareWithdrawal/index.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;AAChD;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".alert {\n    border-top: 1px solid var(--colors_dark_500);\n}\n\n.prepare.prepare {\n    margin-left: 6px;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "RmKTLQ6KdxBnJjYYcGdK",
	"prepare": "uOsdSWxOBg1i9EZxtQN6"
};
export default ___CSS_LOADER_EXPORT___;
