import { parse } from 'query-string';

import { search } from '../utils/location';

export const useGetOrderIdsFromSearchParams = (): string[] => {
    const unparsedOrderIds = (parse(search)?.orderIds as string[]) || [];
    let orderIds = unparsedOrderIds;
    if (typeof unparsedOrderIds === 'string') orderIds = [unparsedOrderIds];
    return orderIds;
};
