import { useEffect } from 'react';

export const LINK_ORIGIN_STORAGE_KEY = 'linkOrigin';

const useStoreLinkOrigin = () => {
    useEffect(() => {
        const originUrl = document.referrer ? new URL(document.referrer) : undefined;

        if (!originUrl) {
            return;
        }

        const linkDomain = new URL(window.location.origin);
        const isInternalLink = originUrl.origin === linkDomain.origin;

        if (!isInternalLink) {
            window.localStorage.setItem(LINK_ORIGIN_STORAGE_KEY, originUrl.hostname);
        }
    }, []);
};

export default useStoreLinkOrigin;
