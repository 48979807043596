import React, { useContext, useEffect } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { createErrorMessageUi } from '../../i18n';
import { ClaimNonUS } from './ClaimNonUS';
import { ClaimUS } from './ClaimUS';
import { ERROR_MSG_DEFAULT } from './constants';
import { useIsUserFromUS } from './hooks/useIsUserFromUS';
import { ClaimProps } from './types';

export function ClaimImxTokens({ setLoading, config, parent, provider }: ClaimProps) {
    const { userIPError, isUserFromUS, userIPAddress } = useIsUserFromUS();
    const hasIPAddressResolved = typeof userIPAddress !== 'undefined';
    const { setErrorLog } = useContext(LinkUiCoreContext);

    useEffect(() => {
        if (!hasIPAddressResolved) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [hasIPAddressResolved]);

    useEffect(() => {
        if (!userIPError) {
            return;
        }

        const { message: customMessage } = userIPError;
        const errorMessages = [ERROR_MSG_DEFAULT, customMessage];
        setErrorLog(userIPError, createErrorMessageUi(errorMessages));
    }, [userIPError]);

    if (!hasIPAddressResolved) {
        return null;
    }

    return isUserFromUS ? (
        <ClaimUS setLoading={setLoading} parent={parent} config={config} provider={provider} />
    ) : (
        <ClaimNonUS setLoading={setLoading} config={config} parent={parent} provider={provider} />
    );
}
