import { AnalyticsEvent, AnalyticsEventProperties } from './types';

declare global {
    interface Window {
        analytics: any;
    }
}

export function loadSegment(apiKey: string) {
    if (!window.analytics || apiKey === '') {
        return;
    }

    // Segment requires the attribute "_writeKey" to be set, but it goes against one of our eslint rules.
    // eslint-disable-next-line no-underscore-dangle
    window.analytics._writeKey = apiKey;
    window.analytics.load(apiKey);
    window.analytics.page();
}

export function trackSegmentEvent(analyticsEvent: AnalyticsEvent, eventProperties?: AnalyticsEventProperties) {
    if (!window.analytics) {
        return;
    }

    window.analytics.track(analyticsEvent.eventName, eventProperties);
}
