import { BigNumber as EtherBigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { BalanceInfo, erc20Balance, ethBalance } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { Observable, Subscription } from 'rxjs';

import { LinkCoreContext } from '../context/App.context';
import { getProvidersConfig, ProvidersConfig } from '../utils/getProvidersConfig';

export interface UseL1BalanceHookProps {
    observable?: Observable<BalanceInfo>;
    onUpdateBalance: (value: BigNumber) => void;
    tokenAddress?: string;
    walletAddress: string;
}

function getBalance(
    walletAddress: string,
    providerOptions: ProvidersConfig,
    tokenAddress?: string,
): Observable<BalanceInfo> {
    if (typeof tokenAddress === 'undefined') {
        return new Observable((subscriber) =>
            subscriber.next({
                balance: EtherBigNumber.from(0),
                decimal: 0,
            }),
        );
    }
    const INTERVAL_30_SECOND = 30000;
    return tokenAddress === ''
        ? ethBalance(walletAddress, INTERVAL_30_SECOND, providerOptions)
        : erc20Balance(walletAddress, tokenAddress, INTERVAL_30_SECOND, providerOptions);
}

export function useL1Balance({ walletAddress, tokenAddress, onUpdateBalance, observable }: UseL1BalanceHookProps) {
    const [error, setError] = useState<Error>();
    const subscription = useRef<Subscription>();
    const balanceObservable = useRef<Observable<BalanceInfo>>();
    const [l1Balance, setL1Balance] = useState<BalanceInfo>();

    const { config } = useContext(LinkCoreContext);
    const providersConfig = getProvidersConfig(config);

    balanceObservable.current = !observable ? getBalance(walletAddress, providersConfig, tokenAddress) : observable;

    useEffect(() => {
        if (error) {
            /* NOTE: Prevents infinite looping on error! */
            return () => subscription.current?.unsubscribe();
        }

        subscription.current = balanceObservable.current?.subscribe(
            (nextBalance) => {
                /**
                 * NOTE: These checks are required to stop an infinite
                 * state update loop because two objects are never equal.
                 */

                /**
                 * NOTE: Set balance if we don't have one, ie on first render (OR) If current
                 * balance is a BigNumber and is not equal to next balance, then update state.
                 */
                if (
                    !l1Balance ||
                    // eslint-disable-next-line no-underscore-dangle
                    (nextBalance && l1Balance.balance?._isBigNumber && !l1Balance.balance.eq(nextBalance.balance))
                ) {
                    setL1Balance(nextBalance);

                    const formattedBalance = new BigNumber(formatUnits(nextBalance.balance, nextBalance.decimal));
                    onUpdateBalance(formattedBalance);
                }
            },
            (balanceError) => setError(balanceError),
        );

        return () => subscription.current?.unsubscribe();
    }, [l1Balance, error, tokenAddress]);

    return {
        l1Balance,
        l1BalanceError: error,
        l1BalanceLoading: !l1Balance && !error,
    };
}
