import { BaseComponentPropTypes, layoutHelpers } from '@imtbl/design-system';
import React from 'react';

import styles from './index.module.css';

export const BatchNftTransferScrollView: React.FC<BaseComponentPropTypes> = ({ children }) => {
    const horizontalPadding = {
        paddingLeft: layoutHelpers.gridUnits(6),
        paddingRight: layoutHelpers.gridUnits(6),
    };
    return (
        <div className={styles.scrollView} style={horizontalPadding}>
            {children}
        </div>
    );
};
