import { Web3Provider } from '@ethersproject/providers';
import {
    ERC20Token,
    ERC20TokenType,
    ERC721Token,
    ERC721TokenType,
    EthAddressBrand,
    ETHToken,
    ETHTokenType,
    FeeType,
    ImmutableMethodResults,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    messageTypes,
} from '@imtbl/imx-sdk';
import { BigNumber } from 'ethers';
import { Branded } from 'io-ts';

import { AcceptOfferAcceptOrderAPIError } from '../../components/Offers/AcceptOffer/AcceptOfferError';
import { TokenDataType } from '../../hooks/useTokensList.hook';
import { sendAnalytics } from '../analytics/send-analytics';
import { FlowEventName } from '../analytics/types';
import { createFlowEvent } from '../analytics/utils';
import { SetErrorLog } from '../init';
import { LinkClientConfig, ParentWindow } from '../types';

export interface AcceptOfferInputs {
    config: LinkClientConfig;
    orderId: number;
    acceptOfferTokens: AcceptOfferTokens;
    fees: FeeType[];
    provider: Web3Provider;
    parent: ParentWindow;
    setErrorLog: SetErrorLog;
}
export interface AcceptOfferTokens {
    tokenBuy: ERC721Token;
    amountBuy: BigNumber;
    tokenSell: ETHToken | ERC20Token;
    amountSell: BigNumber;
}
export function getAcceptOfferTokens(
    offerCurrencyToken: TokenDataType,
    buyOrder: ImmutableMethodResults.ImmutableGetOrderV3Result,
): AcceptOfferTokens | null {
    if (!offerCurrencyToken) return null;

    if (buyOrder.buy.type !== ERC721TokenType.ERC721) return null;
    if (buyOrder.sell.type !== ERC20TokenType.ERC20 && buyOrder.sell.type !== ETHTokenType.ETH) return null;

    const tokenBuy = {
        type: ERC721TokenType.ERC721,
        data: {
            tokenId: buyOrder.buy.data.token_id,
            tokenAddress: buyOrder.buy.data.token_address as Branded<string, EthAddressBrand>,
        },
    };

    const amountBuy = BigNumber.from(1);

    const tokenSell =
        offerCurrencyToken?.token_address !== ''
            ? {
                  type: ERC20TokenType.ERC20,
                  data: {
                      decimals: parseInt(offerCurrencyToken.decimals) || 18,
                      tokenAddress: offerCurrencyToken.token_address as Branded<string, EthAddressBrand>,
                      symbol: offerCurrencyToken.symbol,
                  },
              }
            : {
                  type: ETHTokenType.ETH,
                  data: {
                      decimals: parseInt(offerCurrencyToken.decimals) || 18,
                  },
              };

    // This is sort of unnecessary since Trade v3 doesn't use this value, but
    // due to a typing issue in SDK JS (at time of writing) it is required to be
    // passed in. See createTradeV3 usage in `acceptOffer` below.
    const amountSell = buyOrder.maker_fees.quantity_with_fees;

    return {
        tokenBuy,
        amountBuy,
        tokenSell,
        amountSell,
    };
}

export async function acceptOffer({
    config,
    orderId,
    acceptOfferTokens,
    fees,
    provider,
    parent,
    setErrorLog,
}: AcceptOfferInputs) {
    const { tokenBuy, amountBuy, tokenSell, amountSell } = acceptOfferTokens;
    const client = await ImmutableXClient.build({ ...config, signer: provider.getSigner() });
    const user = client.address;
    try {
        await client.createTradeV3({
            user,
            tokenSell,
            tokenBuy,
            amountSell,
            amountBuy,
            orderId,
            fees,
        });
        sendAnalytics(createFlowEvent(FlowEventName.acceptOfferSucceeded));
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.success }, '*');
    } catch (err) {
        console.error(err);
        sendAnalytics(createFlowEvent(FlowEventName.acceptOfferFailed));
        setErrorLog(AcceptOfferAcceptOrderAPIError);
    }
}
