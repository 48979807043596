import { BaseProvider, Web3Provider } from '@ethersproject/providers';
import {
    errorsToError,
    getProvider,
    LINK_MESSAGE_TYPE,
    messageTypes,
    ProviderPreference,
    taskEitherWithError,
    UnexpectedProviderError,
    // WalletProviderError,
} from '@imtbl/imx-sdk';
import Axios from 'axios';
import * as E from 'fp-ts/Either';
import { constant, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import React from 'react';

import { ErrorMessageProps } from '../components';
import { GamestopExternalProvider } from '../components/Setup/useGamestopConnector';
import { LinkError, MetaMaskErrorCodes } from '../errors';
import { useI18nMessaging as i18nMessaging } from '../hooks/useI18nMessaging.hook';
import { getProvidersConfig } from '../utils/getProvidersConfig';
import { InternalRoutes } from '../utils/internalRoutes';
import { EthNetwork, LinkConfigCodec, ParentWindow } from './types';

const NOCACHE_HEADERS = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
};

const messages = i18nMessaging({});

export type SetErrorLog = (e: any, ui?: ErrorMessageProps) => void;

const mapErrorMessage = (e: Error) => {
    if (e?.stack && e.stack.includes(MetaMaskErrorCodes.PROCESSING_REQUEST_ACCOUNT)) {
        return messages.walletUnavailableError;
    }
    return messages.generalErrorMessage([e.message]);
};

const defineParent = (isIframe: boolean): ParentWindow => {
    if (isIframe) return window.parent as ParentWindow;

    // @NOTE: if the link is not inside an iframe, and
    // there is no window.opener then we have problems!
    if (!window?.opener) throw new Error('window.opener not found');

    return window.opener as ParentWindow;
};

const checkStorageAvailability = (): void => {
    try {
        const storageAvailableKey = 'STORAGE_AVAILABLE';
        window.localStorage.setItem(storageAvailableKey, '');
        window.localStorage.getItem(storageAvailableKey);
        window.localStorage.removeItem(storageAvailableKey);
    } catch {
        throw new LinkError(messages.storageUnavailable.code, messages.storageUnavailable.message);
    }
};

const isPathDenied = (path: string): boolean => {
    const routesAllowedWithNoProvider = Object.values(InternalRoutes);

    return !routesAllowedWithNoProvider.includes(path as InternalRoutes);
};

export function init({
    setErrorLog,
    setLoading,
    setProvider,
    redirectTo,
    pathname,
    setParent,
    setProviderPreference,
}: {
    setErrorLog: SetErrorLog;
    setLoading: (flag: boolean) => void;
    setProvider: React.Dispatch<React.SetStateAction<Web3Provider | undefined>>;
    redirectTo: (path: string) => void;
    pathname: string;
    setParent: React.Dispatch<React.SetStateAction<ParentWindow | undefined>>;
    setProviderPreference: React.Dispatch<React.SetStateAction<ProviderPreference | undefined>>;
}) {
    return pipe(
        taskEitherWithError(() =>
            Axios.get('/config.json', {
                headers: NOCACHE_HEADERS,
            }),
        ),
        TE.mapLeft(constant(new Error('Unable to decode config'))),
        TE.bindTo('configJson'),
        TE.bind('config', ({ configJson }) =>
            pipe(LinkConfigCodec.decode(configJson.data), E.mapLeft(errorsToError), TE.fromEither),
        ),
        TE.bind('parent', () => {
            const isIframe = window.location !== window.parent?.location;

            let parentWindow: ParentWindow | undefined;
            try {
                parentWindow = defineParent(isIframe);

                if (isIframe) {
                    checkStorageAvailability();
                }

                return TE.right(parentWindow);
            } catch (err) {
                const error = err instanceof Error ? err : new Error(String(err));

                if (parentWindow)
                    parentWindow.postMessage(
                        {
                            type: LINK_MESSAGE_TYPE,
                            message: messageTypes.fail,
                            data: {
                                ...(error instanceof LinkError && { code: error.code }),
                                error: error.message,
                            },
                        },
                        '*',
                    );

                return TE.left(error);
            }
        }),
        TE.fold(
            (e) => T.fromIO(() => setErrorLog(e, messages.generalErrorMessage(['Problem initializing app']))),
            ({ config, parent }) =>
                T.fromIO(() => {
                    const updateProvider = pipe(
                        getProvidersConfig(config),
                        getProvider,
                        TE.bindTo('provider'),
                        TE.fold(
                            (error) => {
                                // Error specific for MetaMask - When it was identified that the `isMetaMask` is false but should be true
                                if (error instanceof UnexpectedProviderError) {
                                    redirectTo(InternalRoutes.MULTIPLE_WALLETS);

                                    setLoading(false);
                                    return T.of(undefined);
                                }

                                // // Error specific for WalletConnect - Disconnection and general errors emitted by the provider
                                // if (error instanceof WalletProviderError) {
                                //     setErrorLog(error, messages.generalErrorMessage([error.message]));

                                //     setLoading(false);
                                //     return T.of(undefined);
                                // }

                                /*
                                  TODO: This path denial needs to be revisited - Does it make sense to check if the path is 
                                  allowed or not only after coming to the conclusion that there is no specific error?
                                */
                                if (isPathDenied(pathname)) redirectTo(InternalRoutes.MISSING_WALLET);

                                setLoading(false);
                                return T.of(undefined);
                            },
                            (a) =>
                                pipe(
                                    TE.of<Error, { provider: Web3Provider }>(a),
                                    TE.chainFirst(({ provider }) =>
                                        taskEitherWithError(() => {
                                            const { isMetaMask } = provider.provider;
                                            const { isGamestop } = provider.provider as GamestopExternalProvider;

                                            if (isMetaMask || isGamestop) {
                                                setProviderPreference(
                                                    isMetaMask
                                                        ? ProviderPreference.METAMASK
                                                        : ProviderPreference.GAMESTOP,
                                                );

                                                return provider.send('eth_requestAccounts', []);
                                            }

                                            return Promise.resolve();
                                        }),
                                    ),
                                    TE.mapLeft((e) => ({
                                        error: e,
                                        ui: mapErrorMessage(e),
                                    })),
                                    TE.bind('network', ({ provider }) =>
                                        pipe(
                                            taskEitherWithError(() => provider.getNetwork()),
                                            TE.bimap(
                                                (e) => ({
                                                    error: e,
                                                    ui: messages.generalErrorMessage([e.message]),
                                                }),
                                                (network) => {
                                                    return network.name === 'homestead'
                                                        ? EthNetwork.mainnet
                                                        : network.name;
                                                },
                                            ),
                                            TE.chain((ethNetwork) => {
                                                return ethNetwork === config.ethNetwork
                                                    ? TE.of(ethNetwork)
                                                    : TE.left({
                                                          error: new Error('Change Network'),
                                                          ui: messages.changeNetwork(config.ethNetwork),
                                                      });
                                            }),
                                        ),
                                    ),
                                    TE.fold(
                                        (e) => {
                                            setLoading(false);
                                            return T.fromIO(() => setErrorLog(e.error, e.ui));
                                        },
                                        ({ provider }) =>
                                            T.fromIO(() => {
                                                setLoading(false);
                                                setProvider(provider);
                                            }),
                                    ),
                                ),
                        ),
                    );

                    setParent(parent);

                    if ('ethereum' in window) {
                        const ethereum = (window as any).ethereum as BaseProvider;

                        if (ethereum.on) {
                            ethereum.on('chainChanged', updateProvider);
                            ethereum.on('accountsChanged', updateProvider);
                        }
                    }

                    updateProvider();
                }),
        ),
    );
}
