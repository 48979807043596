import { Web3Provider } from '@ethersproject/providers';
import { BaseComponentPropTypes } from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
import React, { useEffect, useMemo } from 'react';

import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { useBuildClient } from '../../hooks/useBuildClient.hook';
import { useGetEthAddress } from '../../hooks/useGetAddress.hook';
import { useGetFullOrderDetails } from '../../hooks/useGetFullOrderDetails.hook';
import { useMakeBuyTransaction } from '../../hooks/useMakeBuyTransaction.hook';
import { LinkClientConfig, ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { FlowEventName, ScreenEventName } from '../../lib/analytics/types';
import { createFlowEvent, createScreenEvent } from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import useProviderPreference from '../../lib/useProviderPreference';
import { LoadingUi } from '../LoadingUi/LoadingUi.component';
import { BuyCompleteScreen } from './BuyCompleteScreen.component';
import { BuyPromptScreen } from './BuyPromptScreen.component';
import { OTPView } from './OTPFlow/OTPView';

export type BuyV2PropTypes = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
} & Pick<BaseComponentPropTypes, 'testId'>;

export const BuyV2 = ({ config, parent, provider, testId }: BuyV2PropTypes) => {
    const ethAddress = useGetEthAddress(provider);
    const client = useBuildClient(config.publicApiUrl);
    const { providerPreference } = useProviderPreference();

    const flags = useLaunchDarklyFlags();
    const enableRiskAssessment = flags[FEATURE_FLAG.ENABLE_RISK_ASSESSMENT];
    const hideConfirmation = flags[FEATURE_FLAG.HIDE_BUY_CONFIRMATION_FOR_MAGIC_FLOW];
    const { loading: initialViewLoading, orderDetails } = useGetFullOrderDetails({
        config,
        client,
        ethAddress,
    });

    const { transacting, buyResults, finalConsolidatedOrders, beginBuyTransaction, sendAnalyticsCallback } =
        useMakeBuyTransaction({
            config,
            provider,
            orderDetails,
            parent,
        });
    useEffect(() => {
        if (!initialViewLoading)
            sendAnalytics(
                createScreenEvent(ScreenEventName.buyNowNotifiedOpened),
                createFlowEvent(FlowEventName.assetBuyNowStarted),
            );
    }, [initialViewLoading]);

    const getRequestId = useMemo(() => {
        let requestId;
        Object.keys(buyResults || {}).forEach((key) => {
            if (buyResults && buyResults?.[key]?.requestId) {
                requestId = buyResults?.[key]?.requestId;
            }
        });
        return requestId;
    }, [buyResults]);

    const showLoadingUi = initialViewLoading || transacting;
    const isMagic = providerPreference === ProviderPreference.MAGIC_LINK;
    const shouldShowOTPView = enableRiskAssessment && buyResults && getRequestId && isMagic;
    const shouldBeginBuyAutomatically =
        !showLoadingUi && hideConfirmation && isMagic && beginBuyTransaction && buyResults === undefined;
    const shouldShowBuyPromptScreen =
        !showLoadingUi && orderDetails && (!hideConfirmation || (hideConfirmation && !isMagic));

    useEffect(() => {
        if (shouldBeginBuyAutomatically) {
            beginBuyTransaction();
        }
    }, [shouldBeginBuyAutomatically]);

    if (shouldShowOTPView) {
        const params = {
            config,
            provider,
            requestId: getRequestId,
            parent,
            orderDetails: finalConsolidatedOrders,
            sendAnalyticsCallback,
        };
        return <OTPView {...params} />;
    }

    return buyResults ? (
        <BuyCompleteScreen parent={parent} testId={testId} orderDetails={finalConsolidatedOrders} />
    ) : (
        <>
            <LoadingUi showLoading={showLoadingUi} testId={`${testId}__loadingUi`} />
            {shouldShowBuyPromptScreen && (
                <BuyPromptScreen
                    parent={parent}
                    testId={testId}
                    beginBuyTransaction={beginBuyTransaction}
                    orderDetails={orderDetails}
                />
            )}
        </>
    );
};
