import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';

import { COUNTRY_CODE_US, ERROR_MSG_IP_ADDRESS, KEY_IP_SERVICE_ACCESS, URL_IP_SERVICE_CHECK } from '../constants';

export interface IPServiceCheckResponse {
    country_code?: string;
    ip?: string;
}

export interface IsUserFromUSHookResponse {
    userIPAddress?: string | null;
    isUserFromUS: boolean;
    userIPError?: Error;
}

export function useIsUserFromUS(): IsUserFromUSHookResponse {
    const [userIPAddress, setUserIPAddress] = useState<string | null>();
    const [isUserFromUS, setIsUserFromUS] = useState(false);
    const [error, setUserIPError] = useState<Error>();

    useEffect(() => {
        const queryParams = `access_key=${KEY_IP_SERVICE_ACCESS}&fields=ip,country_code`;
        const setErrorResponse = (customError = new Error(ERROR_MSG_IP_ADDRESS)) => {
            setUserIPError(customError);
            setIsUserFromUS(false);
            setUserIPAddress(null);
        };

        const setSuccessResponse = (userAddress: string, isUserInUS: boolean) => {
            setUserIPError(undefined);
            setIsUserFromUS(isUserInUS);
            setUserIPAddress(userAddress);
        };

        const getIPAddressAndCountry = async (url: string, query: string) => {
            let data: IPServiceCheckResponse | null | undefined;

            try {
                // NOTE: This is to catch unexpected errors thrown by the external API.
                ({ data } = await axios.get<IPServiceCheckResponse>(`${url}?${query}`));
            } catch (apiError) {
                console.log(apiError);
                setErrorResponse();
            }

            const { ip, country_code: code } = data ?? {};

            // IP address should at least be '1.1.1.1'.
            if (typeof ip !== 'string' || ip.length < 7) {
                setErrorResponse();
                return;
            }

            // Example values for valid country codes are 'AU', 'US'.
            if (typeof code !== 'string' || code.length < 2) {
                setErrorResponse();
                return;
            }

            setSuccessResponse(ip, code === COUNTRY_CODE_US);
        };

        getIPAddressAndCountry(URL_IP_SERVICE_CHECK, queryParams);
    }, []);

    // NOTE: Allows error object to be passed as a dependency.
    const userIPError = useMemo(() => error, [error?.message]);

    return {
        userIPError,
        isUserFromUS,
        userIPAddress,
    };
}
