/* eslint-disable @typescript-eslint/naming-convention */
import { css } from '@emotion/css';

export const GameWalletTile = css`
    flex-direction: 'row';
    justify-content: 'flex-start';
    align-items: center;
    background-color: var(--colors_bg_500);
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
        background-color: var(--colors_bg_700);
    }
`;

export const CurrentWalletTile = css`
    flex-direction: 'column';
    justify-content: 'center';
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
        background-color: var(--colors_bg_700);
    }
`;

export const GameLogoIcon = css`
    height: 38px;
    width: 38px;
    margin-right: 20px;
`;

export const CurrentWalletIcon = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    background-color: white;
    height: 38px;
    width: 38px;
    margin-right: 15px;
`;

export const WalletDifference = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 36px;
`;

export const WalletDifference_text = css`
    color: var(--colors_light_700);
    cursor: pointer;
    &:hover {
        color: var(--colors_light_500);
    }
`;
