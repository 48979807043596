import { Web3Provider } from '@ethersproject/providers';
import { ImmutableXClient, LINK_MESSAGE_TYPE, LinkParamsCodecs, messageTypes } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import { useLaunchDarklyFlags } from '../../../context/LaunchDarkly';
import { useGetAsset } from '../../../hooks/useGetAsset.hook';
import { TokenDataType, useTokensList } from '../../../hooks/useTokensList.hook';
import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../../lib/featureFlags';
import { getFeesFromSearchParams } from '../../../utils/getFeesFromSearchParams';
import { getLocationSearch } from '../../../utils/location';
import { ErrorMessage, LoadingMessage, StandardLinkRouteContainer } from '../../common';
import { SellProps } from '../index';
import { ListingAsset, ResultMessage } from './stages';
import { listAsset } from './utils';

enum SellStages {
    Loading,
    Listing,
    Result,
}

const getAddress = async (provider: Web3Provider): Promise<string> => {
    const signer = provider.getSigner();
    return (await signer.getAddress()).toLowerCase();
};

const getSellAmountLessMakerFees = (sellParamsAmount: string, decimals: number) => {
    const fees = getFeesFromSearchParams();
    const sellFeePercentage = fees
        .map((f) => f.percentage)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const sellPrice = new BigNumber(sellParamsAmount);
    const sellFeeQuantity = sellPrice.multipliedBy(sellFeePercentage).div(100).decimalPlaces(decimals);
    return sellPrice.minus(sellFeeQuantity);
};

export const SellFlexible = ({ config, setLoading, provider, messages, parent }: SellProps) => {
    // FIXME: The type assertion for sellparams was broken by the below commit. For some reason
    // the assertion doesn't like the new fees t.Array
    // https://github.com/immutable/imx-sdk-js/commit/ae36ff9bf3bdc3621e223fd798776b7b52c8dd93
    const rawSellParams = queryString.parse(getLocationSearch());
    if (!LinkParamsCodecs.Sell.is(rawSellParams)) return null;
    const sellParams = rawSellParams;

    const [stage, setStage] = useState<SellStages>(SellStages.Loading);
    const minSupportDecimalPrice = 0.000001;
    const [selectedToken, setSelectedToken] = React.useState<TokenDataType>();
    const [sellerReceiveAmount, setSellerReceiveAmount] = useState<BigNumber | null>(null);
    const [price, setPrice] = useState<BigNumber | null>(null);
    const [minPrice, setMinPrice] = React.useState<any>(minSupportDecimalPrice);
    const [error, setError] = React.useState<string>();
    const flags = useLaunchDarklyFlags();
    const isEnabled = flags[FEATURE_FLAG.ERC20_LISTING];

    const { tokens: availableTokens } = useTokensList({ config });
    const { asset } = useGetAsset({
        config,
        id: sellParams.tokenId,
        address: sellParams.tokenAddress,
    });
    const hasTokensList = availableTokens.length > 0;

    useEffect(() => {
        if (asset?.orders?.sell_orders && asset.orders.sell_orders.length > 0) {
            setError(messages.sell.unavailableAsset);
        }
    }, [asset]);

    const imxClient = useCallback(async () => {
        return ImmutableXClient.build({
            ...config,
            signer: provider.getSigner(),
        });
    }, [config]);

    const validateRequestAndSetStage = () => {
        if (sellParams.currencyAddress && availableTokens.length > 0) {
            const tokenInfo = availableTokens.find((token) => token.token_address === sellParams.currencyAddress);
            if (tokenInfo) {
                setStage(SellStages.Listing);
                setSelectedToken(tokenInfo);
            } else {
                setError(messages.sell.unknownCurrency);
            }
        }

        if (sellParams.amount) {
            const decimals = parseInt(selectedToken?.decimals || '18');
            setMinPrice(Math.max(1 / Math.pow(10, decimals), minSupportDecimalPrice));
            if (parseFloat(sellParams.amount) >= minPrice) {
                const sellPrice = getSellAmountLessMakerFees(sellParams.amount, decimals);
                setSellerReceiveAmount(sellPrice);
                setPrice(new BigNumber(sellParams.amount));
                setStage(SellStages.Listing);
            } else {
                setError(messages.sell.belowMinPrice + minPrice);
            }
        }
    };

    const onFinishCtaClick = useCallback(() => {
        sendAnalytics(createButtonEvent(ButtonEventName.listAssetConfirmedFinishPressed));
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent]);

    const sell = async (amount: BigNumber | null, token: TokenDataType) => {
        setStage(SellStages.Loading);
        try {
            const client = await imxClient();

            if (client && amount && token) {
                const address = await getAddress(provider);
                await listAsset(client, address, amount, token, sellParams);
                setStage(SellStages.Result);
                parent.postMessage(
                    {
                        type: LINK_MESSAGE_TYPE,
                        message: messageTypes.success,
                    },
                    '*',
                );
            } else {
                console.error('Not defined required sell params');
            }
        } catch (e) {
            const err = e instanceof Error ? e : new Error(String(e));
            setError(messages.sell.failedAPIRequest.message(err.message));
        }
    };

    useEffect(() => {
        if (hasTokensList && asset) {
            setStage(SellStages.Listing);
            setLoading(false);
            sendAnalytics(
                createScreenEvent(ScreenEventName.listForSaleNotifiedOpened),
                createFlowEvent(FlowEventName.listForSaleStarted),
            );
            validateRequestAndSetStage();
        } else {
            setStage(SellStages.Loading);
            setLoading(true);
        }
    }, [hasTokensList, setLoading, asset]);

    if (!hasTokensList) {
        return null;
    }

    const stageElements = {
        [SellStages.Listing]: (
            <ListingAsset
                availableTokens={availableTokens}
                token={selectedToken}
                sellerReceiveAmount={sellerReceiveAmount}
                price={price}
                asset={asset}
                minPrice={minPrice}
                onConfirm={sell}
                onCancel={onFinishCtaClick}
            />
        ),
        [SellStages.Result]: <ResultMessage onFinish={onFinishCtaClick} />,
        [SellStages.Loading]: <LoadingMessage />,
    };

    const generateBodyScreenContent = () => {
        if (error) {
            return <ErrorMessage message={error} onFinish={onFinishCtaClick} />;
        }

        return stageElements[stage];
    };

    return (
        <StandardLinkRouteContainer>
            {isEnabled ? (
                generateBodyScreenContent()
            ) : (
                <ErrorMessage
                    message="ERC-20 listing without amount or with provided currency is not enabled for this environment!"
                    onFinish={onFinishCtaClick}
                />
            )}
        </StandardLinkRouteContainer>
    );
};
