/* eslint-disable @typescript-eslint/no-shadow */
import { Web3Provider } from '@ethersproject/providers';
import { colors, FlexLayout, gradients } from '@imtbl/design-system';
import { isGameWalletProvider, ProviderPreference, Routes } from '@imtbl/imx-sdk';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import styles from './App.module.css';
import {
    Buy,
    Cancel,
    CompleteWithdrawal,
    Deposit,
    ErrorMessage,
    ErrorMessageProps,
    History,
    MissingWallet,
    PrepareWithdrawal,
    Setup,
    Transfer,
} from './components';
import { AppHeaderBar } from './components/AppHeaderBar';
import { BuyCheck } from './components/BuyCheck/BuyCheck';
import { BuyV2 } from './components/BuyV2';
import { ClaimImxTokens, ClaimUSInstant, ClaimUSOverTime, ClaimUSOverTimeDetails } from './components/Claim';
import { StandardLinkRouteContainer } from './components/common';
import { NFTCheckoutPrimary } from './components/Exchange/NFTCheckoutPrimary';
import { NFTCheckoutSecondary } from './components/Exchange/NFTCheckoutSecondary';
import { Offramp } from './components/Exchange/Offramp';
import { Onramp } from './components/Exchange/Onramp';
import GetPublicKey from './components/GetPublicKey';
import { LoadingUi } from './components/LoadingUi/LoadingUi.component';
import { AcceptOffer, CancelOffer, MakeOffer } from './components/Offers';
import ProviderSelection from './components/ProviderSelection';
import { Sell } from './components/Sell';
import HardwareWalletStep from './components/Setup/HardwareWalletStep';
import { MultipleWalletsDetected } from './components/Setup/MultipleWalletsDetected';
import SetupGameWallet from './components/SetupGameWallet/SetupGameWallet';
import SetupMagicWallet from './components/SetupMagicWallet';
import { TransferV2 } from './components/TransferV2';
import WalletDifference from './components/WalletDifference';
import { LinkCoreContext, LinkUiCoreContext } from './context/App.context';
import { LaunchDarklyProvider } from './context/LaunchDarkly';
import { useGetMessagingComponent } from './hooks/useMessagingComponent.hook';
import { useSetSdkVersion } from './hooks/useSetSdkVersion';
import { messagesIndex } from './i18n';
import { LinkConfig, ParentWindow } from './lib';
import { injectAnalytics } from './lib/analytics/inject-analytics';
import { FEATURE_FLAG } from './lib/featureFlags';
import { getFlags } from './lib/getFlags';
import { init } from './lib/init';
import useProviderPreference from './lib/useProviderPreference';
import useStoreLinkOrigin from './lib/useStoreLinkOrigin';
import { prettyPrintVersions } from './lib/version';
import { InternalRoutes } from './utils/internalRoutes';

const messages = messagesIndex.en;

export type AppProps = {
    config: LinkConfig;
};

const showMissingWallet = (providerPreference: ProviderPreference | undefined): JSX.Element | false => {
    const isMetaMask = providerPreference === ProviderPreference.METAMASK;
    const isGameStop = providerPreference === ProviderPreference.GAMESTOP;
    return (!providerPreference || isMetaMask || isGameStop) && <MissingWallet />;
};

export const App = ({ config }: AppProps) => {
    const { pathname } = useLocation();
    const [loading, setLoadingState] = useState(true);
    const [parent, setParent] = useState<ParentWindow>();
    const [provider, setProvider] = useState<Web3Provider>();
    const [error, setErrorState] = useState<ErrorMessageProps>();
    const flags = getFlags(config);

    const showRewardClaimFlow = flags[FEATURE_FLAG.SHOW_REWARD_CLAIM_FLOW];
    const enableOfferRoutes = flags[FEATURE_FLAG.ENABLE_OFFER_ROUTES];
    const enableGameWallets = flags[FEATURE_FLAG.ENABLE_GAME_WALLETS];
    const enableGameWalletAutoDetect = flags[FEATURE_FLAG.ENABLE_GAME_WALLET_AUTO_DETECT];
    useStoreLinkOrigin();

    const { providerPreference, setProviderPreference } = useProviderPreference();

    const setErrorLog = useCallback((e, ui: ErrorMessageProps = messages.generalError) => {
        setErrorState(ui);
        prettyPrintVersions();
        console.error(e);

        const newRelic = (window as any).NREUM;
        if (newRelic) {
            newRelic.noticeError(`Link: setErrorLog: ${e.message}`, e);
        }
    }, []);

    const messagingComponent = useGetMessagingComponent({
        config,
        parent,
        provider,
        setErrorLog,
    });

    const setLoading = useCallback((flag: boolean) => {
        setLoadingState(flag);
    }, []);

    const history = useHistory();

    const redirectTo = (path: string) => {
        history.push(path);
    };

    useEffect(() => {
        prettyPrintVersions();
    }, []);

    useSetSdkVersion();

    useEffect(() => {
        init({
            setErrorLog,
            setLoading,
            setProvider,
            redirectTo,
            pathname,
            setParent,
            setProviderPreference,
        })();
    }, [setErrorLog, history]);

    useEffect(() => {
        if (config) injectAnalytics(config.akuma);
    }, [config]);

    const getBody = () => {
        if (error) {
            return (
                <StandardLinkRouteContainer testId="errorRouteContainer">
                    <ErrorMessage parentWindow={parent} heading={error.heading} body={error.body} />
                </StandardLinkRouteContainer>
            );
        }

        if (config && parent && provider) {
            const { client, ethNetwork, imageResizerServiceUrl } = config;
            const defaultProps = {
                config: client,
                provider,
                parent,
            };

            return (
                <LaunchDarklyProvider flags={flags}>
                    <LinkCoreContext.Provider value={{ parent, config }}>
                        <LinkUiCoreContext.Provider value={{ setErrorLog, imageResizerServiceUrl }}>
                            <Switch>
                                <Route path={InternalRoutes.WALLET_DIFFERENCE} exact>
                                    <WalletDifference />
                                </Route>
                                <Route path={InternalRoutes.MISSING_WALLET} exact>
                                    {showMissingWallet(providerPreference)}
                                    {providerPreference === ProviderPreference.MAGIC_LINK && (
                                        <SetupMagicWallet parent={parent} config={config} />
                                    )}
                                    {enableGameWallets &&
                                        providerPreference &&
                                        isGameWalletProvider(providerPreference) && (
                                            <SetupGameWallet
                                                parent={parent}
                                                config={config}
                                                gameWalletProviderKey={providerPreference}
                                            />
                                        )}
                                    {(providerPreference === ProviderPreference.NONE ||
                                        (!enableGameWallets &&
                                            providerPreference &&
                                            isGameWalletProvider(providerPreference))) && (
                                        <ProviderSelection config={config} />
                                    )}
                                </Route>
                                <Route path={InternalRoutes.MULTIPLE_WALLETS} exact>
                                    <MultipleWalletsDetected parent={parent} />
                                </Route>

                                <Route path="/" exact>
                                    {loading || !messagingComponent ? <LoadingUi showLoading /> : messagingComponent}
                                </Route>

                                <Route path={`/${Routes.Setup}`} exact>
                                    <Setup
                                        config={config}
                                        parent={parent}
                                        provider={provider}
                                        setErrorLog={setErrorLog}
                                        network={config.ethNetwork}
                                        providerPreference={providerPreference}
                                    />
                                </Route>
                                <Route path={InternalRoutes.SETUP_HARDWARE_WALLET}>
                                    <HardwareWalletStep />
                                </Route>
                                <Route path={`/${Routes.Deposit}`} exact>
                                    <Deposit
                                        {...defaultProps}
                                        messages={messages}
                                        ethNetwork={ethNetwork}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.PrepareWithdrawal}`} exact>
                                    <PrepareWithdrawal
                                        {...defaultProps}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.CompleteWithdrawal}`} exact>
                                    <CompleteWithdrawal
                                        {...defaultProps}
                                        messages={messages}
                                        ethNetwork={ethNetwork}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.Buy}`} exact>
                                    {enableGameWallets && enableGameWalletAutoDetect && (
                                        <BuyCheck config={config} parent={parent} provider={provider}>
                                            <Buy {...defaultProps} messages={messages} setErrorLog={setErrorLog} />
                                        </BuyCheck>
                                    )}
                                    {enableGameWallets && !enableGameWalletAutoDetect && (
                                        <Buy {...defaultProps} messages={messages} setErrorLog={setErrorLog} />
                                    )}
                                    {!enableGameWallets && (
                                        <Buy {...defaultProps} messages={messages} setErrorLog={setErrorLog} />
                                    )}
                                </Route>
                                <Route path={`/${Routes.BuyV2}`} exact>
                                    {enableGameWallets && enableGameWalletAutoDetect && (
                                        <BuyCheck config={config} parent={parent} provider={provider}>
                                            <BuyV2 {...defaultProps} />
                                        </BuyCheck>
                                    )}
                                    {enableGameWallets && !enableGameWalletAutoDetect && <BuyV2 {...defaultProps} />}
                                    {!enableGameWallets && <BuyV2 {...defaultProps} />}
                                </Route>
                                <Route path={`/${Routes.Sell}`} exact>
                                    <Sell
                                        {...defaultProps}
                                        messages={messages}
                                        setErrorLog={setErrorLog}
                                        setLoading={setLoading}
                                    />
                                </Route>
                                <Route path={`/${Routes.Transfer}`} exact>
                                    <Transfer
                                        {...defaultProps}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.TransferV2}`} exact>
                                    <TransferV2 config={config.client} parent={parent} provider={provider} />
                                </Route>
                                <Route path={`/${Routes.History}`} exact>
                                    <History {...defaultProps} setErrorLog={setErrorLog} setLoading={setLoading} />
                                </Route>
                                <Route path={`/${Routes.Cancel}`} exact>
                                    <Cancel {...defaultProps} messages={messages} setErrorLog={setErrorLog} />
                                </Route>
                                <Route path={`/${Routes.FiatToCrypto}`}>
                                    <Onramp
                                        {...defaultProps}
                                        config={config}
                                        loading={loading}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.CryptoToFiat}`}>
                                    <Offramp
                                        {...defaultProps}
                                        config={config}
                                        loading={loading}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.Onramp}`}>
                                    <Onramp
                                        {...defaultProps}
                                        config={config}
                                        loading={loading}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.Offramp}`}>
                                    <Offramp
                                        {...defaultProps}
                                        config={config}
                                        loading={loading}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.NFTCheckoutPrimary}`}>
                                    <NFTCheckoutPrimary
                                        {...defaultProps}
                                        config={config}
                                        loading={loading}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>
                                <Route path={`/${Routes.NFTCheckoutSecondary}`}>
                                    <NFTCheckoutSecondary
                                        {...defaultProps}
                                        config={config}
                                        loading={loading}
                                        messages={messages}
                                        setLoading={setLoading}
                                        setErrorLog={setErrorLog}
                                    />
                                </Route>

                                <Route path={`/${Routes.GetPublicKey}`} exact>
                                    <GetPublicKey {...defaultProps} config={config} setErrorLog={setErrorLog} />
                                </Route>

                                {enableOfferRoutes && (
                                    <>
                                        <Route path={`/${Routes.MakeOffer}`} exact>
                                            <MakeOffer {...defaultProps} />
                                        </Route>
                                        <Route path={`/${Routes.CancelOffer}`} exact>
                                            <CancelOffer {...defaultProps} />
                                        </Route>
                                        <Route path={`/${Routes.AcceptOffer}`} exact>
                                            <AcceptOffer {...defaultProps} />
                                        </Route>
                                    </>
                                )}

                                {showRewardClaimFlow && (
                                    <>
                                        <Route path={`/${Routes.Claim}`} exact>
                                            <ClaimImxTokens {...defaultProps} setLoading={setLoading} />
                                        </Route>
                                        <Route path={`/${Routes.ClaimUSInstant}`} exact>
                                            <ClaimUSInstant {...defaultProps} setLoading={setLoading} />
                                        </Route>
                                        <Route path={`/${Routes.ClaimUSOverTime}`} exact>
                                            <ClaimUSOverTime {...defaultProps} setLoading={setLoading} />
                                        </Route>
                                        <Route path={`/${Routes.ClaimUSOverTimeDetails}`} exact>
                                            <ClaimUSOverTimeDetails {...defaultProps} setLoading={setLoading} />
                                        </Route>
                                    </>
                                )}

                                {/* @NOTE: never ever put anything after here, as the above bool /
                                fragment will "match" inside the switch, and thus nothing after it
                                will be possible to be matched via a regular route definition. */}
                            </Switch>
                        </LinkUiCoreContext.Provider>
                    </LinkCoreContext.Provider>
                </LaunchDarklyProvider>
            );
        }

        if (!loading && parent) {
            return (
                <LaunchDarklyProvider flags={flags}>
                    <Switch>
                        <Route path={InternalRoutes.WALLET_DIFFERENCE} exact>
                            <WalletDifference />
                        </Route>
                        <Route path={InternalRoutes.MISSING_WALLET} exact>
                            {showMissingWallet(providerPreference)}
                            {providerPreference === ProviderPreference.MAGIC_LINK && (
                                <SetupMagicWallet parent={parent} config={config} />
                            )}
                            {enableGameWallets && providerPreference && isGameWalletProvider(providerPreference) && (
                                <SetupGameWallet
                                    parent={parent}
                                    config={config}
                                    gameWalletProviderKey={providerPreference}
                                />
                            )}
                            {(providerPreference === ProviderPreference.NONE ||
                                (!enableGameWallets &&
                                    providerPreference &&
                                    isGameWalletProvider(providerPreference))) && <ProviderSelection config={config} />}
                        </Route>
                        <Route path={InternalRoutes.SETUP_HARDWARE_WALLET}>
                            <HardwareWalletStep />
                        </Route>

                        <Route path={InternalRoutes.MULTIPLE_WALLETS} exact>
                            <MultipleWalletsDetected parent={parent} />
                        </Route>
                    </Switch>
                </LaunchDarklyProvider>
            );
        }

        return null;
    };

    return (
        <FlexLayout className={styles.layout} flexDirection="column" backgroundColor={colors.bg[900]}>
            <FlexLayout
                className={styles.app}
                backgroundGradient={gradients.bg.simple('top')}
                flexDirection="column"
                flexGrow={1}
            >
                <AppHeaderBar showLoader={loading && !error} />
                {getBody()}
            </FlexLayout>
        </FlexLayout>
    );
};
