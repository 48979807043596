import { Icon, StyledLink } from '@imtbl/design-system';
import React from 'react';

import { location } from '../../utils/window';
import * as styles from './index.styles';
import { ProviderSelectionOptionProps } from './ProviderSelectionOption';
import { providerSelectionClickHandler } from './utils';

export const GameWalletProviderSelectionOption = ({
    providerPreference,
    testId,
    providerLogo,
    providerIcon,
    selection,
    text,
    enableAsyncSendAnalytics,
    linkUrlPrefix = '/setup?providerPreference=',
}: ProviderSelectionOptionProps) => {
    return (
        <StyledLink
            fontSize="small"
            fontWeight="bold"
            className={styles.ProviderButton}
            testId={testId}
            onClick={() =>
                providerSelectionClickHandler(
                    selection,
                    enableAsyncSendAnalytics,
                    location,
                    providerPreference,
                    linkUrlPrefix,
                )
            }
        >
            {providerLogo && (
                <img src={providerLogo} alt={`${text} logo`} className={styles.GameWalletProviderButton_logo} />
            )}
            {providerIcon && (
                <Icon ligature={providerIcon} iconSize="x-large" className={styles.GameWalletProviderButton_logo} />
            )}
            {text}
        </StyledLink>
    );
};
