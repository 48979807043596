import { ExchangeProvider, ImmutableExchangeTypeV3, LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import { parseUnits } from 'ethers/lib/utils';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useLaunchDarklyFlags } from '../../../context/LaunchDarkly';
import { useGetEthAddress } from '../../../hooks/useGetAddress.hook';
import { useL2Balance } from '../../../hooks/useL2Balance.hook';
import { TokenDataType, TokensListType } from '../../../hooks/useTokensList.hook';
import {
    asyncValidateAmount,
    closeWindow,
    createExchangeAndGetProviderUrl,
    createExchangeTransfer,
    ExchangeInfo,
    flatTokenToToken,
    getExchangeInfo,
    poll,
    ProviderWidget,
} from '../../../lib';
import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { ExchangeEventName } from '../../../lib/analytics/types';
import { createExchangeEvent } from '../../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../../lib/featureFlags';
import { StandardLinkRouteContainer } from '../../common';
import { InProgressScreen } from '../../common/InProgressScreen';
import { ResultScreen } from '../../common/ResultScreen';
import { tokenToFlatToken } from '../../Deposit/FlexibleDeposit/utils';
import { exchangeErrorMessage } from '../exchangeErrorMessage';
import { ExchangeProps } from '../Onramp';
import { showLayerswapExchangeIframe } from '../ProviderIframes/LayerSwapExchangeIframe';
import { showMoonpayExchangeIframe } from '../ProviderIframes/MoonPayExchangeIframe';
import { ExchangeCurrency } from '../types';

export type ProviderOfframpProps = ExchangeProps & {
    currency: string;
    amount: string;
    skipValidation?: boolean;
    availableTokens: TokensListType;
    availableCurrencies?: ExchangeCurrency[];
    providerName?: ExchangeProvider;
};

const symbolToToken = (symbol: string, tokens: TokensListType): TokenDataType =>
    tokens && tokens.find((t) => `${t.symbol.toLowerCase()}` === symbol.toLowerCase());

enum WithdrawStages {
    Provider,
    InProcess,
    Result,
}

export const ProviderOfframp = ({
    parent,
    loading,
    setLoading,
    config,
    provider,
    setErrorLog,
    messages,
    amount,
    currency,
    skipValidation,
    availableTokens,
    providerName = 'moonpay',
}: ProviderOfframpProps) => {
    const flags = useLaunchDarklyFlags();
    const history = useHistory();
    const enableOfframp = flags[FEATURE_FLAG.ENABLE_CRYPTO_TO_FIAT];

    const [stage, setStage] = useState<WithdrawStages>(WithdrawStages.Provider);
    const [providerUrl, setProviderUrl] = useState<string>();
    const [providerWalletAddress, setProviderWalletAddress] = useState<string | null>();
    const [exchangeId, setExchangeId] = useState<number>();
    const [exchangeStatus, setExchangeStatus] = useState<string | null>();
    const [exchangeAmount, setExchangeAmount] = useState<string>(amount);
    const [exchangeCurrency, setExchangeCurrency] = useState<string>(currency);

    const user = useGetEthAddress(provider);
    const token = symbolToToken(exchangeCurrency, availableTokens);
    const balance = useL2Balance(config.client, user, token?.token_address, token?.decimals);
    const onClose = closeWindow(parent);

    useEffect(() => {
        const validate = async () => {
            if (!balance) return;

            setLoading(true);

            const errors = await asyncValidateAmount(
                providerName,
                ImmutableExchangeTypeV3.offramp,
                config,
                new BigNumber(amount),
                currency,
                balance,
            );

            if (skipValidation || errors.length === 0) {
                const { exchangeId: id, providerIframeSrc } = await createExchangeAndGetProviderUrl({
                    config,
                    provider,
                    supportedCurrencies: [currency],
                    providerWidget: ProviderWidget.SELL,
                    providerName,
                    amount,
                });
                setExchangeId(id);
                setProviderUrl(providerIframeSrc);
                sendAnalytics(
                    createExchangeEvent(ExchangeEventName.offrampCreated, {
                        exchangeId: String(id),
                        path: history.location.pathname,
                    }),
                );
            } else {
                setErrorLog(new Error('Invalid currency amount'), messages.offramp.error.invalidCurrency);
            }

            setLoading(false);
        };

        if (enableOfframp) {
            setLoading(false);
            validate();
        }
    }, [enableOfframp, balance]);

    useEffect(() => {
        if (exchangeStatus === null) {
            sendAnalytics(
                createExchangeEvent(ExchangeEventName.offrampNetworkError, {
                    path: history.location.pathname,
                }),
            );

            setErrorLog(
                { message: messages.offramp.error.connectionError },
                exchangeErrorMessage(messages.offramp.title.error, messages.offramp.error.connectionError, () =>
                    parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*'),
                ),
            );
        }

        switch (exchangeStatus) {
            case 'waitingPayment':
                if (exchangeId && providerWalletAddress) {
                    const completeToken = flatTokenToToken(tokenToFlatToken(token));
                    setStage(WithdrawStages.InProcess);
                    const quantity = parseUnits(exchangeAmount, token?.decimals);
                    createExchangeTransfer(config, provider, exchangeId, completeToken, quantity, providerWalletAddress)
                        .then(() => {
                            sendAnalytics(
                                createExchangeEvent(ExchangeEventName.offrampCompleted, {
                                    exchangeId: String(exchangeId),
                                    path: history.location.pathname,
                                }),
                            );
                            parent.postMessage(
                                { type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: { exchangeId } },
                                '*',
                            );
                            setStage(WithdrawStages.Result);
                        })
                        .catch((err) => {
                            console.error(err);
                            sendAnalytics(
                                createExchangeEvent(ExchangeEventName.offrampFailed, {
                                    exchangeId: String(exchangeId),
                                    path: history.location.pathname,
                                }),
                            );
                            setErrorLog(
                                { message: messages.offramp.error.incorrectTransactionFormat },
                                exchangeErrorMessage(
                                    messages.onramp.title.error,
                                    messages.offramp.error.incorrectTransactionFormat,
                                    () =>
                                        parent.postMessage(
                                            { type: LINK_MESSAGE_TYPE, message: messageTypes.close },
                                            '*',
                                        ),
                                ),
                            );
                        });
                }
                break;
            case 'failed':
                sendAnalytics(
                    createExchangeEvent(ExchangeEventName.offrampFailed, {
                        exchangeId: String(exchangeId),
                        path: history.location.pathname,
                    }),
                );
                setErrorLog(
                    { message: messages.offramp.error.statusFail },
                    exchangeErrorMessage(messages.onramp.title.error, messages.offramp.error.statusFail, () =>
                        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*'),
                    ),
                );
                break;
            default:
                break;
        }
    }, [exchangeStatus, providerWalletAddress, exchangeAmount, exchangeCurrency]);

    useEffect(() => {
        // Polling
        if (exchangeId) {
            const setInfo = (info: ExchangeInfo | null) => {
                info?.providerWalletAddress && setProviderWalletAddress(info.providerWalletAddress);
                info?.currency && setExchangeCurrency(info.currency);
                info?.amount && setExchangeAmount(info.amount);
                setExchangeStatus(info?.status);
            };

            poll(
                async () => getExchangeInfo(config.client, exchangeId),
                (info): boolean => {
                    setInfo(info);

                    return !(
                        info === null ||
                        info?.status === 'failed' ||
                        (info?.status === 'waitingPayment' && info?.providerWalletAddress)
                    );
                },
                1500,
            ).then(setInfo);
        }
    }, [exchangeId]);

    if (enableOfframp === undefined || loading) {
        return null;
    }

    if (!enableOfframp) {
        setErrorLog(
            new Error(`${FEATURE_FLAG.ENABLE_CRYPTO_TO_FIAT} is disabled`),
            messages.offramp.error.featureNotYetSupported,
        );
        return null;
    }

    const iframesMapper: Record<string, (url: string) => React.ReactNode> = {
        moonpay: showMoonpayExchangeIframe,
        layerswap: showLayerswapExchangeIframe,
    };

    const stageElements = {
        [WithdrawStages.Provider]: providerUrl && iframesMapper[providerName](providerUrl),
        [WithdrawStages.InProcess]: (
            <StandardLinkRouteContainer>
                <InProgressScreen
                    heading={messages.offramp[providerName].inProgress.title}
                    paragraph={messages.offramp[providerName].inProgress.text.transfer}
                />
            </StandardLinkRouteContainer>
        ),
        [WithdrawStages.Result]: (
            <StandardLinkRouteContainer>
                <ResultScreen
                    heading={messages.offramp[providerName].result.title}
                    paragraph={messages.offramp[providerName].result.text.funds}
                    subParagraph={messages.offramp[providerName].result.text.check}
                    onFinish={onClose}
                />
            </StandardLinkRouteContainer>
        ),
    };

    return <>{stageElements[stage]}</>;
};
