import { Box, colors, Icon, StyledLink } from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
import React from 'react';

import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../lib/analytics/types';
import { createButtonEvent } from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { location } from '../../utils/window';
import { StyledReactRouterLink } from '../DsConnectorButtons';
import * as styles from './index.styles';
import { providerSelectionClickHandler } from './utils';

const WC_DEPRECATION_BLOG_POST = 'https://www.immutable.com/blog/walletconnect-v1-deprecation';

export interface ProviderSelectionOptionProps {
    providerPreference?: ProviderPreference;
    testId: string;
    providerLogo?: string;
    providerIcon?: string;
    selection: string;
    text: string;
    enableAsyncSendAnalytics?: boolean;
    linkUrlPrefix?: string;
}

export const ProviderSelectionOption = ({
    providerPreference,
    testId,
    providerLogo,
    providerIcon,
    selection,
    text,
    enableAsyncSendAnalytics,
    linkUrlPrefix = '/setup?providerPreference=',
}: ProviderSelectionOptionProps) => {
    return (
        <div>
            <StyledLink
                fontSize="small"
                fontWeight="bold"
                className={styles.ProviderButton}
                testId={testId}
                onClick={() =>
                    providerSelectionClickHandler(
                        selection,
                        enableAsyncSendAnalytics,
                        location,
                        providerPreference,
                        linkUrlPrefix,
                    )
                }
            >
                {(providerLogo || providerIcon) && (
                    <Box className={styles.ProviderButton_logo_background}>
                        {providerLogo && (
                            <img src={providerLogo} alt={`${text} logo`} className={styles.ProviderButton_logo} />
                        )}
                        {providerIcon && (
                            <Icon
                                ligature={providerIcon}
                                fillColor={colors.dark['500']}
                                iconSize="x-large"
                                className={styles.ProviderButton_logo}
                            />
                        )}
                    </Box>
                )}
                {text}
            </StyledLink>
        </div>
    );
};

type ProviderSelectionOptionReactRouterProps = Omit<
    ProviderSelectionOptionProps,
    'linkUrlPrefix' | 'enableAsyncAnalytics'
> & {
    to: string;
};

export const ProviderSelectionOptionReactRouter = ({
    testId,
    providerLogo,
    providerIcon,
    selection,
    text,
    to,
}: ProviderSelectionOptionReactRouterProps) => {
    return (
        <StyledReactRouterLink
            to={to}
            fontSize="small"
            fontWeight="bold"
            className={styles.ProviderButton}
            testId={testId}
            onClick={() => {
                sendAnalytics(createButtonEvent(ButtonEventName.providerSelectionPressed, { selection }));
            }}
        >
            <Box className={styles.ProviderButton_logo_background}>
                {providerLogo && <img src={providerLogo} alt={`${text} logo`} className={styles.ProviderButton_logo} />}
                {providerIcon && (
                    <Icon ligature={providerIcon} iconSize="x-large" className={styles.ProviderButton_logo} />
                )}
            </Box>
            {text}
        </StyledReactRouterLink>
    );
};
