import { Web3Provider } from '@ethersproject/providers';
import {
    colors,
    FlexLayout,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    TextPrettyInput,
    VerticalSpace,
} from '@imtbl/design-system';
import { AwaitingOrderStatus } from '@imtbl/imx-sdk';
import React, { useCallback, useState } from 'react';

import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { LinkClientConfigTS } from '../../../lib';
import useStoreUserEmail from '../../../lib/useStoreUserEmail';
import { verifyOTP } from '../../../lib/verifyOTP';
import { errorMessageMapper, OTPError } from './OTPError';

interface ICodeInputProps {
    config: LinkClientConfigTS;
    provider: Web3Provider;
    requestId?: string;
}

export const CodeInput = ({ config, provider, requestId }: ICodeInputProps) => {
    const [code, setCode] = useState('');
    const [showError, setShowError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>();

    const { email } = useStoreUserEmail();
    const textMessages = useI18nMessaging({});

    const isCodeValid = useCallback(() => {
        const otpRegex = new RegExp(/^(\d\s*){6}$/g);
        return otpRegex.test(code);
    }, [code]);

    const verify = async () => {
        if (config && provider) {
            try {
                setMessage('Verifying...');
                await verifyOTP(config, provider, { request_id: requestId, code });
                setMessage('In progress');
            } catch (_) {
                setShowError(true);
            }
        }
    };

    if (message && !showError) {
        return (
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {message}
            </ParagraphText>
        );
    }

    if (showError) {
        return <OTPError message={errorMessageMapper[AwaitingOrderStatus.MFATriggerFailed]} />;
    }

    return (
        <>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">{textMessages.buy.title.otp}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                We’ve sent a unique code to{' '}
                <SimpleText fontWeight="bold" fillColor={colors.white}>
                    {email || 'your email'}.
                </SimpleText>
                <br />
                Enter it below within 5 minutes to confirm your transaction.
            </ParagraphText>
            <VerticalSpace top="small" bottom="large">
                <FlexLayout flexDirection="column">
                    <TextPrettyInput labelText="Enter code" onChange={(value) => setCode(value)} />
                </FlexLayout>
            </VerticalSpace>
            <StandardButton testId="otp_verify" onClick={verify} buttonKind="ultimate-cta" disabled={!isCodeValid()}>
                Confirm
            </StandardButton>
        </>
    );
};
