import React from 'react';

interface AnimatedLoadingIconProps {
    fill?: string;
}

// This component was created to provide an animated loading icon as part of
// batch NFT transfer feature https://github.com/immutable/imx-link/pull/600
// @TODO: Incorporate this animated functionality into the @imtbl/design-system as part of <StandardButton />
export function AnimatedLoadingIcon({ fill = '#F6F6F6' }: AnimatedLoadingIconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid">
            <rect x="2" y="6" width="3" height="12" rx="2" fill={fill}>
                <animate
                    attributeName="y"
                    repeatCount="indefinite"
                    dur="1.3s"
                    calcMode="spline"
                    keyTimes="0;0.5;1"
                    values="4;6;6"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-0.29850746268656714s"
                />
                <animate
                    attributeName="height"
                    repeatCount="indefinite"
                    dur="1.3s"
                    calcMode="spline"
                    keyTimes="0;0.5;1"
                    values="20;12;12"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-0.29850746268656714s"
                />
            </rect>
            <rect x="11" y="6" width="3" height="12" rx="2" fill={fill}>
                <animate
                    attributeName="y"
                    repeatCount="indefinite"
                    dur="1.3s"
                    calcMode="spline"
                    keyTimes="0;0.5;1"
                    values="4;6;6"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-0.14925373134328357s"
                />
                <animate
                    attributeName="height"
                    repeatCount="indefinite"
                    dur="1.3s"
                    calcMode="spline"
                    keyTimes="0;0.5;1"
                    values="20;12;12"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-0.14925373134328357s"
                />
            </rect>
            <rect x="19" y="6" width="3" height="12" rx="2" fill={fill}>
                <animate
                    attributeName="y"
                    repeatCount="indefinite"
                    dur="1.3s"
                    calcMode="spline"
                    keyTimes="0;0.5;1"
                    values="4;6;6"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                />
                <animate
                    attributeName="height"
                    repeatCount="indefinite"
                    dur="1.3s"
                    calcMode="spline"
                    keyTimes="0;0.5;1"
                    values="20;12;12"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                />
            </rect>
        </svg>
    );
}
