import { ExchangeProvider, ImmutableExchangeTypeV3, ImmutableXClient } from '@imtbl/imx-sdk';
import { useContext, useEffect, useMemo, useState } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { LinkClientConfig } from '../../lib';
import { ExchangeCurrency } from './types';

export interface UseSupportedCurrenciesProp {
    config: LinkClientConfig;
    exchangeType: ImmutableExchangeTypeV3;
    provider: ExchangeProvider;
}

export interface UseSupportedCurrenciesResponse {
    currencies?: ExchangeCurrency[];
    error?: Error;
}

export function useSupportedCurrencies({
    config,
    exchangeType,
    provider,
}: UseSupportedCurrenciesProp): UseSupportedCurrenciesResponse {
    const textMessages = useI18nMessaging({});
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [apiResult, setApiResult] = useState<ExchangeCurrency[]>();
    const [apiError, setApiError] = useState<Error>();
    const { publicApiUrl } = config;

    useEffect(() => {
        async function getSupportedCurrencies() {
            try {
                const client = await ImmutableXClient.build({
                    publicApiUrl,
                });

                const currencies = await client.getCurrenciesV3(exchangeType, provider);
                if (currencies) {
                    setApiResult(currencies);
                } else {
                    setApiError(new Error('Could not get currencies.'));
                }
            } catch (err) {
                console.error('API error! Could not get currencies. :(', err);
                setApiError(new Error('Could not get currencies.'));
                setErrorLog(
                    err,
                    exchangeType === ImmutableExchangeTypeV3.offramp
                        ? textMessages.generalErrorMessage([textMessages.offramp.error.couldNotGetCurrencies])
                        : textMessages.onramp.text.couldNotGetCurrencies,
                );
            }
        }
        if (!apiResult) {
            getSupportedCurrencies();
        }
    }, [publicApiUrl, exchangeType]);

    const error = useMemo(() => apiError, [apiError?.message]);
    const currencies = useMemo(() => apiResult, [apiResult]);
    return useMemo(() => ({ currencies, error }), [currencies, error]);
}
