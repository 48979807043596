import {
    ERC20TokenType,
    FlatTokenWithAmountAndToAddress,
    ImmutableXClient,
    LinkParams,
    valueOrThrowTE,
} from '@imtbl/imx-sdk';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { getTokenWithDetails, LinkClientConfig } from '../lib';
import { TransferInfoWithTokenInfo } from '../types/SharedTransfer.types';
import { DEFAULT_DECIMALS } from '../utils/constants';
import { useI18nMessaging } from './useI18nMessaging.hook';
import { usePrevious } from './usePrevious.hook';
import { useTokensList } from './useTokensList.hook';

export const useGetTransferTokenDetails = ({
    client,
    params,
    config,
}: {
    client?: ImmutableXClient;
    params?: LinkParams.TransferV2;
    config: LinkClientConfig;
}) => {
    const [transferDetails, setTransferDetails] = useState<TransferInfoWithTokenInfo[]>();
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const { tokens: tokenList } = useTokensList({ config });

    const cachedProps = usePrevious({ client, params, tokenList });

    const hasTokenListUpdated = tokenList?.length !== cachedProps?.tokenList?.length;
    const hasTransferParamsUpdated = params !== cachedProps?.params;
    const shouldGetTokenTransferDetail = hasTransferParamsUpdated && hasTokenListUpdated;
    // @NOTE: For each order, get all the token details:
    useEffect(() => {
        async function getAllOrdersTokenInfo() {
            const textMessages = useI18nMessaging();
            if (!client || !params || !tokenList) {
                return;
            }
            if (!shouldGetTokenTransferDetail) {
                return;
            }

            try {
                const transferTokenDetailsResponse = await Promise.all(
                    [
                        ...params.map((token) => {
                            const result =
                                token.type === ERC20TokenType.ERC20
                                    ? tokenList.find((item) => item.token_address === token.tokenAddress)
                                    : undefined;
                            const decimals = result ? parseInt(result.decimals, 10) : DEFAULT_DECIMALS;

                            return [
                                valueOrThrowTE(
                                    getTokenWithDetails(token as FlatTokenWithAmountAndToAddress, decimals)(client),
                                ),
                            ];
                        }),
                    ].flat(),
                );
                const mergeResponseWithParams = transferTokenDetailsResponse.map((token, index) => ({
                    tokenDetails: {
                        ...params[index],
                        ...token,
                    },
                    originalParams: params[index],
                }));
                setTransferDetails(mergeResponseWithParams);
            } catch (err) {
                setErrorLog(
                    err,
                    textMessages.generalErrorMessage([textMessages.transfer.failedRetrievingTokenDetails]),
                );
            }
        }

        getAllOrdersTokenInfo();
    }, [params, client, setErrorLog, cachedProps, tokenList]);

    return transferDetails;
};
