import { ImmutableXClient, LinkParams } from '@imtbl/imx-sdk';
import { useEffect, useMemo, useState } from 'react';

import { LinkClientConfig } from '../lib';
import { useGetBatchNftTransferTokenDetails } from './useGetBatchNftTransferTokenDetails.hook';
import { useGetTransferTokenMetadata } from './useGetTransferTokenMetadata.hook';
import { useGetUserTokenBalances } from './useGetUserTokenBalances.hook';
import { useValidateBatchNftTransfers } from './useValidateBatchNftTransfers.hook';

export const useGetFullBatchNftTransferDetails = ({
    client,
    ethAddress,
    config,
    params,
}: {
    client?: ImmutableXClient;
    ethAddress?: string;
    config: LinkClientConfig;
    params: LinkParams.BatchNftTransfer;
}) => {
    const [loading, setLoading] = useState(true);
    const transferDetailsWithTokenDetails = useGetBatchNftTransferTokenDetails({ client, params });
    const transferDetailsWithMetadata = useGetTransferTokenMetadata({
        transferDetails: transferDetailsWithTokenDetails,
        config,
    });
    const tokens = useMemo(
        () => transferDetailsWithTokenDetails?.map((transfer) => ({ ...transfer.tokenDetails?.token })),
        [transferDetailsWithTokenDetails],
    );
    const userTokenBalances = useGetUserTokenBalances({
        tokens,
        client,
        ethAddress,
    });
    const transferDetailsWithMetadataAndUserBalances = useMemo(
        () =>
            transferDetailsWithMetadata?.map((transfer, index) => ({
                ...transfer,
                userBalance: userTokenBalances?.[index],
            })),
        [transferDetailsWithMetadata, userTokenBalances],
    );
    const validatedTransfers = useValidateBatchNftTransfers({
        transferDetails: transferDetailsWithMetadataAndUserBalances,
        ethAddress,
    });
    useEffect(() => {
        if (validatedTransfers) setLoading(false);
    }, [validatedTransfers]);

    return { loading, transferDetails: validatedTransfers };
};
