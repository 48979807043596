// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".i_11HzW3H2hlyB_6rTrA {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    height: calc(100vh - var(--appHeaderHeight));\n}\n\n.DipUJX7rluKVbs6lOPWR {\n    overflow: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/BatchNftTransfer/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,4CAA4C;AAChD;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".screenContainer {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    height: calc(100vh - var(--appHeaderHeight));\n}\n\n.scrollView {\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screenContainer": "i_11HzW3H2hlyB_6rTrA",
	"scrollView": "DipUJX7rluKVbs6lOPWR"
};
export default ___CSS_LOADER_EXPORT___;
