import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber';
import { colors, measurements, OuterSpace, ParagraphText } from '@imtbl/design-system';
import {
    assertEither,
    ERC721TokenType,
    errorsToError,
    ETHTokenType,
    FeeType,
    FlatTokenWithAmountCodec,
    ImmutableAssetStatus,
    ImmutableXClient,
    LinkError,
    LinkParamsCodecs,
    LinkParamsF,
    PositiveBigNumber,
    PositiveInteger,
    PositiveIntegerC,
    TokenCodec,
} from '@imtbl/imx-sdk';
import BigNumberJs from 'bignumber.js';
import { utils } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { Errors } from 'io-ts';
import React, { useCallback, useContext } from 'react';

import { LinkCoreContext } from '../../../context/App.context';
import {
    getAddress,
    getTokenWithDetails,
    NotifyMethod,
    sell,
    tokenToTokenWithDetails,
    TokenWithDetails,
} from '../../../lib';
import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../../lib/analytics/utils';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../../utils/constants';
import { getFeesFromSearchParams } from '../../../utils/getFeesFromSearchParams';
import { getProvidersConfig } from '../../../utils/getProvidersConfig';
import { Action } from '../../Action';
import { HelperMessageType } from '../../HelperMessageBox';
import { TokenDetailsDisplayRow } from '../../TokenDetailsDisplayRow';
import { SellProps } from '../index';

export type SellInput = {
    tokenSell: TokenWithDetails;
    tokenBuy: TokenWithDetails;
    expirationTimestamp: PositiveInteger | undefined;
};

export function formatV3ListingAmount(tokenBuyQuantity: EthersBigNumber, fees: FeeType[]) {
    const sellFeePercentage = fees
        .map((f) => f.percentage)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    // convert to bignumber.js to do the math
    const buyQuantityBigNumberJs = new BigNumberJs(tokenBuyQuantity.toString());
    const quantity = buyQuantityBigNumberJs
        .multipliedBy(100 - sellFeePercentage)
        .dividedBy(100)
        .integerValue();

    // convert back to @ethersproject/bignumber for prior formatting compatibility
    return formatEther(EthersBigNumber.from(quantity.toString()));
}

export const SellInEth = ({ config, parent, provider, messages, setErrorLog }: SellProps) => {
    const { config: linkConfig } = useContext(LinkCoreContext);
    const providerOptions = getProvidersConfig(linkConfig);
    const loadInput = (params: LinkParamsF.Sell) =>
        pipe(
            TE.bindTo('client')(ImmutableXClient.buildF({ publicApiUrl: config.publicApiUrl })),
            TE.bind('address', () => getAddress(provider)),
            TE.bind('tokenSell', ({ client }) =>
                pipe(
                    E.bindTo('token')(
                        TokenCodec.decode({
                            type: ERC721TokenType.ERC721,
                            data: {
                                tokenId: params.tokenId,
                                tokenAddress: params.tokenAddress,
                            },
                        }),
                    ),
                    E.bind('amount', () => PositiveBigNumber.decode(EthersBigNumber.from(1))),
                    E.mapLeft(errorsToError),
                    TE.fromEither,
                    TE.chain(({ token, amount }) => tokenToTokenWithDetails(client)(amount)(token)),
                ),
            ),
            TE.chainFirst(({ tokenSell, address }) =>
                pipe(
                    tokenSell.asset,
                    E.fromNullable(new Error(messages.sell.unableToRetrieveAssetDetails)),
                    E.chain((asset) =>
                        A.sequence(E.either)([
                            assertEither(asset.status !== ImmutableAssetStatus.imx, messages.assetNotDeposited),
                            assertEither(asset.user !== address, messages.userNotAssetOwner),
                        ]),
                    ),
                    TE.fromEither,
                ),
            ),
            TE.bind('tokenBuy', ({ client }) =>
                pipe(
                    FlatTokenWithAmountCodec.decode({
                        type: ETHTokenType.ETH,
                        amount: params.amount,
                    }),
                    E.mapLeft(errorsToError),
                    TE.fromEither,
                    TE.chain((token) => getTokenWithDetails(token)(client)),
                ),
            ),
            TE.chainFirst(({ tokenBuy }) =>
                pipe(
                    assertEither(tokenBuy.quantity.lt(utils.parseEther('0.000001')), messages.sell.minNum),
                    TE.fromEither,
                ),
            ),
            TE.bind('expirationTimestamp', () =>
                pipe(
                    params.expirationTimestamp
                        ? PositiveIntegerC.decode(parseInt(params.expirationTimestamp))
                        : E.of<Errors, PositiveInteger | undefined>(undefined),
                    E.mapLeft(errorsToError),
                    TE.fromEither,
                ),
            ),
            TE.chainFirst(() =>
                TE.fromIO(() => {
                    sendAnalytics(
                        createScreenEvent(ScreenEventName.listForSaleNotifiedOpened),
                        createFlowEvent(FlowEventName.listForSaleStarted),
                    );
                }),
            ),
            TE.map(({ tokenSell, tokenBuy, expirationTimestamp }) => ({
                tokenSell,
                tokenBuy,
                expirationTimestamp,
            })),
        );

    const showDetailsStart = ({ tokenSell, tokenBuy }: SellInput) => {
        const fees = getFeesFromSearchParams();
        const tokenDisplayAmount = formatV3ListingAmount(tokenBuy.quantity, fees);
        return (
            <>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Please confirm the assets to list for sale:
                </ParagraphText>
                <OuterSpace top={measurements.SpacingTeeShirtAmounts.large}>
                    <TokenDetailsDisplayRow
                        testId="sellTokenDetails"
                        tokenType={tokenSell?.token?.type}
                        asset={tokenSell?.asset}
                        transactionCostTokenImage={DEFAULT_ETH_TOKEN_IMAGERY}
                        tokenDisplayAmount={tokenDisplayAmount}
                    />
                </OuterSpace>
            </>
        );
    };

    const showDetailsDone = (_: SellInput) => {
        return (
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your item(s) have been listed for sale.
            </ParagraphText>
        );
    };

    const execute = (input: SellInput, notify: NotifyMethod) => {
        sendAnalytics(createButtonEvent(ButtonEventName.listForSaleNotifiedConfirmPressed));
        const fees = getFeesFromSearchParams();
        return pipe(
            sell(config, input, notify, fees, providerOptions),
            TE.bimap(
                (error) => {
                    return new LinkError(
                        messages.sell.failedAPIRequest.code,
                        messages.sell.failedAPIRequest.message(error.message),
                    );
                },
                (orderId) => orderId,
            ),
        );
    };

    const onFinish = useCallback(() => {
        sendAnalytics(createButtonEvent(ButtonEventName.listForSaleConfirmedFinishPressed));
    }, []);

    const onCancel = useCallback(() => {
        sendAnalytics(
            createButtonEvent(ButtonEventName.listForSaleNotifiedCancelPressed),
            createFlowEvent(FlowEventName.listForSaleFailed),
        );
    }, []);

    return (
        <Action
            parent={parent}
            title={messages.sell.title}
            paramsDecoder={LinkParamsCodecs.Sell.decode}
            loadInput={loadInput}
            execute={execute}
            helperMessageType={HelperMessageType.IS_CONFIRM}
            showDetails={{
                start: showDetailsStart,
                done: showDetailsDone,
            }}
            setErrorLog={setErrorLog}
            onFinishClick={onFinish}
            onDenyClick={onCancel}
        />
    );
};
