import { ImmutableMethodResults, ImmutableXClient } from '@imtbl/imx-sdk';
import { useEffect, useMemo, useState } from 'react';

// import offerMock from '../components/Offers/offerMock.json';
import { LinkClientConfig } from '../lib';

export interface UseGetBuyOrderResponse {
    order: ImmutableMethodResults.ImmutableGetOrderV3Result;
    error?: Error;
}

export interface UseGetBuyOrderPropTypes {
    config: LinkClientConfig;
    orderId: number;
}

export function useGetBuyOrder({ orderId, config }: UseGetBuyOrderPropTypes): UseGetBuyOrderResponse {
    const [apiResult, setApiResult] = useState<ImmutableMethodResults.ImmutableGetOrderV3Result>();
    const [apiError, setApiError] = useState<Error>();
    const { publicApiUrl } = config;

    useEffect(() => {
        async function getBuyOrder() {
            try {
                const client = await ImmutableXClient.build({
                    publicApiUrl,
                });

                const buyOrder = await client.getOrderV3({ orderId, include_fees: true });
                if (buyOrder) {
                    setApiResult(buyOrder);
                } else {
                    setApiError(new Error('Could not get buy order.'));
                }
            } catch (err) {
                console.error('API error! Could not get order.', err);
                setApiError(new Error('Could not get order.'));
            }
        }

        getBuyOrder();
    }, [publicApiUrl]);

    const error = useMemo(() => apiError, [apiError?.message]);
    const order = useMemo(() => apiResult, [apiResult]);
    return useMemo(() => ({ order: order as any, error }), [order, error]);
}
