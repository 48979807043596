import {
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import { ImmutableMethodResults, LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import React, { useCallback, useContext } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { useScrollToTop } from '../../hooks/useScrollToTop.hook';
import { ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName } from '../../lib/analytics/types';
import { createButtonEvent, createFlowEvent } from '../../lib/analytics/utils';
import { FullOrderInfo } from '../../types/SharedOrder.types';
import { CancelLink, StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { OrderSummaryListItem } from '../OrderSummaryListItem/OrderSummaryListItem.component';

export interface BuyPromptScreenPropTypes extends BaseComponentPropTypes {
    buyTokenInfo?: ImmutableMethodResults.ImmutableGetTokenResult;
    parent: ParentWindow;
    beginBuyTransaction: () => void;
    orderDetails?: FullOrderInfo[];
}

// @TODO: Alot of the below is a standard Link screen, so this should eventually be templatized in such a way
// that other components too, can use this exact same layout (albeit with their own actions etc)
export const BuyPromptScreen = ({ parent, beginBuyTransaction, testId, orderDetails }: BuyPromptScreenPropTypes) => {
    useScrollToTop();
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const textMessages = useI18nMessaging({});
    const onBuyDenyClick = useCallback(() => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
        sendAnalytics(
            createButtonEvent(ButtonEventName.buyNowNotifiedCancelPressed),
            createFlowEvent(FlowEventName.assetBuyNowFailed),
        );
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent, setErrorLog, textMessages]);

    return (
        <StandardLinkRouteContainer testId={`${testId}__buyPromptContainer`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">{textMessages.buy.title.start}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.buy.bodyText.start}
            </ParagraphText>

            <VerticalSpace top="large">
                <FlexLayout flexGrow={1} flexDirection="column">
                    {orderDetails?.map((order) => (
                        <OrderSummaryListItem
                            order={order}
                            testId={`${testId}__buyPromptContainer__orderSummary`}
                            key={order.details.order_id}
                        />
                    ))}
                </FlexLayout>
            </VerticalSpace>

            <StandardLinkRouteButtonsFooter>
                <StandardButton testId={`${testId}__beginCta`} buttonKind="ultimate-cta" onClick={beginBuyTransaction}>
                    {textMessages.standardUiButtons.confirm}
                </StandardButton>
                <CancelLink title="Cancel" onClick={onBuyDenyClick} />
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
