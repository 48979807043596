import { EthAddress, FeeType, valueOrThrow } from '@imtbl/imx-sdk';
import { parse } from 'query-string';

import { getLocationSearch, search } from './location';

function getListFromQueryString(paramName: string, searchStr: string = search): string[] {
    const parsedQuery = parse(searchStr);
    const result = parsedQuery[paramName] ?? [];

    if (typeof result === 'string') {
        return [result];
    }

    return result;
}

export const getFeesFromSearchParams = (searchStr = ''): FeeType[] => {
    const locationSearch = searchStr || getLocationSearch();

    const feeRecipients = getListFromQueryString('fee_recipients', locationSearch);
    const feePercentages = getListFromQueryString('fee_percentages', locationSearch);

    if (feeRecipients.length !== feePercentages.length) {
        throw new Error('There need to be the same number of fee recipients and fee percentages.');
    }

    const fees = feeRecipients.map((feeRecipient, i) => {
        try {
            const recipient = valueOrThrow(EthAddress.decode(feeRecipient));

            return {
                recipient,
                percentage: parseFloat(feePercentages[i]),
            };
        } catch {
            throw new Error(`Could not interpret ${feeRecipient} as an Ethereum address.`);
        }
    });

    return fees;
};
