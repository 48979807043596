import { Box, colors, FlexLayout, gradients, ImxLogoFullWordmarkAndSymbolLight } from '@imtbl/design-system';
import React from 'react';

import { LoadingUi } from '../LoadingUi/LoadingUi.component';
import styles from './index.module.css';

export function AppHeaderBar({ showLoader }: { showLoader?: boolean }) {
    return (
        <FlexLayout
            className={styles.header}
            justifyContent="center"
            backgroundColor={colors.bg[900]}
            alignItems="center"
        >
            <ImxLogoFullWordmarkAndSymbolLight />
            <Box className={styles.header__line} backgroundGradient={gradients.blue.simple('left')} />
            <LoadingUi showLoading={showLoader} />
        </FlexLayout>
    );
}
