import { css } from '@emotion/css';
import {
    colors,
    FlexLayout,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    StyledLink,
    Tickbox,
} from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import React, { useCallback, useContext, useState } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName } from '../../lib/analytics/types';
import { createButtonEvent, createFlowEvent } from '../../lib/analytics/utils';
import { claim, signOption } from '../../lib/claimImxTokens';
import { actionDeniedError } from '../Action';
import { BalanceItem } from '../BalanceItem';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { CLAIM_CONFIG } from './config';
import { Congrats } from './Congrats';
import { GENERIC_CLAIM_ERROR_SCREEN } from './constants';
import { useGetImxFinancialNumbers } from './hooks/useGetImxFinancialNumbers.hook';
import * as txt from './textProps';
import { ClaimProps } from './types';

const option = 'imx';

export function ClaimNonUS({ config, parent, provider, testId }: ClaimProps) {
    const textMessages = useI18nMessaging({});
    const { setErrorLog } = useContext(LinkUiCoreContext);

    const { publicApiUrl } = config;
    const [ctaDisabled, setCtaDisabled] = useState(true);
    const [isClaimed, setIsClaimed] = useState(false);
    const imxFinancialDetails = useGetImxFinancialNumbers({ config, provider, parent });

    const execute = async () => {
        const { ethSignature } = await signOption(config, provider, option);
        const res = await (await claim(CLAIM_CONFIG.ENDPOINT(publicApiUrl), option, ethSignature))();
        setIsClaimed(res);
        // error handling:
        // we catching actual error in the claim() to avoid clash with nrWrapper, that will crash otherwise.
        res || setErrorLog(FlowEventName.claimNonUSFailed, GENERIC_CLAIM_ERROR_SCREEN);
    };

    const onDenyClick = useCallback(() => {
        setErrorLog(actionDeniedError, textMessages.actionDenied);
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
        sendAnalytics(
            createButtonEvent(ButtonEventName.claimNonUSCancelPressed),
            createFlowEvent(FlowEventName.claimNonUSCancelled),
        );
    }, [parent, setErrorLog, textMessages]);

    return isClaimed ? (
        <Congrats parent={parent} />
    ) : (
        <StandardLinkRouteContainer testId={`${testId}__ClaimUSInstantContainer`}>
            <OuterSpace bottom={measurements.SpacingTeeShirtAmounts.small}>
                <SectionHeading testId="heading">{textMessages.claim.nonUS.title.start}</SectionHeading>
            </OuterSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.claim.nonUS.text.congrats}
                <StyledLink fontSize="small" href={CLAIM_CONFIG.PLAY_TO_EARN} anchorDomProps={{ target: '_new' }}>
                    {textMessages.claim.nonUS.text.playToEarn}
                </StyledLink>
                {textMessages.claim.nonUS.text.campaign}
            </ParagraphText>
            <OuterSpace
                top={measurements.SpacingTeeShirtAmounts.medium}
                bottom={measurements.SpacingTeeShirtAmounts.medium}
            >
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    {textMessages.claim.nonUS.text.your}
                    <SimpleText fontSize="small" fontWeight="bold">
                        {imxFinancialDetails.points} {Number(imxFinancialDetails.points) > 1 ? 'points' : 'point'}
                    </SimpleText>
                    {textMessages.claim.nonUS.text.accounted}
                    <SimpleText fontSize="small" fontWeight="bold">
                        {imxFinancialDetails.percent}%
                    </SimpleText>
                    {textMessages.claim.nonUS.text.allPoints}
                    <SimpleText fontSize="small" fontWeight="bold">
                        {imxFinancialDetails.imxTotal} IMX!
                    </SimpleText>
                </ParagraphText>
            </OuterSpace>
            {imxFinancialDetails.imxRetro !== '0' && (
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    <SimpleText fontSize="small" fontWeight="bold" fontStyle="italic" fillColor={colors.yellow[100]}>
                        {textMessages.claim.nonUS.text.note}
                    </SimpleText>
                    {textMessages.claim.nonUS.text.retro}
                    <SimpleText fontSize="small" fontWeight="bold">
                        {imxFinancialDetails.imxRetro} IMX
                    </SimpleText>
                    {textMessages.claim.nonUS.text.included}
                </ParagraphText>
            )}
            <FlexLayout flexDirection="column" flexGrow={1}>
                <OuterSpace
                    top={measurements.SpacingTeeShirtAmounts['x-large']}
                    bottom={measurements.SpacingTeeShirtAmounts['x-large']}
                >
                    <FlexLayout justifyContent="space-between">
                        <BalanceItem
                            amount={imxFinancialDetails.imxTotal}
                            platform="Immutable X token"
                            type="IMX"
                            imageUrl="/images/imx-token.png"
                        />
                        <ParagraphText {...txt.priceProps} textAlign="right">
                            +{imxFinancialDetails.imxTotal}
                        </ParagraphText>
                    </FlexLayout>
                </OuterSpace>
                <ParagraphText fillColor={colors.light[700]} fontStyle="italic" fontSize="small" textAlign="center">
                    {textMessages.claim.nonUS.text.comment}
                </ParagraphText>

                <OuterSpace bottom={measurements.SpacingTeeShirtAmounts.medium}>
                    {imxFinancialDetails.imxTotal !== '0' && (
                        <Tickbox
                            className={css`
                                margin-top: auto;
                            `}
                            labelText={
                                <SimpleText fillColor={colors.light[300]} fontSize="tag">
                                    {textMessages.claim.nonUS.text.agree}
                                    <StyledLink
                                        fillColor={colors.light[700]}
                                        fontWeight="bold"
                                        fontSize="tag"
                                        onClick={() => {
                                            window.open(CLAIM_CONFIG.TERMS, '_blank');
                                        }}
                                    >
                                        {textMessages.claim.nonUS.text.terms}
                                    </StyledLink>
                                </SimpleText>
                            }
                            onChange={() => setCtaDisabled(!ctaDisabled)}
                        />
                    )}
                </OuterSpace>
            </FlexLayout>

            <StandardLinkRouteButtonsFooter>
                <StandardButton
                    testId={`${testId}__beginCta`}
                    onClick={execute}
                    buttonKind="ultimate-cta"
                    disabled={ctaDisabled}
                >
                    {textMessages.standardUiButtons.confirm}
                </StandardButton>
                <StyledLink
                    testId={`${testId}__denyCta`}
                    fontSize="small"
                    fontWeight="bold"
                    fillColor={colors.red[300]}
                    onClick={onDenyClick}
                    className={css`
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    {textMessages.standardUiButtons.deny}
                </StyledLink>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
}
