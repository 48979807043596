import { css } from '@emotion/css';
import {
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    InlineEllipsizedTextLine,
    measurements,
    OuterSpace,
    SimpleText,
    SimpleTextPropTypes,
} from '@imtbl/design-system';
import { ERC721TokenType } from '@imtbl/imx-sdk';
import React from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { FullTransferInfoWithFinalStatus } from '../../types/SharedTransfer.types';
import { BurnEthAddress } from '../../utils/constants';
import { TokenDetailsDisplayRow } from '../TokenDetailsDisplayRow';

type ListOutTransfersPropTypes = BaseComponentPropTypes & {
    transfers?: FullTransferInfoWithFinalStatus[];
    displayErrorInformation?: boolean;
};

export const DISPLAY_AMOUNT_PROPS: SimpleTextPropTypes<any> = {
    fontSize: 'small',
    fillColor: colors.light[100],
    fontWeight: 'bold',
};

const ERROR_TEXT_PROPS = {
    fontSize: 'tag',
    fillColor: colors.red[300],
    className: css`
        align-self: flex-start;
    `,
} as SimpleTextPropTypes;

const TO_ADDRESS_TEXT_PROPS = {
    fontSize: 'tag',
    fillColor: colors.light[300],
    textAlign: 'right',
} as SimpleTextPropTypes;

export const TransferDetailsListing = ({ transfers, testId, displayErrorInformation }: ListOutTransfersPropTypes) => {
    const textMessages = useI18nMessaging();
    return (
        <FlexLayout flexGrow={1} flexDirection="column" testId={testId}>
            {transfers?.map(
                ({ tokenMetadata, tokenDetails, originalParams, validationResult, transferTransactionStatus }) => {
                    const transferTransactionStatusError = displayErrorInformation &&
                        transferTransactionStatus?.status === 'error' && (
                            <SimpleText testId={`${testId}__tokenDetails__validationInfo`} {...ERROR_TEXT_PROPS}>
                                <strong>&#9888; API Error:</strong> {transferTransactionStatus?.message}
                            </SimpleText>
                        );

                    const validationResultError = displayErrorInformation && validationResult.status === 'error' && (
                        <SimpleText testId={`${testId}__tokenDetails__validationInfo`} {...ERROR_TEXT_PROPS}>
                            <strong>&#9888; Validation Error:</strong> {validationResult.message}
                        </SimpleText>
                    );

                    const errorInformation = transferTransactionStatusError || validationResultError || null;

                    return (
                        <TokenDetailsDisplayRow
                            testId={`${testId}__tokenDetails`}
                            key={`${tokenDetails.token.type}-${tokenMetadata?.symbol}-${originalParams.toAddress}`}
                            tokenType={tokenDetails.token.type}
                            asset={tokenDetails?.asset}
                            tokenMetadata={tokenMetadata}
                            tokenDisplayAmount={
                                tokenDetails.token.type !== ERC721TokenType.ERC721 ? tokenDetails.amount : undefined
                            }
                            transactionCostTokenImage={tokenMetadata?.image_url || undefined}
                            additionalNoteText={
                                <>
                                    <SimpleText {...TO_ADDRESS_TEXT_PROPS}>
                                        To:{' '}
                                        <InlineEllipsizedTextLine
                                            {...TO_ADDRESS_TEXT_PROPS}
                                            testId={`${testId}__tokenDetails__toAddress`}
                                            text={originalParams.toAddress}
                                        />
                                    </SimpleText>

                                    {errorInformation}

                                    {originalParams.toAddress === BurnEthAddress && (
                                        <OuterSpace top={measurements.SpacingTeeShirtAmounts.small}>
                                            <SimpleText
                                                textAlign="right"
                                                fontSize="tag"
                                                fillColor={colors.yellow[100]}
                                                testId={`${testId}__tokenDetails__burnAddress`}
                                            >
                                                {textMessages.transfer.burn.burnBodyTextMessage1}
                                                <strong
                                                    data-testid={`${testId}__tokenDetails__burnAddress__burnTooltip`}
                                                    title={textMessages.transfer.burn.burnTooltip}
                                                >
                                                    {textMessages.transfer.burn.burnTextLabel}
                                                </strong>
                                                <br />
                                                {textMessages.transfer.burn.burnBodyTextMessage2}
                                            </SimpleText>
                                        </OuterSpace>
                                    )}
                                </>
                            }
                        />
                    );
                },
            )}
        </FlexLayout>
    );
};
