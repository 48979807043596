import { Web3Provider } from '@ethersproject/providers';
import { ExchangeProvider, ImmutableExchangeTypeV3, LINK_MESSAGE_TYPE, LinkParams, messageTypes } from '@imtbl/imx-sdk';
import { History, Location } from 'history';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useLaunchDarklyFlags } from '../../../context/LaunchDarkly';
import { Messages } from '../../../i18n';
import {
    closeWindow,
    ConnectionError,
    createExchangeAndGetProviderUrl,
    DispatchSetError,
    DispatchSetLoading,
    ExchangeStatus,
    getExchangeStatus,
    LinkConfig,
    ParentWindow,
    poll,
    ProviderWidget,
} from '../../../lib';
import { sendAnalytics, sendAnalyticsAsync } from '../../../lib/analytics/send-analytics';
import { ExchangeEventName } from '../../../lib/analytics/types';
import { createExchangeEvent } from '../../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../../lib/featureFlags';
import { getLocationSearch } from '../../../utils/location';
import { getValueAsArray } from '../../../utils/parser';
import { showLayerswapExchangeIframe } from '../ProviderIframes/LayerSwapExchangeIframe';
import { showMoonpayExchangeIframe } from '../ProviderIframes/MoonPayExchangeIframe';
import { useSupportedCurrencies } from '../useSupportedCurrencies.hook';
import { CompletedExchange } from './CompletedExchange';
import { showErrorDuringExchange } from './ErrorDuringExchange';
import { validateCurrency } from './utils';

export type ExchangeProps = {
    config: LinkConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    loading: boolean;
    setErrorLog: DispatchSetError;
    setLoading: DispatchSetLoading;
};

function goTo(location: Location, history: History, path: string) {
    if (location.pathname !== path) {
        history.push(path);
    }
}

export const Onramp = ({ parent, config, provider, setLoading, loading, setErrorLog, messages }: ExchangeProps) => {
    const exchangeParams = queryString.parse(getLocationSearch()) as unknown as LinkParams.Onramp;
    const providerName = exchangeParams.provider || 'moonpay';
    const isMoonpayProvider = providerName === 'moonpay';
    const isLayerswapProvider = providerName === 'layerswap';

    const { path: parentRoute } = useRouteMatch();
    const routePaths = {
        waiting: '/deposit-waiting',
        failed: '/deposit-failed',
        completed: '/deposit-completed',
        connectionError: '/connection-error',
    };
    const localRoutes = Object.entries(routePaths).reduce(
        (routes, [pathName, path]) => ({ ...routes, [pathName]: parentRoute + path }),
        {},
    ) as typeof routePaths;

    const flags = useLaunchDarklyFlags();
    const enableExchange = flags[FEATURE_FLAG.ENABLE_EXCHANGE];
    const { currencies: availableCurrencies, error: currenciesError } = useSupportedCurrencies({
        config: config.client,
        exchangeType: ImmutableExchangeTypeV3.onramp,
        provider: providerName as ExchangeProvider,
    });

    const [providerUrl, setProviderUrl] = useState<string>();
    const [exchangeId, setExchangeId] = useState<number>();
    const [exchangeStatus, setExchangeStatus] = useState<string | null>();
    const history = useHistory();
    const location = useLocation();
    const onClose = closeWindow(parent);

    function validateParams(currencies: string[]) {
        const valid = validateCurrency(currencies, availableCurrencies || [], providerName);
        if (!valid) {
            setErrorLog(
                new Error('Invalid cryptoCurrencies'),
                messages.generalErrorMessage([messages.onramp.text.invalidCurrencies]),
            );
        }
    }

    const handleExchangeStatusUpdate = useCallback(async () => {
        let redirectTo: string | undefined;

        if (exchangeStatus === null) {
            sendAnalytics(
                createExchangeEvent(ExchangeEventName.onrampNetworkError, { path: history.location.pathname }),
            );
            redirectTo = localRoutes.connectionError;
        }

        switch (exchangeStatus) {
            case 'received':
                redirectTo = parentRoute;
                break;
            case 'completed':
                await sendAnalyticsAsync(
                    createExchangeEvent(ExchangeEventName.onrampCompleted, {
                        exchangeId: String(exchangeId),
                        path: history.location.pathname,
                    }),
                );
                parent.postMessage(
                    { type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: { exchangeId } },
                    '*',
                );
                redirectTo = localRoutes.completed;
                break;
            case 'failed':
                await sendAnalyticsAsync(
                    createExchangeEvent(ExchangeEventName.onrampFailed, {
                        exchangeId: String(exchangeId),
                        path: history.location.pathname,
                    }),
                );
                redirectTo = localRoutes.failed;
                break;
            default:
                break;
        }

        if (redirectTo) {
            goTo(location, history, redirectTo);
        }
    }, [parent, exchangeId, exchangeId, localRoutes, exchangeStatus]);

    useEffect(() => {
        handleExchangeStatusUpdate();
    }, [exchangeStatus]);

    useEffect(() => {
        if (enableExchange && availableCurrencies) {
            setLoading(true);

            const currencies = getValueAsArray(exchangeParams, 'cryptoCurrencies') || [];
            validateParams(currencies);

            createExchangeAndGetProviderUrl({
                config,
                provider,
                supportedCurrencies: currencies,
                providerWidget: ProviderWidget.BUY,
                providerName,
            }).then(({ exchangeId: id, providerIframeSrc }) => {
                setExchangeId(id);
                setProviderUrl(providerIframeSrc);
                setLoading(false);
                sendAnalytics(
                    createExchangeEvent(ExchangeEventName.onrampCreated, {
                        exchangeId: String(id),
                        path: history.location.pathname,
                    }),
                );
            });
        }
    }, [enableExchange, availableCurrencies]);

    useEffect(() => {
        // Polling
        if (exchangeId) {
            poll<ExchangeStatus | ConnectionError>(
                async () => getExchangeStatus(config.client, exchangeId),
                (status): boolean => {
                    setExchangeStatus(status);
                    return status !== null && !['completed', 'failed'].includes(status);
                },
                1500,
            ).then((status) => setExchangeStatus(status));
        }
    }, [exchangeId]);

    // Render nothing while the feature flag or the exchange are being fetched
    if (enableExchange === undefined || loading || currenciesError) {
        return null;
    }

    // Error out when feature is disabled so the user understands what has happened
    if (!enableExchange) {
        setErrorLog(
            new Error(`${FEATURE_FLAG.ENABLE_EXCHANGE} is disabled`),
            messages.onramp.text.featureNotYetSupported,
        );
        return null;
    }

    return (
        <Switch>
            <Route path={parentRoute} exact>
                {isMoonpayProvider && showMoonpayExchangeIframe(providerUrl)}
                {isLayerswapProvider && showLayerswapExchangeIframe(providerUrl)}
            </Route>
            <Route path={localRoutes.completed} exact>
                {isMoonpayProvider && (
                    <CompletedExchange headingMessage={messages.onramp.title.done} onFinish={onClose} />
                )}
                {isLayerswapProvider && (
                    <CompletedExchange
                        headingMessage={messages.onramp.success.layerswap.title}
                        onFinish={onClose}
                        description={messages.onramp.success.layerswap.description}
                        buttonTitle={messages.onramp.success.layerswap.buttonTitle}
                    />
                )}
            </Route>
            <Route path={localRoutes.failed} exact>
                {isMoonpayProvider && showErrorDuringExchange(messages.onramp.title.error, messages.onramp.text.error)}
                {isLayerswapProvider &&
                    showErrorDuringExchange(
                        messages.onramp.error.layerswap.title,
                        messages.onramp.error.layerswap.description,
                    )}
            </Route>
            <Route path={localRoutes.connectionError} exact>
                {showErrorDuringExchange(messages.onramp.title.error, messages.onramp.text.connectionError)}
            </Route>
        </Switch>
    );
};
