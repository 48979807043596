import { Web3Provider } from '@ethersproject/providers';
import { colors, FlexLayout, SectionHeading, SimpleText, VerticalSpace } from '@imtbl/design-system';
import { ERC721TokenType, ImmutableXClient, ProviderPreference } from '@imtbl/imx-sdk';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';

import { LinkConfig, ParentWindow } from '../../lib';
import useProviderPreference from '../../lib/useProviderPreference';
import { getLocationSearch } from '../../utils/location';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { LoadingUi } from '../LoadingUi/LoadingUi.component';
import { getGameIconByPreference, getWalletIconByPreference } from '../ProviderSelection/utils';
import SetupGameWallet from '../SetupGameWallet/SetupGameWallet';
import WalletDifference from '../WalletDifference';
import * as styles from './BuyCheck.styles';

export interface GameWalletAutoDetectProps {
    config: LinkConfig;
    parent: ParentWindow;
    provider: Web3Provider;
}

type GameWalletDetails = {
    gameTitle: string;
    providerPreference: string;
    gameIconUrl: string;
};

export const BuyCheck: React.FC<GameWalletAutoDetectProps> = ({ config, parent, provider, children }) => {
    const {
        providerOptions: { gameWalletOptions },
    } = config;

    const [loading, setLoading] = useState(true);
    const [loadingGetOrder, setLoadingGetOrder] = useState(true);
    const [order, setOrder] = useState<any>();
    const [gameWalletDetected, setGameWalletDetected] = useState<GameWalletDetails>();
    const [autoDetectWalletSelected, setAutoDetectWalletSelected] = useState(false);
    const [proceedWithCurrentWallet, setProceedWithCurrentWallet] = useState(false);
    const [showWalletDifference, setShowWalletDifference] = useState(false);

    const urlParams = queryString.parse(getLocationSearch());
    const { providerPreference } = useProviderPreference();
    const { currentWalletIcon, currentWalletIconSize } = getWalletIconByPreference(
        providerPreference as ProviderPreference,
    );

    const gameIcon = useMemo(() => {
        if (!gameWalletDetected) {
            return undefined;
        }
        return getGameIconByPreference(
            gameWalletDetected.providerPreference as ProviderPreference,
            gameWalletDetected.gameIconUrl,
        );
    }, [gameWalletDetected]);

    const [walletAddress, setWalletAddress] = useState('');

    useEffect(() => {
        const getCurrentWalletAddress = async () => {
            const address = await provider.getSigner().getAddress();
            setWalletAddress(address.toLowerCase());
        };
        getCurrentWalletAddress();
    }, [provider]);

    useEffect(() => {
        const getOrderAsync = async () => {
            if (!order && loadingGetOrder && urlParams.orderIds !== null && parseInt(urlParams.orderIds as string)) {
                setLoadingGetOrder(false);
                const client = await ImmutableXClient.build({
                    ...config.client,
                });
                const result = await client.getOrderV3({ orderId: parseInt(urlParams.orderIds as string) });
                setOrder(result);
            }
        };
        getOrderAsync();
    }, [urlParams, setOrder, setLoadingGetOrder]);

    // find if the game matches anything from the games collections
    // -> -> else show normal buy screen
    // if it does -> check the currently connected wallet address exists and check saved providerPreference
    // if not correct game wallet then show the page
    // -> -> else if correct wallet already show normal buy screen

    useEffect(() => {
        const autoDetectGameWallet = () => {
            if (loadingGetOrder) return;
            if (gameWalletDetected) return;
            if (order && order?.sell?.type === ERC721TokenType.ERC721) {
                const orderContractAddress = order?.sell?.data?.token_address as string;
                if (!orderContractAddress) return;

                gameWalletOptions.forEach((gameWallet) => {
                    if (
                        ((gameWallet as any).collectionAddresses as string[])
                            .map((address) => address.toLowerCase())
                            .includes(orderContractAddress.toLowerCase())
                    ) {
                        setGameWalletDetected({
                            gameTitle: gameWallet.gameTitle,
                            providerPreference: gameWallet.providerPreference,
                            gameIconUrl: gameWallet.gameIconUrl,
                        });
                    }
                });
                setLoading(false);
            }
        };

        autoDetectGameWallet();
    }, [loadingGetOrder, order, setLoading]);

    const autoDetectProviderPreference = useMemo(() => {
        if (!gameWalletDetected) {
            return undefined;
        }
        return gameWalletDetected.providerPreference as ProviderPreference;
    }, [gameWalletDetected]);

    const handleAutoDetectWalletClick = () => {
        setAutoDetectWalletSelected(true);
    };

    const handleContinueWithCurrentWalletClick = () => {
        setProceedWithCurrentWallet(true);
    };

    const toggleShowWalletDifference = () => {
        setShowWalletDifference(!showWalletDifference);
    };

    if (loading) return <LoadingUi showLoading />;
    if (showWalletDifference) return <WalletDifference backButtonClick={toggleShowWalletDifference} />;
    if (proceedWithCurrentWallet) return <>{children}</>;
    if (gameWalletDetected && autoDetectProviderPreference !== providerPreference && !autoDetectWalletSelected)
        return (
            // Return the page to direct user to connect with a different wallet
            <StandardLinkRouteContainer testId="autoDetect__container">
                <VerticalSpace bottom="3x-large">
                    <SectionHeading>
                        Connect to your {gameWalletDetected?.gameTitle} wallet to access game items?
                    </SectionHeading>
                </VerticalSpace>
                <FlexLayout flexDirection="column" rowGap="10px">
                    <FlexLayout
                        role="button"
                        className={styles.GameWalletTile}
                        onClick={handleAutoDetectWalletClick}
                        testId="autoDetect__walletSuggestion"
                    >
                        {gameIcon && (
                            <img alt={gameWalletDetected?.gameTitle} src={gameIcon} className={styles.GameLogoIcon} />
                        )}
                        <SimpleText fontSize="small" fontWeight="bold" fillColor={colors.light[100]}>
                            Yes, connect {gameWalletDetected?.gameTitle}
                        </SimpleText>
                    </FlexLayout>
                    <FlexLayout
                        role="button"
                        className={styles.GameWalletTile}
                        onClick={handleContinueWithCurrentWalletClick}
                        testId="autoDetect__continueWithCurrentWallet"
                    >
                        <FlexLayout className={styles.CurrentWalletIcon}>
                            <img
                                alt="new wallet"
                                src={currentWalletIcon}
                                height={currentWalletIconSize}
                                width={currentWalletIconSize}
                            />
                        </FlexLayout>
                        <FlexLayout flexDirection="column">
                            <SimpleText fontSize="small" fontWeight="bold" fillColor={colors.light[100]}>
                                No, proceed with current wallet
                            </SimpleText>
                            <SimpleText fontSize="tag" fontWeight="bold" fillColor={colors.light[300]}>
                                {`${walletAddress.substring(0, 6)}...${walletAddress.substring(36)}`}
                            </SimpleText>
                        </FlexLayout>
                    </FlexLayout>
                </FlexLayout>
                <StandardLinkRouteButtonsFooter>
                    <FlexLayout className={styles.WalletDifference}>
                        <SimpleText
                            fontSize="small"
                            fontWeight="bold"
                            onClick={toggleShowWalletDifference}
                            className={styles.WalletDifference_text}
                        >
                            What&apos;s the difference?
                        </SimpleText>
                    </FlexLayout>
                </StandardLinkRouteButtonsFooter>
            </StandardLinkRouteContainer>
        );

    if (autoDetectProviderPreference && autoDetectWalletSelected)
        return (
            <SetupGameWallet
                parent={parent}
                config={config}
                gameWalletProviderKey={autoDetectProviderPreference as string}
                nextRoute={window.location.href}
            />
        );

    return <>{children}</>;
};
