import { colors, ParagraphText, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import React from 'react';

import { useScrollToTop } from '../../../../hooks/useScrollToTop.hook';

export const SignInScreen = () => {
    useScrollToTop();
    return (
        <>
            <VerticalSpace bottom="small" top="x-large">
                <SectionHeading testId="heading">Sign the transaction</SectionHeading>
            </VerticalSpace>

            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                A transaction for your deposit has been sent to your Ethereum wallet.
            </ParagraphText>

            <VerticalSpace top="large" bottom="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    <strong style={{ color: colors.yellow[300] }}>Sign the transaction</strong> to complete the deposit.
                </ParagraphText>
            </VerticalSpace>
        </>
    );
};
