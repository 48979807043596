import { Web3Provider } from '@ethersproject/providers';
import {
    colors,
    FlexLayout,
    InlineEllipsizedWalletLine,
    layoutHelpers,
    ParagraphText,
    VerticalSpace,
} from '@imtbl/design-system';
import {
    assertEither,
    assertNever,
    ERC20TokenType,
    ERC721TokenType,
    EthAddress,
    ETHTokenType,
    ImmutableAssetStatus,
    ImmutableXClient,
    LinkError,
    LinkParamsCodecs,
    LinkParamsF,
    MintableERC20TokenType,
    MintableERC721TokenType,
} from '@imtbl/imx-sdk';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import React, { useContext, useEffect } from 'react';

import { LinkCoreContext } from '../../context/App.context';
import { findTokenWithAddress, useTokensList } from '../../hooks/useTokensList.hook';
import { Messages } from '../../i18n';
import {
    checkSufficientTokenBalance,
    DispatchSetError,
    DispatchSetLoading,
    getAddress,
    getBalanceForTokenType,
    getTokenWithDetails,
    LinkClientConfig,
    NotifyMethod,
    ParentWindow,
    TokenWithDetails,
    transfer,
} from '../../lib';
import { getProvidersConfig } from '../../utils/getProvidersConfig';
import { TokenDetails } from '..';
import { Action } from '../Action';
import FullWidthBorderLine from '../FullWidthBorderLine';

export type TransferProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    setErrorLog: DispatchSetError;
    setLoading: DispatchSetLoading;
};

export type TransferInput = {
    token: TokenWithDetails;
    to: EthAddress;
};

export const Transfer = ({ config, parent, provider, messages, setErrorLog, setLoading }: TransferProps) => {
    const { config: linkConfig } = useContext(LinkCoreContext);
    const providerOptions = getProvidersConfig(linkConfig);
    const { tokens, error } = useTokensList({ config });
    const hasTokensList = tokens.length > 0;

    const getTokenInfo = (address: EthAddress) => {
        return findTokenWithAddress(tokens, address);
    };

    useEffect(() => {
        if (!hasTokensList) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [hasTokensList, setLoading]);

    useEffect(() => {
        if (error) {
            setErrorLog(error);
            setLoading(false);
        }
    }, [error, setErrorLog, setLoading]);

    const loadInput = (params: LinkParamsF.Transfer) =>
        pipe(
            // @NOTE: factory function, we stil need to do this
            // const client = await ImmutableXClient.build({ publicApiUrl: config.publicApiUrl });
            TE.bindTo('client')(ImmutableXClient.buildF({ publicApiUrl: config.publicApiUrl })),

            // @NOTE: this gets the users eth address from MM
            // const address = await valueOrThrowTE(getAddress(provider));
            TE.bind('address', () => getAddress(provider)),

            // @NOTE: this gets token with decimal details
            TE.bind('tokenWithDecimals', ({ client }) =>
                client.getTokenF(params.type === ERC20TokenType.ERC20 ? { tokenAddress: params.tokenAddress } : {}),
            ),
            TE.bind('token', ({ client, tokenWithDecimals }) =>
                getTokenWithDetails(params, parseInt(tokenWithDecimals.decimals))(client),
            ),

            // @NOTE: we get the user's balance here, so that we can check they have enough funds...
            // const balance = await valueOrThrowTE(getBalanceForTokenType(tokenBuy.token, client, address));
            TE.bind('balance', ({ client, address, token }) => getBalanceForTokenType(token.token, client, address)),

            // @NOTE: validation of the purposed operation:
            TE.chainFirst(({ token, address, balance }) => {
                switch (token.token.type) {
                    case ERC721TokenType.ERC721:
                        return pipe(
                            // @NOTE: These 2 lines just throw when token.asset is null
                            token.asset,
                            E.fromNullable(new Error(messages.loadAssetError)),
                            // @NOTE: just doing a bunch of if checks
                            // if (asset.user !== address) throw new Error(messages.userNotAssetOwner);
                            E.chain((asset) =>
                                A.sequence(E.either)([
                                    assertEither(asset.user !== address, messages.userNotAssetOwner),
                                    assertEither(asset.user === params.to, messages.transfer.ownAsset),
                                    assertEither(asset.status !== ImmutableAssetStatus.imx, messages.assetNotDeposited),
                                ]),
                            ),
                            TE.fromEither,
                        );
                    case ETHTokenType.ETH:
                        // case ERC20TokenType.ERC20:
                        // @NOTE: check the balance is enough:
                        // const sufficientTokenBalance = await valueOrThrowTE(checkSufficientTokenBalance(
                        //     token.quantity,
                        //     balance.balance,
                        //     messages.insufficientEthFunds, // the only thing thats different
                        // ))
                        return checkSufficientTokenBalance(
                            token.quantity,
                            balance.balance,
                            messages.insufficientEthFunds,
                        );
                    case ERC20TokenType.ERC20:
                        // case ERC20TokenType.ERC20:
                        // @NOTE: check the balance is enough:
                        // const sufficientTokenBalance = await valueOrThrowTE(checkSufficientTokenBalance(
                        //     token.quantity,
                        //     balance.balance,
                        //     messages.insufficientERC20Funds, // the only thing thats different
                        // ))
                        return checkSufficientTokenBalance(
                            token.quantity,
                            balance.balance,
                            messages.insufficientERC20Funds(token.token.data.symbol),
                        );
                    case MintableERC20TokenType.MINTABLE_ERC20:
                        // @NOTE: error state
                        return TE.left(new Error('Not implemented'));
                    case MintableERC721TokenType.MINTABLE_ERC721:
                        // @NOTE: error state
                        return TE.left(new Error('Not implemented'));
                    default:
                        // @NOTE: so this line ensures that if TS ever gets to this line, something is wrong.
                        // forces eng to handle all cases
                        return assertNever(token.token);
                }
            }),
            TE.bind('to', (_) => TE.of(params.to)),
        );

    const execute = (input: TransferInput, notify: NotifyMethod) => {
        return pipe(
            transfer(config, input, notify, providerOptions),
            TE.mapLeft((err) => {
                return new LinkError(
                    messages.transfer.failedAPIRequest.code,
                    messages.transfer.failedAPIRequest.message(err.message),
                );
            }),
        );
    };

    const showDetailsStart = ({ token: details, to }: TransferInput) => {
        let tokenSymbol: string | undefined;
        let tokenImageUrl: string | null;

        switch (details.token.type) {
            case ERC20TokenType.ERC20: {
                const { symbol = '', tokenAddress } = details.token.data;
                const tokenInfo = getTokenInfo(tokenAddress);
                tokenImageUrl = tokenInfo?.image_url || null;
                tokenSymbol = tokenInfo?.symbol || symbol;
                break;
            }

            default: {
                tokenSymbol = undefined;
                tokenImageUrl = null;
            }
        }

        return (
            <>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Please confirm the assets to transfer:
                </ParagraphText>
                <VerticalSpace top="large">
                    <TokenDetails details={details} symbol={tokenSymbol} imageUrl={tokenImageUrl} />
                </VerticalSpace>
                <FlexLayout alignItems="stretch" justifyContent="flex-end" flexDirection="column" flexGrow={1}>
                    <FlexLayout
                        justifyContent="center"
                        paddingTop={layoutHelpers.gridUnits(3)}
                        paddingBottom={layoutHelpers.gridUnits(3)}
                    >
                        <FullWidthBorderLine />
                        <ParagraphText fontSize="tag" fillColor={colors.light[300]}>
                            Assets will be sent to{' '}
                            <InlineEllipsizedWalletLine
                                fillColor={colors.blue[300]}
                                fontSize="tag"
                                walletAddress={to}
                                fontWeight="bold"
                            />
                        </ParagraphText>
                    </FlexLayout>
                </FlexLayout>
            </>
        );
    };

    const showDetailsDone = (_: TransferInput) => {
        return (
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your transfer has been completed.
            </ParagraphText>
        );
    };

    if (!hasTokensList) {
        return null;
    }

    return (
        <Action
            type="transfer"
            parent={parent}
            title={messages.transfer.title}
            paramsDecoder={LinkParamsCodecs.Transfer.decode}
            loadInput={loadInput}
            execute={execute}
            showDetails={{
                start: showDetailsStart,
                done: showDetailsDone,
            }}
            setErrorLog={setErrorLog}
        />
    );
};
