import { colors, FontStyle, SimpleTextSizes, StandardFontWeight } from '@imtbl/design-system';

export const defaultTextProps = {
    fontSize: 'small' as SimpleTextSizes,
    fillColor: colors.light[300],
};

export const italicTextProps = {
    ...defaultTextProps,
    fontStyle: 'italic' as FontStyle,
};

export const bottomTextProps = {
    margin: '20px',
};

export const highlightTextProps = {
    ...defaultTextProps,
    fontWeight: 'bold' as StandardFontWeight,
    fillColor: colors.light[100],
};

export const priceProps = {
    ...defaultTextProps,
    fontWeight: 'bold' as StandardFontWeight,
    fillColor: colors.blue[100],
};

export const highlightNoteProps = {
    ...highlightTextProps,
    fontWeight: 'bold' as StandardFontWeight,
    fillColor: colors.yellow[100],
    fontStyle: 'italic' as FontStyle,
};

export const highlightLinkProps = {
    ...highlightTextProps,
    fillColor: colors.light[700],
};
