import { LINK_ORIGIN_STORAGE_KEY } from '../useStoreLinkOrigin';
import { AkumaAnalytics, AnalyticsEventOptions } from './akuma-analytics';
import { trackSegmentEvent } from './segment';
import { AnalyticsEvent, AnalyticsEventProperties } from './types';

// NOTE: 'appEnvironment' variables are
// set by tools running the 'e2e' tests.
type E2ETestEnv = { NODE_ENV?: string };

type CustomWindow = Window & {
    appEnvironment?: E2ETestEnv;
};

export class AnalyticsClient {
    akumaStream: AkumaAnalytics;

    canPublishEvents: boolean;

    googleAnalyticsStream: any;

    constructor(config?: { version: string }) {
        const { appEnvironment } = window as CustomWindow;
        this.akumaStream = new AkumaAnalytics(config?.version);
        this.canPublishEvents = !this.isTestEnv(appEnvironment);
    }

    isTestEnv(e2eTestEnv?: E2ETestEnv) {
        const isAppTest = process.env.NODE_ENV === 'test';
        const isE2ETest = e2eTestEnv?.NODE_ENV === 'test';
        return isE2ETest || isAppTest;
    }

    publishEvent(analyticsEvent: AnalyticsEvent, options: AnalyticsEventOptions = {}) {
        if (this.canPublishEvents) {
            const linkOrigin = window.localStorage.getItem(LINK_ORIGIN_STORAGE_KEY);
            const eventProperties: AnalyticsEventProperties | undefined = linkOrigin
                ? { ...analyticsEvent.eventProperties, origin: linkOrigin }
                : analyticsEvent.eventProperties;
            const eventWithOrigin = {
                ...analyticsEvent,
                eventProperties,
            };

            trackSegmentEvent(analyticsEvent, eventProperties);
            gtag('event', analyticsEvent.eventName, eventProperties);
            return this.akumaStream.publish(eventWithOrigin, options);
        }

        return this;
    }
}
