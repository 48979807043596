import { FeeType, getProvider, ImmutableXClient } from '@imtbl/imx-sdk';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as t from 'io-ts';

import { SellInput } from '../components/Sell/SellInEth';
import { ProvidersConfig } from '../utils/getProvidersConfig';
import { NotifyMethod } from '.';
import { sendAnalytics } from './analytics/send-analytics';
import { FlowEventName, ScreenEventName } from './analytics/types';
import { createFlowEvent, createScreenEvent } from './analytics/utils';
import { LinkClientConfig } from './types';

export function sell(
    config: LinkClientConfig,
    { tokenSell, tokenBuy, expirationTimestamp }: SellInput,
    notify: NotifyMethod,
    fees: FeeType[],
    providerOptions: ProvidersConfig,
): TE.TaskEither<Error, t.Int> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.chain(() => TE.bindTo('provider')(getProvider(providerOptions))),
        TE.bind('client', ({ provider }) =>
            ImmutableXClient.buildF({
                ...config,
                signer: provider.getSigner(),
            }),
        ),
        TE.chainFirst(() => TE.fromIO(() => notify({ msg: 'Creating order' }))),
        TE.bind('order', ({ client, client: { address } }) =>
            client.createOrderV3F({
                user: address,
                tokenSell: tokenSell.token,
                amountSell: tokenSell.quantity,
                tokenBuy: tokenBuy.token,
                amountBuy: tokenBuy.quantity,
                fees,
                // Split fees = true: forces fee calculation to be split in the "v3" way.
                // https://immutable.atlassian.net/wiki/spaces/TRAD/pages/2101051579/DACI+-+GetSignableDetails+v3+public-api+changes
                splitFees: true,
                expiration_timestamp: expirationTimestamp,
            }),
        ),
        TE.chainFirst(() =>
            TE.fromIO(() => {
                sendAnalytics(
                    createScreenEvent(ScreenEventName.listForSaleConfirmedOpened),
                    createFlowEvent(FlowEventName.listForSaleSucceeded),
                );
            }),
        ),
        TE.map(({ order }) => order.order_id),
    );
}
