import { colors, FlexLayout, layoutHelpers, ParagraphText, StyledLink } from '@imtbl/design-system';
import React from 'react';

import FullWidthBorderLine from '../FullWidthBorderLine';

type AccountActivationFeeProps = {
    action: 'Withdrawal' | 'Deposit';
    requiresSeparateTransaction?: boolean;
};

export const AccountActivationFee = ({ requiresSeparateTransaction, action }: AccountActivationFeeProps) => {
    const tooltipTitle =
        'The first time a wallet moves funds or tokens in or out of Immutable X, the account will also be registered on the Ethereum blockchain. This requires an additional transaction, which will only occur this one time.';

    return requiresSeparateTransaction ? (
        <>
            <FlexLayout
                justifyContent="center"
                paddingTop={layoutHelpers.gridUnits(3)}
                paddingBottom={layoutHelpers.gridUnits(3)}
                title={tooltipTitle}
            >
                <FullWidthBorderLine />
                <ParagraphText fontSize="tag" fillColor={colors.light[100]}>
                    {action} will include{' '}
                    <StyledLink fillColor={colors.light[700]} fontWeight="bold" fontSize="tag" underline>
                        one-time account activation
                    </StyledLink>
                </ParagraphText>
            </FlexLayout>
            <FlexLayout justifyContent="center">
                <ParagraphText fontSize="tag" fillColor={colors.light[100]}>
                    You will see two transactions to sign in your wallet.
                </ParagraphText>
            </FlexLayout>
        </>
    ) : (
        <FlexLayout
            justifyContent="center"
            paddingTop={layoutHelpers.gridUnits(3)}
            paddingBottom={layoutHelpers.gridUnits(3)}
            title={tooltipTitle}
        >
            <FullWidthBorderLine />

            <ParagraphText fontSize="tag" fillColor={colors.light[100]}>
                Gas fee will include{' '}
                <StyledLink fillColor={colors.light[700]} fontWeight="bold" fontSize="tag" underline>
                    one-time account activation
                </StyledLink>
            </ParagraphText>
        </FlexLayout>
    );
};
