import { ProviderPreference } from '@imtbl/imx-sdk';

import arrowRight from '../../assets/images/providers/arrow-right.svg';
import { sendAnalytics, sendAnalyticsAsync } from '../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../lib/analytics/types';
import { createButtonEvent } from '../../lib/analytics/utils';
import { getProviderData } from '../../lib/providers';
import newGanymedeLogo from '../../assets/images/providers/new-ganymede.svg';

const linkSetupProviderSelectionUrlPrefix = '/setup?providerPreference=';

export const providerSelectionClickHandler = async (
    selection: string,
    enableAsyncSendAnalytics: boolean | undefined,
    location: Location,
    providerPreference: ProviderPreference | string | undefined,
    linkUrlPrefix = linkSetupProviderSelectionUrlPrefix,
) => {
    if (enableAsyncSendAnalytics) {
        await sendAnalyticsAsync(createButtonEvent(ButtonEventName.providerSelectionPressed, { selection }));
    } else {
        sendAnalytics(createButtonEvent(ButtonEventName.providerSelectionPressed, { selection }));
    }
    location.assign(`${linkUrlPrefix}${providerPreference || ''}`);
};

interface WalletIconDetails {
    currentWalletIcon: string;
    currentWalletIconSize: number;
}

export const getWalletIconByPreference = (providerPreference: ProviderPreference): WalletIconDetails => {
    const defaultPreference = { iconUri: arrowRight, iconSize: 16 };
    const preference = getProviderData(providerPreference);
    if (preference === ProviderPreference.NONE) {
        return {
            currentWalletIcon: defaultPreference.iconUri,
            currentWalletIconSize: defaultPreference.iconSize,
        };
    }
    return {
        currentWalletIcon: preference.iconUri,
        currentWalletIconSize: preference.iconSize,
    };
};

export const getGameIconByPreference = (providerPreference: ProviderPreference, gameIconUrl: string) => {
    const preference = getProviderData(providerPreference);
    if (preference === ProviderPreference.NONE || preference.iconUri === '') {
        return gameIconUrl;
    }
    if (preference.name === ProviderPreference.NEW_GANYMEDE) {
        return newGanymedeLogo;
    }
    return preference.iconUri;
};
