import { css } from '@emotion/css';
import { BaseComponentPropTypes, colors, gradients, HorizontalLoader } from '@imtbl/design-system';
import React from 'react';

export interface LoadingUiPropTypes extends BaseComponentPropTypes {
    showLoading?: boolean;
}

export const LoadingUi = ({ showLoading, testId }: LoadingUiPropTypes) =>
    showLoading ? (
        <HorizontalLoader
            className={css`
                position: absolute;
                left: 0;
                top: var(--appHeaderHeight);
                z-index: var(--appHeaderZIndex);
            `}
            lineFillGradient={gradients.blue.simple('left')}
            backgroundColor={colors.dark[300]}
            testId={testId}
        />
    ) : null;
