// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B356mwut_G3jPl16mCy3 {\n    overflow: hidden;\n    max-width: 150px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/components/BalanceERC20/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".truncate {\n    overflow: hidden;\n    max-width: 150px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"truncate": "B356mwut_G3jPl16mCy3"
};
export default ___CSS_LOADER_EXPORT___;
