import { ImmutableMethodResults, ImmutableXClient, TokenTS } from '@imtbl/imx-sdk';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { getBalanceForTokenTypeTS } from '../lib';
import { useI18nMessaging } from './useI18nMessaging.hook';

export const useGetUserTokenBalances = ({
    tokens = [],
    client,
    ethAddress,
}: {
    tokens?: TokenTS[];
    client?: ImmutableXClient;
    ethAddress?: string;
}) => {
    const [tokenBalances, setTokenBalances] = useState<ImmutableMethodResults.ImmutableGetBalanceResult[]>([]);
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const textMessaging = useI18nMessaging();

    // @NOTE: For each order, get the user's balance amount
    // (this can vary depending on the token (eg erc20 vs erc721));
    useEffect(() => {
        async function getAllOrdersBalanceInfo() {
            if (!!client && !!ethAddress && tokens.length) {
                // @NOTE: Allows me to tell the user which token failed
                let currToken: any;
                try {
                    const tokenBalancesResponse = await Promise.all(
                        tokens.map((token) => {
                            currToken = token;
                            return getBalanceForTokenTypeTS(token, client, ethAddress);
                        }),
                    );

                    // @NOTE: by now, none of the fields should be empty, and `completeOrdersIno`
                    // is be a complete collection of ordersInfo
                    setTokenBalances(tokenBalancesResponse);
                } catch (err) {
                    setErrorLog(
                        new Error('Could not fetch token balance.'),
                        textMessaging.generalErrorMessage([
                            textMessaging.transfer.failedRetrievingTokenBalance(currToken.data.symbol),
                        ]),
                    );
                }
            }
        }

        getAllOrdersBalanceInfo();
    }, [tokens, ethAddress, textMessaging, setErrorLog, client]);

    return tokenBalances;
};
