import {
    Box,
    colors,
    layoutHelpers,
    ParagraphText,
    SectionHeading,
    StandardButton,
    StyledLink,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../../common/StandardLinkUiContainers';

export function showErrorDuringExchange(headingMessage: string, errorMessage: string) {
    return (
        <StandardLinkRouteContainer>
            <VerticalSpace bottom="small">
                <SectionHeading fillColor={colors.red[300]}>{headingMessage}</SectionHeading>
            </VerticalSpace>
            <Box paddingBottom={layoutHelpers.gridUnits(6)}>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    {errorMessage}
                </ParagraphText>
            </Box>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                For further assistance, please visit our{' '}
                <StyledLink
                    href="https://support.immutable.com"
                    fillColor={colors.light[700]}
                    testId="supportLink"
                    fontWeight="bold"
                    target="_blank"
                    fontSize="small"
                >
                    support page
                </StyledLink>
                .
            </ParagraphText>
            <StandardLinkRouteButtonsFooter>
                <StandardButton testId="close" buttonKind="primary" onClick={window.close}>
                    CLOSE
                </StandardButton>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
}
