import {
    BaseComponentPropTypes,
    colors,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

import { useScrollToTop } from '../../hooks/useScrollToTop.hook';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { ErrorMessageProps } from '../ErrorMessage';

export interface OfferMessages {
    title: { start: string; done: string };
    bodyText: {
        start: string;
        done: string;
    };
    buttons: {
        done: string;
        confirm: string;
        cancel: string;
    };
    invalidParameters: ErrorMessageProps;
}
interface OfferFlowCompleteProps {
    offerMessages: OfferMessages;
    onContinueClick: () => void;
}

export const OfferFlowComplete = ({
    offerMessages,
    onContinueClick,
    testId,
}: OfferFlowCompleteProps & BaseComponentPropTypes) => {
    useScrollToTop();
    return (
        <StandardLinkRouteContainer testId={`${testId}__Container`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId={`${testId}__heading`}>{offerMessages.title.done}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {offerMessages.bodyText.done}
            </ParagraphText>

            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large">
                    <StandardButton
                        testId={`${testId}__continueCta`}
                        buttonKind="ultimate-cta"
                        onClick={onContinueClick}
                    >
                        {offerMessages.buttons.done}
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
