import { LinkParams } from '@imtbl/imx-sdk';

export const getBatchParams = (
    params: LinkParams.BatchNftTransfer,
    batchIndex: number,
    batchAmount: number,
): LinkParams.BatchNftTransfer => {
    const offset = batchIndex * batchAmount;
    return params.slice(offset, offset + batchAmount);
};
