import { colors, ParagraphText } from '@imtbl/design-system';
import { AwaitingOrderStatus } from '@imtbl/imx-sdk';
import React, { useCallback, useEffect, useState } from 'react';

import { LinkClientConfigTS, ParentWindow, poll, wait } from '../../../lib';
import { getAwaitingOrderStatus } from '../../../lib/awaitingOrder';
import { FullOrderInfoWithFinalStatus } from '../../../types/SharedOrder.types';
import { BuyProps } from '../../Buy';
import { StandardLinkRouteContainer } from '../../common';
import { LoadingUi } from '../../LoadingUi/LoadingUi.component';
import { BuyCompleteScreen } from '../BuyCompleteScreen.component';
import { CodeInput } from './CodeInput';
import { OTPError } from './OTPError';

enum OTPStep {
    // initial screen where the polling starts
    LOADING,
    // otp confirmation screen
    OTP_INPUT,
    // waiting for AwaitingOrderStatus.Settled
    IN_PROGRESS,
    // order settled
    COMPLETE,
    ERROR,
}

type OTPViewProps = Pick<BuyProps, 'provider'> & {
    config: LinkClientConfigTS;
    requestId?: string;
    testId?: string;
    parent: ParentWindow;
    orderDetails?: FullOrderInfoWithFinalStatus[];
    sendAnalyticsCallback?: () => void;
};

export const OTPView = ({
    config,
    provider,
    requestId,
    testId,
    parent,
    orderDetails,
    sendAnalyticsCallback = () => {},
}: OTPViewProps) => {
    const [currentStep, setCurrentStep] = useState(OTPStep.LOADING);
    const [orderStatus, setOrderStatus] = useState<AwaitingOrderStatus>();

    const shouldContinuePolling = (status?: AwaitingOrderStatus) => {
        if (status !== orderStatus) {
            setOrderStatus(status);
        }
        return (
            [AwaitingOrderStatus.AwaitingApproval, AwaitingOrderStatus.Confirmed].includes(
                status as AwaitingOrderStatus,
            ) || status === undefined
        );
    };

    useEffect(() => {
        function runPolling(id: string) {
            poll<AwaitingOrderStatus | undefined>(
                async () => getAwaitingOrderStatus(config, id),
                shouldContinuePolling,
                config.orderStatusPollingInterval,
            )
                .then((status) => {
                    setOrderStatus(status);
                })
                .catch((e) => {
                    if (e.status_code === 404 && currentStep === OTPStep.LOADING) {
                        wait(config.orderStatusPollingInterval).then(() => runPolling(id));
                    }
                });
        }

        // Run Polling on init
        if (requestId && config) {
            runPolling(requestId);
        }
    }, []);

    useEffect(() => {
        let step = OTPStep.LOADING;

        switch (orderStatus) {
            case AwaitingOrderStatus.AwaitingApproval:
                step = OTPStep.OTP_INPUT;
                break;
            case AwaitingOrderStatus.Confirmed:
                step = OTPStep.IN_PROGRESS;
                break;
            case AwaitingOrderStatus.Settled:
                sendAnalyticsCallback();
                step = OTPStep.COMPLETE;
                break;
            case AwaitingOrderStatus.Expired:
            case AwaitingOrderStatus.SettlementFailed:
                step = OTPStep.ERROR;
                break;
            default:
        }

        if (currentStep !== step && currentStep !== OTPStep.ERROR) {
            setCurrentStep(step);
        }
    }, [orderStatus]);

    const renderComponent = useCallback(() => {
        const stepMapper = {
            [OTPStep.LOADING]: (
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Loading...
                </ParagraphText>
            ),
            [OTPStep.OTP_INPUT]: <CodeInput config={config} provider={provider} requestId={requestId} />,
            [OTPStep.IN_PROGRESS]: <LoadingUi showLoading />,
            [OTPStep.COMPLETE]: <BuyCompleteScreen parent={parent} testId={testId} orderDetails={orderDetails} />,
            [OTPStep.ERROR]: <OTPError orderStatus={orderStatus} />,
        };
        return stepMapper[currentStep];
    }, [currentStep]);

    return (
        <StandardLinkRouteContainer testId={`${testId}__riskAssessmentContainer`}>
            {renderComponent()}
        </StandardLinkRouteContainer>
    );
};
