import { css } from '@emotion/css';
import { BaseComponentPropTypes, colors, FlexLayout, Icon, ParagraphText, VerticalSpace } from '@imtbl/design-system';
import pluralize from 'pluralize';
import React from 'react';

import FullWidthBorderLine from '../FullWidthBorderLine';

interface Props extends BaseComponentPropTypes {
    batchNumber: number;
    numberOfBatches: number;
}

export const BatchNftTransferWarning: React.FC<Props> = ({ batchNumber, numberOfBatches, testId }) => {
    const remainingBatches = numberOfBatches - batchNumber;
    const message = remainingBatches === 0 ? 'no' : remainingBatches;
    return (
        <>
            <FlexLayout
                flexDirection="column"
                alignItems="center"
                testId={`${testId}__batchNftTransferWarning__container`}
            >
                {numberOfBatches <= 1 ? (
                    <>
                        {' '}
                        <FullWidthBorderLine
                            className={css`
                                margin-top: 0 !important;
                            `}
                        />
                    </>
                ) : (
                    <>
                        <FullWidthBorderLine
                            className={css`
                                margin-top: 0 !important;
                            `}
                        />
                        <VerticalSpace bottom="none" top="small">
                            <Icon
                                ligature="misc_alert"
                                iconSize="x-large"
                                fillColor={colors.yellow[300]}
                                textAlign="center"
                            />
                        </VerticalSpace>
                        <VerticalSpace bottom="none" top="x-small">
                            <ParagraphText
                                fillColor={colors.light[100]}
                                fontSize="small"
                                fontWeight="bold"
                                textAlign="center"
                            >
                                {`This is batch ${batchNumber} of ${numberOfBatches}.`}
                                <br />
                                {`There will be ${message} more transaction ${pluralize('batch', remainingBatches)}`}
                            </ParagraphText>
                        </VerticalSpace>
                    </>
                )}
            </FlexLayout>
        </>
    );
};
