import { colors, ParagraphText, SectionHeading, StandardButton, VerticalSpace } from '@imtbl/design-system';
import BigNumber from 'bignumber.js';
import React, { useEffect, useMemo, useState } from 'react';

import { useScrollToTop } from '../../../../hooks/useScrollToTop.hook';
import { TokenDataType, TokensListType } from '../../../../hooks/useTokensList.hook';
import { calculateMinQuantisedTokenAmount, getAmountValidationErrorMessage } from '../../../../lib';
import { sendAnalytics } from '../../../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../../../lib/analytics/types';
import { createButtonEvent } from '../../../../lib/analytics/utils';
import { CancelLink, CurrencySelectBox, PriceInput, StandardLinkRouteButtonsFooter } from '../../../common';
import { useDepositBalance } from './DepositBalanceProvider';
import { L1Balance } from './L1Balance';

type DepositFormProps = {
    availableTokens: TokensListType;
    token?: string;
    user: string;
    onConfirm: (amount: any, token: TokenDataType) => void;
    onCancel: () => void;
};

export const DepositForm = ({ availableTokens, onConfirm, onCancel, token, user }: DepositFormProps) => {
    useScrollToTop();
    const { balance, setBalance } = useDepositBalance();
    const [selectedToken, setSelectedToken] = useState<TokenDataType>();
    const [amount, setAmount] = useState<BigNumber>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const onChangeToken = (newToken: TokenDataType) => {
        sendAnalytics(
            createButtonEvent(ButtonEventName.flexibleDepositCurrencyBtnPressed, {
                currency: newToken?.symbol,
            }),
        );
        setBalance(undefined);
        setSelectedToken(newToken);
    };

    const minAmount = useMemo(() => calculateMinQuantisedTokenAmount(selectedToken), [selectedToken]);

    useEffect(
        () => setErrorMessage(getAmountValidationErrorMessage(amount, balance, minAmount)),
        [minAmount, amount, balance],
    );

    const handleOnCancel = () => {
        sendAnalytics(createButtonEvent(ButtonEventName.flexibleDepositCancelBtnPressed));
        onCancel();
    };

    return (
        <>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">Deposit</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Create an ETH or ERC20 deposit:
            </ParagraphText>
            <VerticalSpace top="large">
                <CurrencySelectBox
                    tokens={availableTokens}
                    onSelect={onChangeToken}
                    selectedToken={token}
                    disabled={typeof token !== 'undefined'}
                />
            </VerticalSpace>
            <VerticalSpace top="large">
                <ParagraphText fillColor={colors.light[700]} fontSize="small" fontWeight="bold">
                    Available balance:
                </ParagraphText>
                <L1Balance
                    walletAddress={user}
                    tokenAddress={selectedToken?.token_address}
                    tokenImage={selectedToken?.image_url || ''}
                />
            </VerticalSpace>
            <VerticalSpace top="large">
                <PriceInput
                    token={selectedToken}
                    onEnter={setAmount}
                    minPrice={0}
                    disabled={!selectedToken}
                    displayHelp={false}
                    errorMsg={errorMessage}
                    isValid={!errorMessage}
                />
            </VerticalSpace>
            <StandardLinkRouteButtonsFooter testId="deposit__ButtonsFooter">
                <StandardButton
                    testId="deposit__Container__nextCta"
                    buttonKind="ultimate-cta"
                    onClick={() => {
                        onConfirm(amount, selectedToken);
                    }}
                    disabled={!!errorMessage}
                >
                    NEXT
                </StandardButton>
                <CancelLink title="Cancel" onClick={handleOnCancel} />
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
