import { colors, ParagraphText, SectionHeading, StandardButton, VerticalSpace } from '@imtbl/design-system';
import React from 'react';

import { useI18nMessaging } from '../../../../hooks/useI18nMessaging.hook';
import { useScrollToTop } from '../../../../hooks/useScrollToTop.hook';
import { StandardLinkRouteButtonsFooter } from '../../../common/StandardLinkUiContainers';

type ResultProps = {
    onFinish: () => void;
};

export const ResultMessage = ({ onFinish }: ResultProps) => {
    useScrollToTop();
    const textMessages = useI18nMessaging({});
    return (
        <>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading" fillColor={colors.blue[300]}>
                    Listing confirmed
                </SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your item(s) have been listed for sale.
            </ParagraphText>

            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large" top="large">
                    <StandardButton
                        testId="listAsset__listCompleteContainer__finishCta"
                        buttonKind="ultimate-cta"
                        onClick={onFinish}
                    >
                        {textMessages.standardUiButtons.continue}
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
