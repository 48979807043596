import { LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import { useCallback } from 'react';

import { ParentWindow } from '.';

export const closeWindow = (parent: ParentWindow): ((...args: any[]) => any) => {
    return useCallback(() => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent]);
};
