import BigNumber from 'bignumber.js';
import React from 'react';

type DepositBalanceContext = {
    balance?: BigNumber;
    setBalance: (balance: BigNumber | undefined) => void;
};

// @ts-ignore
export const DepositBalanceContext = React.createContext<DepositBalanceContext>();

export function DepositBalanceProvider(props: any): JSX.Element {
    const [balance, setBalance] = React.useState<BigNumber>();
    return <DepositBalanceContext.Provider value={{ balance, setBalance }} {...props} />;
}

export function useDepositBalance() {
    const context = React.useContext(DepositBalanceContext);
    if (!context) {
        throw new Error('useDepositBalance must be used within a DepositBalanceProvider');
    }

    return context;
}
