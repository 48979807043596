import {
    EthAddress,
    fromEnum,
    ImmutableMethodResults,
    PositiveBigNumber,
    PositiveNumberStringC,
    TokenCodec,
} from '@imtbl/imx-sdk';
import * as t from 'io-ts';

import { ErrorMessageProps } from '../components';
import { ErrorWithCode } from '../errors';

export enum EthNetwork {
    mainnet = 'mainnet',
    ropsten = 'ropsten',
    goerli = 'goerli',
    sepolia = 'sepolia',
}
export const EthNetworkCodec = fromEnum<EthNetwork>('EthNetwork', EthNetwork);

export const LinkMoonpayCodec = t.type({
    buyNFTUrl: t.string,
    buyCryptoUrl: t.string,
    sellCryptoUrl: t.string,
    redirectUrl: t.string,
    apiKey: t.string,
    baseCurrencyCode: t.string,
    colorCode: t.string,
});

export const LinkClientConfigCodec = t.type({
    publicApiUrl: t.string,
    starkContractAddress: EthAddress,
    registrationContractAddress: EthAddress,
    orderStatusPollingInterval: t.number,
    orderStatusPollingAttemptsNumber: t.number,
});

export const ProviderOptionsCodec = t.type({
    walletConnectProviderOption: t.type({
        rpc: t.record(t.string, t.string),
    }),
    magicProviderOptions: t.intersection([
        t.type({ publishableKey: t.string }),
        t.partial({
            network: t.union([
                t.string,
                t.type({
                    chainId: t.number,
                    rpcUrl: t.string,
                }),
            ]),
        }),
    ]),
    gameWalletOptions: t.array(
        t.type({
            gameTitle: t.string,
            gameIconUrl: t.string,
            publishableKey: t.string,
            providerPreference: t.string,
            collectionAddresses: t.array(t.string),
        }),
    ),
});

export const SegmentCodec = t.type({
    apiKey: t.string,
});

export type LinkClientConfig = t.TypeOf<typeof LinkClientConfigCodec>;
export type LinkClientConfigTS = t.OutputOf<typeof LinkClientConfigCodec>;
export type ProviderOptions = t.TypeOf<typeof ProviderOptionsCodec>;
export type ProviderOptionsTS = t.TypeOf<typeof ProviderOptionsCodec>;

export const LinkConfigCodec = t.type({
    debug: t.boolean,
    ethNetwork: EthNetworkCodec,
    client: LinkClientConfigCodec,
    akuma: t.string,
    ldClientId: t.string,
    flags: t.UnknownRecord,
    imageResizerServiceUrl: t.string,
    moonpay: LinkMoonpayCodec,
    deniedSignMessagePatterns: t.array(t.string),
    providerOptions: ProviderOptionsCodec,
    segment: SegmentCodec,
});

export type LinkConfig = t.TypeOf<typeof LinkConfigCodec>;
export type LinkConfigTS = t.OutputOf<typeof LinkConfigCodec>;

export interface ParentWindow {
    postMessage(message: any, targetOrigin: string, transfer?: Transferable[]): void;
}

export type LinkFlagsConfig = Record<string, unknown>;

export type NotifyMethod = (progress: { msg: string; tx?: string }) => void;
export type DispatchSetError = (e: ErrorWithCode, ui?: ErrorMessageProps) => void;
export type DispatchSetLoading = (loading: boolean) => void;

export const TokenWithAmountCodec = t.interface({
    token: TokenCodec,
    amount: PositiveNumberStringC,
    quantity: PositiveBigNumber,
});
export type TokenWithAmount = t.TypeOf<typeof TokenWithAmountCodec>;
export type TokenAsset = t.TypeOf<typeof ImmutableMethodResults.ImmutableAssetCodec>;

export const TokenWithDetailsCodec = t.intersection([
    TokenWithAmountCodec,
    t.interface({
        asset: t.union([ImmutableMethodResults.ImmutableAssetCodec, t.undefined]),
    }),
]);

export type TokenWithDetails = t.TypeOf<typeof TokenWithDetailsCodec>;
export type TokenWithDetailsTS = t.OutputOf<typeof TokenWithDetailsCodec>;
