import { css } from '@emotion/css';
import { Web3Provider } from '@ethersproject/providers';
import {
    colors,
    FlexLayout,
    Icon,
    layoutHelpers,
    measurements,
    OuterSpace,
    ParagraphText,
    StyledLink,
    VerticalSpace,
} from '@imtbl/design-system';
import {
    assertEither,
    assertNever,
    ERC20TokenType,
    ERC721TokenType,
    ETHTokenType,
    ImmutableAssetStatus,
    ImmutableMethodResults,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LinkParamsCodecs,
    LinkParamsF,
    LinkResults,
    messageTypes,
    MintableERC20TokenType,
    MintableERC721TokenType,
} from '@imtbl/imx-sdk';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import React, { useCallback, useContext, useEffect } from 'react';

import { LinkCoreContext } from '../../context/App.context';
import { ErrorWithCode } from '../../errors';
import { useTokensList } from '../../hooks/useTokensList.hook';
import { Messages } from '../../i18n';
import {
    checkSufficientTokenBalance,
    DispatchSetError,
    DispatchSetLoading,
    getAddress,
    getBalanceForTokenType,
    getTokenWithDetails,
    isEthOrERC20TokenType,
    LinkClientConfig,
    NotifyMethod,
    ParentWindow,
    prepareWithdrawal,
    TokenWithDetails,
} from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../lib/analytics/types';
import { createButtonEvent } from '../../lib/analytics/utils';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../utils/constants';
import { getProvidersConfig } from '../../utils/getProvidersConfig';
import { Action } from '../Action';
import FullWidthBorderLine from '../FullWidthBorderLine';
import { TokenDetailsDisplayRow } from '../TokenDetailsDisplayRow';
import styles from './index.module.css';

export type PrepareWithdrawalProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    setErrorLog: DispatchSetError;
    setLoading: DispatchSetLoading;
};

export type PrepareWithdrawalInput = {
    token: TokenWithDetails;
    tokenResult: ImmutableMethodResults.ImmutableGetTokenResult;
};

export const PrepareWithdrawal = ({
    config,
    parent,
    provider,
    messages,
    setLoading,
    setErrorLog,
}: PrepareWithdrawalProps) => {
    const { config: linkConfig } = useContext(LinkCoreContext);
    const providerOptions = getProvidersConfig(linkConfig);
    const { tokens, error } = useTokensList({ config });
    const hasTokensList = tokens.length > 0;

    useEffect(() => {
        if (!hasTokensList) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [hasTokensList, setLoading]);

    useEffect(() => {
        if (error) {
            setErrorLog(error, messages.generalErrorMessage([messages.failedToRetrieveTokenList(error.message)]));
            setLoading(false);
        }
    }, [error, setErrorLog, setLoading]);

    const loadInput = (params: LinkParamsF.PrepareWithdrawal) =>
        pipe(
            TE.bindTo('client')(ImmutableXClient.buildF({ publicApiUrl: config.publicApiUrl })),
            TE.chainFirst(() => TE.fromIO(() => console.log(`got here`))),
            TE.bind('address', () => getAddress(provider)),

            TE.bind('tokenResult', ({ client }) =>
                pipe(
                    client.getTokenF(params.type === ERC20TokenType.ERC20 ? { tokenAddress: params.tokenAddress } : {}),
                    TE.mapLeft(() => E.toError(messages.failedToRetrieveTokenList(''))),
                ),
            ),
            TE.bind('token', ({ client, tokenResult }) =>
                getTokenWithDetails(params, parseInt(tokenResult.decimals))(client),
            ),
            TE.bind('balance', ({ client, address, token }) => getBalanceForTokenType(token.token, client, address)),
            TE.chainFirst(({ token, address, balance }) => {
                switch (token.token.type) {
                    case ERC721TokenType.ERC721:
                        return pipe(
                            token.asset,
                            E.fromNullable(new Error(messages.loadAssetError)),
                            E.chain((asset) =>
                                A.sequence(E.either)([
                                    assertEither(asset.user !== address, messages.userNotAssetOwner),
                                    assertEither(
                                        asset.status === ImmutableAssetStatus.preparing_withdrawal,
                                        messages.prepareWithdrawal.assetAlreadyPreparingWithdraw,
                                    ),
                                    assertEither(
                                        asset.status !== ImmutableAssetStatus.imx,
                                        messages.prepareWithdrawal.assetNotAvailableToWithdraw,
                                    ),
                                ]),
                            ),
                            TE.fromEither,
                        );
                    case ETHTokenType.ETH:
                        return checkSufficientTokenBalance(
                            token.quantity,
                            balance.balance,
                            messages.insufficientEthFunds,
                        );
                    case ERC20TokenType.ERC20:
                        return checkSufficientTokenBalance(
                            token.quantity,
                            balance.balance,
                            messages.insufficientERC20Funds(token.token.data.symbol),
                        );
                    case MintableERC20TokenType.MINTABLE_ERC20:
                        return TE.left(new Error('Not implemented'));
                    case MintableERC721TokenType.MINTABLE_ERC721:
                        return TE.left(new Error('Not implemented'));
                    default:
                        return assertNever(token.token);
                }
            }),
        );

    const execute = (
        { token }: PrepareWithdrawalInput,
        notify: NotifyMethod,
    ): TE.TaskEither<ErrorWithCode, LinkResults.PrepareWithdrawal> => {
        sendAnalytics(createButtonEvent(ButtonEventName.prepareWdrawNotifiedConfirmPressed));
        setLoading(true);
        return pipe(
            prepareWithdrawal(config, token, notify, providerOptions),
            TE.bimap(
                (e) => {
                    setLoading(false);
                    return e;
                },
                (result) => {
                    setLoading(false);
                    return result;
                },
            ),
        );
    };

    const showDetailsStart = ({ token, tokenResult }: PrepareWithdrawalInput) => {
        return (
            <>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Please confirm the assets you wish to prepare:
                </ParagraphText>
                <OuterSpace top={measurements.SpacingTeeShirtAmounts.large}>
                    <TokenDetailsDisplayRow
                        testId="prepareWithdrawalTokenDetails"
                        tokenType={token.token.type}
                        asset={token.asset}
                        transactionCostTokenImage={tokenResult.image_url || DEFAULT_ETH_TOKEN_IMAGERY}
                        tokenDisplayAmount={isEthOrERC20TokenType(token.token.type) ? token.amount : undefined}
                        tokenMetadata={tokenResult}
                        className={css`
                            border: none;
                        `}
                    />
                </OuterSpace>
                <FlexLayout alignItems="stretch" justifyContent="flex-end" flexDirection="column" flexGrow={1}>
                    <FlexLayout
                        justifyContent="center"
                        paddingTop={layoutHelpers.gridUnits(3)}
                        paddingBottom={layoutHelpers.gridUnits(3)}
                    >
                        <FullWidthBorderLine />
                        <ParagraphText fontSize="tag" fillColor={colors.light[100]}>
                            Gas fee will apply when finalising withdrawal later
                        </ParagraphText>
                    </FlexLayout>

                    <FlexLayout
                        alignSelf="center"
                        justifyContent="center"
                        className={styles.alert}
                        paddingTop={layoutHelpers.gridUnits(3)}
                        paddingBottom={layoutHelpers.gridUnits(3)}
                        title="Preparing a withdrawal commits assets to be transferred from Immutable X to your Ethereum wallet. Assets will remain in the “Withdraw Ready” state until withdrawn from Immutable X. "
                        width="100%"
                    >
                        <Icon ligature="misc_alert" fillColor={colors.yellow[100]} iconSize="x-large" />
                        <ParagraphText
                            fontSize="tag"
                            fontWeight="bold"
                            className={styles.prepare}
                            fillColor={colors.light[300]}
                        >
                            Prepared items MUST be withdrawn{' '}
                            <StyledLink
                                fontSize="tag"
                                fontWeight="bold"
                                fillColor={colors.light[700]}
                                href="https://support.immutable.com/hc/en-us/articles/1500003550482"
                                underline={false}
                                target="_blank"
                            >
                                (i)
                            </StyledLink>
                        </ParagraphText>
                    </FlexLayout>
                </FlexLayout>
            </>
        );
    };

    const showDetailsDone = () => (
        <>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your withdrawal is being prepared.
            </ParagraphText>

            <VerticalSpace top="large" bottom="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    The &quot;Withdraw Ready&quot; option will soon appear in your{' '}
                    <strong style={{ color: colors.light[100] }}>Immutable X Inventory</strong>.
                </ParagraphText>
            </VerticalSpace>

            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Once ready, you will need to <strong style={{ color: colors.yellow[100] }}>sign a transaction</strong>{' '}
                via your Ethereum wallet to complete the withdrawal.
            </ParagraphText>
        </>
    );

    const onFinish = useCallback(() => {
        sendAnalytics(createButtonEvent(ButtonEventName.prepareWdrawConfirmedFinishPressed));
    }, []);

    const onDenyClick = useCallback(() => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, []);

    if (!hasTokensList) {
        return null;
    }

    return (
        <Action
            parent={parent}
            title={messages.prepareWithdrawal.title}
            paramsDecoder={LinkParamsCodecs.PrepareWithdrawal.decode}
            loadInput={loadInput}
            execute={execute}
            showDetails={{
                start: showDetailsStart,
                done: showDetailsDone,
            }}
            setErrorLog={setErrorLog}
            onFinishClick={onFinish}
            onDenyClick={onDenyClick}
        />
    );
};
