import { BigNumber } from '@ethersproject/bignumber';
import { Web3Provider } from '@ethersproject/providers';
import {
    ERC20Token,
    ERC20TokenType,
    ERC721Token,
    ERC721TokenType,
    EthAddressBrand,
    ETHToken,
    ETHTokenType,
    FeeType,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    messageTypes,
} from '@imtbl/imx-sdk';
import { utils } from 'ethers';
import { Branded } from 'io-ts';

import { MakeOfferInputParams } from '../../components/Offers';
import { MakeOfferOrderAPIError } from '../../components/Offers/MakeOffer/MakeOfferError';
import { SetErrorLog } from '../../context/App.context';
import { TokenDataType } from '../../hooks/useTokensList.hook';
import { sendAnalytics } from '../analytics/send-analytics';
import { FlowEventName } from '../analytics/types';
import { createFlowEvent } from '../analytics/utils';
import { LinkClientConfig, ParentWindow } from '../types';

export interface MakeOfferTokens {
    tokenBuy: ERC721Token;
    amountBuy: BigNumber;
    tokenSell: ETHToken | ERC20Token;
    amountSell: BigNumber;
    expirationTimestamp?: number;
}

export function getMakeOfferTokens(
    offerCurrencyToken: TokenDataType,
    makeOfferParams: MakeOfferInputParams,
): MakeOfferTokens | null {
    if (!offerCurrencyToken) return null;

    const tokenBuy = {
        type: ERC721TokenType.ERC721,
        data: {
            tokenId: makeOfferParams.tokenId,
            tokenAddress: makeOfferParams.tokenAddress as Branded<string, EthAddressBrand>,
        },
    };

    const amountBuy = BigNumber.from(1);

    const tokenSell =
        offerCurrencyToken?.token_address !== ''
            ? {
                  type: ERC20TokenType.ERC20,
                  data: {
                      decimals: parseInt(offerCurrencyToken.decimals) || 18,
                      tokenAddress: offerCurrencyToken.token_address as Branded<string, EthAddressBrand>,
                      symbol: offerCurrencyToken.symbol,
                  },
              }
            : {
                  type: ETHTokenType.ETH,
                  data: {
                      decimals: parseInt(offerCurrencyToken.decimals) || 18,
                  },
              };

    const amountSell = utils.parseUnits(makeOfferParams.amount as string, BigNumber.from(offerCurrencyToken.decimals));

    const expirationTimestamp = makeOfferParams.expirationTimestamp
        ? parseInt(makeOfferParams.expirationTimestamp)
        : undefined;

    return {
        tokenBuy,
        amountBuy,
        tokenSell,
        amountSell,
        expirationTimestamp,
    };
}

export interface MakeOfferTransactionInputs {
    config: LinkClientConfig;
    makeOfferTokens: MakeOfferTokens;
    fees: FeeType[];
    provider: Web3Provider;
    parent: ParentWindow;
    setErrorLog: SetErrorLog;
}

export async function makeOffer({
    config,
    makeOfferTokens,
    fees,
    provider,
    parent,
    setErrorLog,
}: MakeOfferTransactionInputs) {
    const { tokenBuy, amountBuy, tokenSell, amountSell, expirationTimestamp } = makeOfferTokens;

    const client = await ImmutableXClient.build({ ...config, signer: provider.getSigner() });
    const user = client.address;

    try {
        const createOrderResponse = await client.createOrderV3({
            user,
            tokenBuy,
            amountBuy,
            tokenSell,
            amountSell,
            expiration_timestamp: expirationTimestamp,
            fees,
        });

        sendAnalytics(createFlowEvent(FlowEventName.makeOfferSucceeded));
        parent.postMessage(
            {
                type: LINK_MESSAGE_TYPE,
                message: messageTypes.success,
                data: { order_id: createOrderResponse.order_id.toString(), status: createOrderResponse.status },
            },
            '*',
        );
    } catch (err) {
        console.error(err);
        sendAnalytics(createFlowEvent(FlowEventName.makeOfferFailed));
        setErrorLog(MakeOfferOrderAPIError);
    }
}
