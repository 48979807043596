import { css } from '@emotion/css';
import {
    BaseComponentPropTypes,
    colors,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    StandardButton,
    StyledLink,
} from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import React, { useCallback, useContext } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { ParentWindow } from '../../lib';
import { FullTransferTokenInfo } from '../../types/SharedTransfer.types';
import { actionDeniedError } from '../Action';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { TransferDetailsListing } from './common.components';

export interface TransferPromptScreenPropTypes extends BaseComponentPropTypes {
    parent: ParentWindow;
    beginTransferTransaction: () => void;
    transferDetails: FullTransferTokenInfo[];
}

// @TODO: Alot of the below is a standard Link screen, so this should eventually be templatized in such a way
// that other components too, can use this exact same layout (albiet with their own actions etc)
export const TransferPromptScreen = ({
    parent,
    beginTransferTransaction,
    testId,
    transferDetails,
}: TransferPromptScreenPropTypes) => {
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const textMessages = useI18nMessaging({});
    const onTransferDenyClick = useCallback(() => {
        setErrorLog(actionDeniedError, textMessages.actionDenied);
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent, setErrorLog, textMessages]);

    return (
        <StandardLinkRouteContainer testId={`${testId}__transferPromptContainer`}>
            <SectionHeading testId="heading">{textMessages.transfer.title.start}</SectionHeading>
            <OuterSpace top={measurements.SpacingTeeShirtAmounts.medium}>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    {textMessages.transfer.bodyText.start}
                </ParagraphText>
            </OuterSpace>

            <TransferDetailsListing
                testId={`${testId}__transferCompleteContainer__transfersListing`}
                transfers={transferDetails}
            />

            <StandardLinkRouteButtonsFooter>
                <StandardButton
                    testId={`${testId}__beginCta`}
                    buttonKind="ultimate-cta"
                    onClick={beginTransferTransaction}
                >
                    {textMessages.standardUiButtons.confirm}
                </StandardButton>
                <StyledLink
                    testId={`${testId}__denyCta`}
                    fontSize="small"
                    fontWeight="bold"
                    fillColor={colors.red[300]}
                    onClick={onTransferDenyClick}
                    className={css`
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    {textMessages.standardUiButtons.deny}
                </StyledLink>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
