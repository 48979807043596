import { LinkParams } from '@imtbl/imx-sdk';
import queryString from 'query-string';
import React, { useEffect } from 'react';

import { useGetEthAddress } from '../../../hooks/useGetAddress.hook';
import { LinkConfig } from '../../../lib';
import { search } from '../../../utils/location';
import { LoadingMessage, StandardLinkRouteContainer } from '../../common';
import { ExchangeProps } from '../Onramp';
import { ProviderNFTCheckoutSecondary } from './ProviderNFTCheckoutSecondary';

export type NFTCheckoutSecondaryProps = ExchangeProps & {
    config: LinkConfig;
};

export const NFTCheckoutSecondary = (props: NFTCheckoutSecondaryProps) => {
    const queryParams = queryString.parse(search) as unknown as LinkParams.NFTCheckoutSecondary;
    const { provider, loading, setLoading } = props;
    const walletAddress = useGetEthAddress(provider);

    useEffect(() => {
        setLoading(!walletAddress);
    }, [walletAddress]);

    return (
        <>
            {loading ? (
                <StandardLinkRouteContainer>
                    <LoadingMessage />
                </StandardLinkRouteContainer>
            ) : (
                <ProviderNFTCheckoutSecondary
                    {...props}
                    providerName={queryParams.provider}
                    orderId={queryParams.orderId}
                    userWalletAddress={walletAddress}
                />
            )}
        </>
    );
};
