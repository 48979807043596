import { ImmutableMethodResults, ImmutableXClient, valueOrThrowTE } from '@imtbl/imx-sdk';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { immutableTokenToTokenWithDetails } from '../lib';
import { OrderInfoWithTokenInfo } from '../types/SharedOrder.types';
import { useI18nMessaging } from './useI18nMessaging.hook';

const generalMessages = useI18nMessaging();

export const useGetOrderTokenDetails = ({
    client,
    orderDetails,
}: {
    client?: ImmutableXClient;
    orderDetails?: ImmutableMethodResults.ImmutableGetOrderV3Result[];
}) => {
    const [orderDetailsWithTokenInfo, setOrderDetailsWithTokenInfo] = useState<OrderInfoWithTokenInfo[]>();
    const { setErrorLog } = useContext(LinkUiCoreContext);

    // @NOTE: For each order, get the token details:
    useEffect(() => {
        async function getAllOrdersTokenInfo() {
            if (!!orderDetails && !!client) {
                try {
                    const ordersTokenInfoResponse = await Promise.all(
                        [
                            ...orderDetails.map((order) => {
                                return [
                                    valueOrThrowTE(immutableTokenToTokenWithDetails(client)(order.buy)),
                                    valueOrThrowTE(immutableTokenToTokenWithDetails(client)(order.sell)),
                                ];
                            }),
                        ].flat(),
                    );
                    const completeOrdersInfo: OrderInfoWithTokenInfo[] = orderDetails.map((order, index) => {
                        const fullInfo = {
                            details: order,
                            tokenBuyDetails: ordersTokenInfoResponse[index * 2],
                            tokenSellDetails: ordersTokenInfoResponse[index * 2 + 1],
                        };
                        return fullInfo;
                    });
                    setOrderDetailsWithTokenInfo(completeOrdersInfo);
                } catch (err) {
                    setErrorLog(
                        err,
                        generalMessages.generalErrorMessage([
                            generalMessages.buy.failedRetrieveAssetDetails(err.message),
                        ]),
                    );
                }
            }
        }

        getAllOrdersTokenInfo();
    }, [orderDetails, client, setErrorLog]);

    return orderDetailsWithTokenInfo;
};
