// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vLD_mTe2ZPi6i68ZOcl5 {\n    padding-left: 24px;\n    padding-right: 24px;\n    min-height: 55px;\n    margin-bottom: 15px;\n    border-bottom: 2px solid var(--colors_dark_300);\n}\n\n.NhN615J7Q2xt43yeetIS {\n    padding-top: 3px;\n    margin-right: 35px;\n}\n\n.D30k26Nt5fP8FZ3aC_ZU {\n    overflow-y: auto;\n}\n\n._ERaiE8s1QuTjSRk3uFv {\n    margin-top: 6px;\n}\n\n.c7Ty4EqHfUpSewnOX4jk {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    color: var(--colors_dark_100);\n    cursor: pointer;\n}\n\n.oB3G_aYC8GMQfZLVpFXX {\n    position: relative;\n    top: 3px;\n    margin-right: 3px;\n}", "",{"version":3,"sources":["webpack://./src/components/History/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,+CAA+C;AACnD;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,iBAAiB;AACrB","sourcesContent":[".header {\n    padding-left: 24px;\n    padding-right: 24px;\n    min-height: 55px;\n    margin-bottom: 15px;\n    border-bottom: 2px solid var(--colors_dark_300);\n}\n\n.heading {\n    padding-top: 3px;\n    margin-right: 35px;\n}\n\n.content {\n    overflow-y: auto;\n}\n\n.amount {\n    margin-top: 6px;\n}\n\n.address {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    color: var(--colors_dark_100);\n    cursor: pointer;\n}\n\n.copyIcon {\n    position: relative;\n    top: 3px;\n    margin-right: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "vLD_mTe2ZPi6i68ZOcl5",
	"heading": "NhN615J7Q2xt43yeetIS",
	"content": "D30k26Nt5fP8FZ3aC_ZU",
	"amount": "_ERaiE8s1QuTjSRk3uFv",
	"address": "c7Ty4EqHfUpSewnOX4jk",
	"copyIcon": "oB3G_aYC8GMQfZLVpFXX"
};
export default ___CSS_LOADER_EXPORT___;
