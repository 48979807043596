import { Box, colors, layoutHelpers, ParagraphText, StyledLink } from '@imtbl/design-system';
import React from 'react';

import { messagesIndex } from '../../i18n';
import styles from './HelperMessageBox.module.css';

interface HelperMessageBoxProps {
    helperMessageType?: HelperMessageType;
}

export enum HelperMessageType {
    IS_CONFIRM = 'is-confirm',
    IS_CANCEL = 'is-cancel',
}

export const HelperMessageBox = ({ helperMessageType }: HelperMessageBoxProps) => {
    const showCancelMessage = helperMessageType === HelperMessageType.IS_CANCEL;
    const messages = messagesIndex.en;

    return (
        <Box
            className={styles.helperMessageBox}
            backgroundColor={colors.bg['900']}
            padding={layoutHelpers.gridUnits(3)}
        >
            <ParagraphText textAlign="center" fontSize="tag" fontWeight="bold" fillColor={colors.light[100]}>
                {showCancelMessage ? messages.helperMessage.cancel.begin : messages.helperMessage.confirm.begin}
                {` `}
                <StyledLink
                    href={messages.helperMessage.link}
                    fontSize="tag"
                    underline={false}
                    fontWeight="bold"
                    fillColor={showCancelMessage ? colors.red[300] : colors.blue[300]}
                >
                    {messages.helperMessage.name}
                </StyledLink>
                {` `}
                {showCancelMessage ? messages.helperMessage.cancel.end : messages.helperMessage.confirm.end}.
            </ParagraphText>
        </Box>
    );
};
