import { ApiError } from './api';

export const getPositionsFromApiError = (error: ApiError): number[] => {
    const contentsWithinBrackets = /\[(.*?)\]/;
    const match = error.message.match(contentsWithinBrackets);
    if (match !== null) {
        const stringsArray = match[1].split(',');

        const positions: number[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const str of stringsArray) {
            const parsed = parseInt(str, 10);
            if (!Number.isNaN(parsed)) {
                positions.push(parsed);
            }
        }
        return positions;
    }
    return [];
};
