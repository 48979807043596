import { ParagraphText, SectionHeading, SimpleText, StandardButton, VerticalSpace } from '@imtbl/design-system';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { InternalRoutes } from '../../utils/internalRoutes';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import styles from './index.module.css';

export interface WalletDifferenceProps {
    backButtonClick?: () => void;
}
const WalletDifference = (props: WalletDifferenceProps) => {
    const { backButtonClick } = props;
    const history = useHistory();

    const handleBackButtonClick = () => {
        if (backButtonClick) {
            backButtonClick();
        } else {
            history.push(InternalRoutes.MISSING_WALLET);
        }
    };

    return (
        <StandardLinkRouteContainer>
            <VerticalSpace bottom="large">
                <SectionHeading>Not sure what to pick?</SectionHeading>
            </VerticalSpace>
            <div className={styles.provider}>
                <div className={styles.providerInfo}>
                    <SimpleText fontWeight="bold" fontSize="small">
                        Game Wallets
                    </SimpleText>
                </div>
                <ParagraphText className={styles.providerDescription} fontSize="small">
                    If you’re buying or selling an item from one of the games listed in this option, you’ll need to
                    connect to the game wallet to access your item/s. Go back and open this option to view the games
                    listed.
                </ParagraphText>
            </div>
            <div className={styles.provider}>
                <div className={styles.providerInfo}>
                    <SimpleText fontWeight="bold" fontSize="small">
                        Email wallet
                    </SimpleText>
                </div>
                <ParagraphText className={styles.providerDescription} fontSize="small">
                    This wallet is supported by Magic. It is separate to any other wallet you may have created using
                    your email address.
                </ParagraphText>
            </div>
            <div className={styles.provider}>
                <div className={styles.providerInfo}>
                    <SimpleText fontWeight="bold" fontSize="small">
                        MetaMask & more
                    </SimpleText>
                </div>
                <ParagraphText className={styles.providerDescription} fontSize="small">
                    We support a range of external wallets that are best suited for staking & trading across multiple
                    games.
                </ParagraphText>
            </div>
            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large" top="large">
                    <StandardButton testId="back-to-wallets" buttonKind="ultimate-cta" onClick={handleBackButtonClick}>
                        Go Back
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};

export default WalletDifference;
