import { BaseComponentPropTypes, Box, colors, FlexLayout, layoutHelpers } from '@imtbl/design-system';
import { ERC20TokenType } from '@imtbl/imx-sdk';
import React from 'react';

import { Balance } from '../Balance';
import { BalanceERC20 } from '../BalanceERC20';
import styles from './index.module.css';
import TokenIcon from './TokenIcon';

interface BalanceItemProps extends Pick<BaseComponentPropTypes, 'testId'> {
    type: string;
    amount?: string;
    symbol?: string;
    platform?: string;
    className?: string;
    imageUrl?: string | null;
}

export const BalanceItem = ({
    type,
    amount = 'N/A',
    symbol = '',
    platform,
    className,
    imageUrl,
    testId = 'balanceItem',
}: BalanceItemProps) => (
    <FlexLayout alignItems="center" className={className} testId={testId}>
        <Box
            width="38px"
            height="38px"
            className={styles.thumbnail}
            backgroundColor={colors.bg[900]}
            marginRight={layoutHelpers.gridUnits(3)}
        >
            <TokenIcon type={type} imageUrl={imageUrl} testId={`${testId}__tokenImageOrIcon`} />
        </Box>

        {type !== ERC20TokenType.ERC20 ? (
            <Balance type={type} amount={amount} platform={platform} testId={`${testId}__ethBalance`} />
        ) : (
            <BalanceERC20 ticker={symbol} amount={amount} platform={platform} testId={`${testId}__erc20Balance`} />
        )}
    </FlexLayout>
);
