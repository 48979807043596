import { BaseComponentPropTypes, SelectBox, SelectBoxOption } from '@imtbl/design-system';
import React from 'react';

import { TokenDataType, TokensListType } from '../../hooks/useTokensList.hook';

interface AvailableTokensProps extends Pick<BaseComponentPropTypes, 'testId'> {
    tokens: TokensListType;
    onSelect: (value: any) => void;
    selectedToken?: string;
    className?: string;
    disabled?: boolean;
}

export const CurrencySelectBox = ({
    tokens,
    onSelect,
    selectedToken,
    className,
    disabled = false,
}: AvailableTokensProps) => {
    const handleChange = (option: SelectBoxOption<string>) => {
        onSelect(tokens.find((token) => token.token_address === option.value));
    };

    const options: SelectBoxOption<string>[] = [];
    tokens.forEach((token: TokenDataType) => {
        if (token) {
            options.push({
                label: `${token.name} (${token.symbol})`,
                value: token.token_address,
            });
        }
    });

    const selectedOption = options.findIndex((item: SelectBoxOption<string>) => item.value === selectedToken);
    const defaultOption = selectedOption >= 0 ? selectedOption : undefined;

    return (
        <SelectBox
            testId="sortingSelectBox"
            inputId="selectbox"
            labelText="Choose a currency"
            options={options}
            disabled={disabled}
            selectedOveride={defaultOption}
            onChange={handleChange}
            className={className}
        />
    );
};
