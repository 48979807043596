import { BaseComponentPropTypes } from '@imtbl/design-system';
import { ERC20TokenType } from '@imtbl/imx-sdk';
import React from 'react';

import { useL1Balance } from '../../../../hooks/useL1Balance';
import { Balance } from '../../../Balance';
import { useDepositBalance } from './DepositBalanceProvider';

interface L1BalanceProps extends Pick<BaseComponentPropTypes, 'testId'> {
    walletAddress: string;
    tokenAddress?: string;
    tokenImage: string;
}

export const L1Balance = ({ walletAddress, tokenAddress, tokenImage = '' }: L1BalanceProps) => {
    const { balance, setBalance } = useDepositBalance();

    useL1Balance({
        walletAddress,
        tokenAddress,
        onUpdateBalance: setBalance,
    });

    return (
        <Balance
            testId="totalAmount"
            fontSize="normal"
            type={ERC20TokenType.ERC20}
            amount={typeof tokenAddress !== 'undefined' && balance ? balance.toString() : '-'}
            tokenImage={tokenImage || ''}
        />
    );
};
