import {
    errorsToError,
    EthAddressBrand,
    getProvider,
    ImmutableMethodParams,
    ImmutableMethodResults,
    ImmutableXClient,
    PositiveBigNumber,
} from '@imtbl/imx-sdk';
import { either } from 'fp-ts';
import { constVoid, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { Branded } from 'io-ts';

import { TransferInput } from '../components';
import { FullTransferTokenInfo } from '../types/SharedTransfer.types';
import { ProvidersConfig } from '../utils/getProvidersConfig';
import { calculateTokenQuantity, NotifyMethod } from '.';
import { LinkClientConfig } from './types';

export function transfer(
    config: LinkClientConfig,
    { token, to: receiverEtherKey }: TransferInput,
    notify: NotifyMethod,
    providerOptions: ProvidersConfig,
): TE.TaskEither<Error, void> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.chain(() => TE.bindTo('provider')(getProvider(providerOptions))),
        TE.bind('client', ({ provider }) =>
            ImmutableXClient.buildF({
                ...config,
                signer: provider.getSigner(),
            }),
        ),
        TE.chainFirst(() => TE.fromIO(() => notify({ msg: 'Transferring' }))),
        TE.bind('quantity', () =>
            pipe(calculateTokenQuantity(token), PositiveBigNumber.decode, either.mapLeft(errorsToError), TE.fromEither),
        ),
        TE.bind('transfer', ({ client, client: { address }, quantity }) =>
            client.transferF({
                sender: address,
                token: token.token,
                quantity,
                receiver: receiverEtherKey,
            }),
        ),
        TE.map(constVoid),
    );
}

export function transferV2(
    client: ImmutableXClient,
    { tokenDetails, originalParams: { toAddress } }: FullTransferTokenInfo,
    notify: NotifyMethod,
): TE.TaskEither<Error, ImmutableMethodResults.ImmutableTransferResult> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.bind('quantity', () =>
            pipe(
                calculateTokenQuantity(tokenDetails),
                PositiveBigNumber.decode,
                either.mapLeft(errorsToError),
                TE.fromEither,
            ),
        ),
        TE.chainFirst(() => TE.fromIO(() => notify({ msg: 'Transferring' }))),
        TE.bind('transferResponse', ({ quantity }) =>
            client.transferF({
                sender: client.address,
                token: tokenDetails.token,
                quantity,
                receiver: toAddress as Branded<string, EthAddressBrand>,
            }),
        ),
        TE.map(({ transferResponse }) => transferResponse),
    );
}

export function batchNftTransfer(
    client: ImmutableXClient,
    transfers: ImmutableMethodParams.ImmutableTransferRequest[],
    notify: NotifyMethod = () => ({}),
): Promise<ImmutableMethodResults.ImmutableTransferV2Result> {
    notify({ msg: 'Connecting wallet' });
    notify({ msg: 'Transferring' });
    return client.transferV2({
        sender_ether_key: client.address,
        transfer_request: transfers,
    });
}
