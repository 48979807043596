import { History, Location } from 'history';

import { location } from './window';

export const { href, origin, pathname } = location;

export const getLocationSearch = () => {
    return window?.location?.search?.replace(/&?sdkVersion=[\d\w.]+/, '') ?? '';
};

const searchProxy = {
    get search() {
        return getLocationSearch();
    },
};

export const { search } = searchProxy;

export function goTo(locationState: Location, history: History, path: string) {
    if (locationState.pathname !== path) {
        history.push(path);
    }
}
