export type Timeout = ReturnType<typeof setTimeout>;

const MAXIMUM_WAIT_TIME = 6000;

export async function poll<T>(fn: () => Promise<T>, fnCondition: (result: T) => boolean, ms: number) {
    let waitTimeMs = ms;
    let totalElapsedTime = 0;

    let result = await fn();
    while (fnCondition(result)) {
        // eslint-disable-next-line no-await-in-loop
        const timeoutId: Timeout = await wait(waitTimeMs);
        timeoutId && clearTimeout(timeoutId);
        // eslint-disable-next-line no-await-in-loop
        result = await fn();

        totalElapsedTime += waitTimeMs;
        waitTimeMs = Math.min(Math.max(totalElapsedTime * 0.1, waitTimeMs), MAXIMUM_WAIT_TIME);
    }
    return result;
}

export function wait(ms = 16000): Promise<Timeout> {
    return new Promise((resolve) => {
        const toId = setTimeout(() => {
            resolve(toId);
        }, ms);
    });
}
