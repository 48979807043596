import { AnalyticsEvent } from './types';
import { getAppName, getUserAgent, getUTMParams, getWalletAddressFromLocalStorage } from './utils';

declare const window: any;
export type AkumaEvent = AnalyticsEvent & {
    appName: string;
    appBrand: string;
    appPlatform: string;
    appVersion: string;
    moduleName: string;
};

export type AnalyticsEventOptions = {
    requestType?: string;
};

export class AkumaAnalytics {
    // Default values for the passed core attributes.
    // NOTE: These can be overriden by constructor!
    appName = 'Immutable X';

    appBrand = 'imx';

    appPlatform = 'web';

    appVersion = '';

    moduleName = '';

    constructor(version?: string) {
        if (version && version.length) {
            this.appVersion = version;
        }

        const name = getAppName();
        if (name && name.length) {
            this.appName = name;
        }
    }

    publish(analyticsEvent: AnalyticsEvent, options: AnalyticsEventOptions = {}) {
        const publishEvent = {
            appName: this.appName,
            appBrand: this.appBrand,
            appPlatform: this.appPlatform,
            appVersion: this.appVersion,
            moduleName: this.moduleName,
            ...analyticsEvent,
        } as AkumaEvent;

        const walletAddress = getWalletAddressFromLocalStorage();
        const userAgent = getUserAgent();
        const utmParams = getUTMParams();
        let { userProperties = {} } = publishEvent;

        if (!userProperties.userAgent && userAgent) {
            publishEvent.userProperties = {
                ...userProperties,
                userAgent,
            };
        }

        if (Object.entries(utmParams).length) {
            ({ userProperties = {} } = publishEvent);
            publishEvent.userProperties = {
                ...userProperties,
                ...utmParams,
            };
        }

        if (walletAddress) {
            publishEvent.userId = walletAddress;
        }

        try {
            return window.akuma(publishEvent, options);
        } catch (e) {
            console.error('Akuma Event Publish ERROR', e);
            return e;
        }
    }
}
