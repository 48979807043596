import { css } from '@emotion/css';
import { BaseComponentPropTypes, FlexLayout, layoutHelpers } from '@imtbl/design-system';
import React, { ReactNode } from 'react';

export interface StandardLinkRouteContainerPropTypes extends BaseComponentPropTypes {
    children: ReactNode;
}
/**
 * StandardLinkRouteContainer is the general wrapper for Link route content
 * It is used in conjunction with StandardLinkRouteButtonsFooter to make content
 * scrollable behind the fixed buttons at the bottom
 */
export const StandardLinkRouteContainer = ({ children, testId }: StandardLinkRouteContainerPropTypes) => {
    /**
     * NOTE: Top padding needs to be 24px + 4px
     * (added for the blue line below the header).
     */
    return (
        <FlexLayout
            testId={testId}
            paddingTop={layoutHelpers.gridUnits(7)}
            paddingLeft={layoutHelpers.gridUnits(6)}
            paddingRight={layoutHelpers.gridUnits(6)}
            flexDirection="column"
            flexGrow={1}
            className={css`
                overflow-y: auto;
                padding-bottom: calc(var(--standardLinkRouteFooterHeight) + 24px);
            `}
        >
            {children}
        </FlexLayout>
    );
};
