import { ExchangeCurrency, ExchangeProvider } from '../types';

export function validateCurrency(
    currencies: string[],
    availableCurrencies: ExchangeCurrency[],
    provider: string = ExchangeProvider.moonpay,
): boolean {
    return currencies.every((currency) => {
        const index = availableCurrencies.findIndex(
            (available) =>
                available.symbol.toLowerCase() === currency.toLowerCase() &&
                available.provider.toLowerCase() === provider.toLowerCase(),
        );

        return index !== -1;
    });
}

export function getSupportedCurrenciesForProvider(
    availableCurrencies: ExchangeCurrency[],
    param: keyof ExchangeCurrency = 'symbol',
    provider: string = ExchangeProvider.moonpay,
): any[] {
    const supported = availableCurrencies.filter((available) => available.provider === provider);

    return supported.map((item: ExchangeCurrency) => {
        return item[param];
    });
}
