import { Web3Provider } from '@ethersproject/providers';
import { colors, SectionHeading, SimpleText, StandardButton, VerticalSpace } from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, LinkParams, messageTypes } from '@imtbl/imx-sdk';
import React, { useCallback, useEffect } from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { DispatchSetError, LinkConfig, ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, ScreenEventName } from '../../lib/analytics/types';
import { createButtonEvent, createScreenEvent } from '../../lib/analytics/utils';
import { isValidMessage } from '../../lib/sign';
import { CancelLink, ErrorMessage } from '../common';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { ErrorMessageProps } from '../ErrorMessage';

export type SignMessageProps = {
    config: LinkConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    params: LinkParams.Sign;
    setErrorLog: DispatchSetError;
};

const signErrorMessageProps = (title: string, paragraph: string, onFinish: () => void): ErrorMessageProps => ({
    heading: title,
    body: <ErrorMessage message={paragraph} onFinish={onFinish} withTitle={false} />,
});

const SignMessage = ({ parent, params, provider, config, setErrorLog }: SignMessageProps) => {
    const messages = useI18nMessaging({});
    const isIframe = window.location !== window.parent?.location;
    const requestOriginUrl = new URL(isIframe ? window.location.origin : document.referrer).hostname;

    if (!isValidMessage(params.message, config.deniedSignMessagePatterns)) {
        sendAnalytics(
            createScreenEvent(ScreenEventName.signMessageInvalid, {
                message: params.message,
                description: params.description,
            }),
        );

        setErrorLog(
            { message: messages.signMessage.errors.messages.invalidMessage },
            signErrorMessageProps(
                messages.signMessage.errors.title,
                messages.signMessage.errors.messages.invalidMessage,
                () => parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*'),
            ),
        );

        return null;
    }

    useEffect(() => {
        sendAnalytics(
            createScreenEvent(ScreenEventName.signMessageOpened, {
                message: params.message,
                description: params.description,
            }),
        );
    }, []);

    const onCancel = useCallback(() => {
        sendAnalytics(
            createButtonEvent(ButtonEventName.signMessageCancelPressed, {
                message: params.message,
                description: params.description,
            }),
        );
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent]);

    const onNext = async () => {
        sendAnalytics(
            createButtonEvent(ButtonEventName.signMessageNextPressed, {
                message: params.message,
                description: params.description,
            }),
        );
        try {
            const signer = provider.getSigner();
            const signedMessage = await signer.signMessage(params.message);
            parent.postMessage(
                {
                    type: LINK_MESSAGE_TYPE,
                    message: messageTypes.success,
                    data: {
                        result: signedMessage,
                    },
                },
                '*',
            );
        } catch (e) {
            console.error(e);
            sendAnalytics(createButtonEvent(ButtonEventName.signMessageCancelPressed));
        }

        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    };

    return (
        <>
            <StandardLinkRouteContainer>
                <VerticalSpace bottom="small" top="x-large">
                    <SectionHeading testId="heading">Signature request</SectionHeading>
                </VerticalSpace>
                <VerticalSpace bottom="large">
                    <SimpleText fontSize="small" fillColor={colors.light[300]}>
                        {requestOriginUrl} requested your signature:
                    </SimpleText>
                </VerticalSpace>
                <VerticalSpace bottom="large">
                    <SimpleText fontSize="small" fontStyle="italic" fillColor={colors.light[300]}>
                        {params.description}
                    </SimpleText>
                </VerticalSpace>
                <StandardLinkRouteButtonsFooter>
                    <StandardButton testId="buttonNext" buttonKind="ultimate-cta" onClick={onNext}>
                        Next
                    </StandardButton>
                    <CancelLink title="Cancel" onClick={onCancel} />
                </StandardLinkRouteButtonsFooter>
            </StandardLinkRouteContainer>
        </>
    );
};

export default SignMessage;
