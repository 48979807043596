export const useMetamaskConnector = () => {
    const changeWallet = async () => {
        return (window as any).ethereum.request({
            method: 'wallet_requestPermissions',
            params: [
                {
                    eth_accounts: {},
                },
            ],
        });
    };
    return { changeWallet };
};
