import { Web3Provider } from '@ethersproject/providers';
import { LinkParamsCodecs, LinkParamsF } from '@imtbl/imx-sdk';
import queryString from 'query-string';
import React from 'react';

import { Messages } from '../../i18n';
import { DispatchSetError, DispatchSetLoading, LinkClientConfig, ParentWindow } from '../../lib';
import { getLocationSearch } from '../../utils/location';
import { SellFlexible } from './SellFlexible';
import { SellInEth } from './SellInEth';

export type SellProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    setErrorLog: DispatchSetError;
    setLoading: DispatchSetLoading;
};

const isFlexible = (params: LinkParamsF.Sell): boolean =>
    !params || !('amount' in params) || 'currencyAddress' in params;

export const Sell = ({ config, setErrorLog, ...restProps }: SellProps) => {
    const props = { config, setErrorLog, ...restProps };
    // FIXME: The type assertion for sellparams was broken by the below commit. For some reason
    // the assertion doesn't like the new fees t.Array
    // https://github.com/immutable/imx-sdk-js/commit/ae36ff9bf3bdc3621e223fd798776b7b52c8dd93
    const rawSellParams = queryString.parse(getLocationSearch());

    if (!LinkParamsCodecs.Sell.is(rawSellParams)) return null;

    const sellParams = rawSellParams;

    if (isFlexible(sellParams)) {
        return <SellFlexible {...props} />;
    }

    return <SellInEth {...props} />;
};
