import { Web3Provider } from '@ethersproject/providers';
import {
    BaseComponentPropTypes,
    Box,
    colors,
    FlexLayout,
    HeadingText,
    Icon,
    ImxHorizontalCondensedAssetTile,
    ImxTransactionKinds,
    InlineEllipsizedTextLine,
    measurements,
    OuterSpace,
    SimpleText,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import { ERC721TokenType, LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import ClipboardJS from 'clipboard';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { LinkCoreContext, LinkUiCoreContext } from '../../context/App.context';
import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { DispatchSetError, DispatchSetLoading, LinkClientConfigTS, ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../lib/analytics/utils';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { useHistory } from './history.hook';
import styles from './index.module.css';

export type HistoryProps = Pick<BaseComponentPropTypes<HTMLDivElement>, 'testId'> & {
    config: LinkClientConfigTS;
    parent: ParentWindow;
    provider: Web3Provider;
    setErrorLog: DispatchSetError;
    setLoading: DispatchSetLoading;
    flags?: LDFlagSet;
};

const Erc721AdditionalNotes = ({
    mode,
    testId,
    toAddress,
    fromAddress,
}: {
    mode: 'in' | 'out';
    testId?: string;
    toAddress?: string;
    fromAddress?: string;
}) => {
    const isNegative = mode === 'out';
    return (
        <FlexLayout alignItems="flex-end" flexDirection="column" testId={`${testId}__additionalNote`}>
            <FlexLayout>
                <OuterSpace right={measurements.SpacingTeeShirtAmounts['x-small']}>
                    <SimpleText
                        fontSize="small"
                        fillColor={isNegative ? colors.red[300] : colors.green[500]}
                        fontWeight="bold"
                        testId={`${testId}__additionalNote__sign`}
                    >
                        {isNegative ? '-' : '+'}
                    </SimpleText>
                </OuterSpace>
                <SimpleText
                    fontSize="small"
                    fillColor={colors.light[100]}
                    fontWeight="bold"
                    testId={`${testId}__additionalNote__statusText`}
                >
                    {isNegative ? 'Removed from' : 'Added to'} Inventory
                </SimpleText>
            </FlexLayout>
            {toAddress || fromAddress ? (
                <SimpleText
                    fontSize="tag"
                    fillColor={colors.light[300]}
                    testId={`${testId}__additionalNote__addressText`}
                >
                    {isNegative ? 'Sent to: ' : 'From: '}
                    <InlineEllipsizedTextLine
                        text={isNegative ? toAddress || '' : fromAddress || ''}
                        fontSize="tag"
                        fillColor={colors.light[300]}
                    />
                </SimpleText>
            ) : null}
        </FlexLayout>
    );
};

export const History = ({ testId, ...props }: HistoryProps) => {
    const copyIcon = 'action_copy';
    const successfulCopyIcon = 'action_accept__circle';
    const flags = useLaunchDarklyFlags();
    const { data } = useHistory({ ...props, flags });
    const [icon, setIcon] = useState(copyIcon);
    const { parent } = useContext(LinkCoreContext);
    const { imageResizerServiceUrl } = useContext(LinkUiCoreContext);

    useEffect(() => {
        sendAnalytics(
            // NOTE: Tests are not required for non-critical analytics events.
            createScreenEvent(ScreenEventName.transactionHistoryOpened),
            createFlowEvent(FlowEventName.historyBrowseStarted),
        );

        const clipboard = new ClipboardJS('.clipboard');
        clipboard.on('success', () => {
            sendAnalytics(createButtonEvent(ButtonEventName.copyWalletAddressPressed));
            setIcon(successfulCopyIcon);
            setTimeout(() => setIcon(copyIcon), 3000);
        });
    }, []);

    const handleClose = useCallback(() => {
        sendAnalytics(createButtonEvent(ButtonEventName.transactionHistoryClosePressed));
        parent?.postMessage(
            {
                type: LINK_MESSAGE_TYPE,
                message: messageTypes.close,
            },
            '*',
        );
    }, [parent]);

    return (
        <>
            <FlexLayout
                className={styles.header}
                flexDirection="row"
                flexWrap="nowrap"
                alignItems="center"
                testId={testId}
                justifyContent="space-between"
            >
                <Box className={styles.heading} flexGrow={1}>
                    <HeadingText fillColor={colors.light[100]} fontSize="large">
                        Transactions
                    </HeadingText>
                </Box>
                {data !== undefined && (
                    <Box className={styles.address}>
                        <SimpleText
                            fillColor={colors.light[100]}
                            fontWeight="bold"
                            fontSize="tag"
                            data-clipboard-text={data.address}
                            testId="copy-wallet-button"
                            className="clipboard"
                            title="Copy wallet address"
                        >
                            Wallet:&nbsp;
                            <Icon
                                ligature={icon}
                                fillColor={colors.blue[100]}
                                iconSize="medium"
                                className={styles.copyIcon}
                            />
                            <SimpleText
                                fillColor={colors.dark[100]}
                                title={data.address}
                                fontWeight="bold"
                                fontSize="tag"
                            >
                                {data.address}
                            </SimpleText>
                        </SimpleText>
                    </Box>
                )}
            </FlexLayout>
            {data !== undefined && (
                <StandardLinkRouteContainer testId={`${testId}__transactionListing`}>
                    {data.items.map((item, index) => {
                        const isErc721Token = item.tokenType === ERC721TokenType.ERC721;
                        const additionalNotesContent =
                            isErc721Token && /Transfer\sout|Withdrawal/.test(item.transactionType) ? (
                                <Erc721AdditionalNotes
                                    toAddress={/Transfer/.test(item.transactionType) ? item.toAddress : ''}
                                    mode="out"
                                    testId={testId}
                                />
                            ) : isErc721Token && /Transfer\sin|Deposit/.test(item.transactionType) ? (
                                <Erc721AdditionalNotes
                                    fromAddress={/Transfer/.test(item.transactionType) ? item.fromAddress : ''}
                                    mode="in"
                                    testId={testId}
                                />
                            ) : null;
                        return (
                            <ImxHorizontalCondensedAssetTile
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                testId={`${testId}__transactionListing__transaction`}
                                transactionKind={item.transactionType as ImxTransactionKinds}
                                transactionPrice={item.amount}
                                primaryText={item.primaryText}
                                secondaryText={item.secondaryText}
                                priceEffect={item.priceEffect}
                                isCurrency={item.isCurrency}
                                assetImagery={item.assetImageUrl}
                                transactionCostTokenImage={item.tokenImageUrl}
                                transactionDate={item.date}
                                transactionId={item.txId.toString()}
                                transactionLinkUrl={item.transactionLink}
                                additionalNote={additionalNotesContent}
                                imageResizeServiceUrl={imageResizerServiceUrl}
                            />
                        );
                    })}

                    <OuterSpace top={measurements.SpacingTeeShirtAmounts['x-large']}>
                        <SimpleText
                            fontStyle="italic"
                            fontSize="small"
                            fillColor={colors.light[300]}
                            textAlign="center"
                        >
                            End of transactions
                        </SimpleText>
                    </OuterSpace>
                    <StandardLinkRouteButtonsFooter>
                        <VerticalSpace bottom="large" top="large">
                            <StandardButton
                                buttonKind="primary"
                                onClick={handleClose}
                                testId={`${testId}__closeButton`}
                            >
                                Close
                            </StandardButton>
                        </VerticalSpace>
                    </StandardLinkRouteButtonsFooter>
                </StandardLinkRouteContainer>
            )}
        </>
    );
};
