import {
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import React, { useCallback, useMemo } from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { useScrollToTop } from '../../hooks/useScrollToTop.hook';
import { ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../lib/analytics/types';
import { createButtonEvent } from '../../lib/analytics/utils';
import { FullOrderInfoWithFinalStatus } from '../../types/SharedOrder.types';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { OrderSummaryListItem } from '../OrderSummaryListItem/OrderSummaryListItem.component';

export interface BuyCompletePropTypes extends BaseComponentPropTypes {
    parent: ParentWindow;
    orderDetails?: FullOrderInfoWithFinalStatus[];
}

export const BuyCompleteScreen = ({ parent, orderDetails, testId }: BuyCompletePropTypes) => {
    useScrollToTop();
    const textMessages = useI18nMessaging({});
    const onFinishCtaClick = useCallback(() => {
        function fillResults() {
            let results = {};
            (orderDetails || []).forEach((item) => {
                results = { ...results, [item.details.order_id]: { status: item.validationResult.status } };
            });
            return results;
        }

        sendAnalytics(createButtonEvent(ButtonEventName.buyNowConfirmedFinishPressed));
        parent.postMessage(
            {
                type: LINK_MESSAGE_TYPE,
                message: messageTypes.result,
                data: {
                    result: fillResults(),
                },
            },
            '*',
        );
    }, [parent]);

    const failedOrders = useMemo(() => {
        return orderDetails?.filter(
            (order) => order.validationResult.status === 'error' || order?.buyTransactionStatus?.status === 'error',
        );
    }, [orderDetails]);

    return (
        <StandardLinkRouteContainer testId={`${testId}__buyCompleteContainer`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId={`${testId}__buyCompleteContainer__heading`}>
                    {textMessages.buy.title.done}
                </SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.buy.bodyText.done}
            </ParagraphText>

            {failedOrders && failedOrders.length > 0 && (
                <>
                    <VerticalSpace top="x-large" bottom="large">
                        <ParagraphText
                            fontWeight="bold"
                            fillColor={colors.yellow[100]}
                            fontSize="small"
                            testId={`${testId}__buyCompleteContainer__partialFailuresText`}
                        >
                            {textMessages.buy.someItemsInvalid}
                        </ParagraphText>
                    </VerticalSpace>
                    <FlexLayout
                        flexGrow={1}
                        flexDirection="column"
                        testId={`${testId}__buyCompleteContainer__unsuccessfulOrders`}
                    >
                        {failedOrders?.map((order) => (
                            <OrderSummaryListItem
                                order={order}
                                testId={`${testId}__buyCompleteContainer__unsuccessfulOrders__orderSummary`}
                                key={order.details.order_id}
                            />
                        ))}
                    </FlexLayout>
                </>
            )}

            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large">
                    <StandardButton
                        testId={`${testId}__buyCompleteContainer__finishCta`}
                        buttonKind="ultimate-cta"
                        onClick={onFinishCtaClick}
                    >
                        {textMessages.standardUiButtons.continue}
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
