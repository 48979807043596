import { Web3Provider } from '@ethersproject/providers';
import {
    ERC20TokenType,
    ERC721TokenType,
    EthAddressBrand,
    ETHTokenType,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    messageTypes,
} from '@imtbl/imx-sdk';
import { Branded } from 'io-ts';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { LinkUiCoreContext } from '../../../context/App.context';
import { useGetBuyOrder } from '../../../hooks/useGetBuyOrder';
import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { useTokensList } from '../../../hooks/useTokensList.hook';
import { LinkClientConfig, ParentWindow } from '../../../lib';
import { sendAnalytics, sendAnalyticsAsync } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../../lib/analytics/utils';
import { acceptOffer, getAcceptOfferTokens } from '../../../lib/offers/acceptOffer';
import { getFeesFromSearchParams } from '../../../utils/getFeesFromSearchParams';
import { getLocationSearch } from '../../../utils/location';
import { LoadingUi } from '../../LoadingUi/LoadingUi.component';
import { OfferFlowComplete } from '../OfferFlowComplete.component';
import {
    AcceptOfferAssetError,
    AcceptOfferInvalidParametersError,
    AcceptOfferOrderError,
    AcceptOfferTokensListError,
} from './AcceptOfferError';
import { AcceptOfferPrompt } from './AcceptOfferPrompt.component';

enum AcceptOfferStages {
    Loading = 'loading',
    Prompt = 'prompt',
    Complete = 'complete',
}

export interface AcceptOfferProps {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
}
export const AcceptOffer = ({ config, parent, provider }: AcceptOfferProps) => {
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [offerStage, setOfferStage] = useState(AcceptOfferStages.Loading);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const textMessages = useI18nMessaging({});
    const acceptOfferParamsRaw = queryString.parse(getLocationSearch());
    const [asset, setAsset] = useState<any>();

    // Validation for acceptOffer should have orderId
    if (!acceptOfferParamsRaw.orderId && !parseInt(acceptOfferParamsRaw.orderId as string)) {
        setErrorLog(AcceptOfferInvalidParametersError, textMessages.offers.acceptOffer.invalidParameters);
        return null;
    }

    const feesFromParams = getFeesFromSearchParams();
    const sellFeePercentage = feesFromParams
        .map((f) => f.percentage)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const acceptOfferParams = {
        orderId: acceptOfferParamsRaw.orderId as string,
        fees: feesFromParams,
    };

    // Get all token currencies so we can get the details of the offer currency
    const { tokens: availableTokens, error: getTokensError } = useTokensList({ config });
    const hasTokensList = useMemo(() => availableTokens.length > 0, [availableTokens]);

    // Get order details from the new client.getOrderV3 in order to retrieve the offer to cancel
    const { order: buyOrder, error: getOrderError } = useGetBuyOrder({
        config,
        orderId: parseInt(acceptOfferParams.orderId),
    });

    // Get the details of the NFT so we can show the image preview
    useEffect(() => {
        const getAssetAsync = async () => {
            try {
                const client = await ImmutableXClient.build({
                    publicApiUrl: config.publicApiUrl,
                });

                const assetResult = await client.getAsset({
                    id: buyOrder.buy.type === ERC721TokenType.ERC721 ? buyOrder.buy.data.token_id : '0',
                    address:
                        buyOrder.buy.type === ERC721TokenType.ERC721
                            ? (buyOrder.buy.data.token_address as Branded<string, EthAddressBrand>)
                            : ('' as Branded<string, EthAddressBrand>),
                });
                setAsset(assetResult);
            } catch {
                setErrorLog(AcceptOfferAssetError, textMessages.offers.apiError);
            }
        };
        if (buyOrder) {
            getAssetAsync();
        }
    }, [buyOrder]);

    useEffect(() => {
        if (getTokensError) {
            setErrorLog(AcceptOfferTokensListError, textMessages.offers.apiError);
        }
    }, [getTokensError]);

    useEffect(() => {
        if (getOrderError) {
            setErrorLog(AcceptOfferOrderError, textMessages.offers.apiError);
        }
    }, [getOrderError]);

    useEffect(() => {
        if (hasTokensList && buyOrder) {
            sendAnalytics(
                createScreenEvent(ScreenEventName.acceptOfferPromptScreenOpened),
                createFlowEvent(FlowEventName.acceptOfferStarted),
            );
            setOfferStage(AcceptOfferStages.Prompt);
        }
    }, [hasTokensList, buyOrder]);

    // Get token sell details (currency) from list of tokens
    const offerCurrencyToken = useMemo(
        () =>
            availableTokens.find(
                (token) =>
                    token.token_address ===
                    (buyOrder?.sell.type === (ERC20TokenType.ERC20 || ETHTokenType.ETH)
                        ? buyOrder?.sell.data.token_address
                        : ''),
            ),
        [availableTokens, buyOrder],
    );

    const acceptOfferTokens = useMemo(() => {
        if (buyOrder) {
            return getAcceptOfferTokens(offerCurrencyToken, buyOrder);
        }
        return null;
    }, [offerCurrencyToken, buyOrder]);

    const acceptOfferTransaction = useCallback(async () => {
        if (buyOrder && acceptOfferTokens) {
            setTransactionLoading(true);
            sendAnalytics(createButtonEvent(ButtonEventName.acceptOfferPromptConfirmBtnPressed));
            await acceptOffer({
                config,
                orderId: buyOrder.order_id,
                acceptOfferTokens,
                fees: acceptOfferParams.fees || [],
                provider,
                parent,
                setErrorLog,
            });
            setOfferStage(AcceptOfferStages.Complete);
            setTransactionLoading(false);
        }
    }, [buyOrder, acceptOfferTokens, setErrorLog, setTransactionLoading, parent]);

    const onAcceptOfferCompleteClick = useCallback(async () => {
        await sendAnalyticsAsync(createButtonEvent(ButtonEventName.acceptOfferCompleteContinueBtnPressed));
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent]);

    switch (offerStage) {
        case AcceptOfferStages.Complete:
            return (
                <OfferFlowComplete
                    testId="acceptOfferComplete"
                    offerMessages={textMessages.offers.acceptOffer}
                    onContinueClick={onAcceptOfferCompleteClick}
                />
            );
        case AcceptOfferStages.Prompt:
            return (
                <>
                    {transactionLoading && <LoadingUi showLoading testId="acceptOfferLoadingUi" />}
                    <AcceptOfferPrompt
                        testId="acceptOfferPrompt"
                        parent={parent}
                        acceptOfferTransaction={acceptOfferTransaction}
                        buyOrder={buyOrder}
                        sellFeePercentage={sellFeePercentage}
                        offerCurrencyToken={offerCurrencyToken}
                        asset={asset}
                        loading={transactionLoading}
                    />
                </>
            );
        case AcceptOfferStages.Loading:
        default:
            return <LoadingUi showLoading testId="acceptOfferLoadingUi" />;
    }
};
