import { version as dsVersion, versionHelpers } from '@imtbl/design-system';
import { version as sdkVersion } from '@imtbl/imx-sdk';

import { isProdEnv } from '../utils/environment';

const devVersion = process.env.REACT_APP_GIT_SHA || 'unknown';
const prodVersion = process.env.REACT_APP_GIT_TAG || 'unknown';

export const versions = {
    linkVersion: isProdEnv ? prodVersion : devVersion,
    sdkVersion,
    dsVersion,
};

export const prettyPrintVersions = () => {
    versionHelpers.prettyLogImtblPackageAndVersion('design-system', versions.dsVersion);
    versionHelpers.prettyLogImtblPackageAndVersion('imx-sdk', versions.sdkVersion);
    versionHelpers.prettyLogImtblPackageAndVersion('imx-link', versions.linkVersion);
};
