import { Web3Provider } from '@ethersproject/providers';
import { BaseComponentPropTypes } from '@imtbl/design-system';
import { EthAddress } from '@imtbl/imx-sdk';
import React from 'react';

import { useBuildClient } from '../../hooks/useBuildClient.hook';
import { useGetEthAddress } from '../../hooks/useGetAddress.hook';
import { useGetFullTransferDetails } from '../../hooks/useGetFullTransferDetails.hook';
import { useMakeTransferTransaction } from '../../hooks/useMakeTransferTransaction.hook';
import { LinkClientConfig, ParentWindow, TokenWithDetails } from '../../lib';
import { LoadingUi } from '../LoadingUi/LoadingUi.component';
import { TransferCompleteScreen } from './TransferCompleteScreen.component';
import { TransferPromptScreen } from './TransferPromptScreen.component';

export type TransferV2PropTypes = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
} & Pick<BaseComponentPropTypes, 'testId'>;

export type TransferInput = {
    token: TokenWithDetails;
    to: EthAddress;
};

export const TransferV2 = ({ config, parent, provider, testId }: TransferV2PropTypes) => {
    const ethAddress = useGetEthAddress(provider);
    const client = useBuildClient(config.publicApiUrl);
    const { loading: initialViewLoading, transferDetails } = useGetFullTransferDetails({
        client,
        ethAddress,
        config,
    });
    const { transacting, transferResults, finalConsolidatedTransfers, beginTransferTransaction } =
        useMakeTransferTransaction({
            config,
            provider,
            transferDetails,
            parent,
        });

    const showLoadingUi = initialViewLoading || transacting;
    return transferResults.length ? (
        <TransferCompleteScreen parent={parent} testId={testId} transferDetails={finalConsolidatedTransfers} />
    ) : (
        <>
            <LoadingUi showLoading={showLoadingUi} testId={`${testId}__loadingUi`} />
            {!showLoadingUi && transferDetails && (
                <TransferPromptScreen
                    parent={parent}
                    testId={testId}
                    beginTransferTransaction={beginTransferTransaction}
                    transferDetails={transferDetails}
                />
            )}
        </>
    );
};
