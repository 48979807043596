import { colors, ParagraphText, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import { AwaitingOrderStatus } from '@imtbl/imx-sdk';
import React from 'react';

export const errorMessageMapper: Partial<Record<AwaitingOrderStatus, string>> = {
    [AwaitingOrderStatus.Expired]: 'Code has expired, please try again.',
    [AwaitingOrderStatus.MFATriggerFailed]: 'Incorrect code entered, please try again.',
    [AwaitingOrderStatus.SettlementFailed]: 'Could not finalise transaction, please try again.',
};

interface IOTPErrorProps {
    orderStatus?: AwaitingOrderStatus;
    message?: string;
}

export const OTPError = ({ orderStatus, message }: IOTPErrorProps) => {
    return (
        <>
            <VerticalSpace bottom="small">
                <SectionHeading fillColor={colors.red[300]}>Error</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {message || errorMessageMapper[orderStatus as AwaitingOrderStatus]}
            </ParagraphText>
        </>
    );
};
