import { VerticalSpace } from '@imtbl/design-system';
import React, { ReactNode } from 'react';

import { StyledReactRouterLink } from '../DsConnectorButtons';
import styles from './index.module.css';

export type FooterLinkProps = {
    testId?: string;
    onClick?: () => void;
    to: string;
    children: ReactNode;
};

const FooterLink = ({ testId, onClick, to, children }: FooterLinkProps) => {
    return (
        <VerticalSpace bottom="large" top="large">
            <StyledReactRouterLink
                testId={testId}
                onClick={onClick}
                to={to}
                textAlign="center"
                fontWeight="bold"
                fontSize="small"
                className={styles.footerLink}
            >
                {children}
            </StyledReactRouterLink>
        </VerticalSpace>
    );
};

export default FooterLink;
