import { ExchangeProvider, ImmutableExchangeTypeV3, LINK_MESSAGE_TYPE, LinkParams, messageTypes } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';

import { useGetEthAddress } from '../../../hooks/useGetAddress.hook';
import { TokenDataType, TokensListType, useTokensList } from '../../../hooks/useTokensList.hook';
import { closeWindow, LinkConfig } from '../../../lib';
import { search } from '../../../utils/location';
import { getValueAsArray } from '../../../utils/parser';
import { LoadingMessage, StandardLinkRouteContainer } from '../../common';
import { exchangeErrorMessage } from '../exchangeErrorMessage';
import { ExchangeProps } from '../Onramp';
import { ExchangeCurrency } from '../types';
import { useSupportedCurrencies } from '../useSupportedCurrencies.hook';
import { OfframpForm } from './OfframpForm';
import { ProviderOfframp } from './ProviderOfframp';

enum OfframpStages {
    Loading,
    UserEntry,
    Widget,
}

const filterTokens = (
    list: TokensListType,
    currencies: string[],
    availableCurrencies: ExchangeCurrency[],
): TokensListType =>
    list.filter((token) => {
        const symbol = token.symbol.toLowerCase();
        const lowCaseCurrencies = currencies.map((currency) => currency.toLowerCase());
        return availableCurrencies.some(
            (item) =>
                item.symbol.toLowerCase() === symbol && (currencies.length === 0 || lowCaseCurrencies.includes(symbol)),
        );
    });

export type OfframpProps = ExchangeProps & {
    config: LinkConfig;
};

function isProviderOnly(params: LinkParams.CryptoToFiat, currencies: string[]): boolean {
    const hasSingleCurrency = !!currencies && currencies.length === 1;
    const hasAmount = !!params.amount;

    return hasSingleCurrency && hasAmount;
}

export const Offramp = (props: OfframpProps) => {
    const queryParams = queryString.parse(search) as unknown as LinkParams.Offramp;
    const currencies = getValueAsArray(queryParams, 'cryptoCurrencies') || [];
    const { parent, provider, config, setLoading, setErrorLog, loading, messages } = props;

    const { tokens: availableTokens } = useTokensList({ config: config.client });
    const [stage, setStage] = useState<OfframpStages>(OfframpStages.Loading);
    const [amount, setAmount] = useState<BigNumber>(new BigNumber(0));
    const [token, setToken] = useState<TokenDataType>();
    const [wantedTokens, setWantedTokens] = useState<TokensListType>([]);
    const user = useGetEthAddress(provider);
    const hasTokensList = availableTokens.length > 0;
    const { currencies: availableCurrencies, error: currenciesError } = useSupportedCurrencies({
        config: config.client,
        exchangeType: ImmutableExchangeTypeV3.offramp,
        provider: queryParams.provider as ExchangeProvider,
    });

    const onCancel = closeWindow(parent);
    const onConfirm = (selectedAmount: BigNumber, selectedToken: TokenDataType) => {
        setAmount(selectedAmount);
        setToken(selectedToken);
        setStage(OfframpStages.Widget);
    };

    useEffect(() => {
        if (currenciesError) {
            setErrorLog(
                { message: messages.offramp.error.couldNotGetCurrencies },
                exchangeErrorMessage(messages.onramp.title.error, messages.offramp.error.couldNotGetCurrencies, () =>
                    parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*'),
                ),
            );
        }
    }, [currenciesError]);

    useEffect(() => {
        if (hasTokensList && availableCurrencies) {
            const filteredTokens = filterTokens(availableTokens, currencies, availableCurrencies);
            if (filteredTokens.length > 0) {
                setWantedTokens(filteredTokens);

                if (currencies.length === 1) {
                    setToken(
                        filteredTokens.find(
                            (filteredToken) => filteredToken.symbol.toLowerCase() === currencies[0].toLowerCase(),
                        ),
                    );
                }
            } else {
                setErrorLog(
                    new Error('No currencies left after filtering'),
                    messages.offramp.error.unavailableCurrencies,
                );
            }
        }
    }, [availableTokens, availableCurrencies]);

    useEffect(() => {
        if (hasTokensList && !!user) {
            if (isProviderOnly(queryParams, currencies)) {
                setToken(availableTokens.find((item) => item.symbol.toLowerCase() === currencies[0].toLowerCase()));
                if (queryParams.amount) {
                    setAmount(new BigNumber(queryParams.amount));
                }
                setStage(OfframpStages.Widget);
            } else {
                setStage(OfframpStages.UserEntry);
            }
            setLoading(false);
        } else {
            setStage(OfframpStages.Loading);
            setLoading(true);
        }
    }, [hasTokensList, user]);

    const stages = {
        [OfframpStages.Loading]: (
            <StandardLinkRouteContainer>
                <LoadingMessage />
            </StandardLinkRouteContainer>
        ),
        [OfframpStages.UserEntry]: (
            <StandardLinkRouteContainer>
                <OfframpForm
                    config={config}
                    availableTokens={wantedTokens}
                    selectedToken={token}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    user={user as string}
                    loading={loading}
                    setLoading={setLoading}
                    providerName={queryParams.provider as ExchangeProvider}
                />
            </StandardLinkRouteContainer>
        ),
        [OfframpStages.Widget]: (
            <ProviderOfframp
                {...props}
                currency={token?.symbol || 'eth'}
                amount={amount.toString()}
                availableTokens={availableTokens}
                availableCurrencies={availableCurrencies}
                providerName={queryParams.provider as ExchangeProvider}
            />
        ),
    };

    return stages[stage];
};
