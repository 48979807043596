// For events that need to be debounced.
export const EVENT_DISPATCH_DELAY = 200;

export enum UTMParam {
    campaign = 'utm_campaign',
    content = 'utm_content',
    source = 'utm_source',
    medium = 'utm_medium',
    term = 'utm_term',
    adId = 'ad_id',
}

export enum LSUserId {
    walletId = 'wallet_id',
    userId = 'user_id',
    user = 'user',
    uid = '_uid',
}

export enum EventType {
    screen = 'screen',
    button = 'Btn',
    flow = 'flow',
    link = 'Lnk',
    box = 'Box',
    exchange = 'exchange',
    wallet = 'wallet',
}

export type AnalyticsEventProperties = {
    [key: string]: any;
    origin?: string;
    assetId?: string;
    price?: string;
};

export type AnalyticsEvent = {
    eventType: EventType;
    eventName: string;
    userId?: string;

    quantity?: number;
    revenue?: number;
    price?: number;

    sessionProperties?: {
        [key: string]: any;
    };

    eventProperties?: AnalyticsEventProperties;

    userProperties?: {
        [key: string]: any;
        userAgent?: string;
    };
};

export enum ButtonEventName {
    setupKeySetUpPressed = 'linkSetupKey_SetUpBtn_pressed',
    setupNoneMetamaskPressed = 'linkSetupWeb3None_MetamaskBtn_pressed',
    setupNoneGamestopPressed = 'linkSetupWeb3None_GamestopBtn_pressed',
    setupSignInConnectPressed = 'linkSetupWalletSignIn_ConnectBtn_pressed',
    setupSignInHardwareWalletPressed = 'linkSetupWalletSignIn_HardwareWalletBtn_pressed',
    setupSignInChangePressed = 'linkSetupWalletSignIn_ChangeBtn_pressed',
    setupCompleteFinishPressed = 'linkSetupComplete_FinishBtn_pressed',
    setupUnlockConnectPressed = 'linkSetupUnlock_ConnectBtn_pressed',
    setupGameWalletsPressed = 'linkSetup_GameWalletsBtn_pressed',

    buyNowNotifiedConfirmPressed = 'linkNotificationBuyNow_ConfirmBtn_pressed',
    buyNowNotifiedCancelPressed = 'linkNotificationBuyNow_DenyBtn_pressed',
    buyNowConfirmedFinishPressed = 'linkConfirmedBuyNow_FinishBtn_pressed',

    listAssetConfirmedFinishPressed = 'listAssetConfirmed_FinishBtn_pressed',

    depositNotifiedConfirmPressed = 'linkNotificationDeposit_ConfirmBtn_pressed',
    depositConfirmedFinishPressed = 'linkConfirmedDeposit_FinishBtn_pressed',

    listForSaleNotifiedConfirmPressed = 'linkNotificationListForSale_ConfirmBtn_pressed',
    listForSaleNotifiedCancelPressed = 'linkNotificationListForSale_DenyBtn_pressed',
    listForSaleConfirmedFinishPressed = 'linkConfirmedListForSale_FinishBtn_pressed',

    claimNonUSConfirmPressed = 'linkNotificationClaimNonUS_ConfirmBtn_pressed',
    claimNonUSCancelPressed = 'linkNotificationClaimNonUS_CancelBtn_pressed',
    claimUSMainNextPressed = 'linkNotificationClaimUSMain_NextBtn_pressed',
    claimUSMainCancelPressed = 'linkNotificationClaimUSMain_CancelBtn_pressed',
    claimUSExchangeNextPressed = 'linkNotificationClaimUSExchange_NextBtn_pressed',
    claimUSExchangeBackPressed = 'linkNotificationClaimUSExchange_BackBtn_pressed',
    claimUSConfirmationConfirmPressed = 'linkNotificationClaimUSConfirmation_ConfirmBtn_pressed',
    claimUSConfirmationBackPressed = 'linkNotificationClaimUSConfirmation_BackBtn_pressed',
    claimFinishPressed = 'linkNotificationClaim_FinishBtn_pressed',

    cancelSaleNotifiedConfirmPressed = 'linkNotificationListForSaleCancel_ConfirmBtn_pressed',
    cancelSaleNotifiedCancelPressed = 'linkNotificationListForSaleCancel_DenyBtn_pressed',
    cancelSaleConfirmedFinishPressed = 'linkConfirmedListForSaleCancel_FinishBtn_pressed',

    prepareWdrawNotifiedConfirmPressed = 'linkNotificationPrepareWithdrawal_ConfirmBtn_pressed',
    prepareWdrawConfirmedFinishPressed = 'linkConfirmedPrepareWithdrawl_FinishBtn_pressed',
    completeWdrawNotifiedProceedPressed = 'linkNotificationWithdrawalReady_NextBtn_pressed',
    completeWdrawConfirmedFinishPressed = 'linkConfirmedWithdrawalReady_FinishBtn_pressed',

    providerSelectionPressed = 'linkProviderSelection_walletBtn_pressed',
    selfCustodialMetaMaskChangePressed = 'linkMetaMask_changeWalletBtn_pressed',
    selfCustodialGamestopChangePressed = 'linkGamestop_changeWalletBtn_pressed',
    custodialMagicChangePressed = 'linkMagic_changeWalletBtn_pressed',

    copyWalletAddressPressed = 'linkHistory_CopyAddressBtn_pressed',

    signMessageCancelPressed = 'linkSignMessageCancel_DenyBtn_pressed',
    signMessageNextPressed = 'linkSignMessageCancel_NextBtn_pressed',

    getPublicKeyCancelPressed = 'getPublicKeyCancel_CancelBtn_pressed',

    errorPageClosePressed = 'linkErrorPage_CloseBtn_pressed',

    transactionHistoryClosePressed = 'linkTransactionHistory_CloseBtn_pressed',

    flexibleDepositNextBtnPressed = 'linkFlexibleDeposit_NextBtn_pressed',
    flexibleDepositCurrencyBtnPressed = 'linkFlexibleDeposit_CurrencyBtn_pressed',
    flexibleDepositCancelBtnPressed = 'linkFlexibleDeposit_CancelBtn_pressed',

    makeOfferCompleteContinueBtnPressed = 'linkMakeOfferComplete_ContinueBtn_pressed',
    makeOfferPromptConfirmBtnPressed = 'linkMakeOfferPrompt_ConfirmBtn_pressed',
    makeOfferPromptCancelBtnPressed = 'linkMakeOfferPrompt_CancelBtn_pressed',

    cancelOfferCompleteContinueBtnPressed = 'linkCancelOfferComplete_ContinueBtn_pressed',
    cancelOfferPromptConfirmBtnPressed = 'linkCancelOfferPrompt_ConfirmBtn_pressed',
    cancelOfferPromptCancelBtnPressed = 'linkCancelOfferPrompt_CancelBtn_pressed',

    acceptOfferCompleteContinueBtnPressed = 'linkAcceptOfferComplete_ContinueBtn_pressed',
    acceptOfferPromptConfirmBtnPressed = 'linkAcceptOfferPrompt_ConfirmBtn_pressed',
    acceptOfferPromptCancelBtnPressed = 'linkAcceptOfferPrompt_CancelBtn_pressed',
}

export enum ScreenEventName {
    setupKeyOpened = 'linkSetupKey_screen_opened',
    setupNoneOpened = 'linkSetupWeb3None_screen_opened',
    setupUnlockOpened = 'linkSetupUnlock_screen_opened',
    setupSignInOpened = 'linkSetupWalletSignIn_screen_opened',
    setupPendingOpened = 'linkSetupPending_screen_opened',
    setupCompleteOpened = 'linkSetupComplete_screen_opened',
    getPublicKeyOpened = 'linkGetPublicKeyOpened_screen_opened',
    setupMultipleWalletsDetected = 'linkSetupMultipleWalletsDetected_screen_opened',

    errorDeniedOpened = 'linkErrorDenied_screen_opened',
    errorGenericOpened = 'linkErrorGeneric_screen_opened',
    errorNetworkOpened = 'linkErrorNetwork_screen_opened',

    buyNowNotifiedOpened = 'linkNotificationBuyNow_screen_opened',
    buyNowConfirmedOpened = 'linkConfirmedBuyNow_screen_opened',

    claimNonUSConfirmedOpened = 'linkConfirmedClaimNonUS_screen_opened',

    depositConfirmedOpened = 'linkConfirmedDeposit_screen_opened',
    depositSignRequestOpened = 'linkSignRequestDeposit_screen_opened',
    depositInProgressScreenOpened = 'linkDepositInProgress_screen_opened',
    despositResultScreenOpened = 'linkDespositResult_screen_opened',
    flexibleDepositFormScreenOpened = 'linkFlexibleDepositForm_screen_opened',

    transactionHistoryOpened = 'linkTransactionHistory_screen_opened',

    listForSaleNotifiedOpened = 'linkNotificationListForSale_screen_opened',
    listForSaleConfirmedOpened = 'linkConfirmedListForSale_screen_opened',

    cancelSaleNotifiedOpened = 'linkNotificationListForSaleCancel_screen_opened',
    cancelSaleConfirmedOpened = 'linkConfirmedListForSaleCancel_screen_opened',

    prepareWdrawConfirmedOpened = 'linkConfirmedPrepareWithdrawl_screen_opened',
    completeWdrawConfirmedOpened = 'linkConfirmedWithdrawalReady_screen_opened',

    signMessageOpened = 'linkSignMessageOpened_screen_opened',
    signMessageInvalid = 'linkSignMessageOpened_screen_invalid',

    linkProviderPreferenceOpened = 'linkProviderPreference_screen_opened',
    linkProviderSelectionOpened = 'linkProviderSelection_opened',
    linkProviderMetaMaskOpened = 'linkMetaMask_opened',
    linkProviderMagicOpened = 'linkMagic_opened',

    linkProviderFinishedScreenOpened = 'linkProviderFinished_screen_opened',

    makeOfferPromptScreenOpened = 'linkMakeOfferPrompt_screen_opened',
    cancelOfferPromptScreenOpened = 'linkCancelOfferPrompt_screen_opened',
    acceptOfferPromptScreenOpened = 'linkAcceptOfferPrompt_screen_opened',
}

export enum WalletEventName {
    walletCancelBtnPressed = 'linkWallet_CancelBtn_pressed',
}

export enum FlowEventName {
    keySetupStarted = 'keySetup_started',
    keySetupSucceeded = 'keySetup_succeeded',

    assetBuyNowFailed = 'buyNow_failed',
    assetBuyNowStarted = 'buyNow_started',
    assetBuyNowSucceeded = 'buyNow_succeeded',

    depositSucceeded = 'deposit_succeeded',

    listForSaleFailed = 'listForSale_failed',
    listForSaleStarted = 'listForSale_started',
    listForSaleSucceeded = 'listForSale_succeeded',

    cancelSaleFailed = 'listForSaleCancel_failed',
    cancelSaleStarted = 'listForSaleCancel_started',
    cancelSaleSucceeded = 'listForSaleCancel_succeeded',

    claimUSCancelled = 'claimUS_cancelled',
    claimNonUSCancelled = 'claimNonUS_cancelled',
    claimNonUSFailed = 'claimNonUS_failed',
    claimNonUSStarted = 'claimNonUS_started',
    claimNonUSSucceeded = 'claimNonUS_succeeded',
    claimNotFound = 'claim_not_found',
    imxGetTokenFailed = 'get_imx_token_failed',
    claimUSInstantFailed = 'claim_US_instant_failed',
    claimUSOverTimeFailed = 'claim_US_over_time_failed',
    claimUSStarted = 'claimUS_started',
    claimUSSucceeded = 'claimUS_succeeded',

    historyBrowseStarted = 'transactionHistoryBrowse_started',

    prepareWdrawSucceeded = 'prepareWithdrawalEthtoEth_succeeded',
    completeWdrawSucceeded = 'completeWithdrawalToEth_succeeded',

    magicSignInStarted = 'linkMagicSignin_started',
    magicSignInSucceeded = 'linkMagicSignin_succeeded',
    magicSignInFailure = 'linkMagicSignin_failure',

    cryptoToFiatFailed = 'crypto_to_fiat_failed',

    makeOfferStarted = 'makeOffer_started',
    makeOfferSucceeded = 'makeOffer_succeeded',
    makeOfferFailed = 'makeOffer_failed',

    cancelOfferStarted = 'cancelOffer_started',
    cancelOfferSucceeded = 'cancelOffer_succeeded',
    cancelOfferFailed = 'cancelOffer_failed',

    acceptOfferStarted = 'acceptOffer_started',
    acceptOfferSucceeded = 'acceptOffer_succeeded',
    acceptOfferFailed = 'acceptOffer_failed',
}

export enum LinkEventName {
    setupKeyTermsPressed = 'linkSetupKey_TermsLnk_pressed',
    setupKeyPolicyPressed = 'linkSetupKey_PolicyLnk_pressed',
    setupKeyCollectionStatementPressed = 'linkSetupKey_CollectionStatementLink_pressed',
    setupKeyMagicTermsPressed = 'linkSetupKey_MagicTermsLnk_pressed',
    setupKeyMagicPrivacyPressed = 'linkSetupKey_MagicPrivacyLnk_pressed',
}

export enum BoxEventName {
    hardwareWalletSetupSelected = 'linkHardwareWallet_setup_selected',
}

export enum ExchangeEventName {
    onrampCreated = 'onramp_created',
    offrampCreated = 'offramp_created',
    nftCheckoutPrimaryCreated = 'nftCheckoutPrimary_created',
    nftCheckoutSecondaryCreated = 'nftCheckoutSecondary_created',

    onrampCompleted = 'onramp_completed',
    offrampCompleted = 'offramp_completed',
    nftCheckoutPrimaryCompleted = 'nftCheckoutPrimary_completed',
    nftCheckoutSecondaryCompleted = 'nftCheckoutSecondary_completed',

    onrampFailed = 'onramp_failed',
    offrampFailed = 'offramp_failed',
    nftCheckoutPrimaryFailed = 'nftCheckoutPrimary_failed',
    nftCheckoutSecondaryFailed = 'nftCheckoutSecondary_failed',

    onrampNetworkError = 'onramp_network_error',
    offrampNetworkError = 'offramp_network_error',
    nftCheckoutSecondaryNetworkError = 'nftCheckoutSecondary_network_error',
    nftCheckoutPrimaryNetworkError = 'nftCheckoutPrimary_network_error',
}

export type ButtonEventProps = {
    message?: string;
    description?: string;
    assetId?: string;
    price?: string;
    selection?: string;
    currency?: string;
    amount?: string;
};

export type ScreenEventProps = {
    message?: string;
    description?: string;
    assetId?: string;
    price?: string;
};

export type FlowEventProps = {
    salePrice?: string;
    orderId?: string;
    assetId?: string;
    provider?: string;
    tempWhatsInLocalStorage?: string | null;
    tempWhatDidIsLocalStorageFuncReturn?: string;
    riskAssessed?: boolean;
};

export type LinkEventProps = {
    assetId?: string;
    price?: string;
};

export type BoxEventProps = {
    value?: string;
};

export type ExchangeEventProps = {
    exchangeId?: string;
    transactionId?: string;
    provider?: string;
    path?: string;
};

export type ButtonAnalyticsEvent = AnalyticsEvent & {
    eventProperties: ButtonEventProps;
    eventType: EventType.button;
    eventName: ButtonEventName;
};

export type ScreenAnalyticsEvent = AnalyticsEvent & {
    eventProperties: ScreenEventProps;
    eventType: EventType.screen;
    eventName: ScreenEventName;
};

export type FlowAnalyticsEvent = AnalyticsEvent & {
    eventProperties: FlowEventProps;
    eventType: EventType.flow;
    eventName: FlowEventName;
};

export type LinkAnalyticsEvent = AnalyticsEvent & {
    eventProperties: LinkEventProps;
    eventType: EventType.link;
    eventName: LinkEventName;
};

export type BoxAnalyticsEvent = AnalyticsEvent & {
    eventProperties: BoxEventProps;
    eventType: EventType.box;
    eventName: BoxEventName;
};

export type ExchangeAnalyticsEvent = AnalyticsEvent & {
    eventProperties: ExchangeEventProps;
    eventType: EventType.exchange;
    eventName: ExchangeEventName;
};

export type WalletAnalyticsEvent = AnalyticsEvent & {
    eventType: EventType.wallet;
    eventName: WalletEventName;
};

export type AnyAnalyticsEvent =
    | ScreenAnalyticsEvent
    | ButtonAnalyticsEvent
    | FlowAnalyticsEvent
    | LinkAnalyticsEvent
    | BoxAnalyticsEvent
    | ExchangeAnalyticsEvent
    | WalletAnalyticsEvent;
