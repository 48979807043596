/* eslint-disable react/no-array-index-key */
import { colors, ParagraphText, VerticalSpace } from '@imtbl/design-system';
import React, { useEffect } from 'react';

import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ScreenEventName } from '../../lib/analytics/types';
import { createScreenEvent } from '../../lib/analytics/utils';
import { removeSupportMessage, SupportLink } from './SupportLink';

interface GeneralErrorProps {
    messages: string[];
}

export const GeneralError = ({ messages }: GeneralErrorProps) => {
    useEffect(() => {
        sendAnalytics(createScreenEvent(ScreenEventName.errorGenericOpened));
    }, []);

    return (
        <>
            {messages.map((message, index) => {
                const isLastMsg = index === messages.length - 1;

                return (
                    // @TODO: If the messages becomes mutable, please change 'key' to use 'uuid'!
                    <VerticalSpace top="large" bottom={isLastMsg ? 'large' : undefined} key={index}>
                        <ParagraphText fillColor={colors.light[300]} fontSize="small">
                            {removeSupportMessage(message)}
                        </ParagraphText>
                    </VerticalSpace>
                );
            })}

            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                <SupportLink />
            </ParagraphText>
        </>
    );
};
