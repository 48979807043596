/* eslint-disable @typescript-eslint/naming-convention */
import { css } from '@emotion/css';

export const ProviderButton = css`
    background-color: transparent;
    color: var(--colors_light_100);
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 22px 0;
    border-bottom: 1px solid var(--colors_dark_100);
    cursor: pointer;

    &:hover {
        color: var(--colors_dark_100);
    }
`;

export const ProviderSelectionWarning = css`
    padding-top: 16px;
    margin-bottom: -6px;
    background-color: transparent;
    text-decoration: none;
    display: block;
    cursor: pointer;
`;

export const ProviderButton_logo_gameWallet_background = css`
    background: linear-gradient(105.76deg, #f191fa -46.96%, #83e3f0 99.99%);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
`;

export const ProviderButton_logo_background = css`
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
`;

export const ProviderButton_logo = css`
    color: black;
    width: 24px;
    height: 24px;
    display: inline-block;
`;

export const ProviderButtonX_logo = css`
    color: black;
`;

export const GameWalletProviderButton_logo = css`
    color: var(--colors_dark_100);
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 15px;
`;

export const ButtonsContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
`;
