import { Box, FlexLayout } from '@imtbl/design-system';
import React from 'react';

import styles from '../Onramp/index.module.css';

export function showLayerswapExchangeIframe(layerswapUrl: string | undefined) {
    return (
        <FlexLayout className={styles.exchangeRoot} justifyContent="center" alignItems="stretch" flexDirection="column">
            <Box className={styles.moonpayIframeContainer}>
                <Box className={styles.moonpayIframeFilter}>
                    <iframe
                        data-testid="providerIframe"
                        className={styles.moonpayIframeFilter}
                        title="LayerswapUrl"
                        allow="accelerometer; autoplay; camera; gyroscope; payment"
                        frameBorder="0"
                        src={layerswapUrl}
                    >
                        <p>Your browser does not support iframes.</p>
                    </iframe>
                </Box>
            </Box>
        </FlexLayout>
    );
}
