import {
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import { ERC20TokenType, ImmutableMethodResults, LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import { formatUnits } from 'ethers/lib/utils';
import React, { useCallback } from 'react';

import { Asset } from '../../../hooks/useGetAsset.hook';
import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { useScrollToTop } from '../../../hooks/useScrollToTop.hook';
import { TokenDataType } from '../../../hooks/useTokensList.hook';
import { ParentWindow } from '../../../lib';
import { sendAnalyticsAsync } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent } from '../../../lib/analytics/utils';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../../utils/constants';
import { CancelLink, StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../../common';
import { TokenDetailsDisplayRow } from '../../TokenDetailsDisplayRow';

export interface CancelOfferPromptScreenPropTypes extends BaseComponentPropTypes {
    parent: ParentWindow;
    cancelOfferTransaction: () => void;
    buyOrder: ImmutableMethodResults.ImmutableGetOrderV3Result;
    offerCurrencyToken: TokenDataType;
    asset: Asset;
    loading: boolean;
}
export const CancelOfferPrompt = ({
    parent,
    cancelOfferTransaction,
    buyOrder,
    offerCurrencyToken,
    asset,
    loading,
    testId,
}: CancelOfferPromptScreenPropTypes) => {
    useScrollToTop();
    const textMessages = useI18nMessaging({});
    const onCancelClick = useCallback(async () => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
        await sendAnalyticsAsync(
            createButtonEvent(ButtonEventName.cancelOfferPromptCancelBtnPressed),
            createFlowEvent(FlowEventName.cancelOfferFailed),
        );
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent, textMessages]);

    // Buyers should see the amount with fees that they are cancelling as that is the amount they signed for when making the offer
    const cancelAmountWithFees = buyOrder?.maker_fees?.quantity_with_fees || '0';
    const tokenDisplayAmount = formatUnits(cancelAmountWithFees, buyOrder?.maker_fees?.decimals || 18);
    return (
        <StandardLinkRouteContainer testId={`${testId}__Container`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">{textMessages.offers.cancelOffer.title.start}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.offers.cancelOffer.bodyText.start}
            </ParagraphText>

            <VerticalSpace top="large">
                <FlexLayout flexGrow={1} flexDirection="column">
                    <TokenDetailsDisplayRow
                        testId={`${testId}__tokenDetails`}
                        tokenType={buyOrder?.buy?.type}
                        asset={asset}
                        transactionCostTokenImage={
                            buyOrder?.sell?.type === ERC20TokenType.ERC20 && offerCurrencyToken?.image_url
                                ? offerCurrencyToken.image_url
                                : DEFAULT_ETH_TOKEN_IMAGERY
                        }
                        tokenDisplayAmount={tokenDisplayAmount}
                    />
                </FlexLayout>
            </VerticalSpace>

            <StandardLinkRouteButtonsFooter testId={`${testId}__buttonsFooter`}>
                <StandardButton
                    testId={`${testId}__buttonsFooter__confirmCta`}
                    buttonKind="ultimate-cta"
                    onClick={cancelOfferTransaction}
                    disabled={loading}
                >
                    {textMessages.offers.cancelOffer.buttons.confirm}
                </StandardButton>
                <CancelLink
                    title={textMessages.offers.cancelOffer.buttons.cancel}
                    onClick={onCancelClick}
                    testId={`${testId}__buttonsFooter__cancel`}
                />
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
