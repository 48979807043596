import { Web3Provider } from '@ethersproject/providers';
import { BaseComponentPropTypes } from '@imtbl/design-system';
import {
    errorsToError,
    ImmutableMethodParams,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LinkParams,
    messageTypes,
    PositiveBigNumber,
    valueOrThrow,
} from '@imtbl/imx-sdk';
import { utils } from 'ethers';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useEffect, useState } from 'react';

import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { useBuildClient } from '../../hooks/useBuildClient.hook';
import { useGetEthAddress } from '../../hooks/useGetAddress.hook';
import * as hooks from '../../hooks/useGetFullBatchNftTransferDetails.hook';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { batchNftTransfer, closeWindow, DispatchSetError, LinkClientConfig, ParentWindow } from '../../lib';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { FullTransferTokenInfo } from '../../types/SharedTransfer.types';
import { actionDeniedError } from '../Action';
import { StandardLinkRouteContainer } from '../common';
import { ErrorMessage } from '../ErrorMessage';
import { LoadingUi } from '../LoadingUi/LoadingUi.component';
import { BatchNftTransferCompleteScreen } from './BatchNftTransferCompleteScreen.component';
import { BatchNftTransferConfirmScreen } from './BatchNftTransferConfirmScreen.component';
import { BatchNftTransferErrorScreen } from './BatchNftTransferErrorScreen.component';
import { BatchNftTransferFooter } from './BatchNftTransferFooter.component';
import { BatchNftTransferInvalidScreen } from './BatchNftTransferInvalidScreen.component';
import { getBatchParams, getInvalidTransfers } from './lib';

export const DISABLED_FEATURE_MESSAGE = 'Batch transfers are not enabled for this environment.';
const DEFAULT_BATCH_SIZE = 100;

export type BatchNftTransferPropTypes = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    params: LinkParams.BatchNftTransfer;
    setErrorLog: DispatchSetError;
    defaultBatchSize?: number;
} & Pick<BaseComponentPropTypes, 'testId'>;

enum CurrentState {
    Loading,
    BatchConfirm,
    BatchInvalid,
    BatchError,
    BatchComplete,
}

interface BatchNftTransferState {
    currentState: CurrentState;
    batchIndex: number;
    errors: any;
    numberOfBatches: number;
    transfers: FullTransferTokenInfo[];
}

export const BatchNftTransfer = ({
    config,
    parent,
    provider,
    testId,
    params,
    setErrorLog,
    defaultBatchSize = DEFAULT_BATCH_SIZE,
}: BatchNftTransferPropTypes) => {
    const flags = useLaunchDarklyFlags();
    const isEnabled = flags[FEATURE_FLAG.ENABLE_BATCH_TRANSFER];

    const messages = useI18nMessaging({});
    const [state, setState] = useState<BatchNftTransferState>({
        currentState: CurrentState.Loading,
        batchIndex: 0,
        errors: null,
        numberOfBatches: Math.ceil(params.length / defaultBatchSize),
        transfers: [],
    });

    const [batchParams, setBatchParams] = useState<LinkParams.BatchNftTransfer>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const ethAddress = useGetEthAddress(provider);
    const client = useBuildClient(config.publicApiUrl);

    // We only want to process the current batch at a time
    // This hook will re-run when batchParams gets updated, which will
    // result in an updated transferDetails value
    const { loading, transferDetails } = hooks.useGetFullBatchNftTransferDetails({
        client,
        ethAddress,
        config,
        params: batchParams,
    });

    useEffect(() => {
        // Display error message if feature flag is not enabled
        if (isEnabled !== undefined && !isEnabled) {
            setErrorLog(
                { message: DISABLED_FEATURE_MESSAGE },
                messages.generalErrorMessage([DISABLED_FEATURE_MESSAGE]),
            );
        }

        // Initially set first batch transfer data
        if (!loading && transferDetails) {
            setState({
                ...state,
                currentState: CurrentState.BatchConfirm,
                transfers: transferDetails,
            });
        }
    }, [isEnabled, loading]);

    // Catch any updates to the transferDetails value
    useEffect(() => {
        if (!loading && transferDetails) {
            setState({
                ...state,
                currentState: CurrentState.BatchConfirm,
                transfers: transferDetails,
            });
        }
    }, [transferDetails]);

    // When the batch index gets updated, we want to process the next batch of params
    useEffect(() => {
        setBatchParams(getBatchParams(params, state.batchIndex, defaultBatchSize));
    }, [state.batchIndex]);

    const submitBatchNftTransfer = async () => {
        // Dont send batch if it contains invalid assets
        if (getInvalidTransfers(state.transfers).length > 0) {
            setState({
                ...state,
                currentState: CurrentState.BatchInvalid,
            });
            return;
        }

        setIsSubmitting(true);
        if (client && ethAddress) {
            parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.inProgress }, '*');
            const clientWithSigner = await ImmutableXClient.build({
                ...config,
                signer: provider.getSigner(),
            });
            const transferRequests: ImmutableMethodParams.ImmutableTransferRequest[] = state.transfers?.map(
                (transfer: FullTransferTokenInfo) => {
                    const amount: PositiveBigNumber = valueOrThrow(
                        pipe(
                            utils.parseEther(transfer.tokenDetails.amount),
                            PositiveBigNumber.decode,
                            E.mapLeft(errorsToError),
                        ),
                    );
                    return {
                        token: transfer.tokenDetails.token,
                        amount,
                        receiver: transfer.originalParams.toAddress,
                    } as ImmutableMethodParams.ImmutableTransferRequest;
                },
            );
            try {
                const res = await batchNftTransfer(clientWithSigner, transferRequests);
                const transferReport = res.transfer_ids.map((transferId, idx) => {
                    return {
                        ...state.transfers[idx].originalParams,
                        status: 'success',
                        toAddress: transferRequests[idx].receiver,
                        txId: transferId,
                        type: transferRequests[idx].token.type,
                    };
                });
                parent.postMessage(
                    { type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: { result: transferReport } },
                    '*',
                );
                setState({
                    ...state,
                    currentState: CurrentState.BatchComplete,
                });
                setIsSubmitting(false);
            } catch (e: any) {
                parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
                setState({
                    ...state,
                    currentState: CurrentState.BatchError,
                    errors: e,
                });
                setIsSubmitting(false);
            }
        }
    };

    // Update the batch index
    const handleNext = () => {
        setState({
            ...state,
            batchIndex: state.batchIndex + 1,
            currentState: CurrentState.Loading,
            errors: null,
        });
    };

    const handleFinish = closeWindow(parent);

    const handleCancel = useCallback(() => {
        setErrorLog(actionDeniedError, messages.actionDenied);
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
    }, [parent, setErrorLog, messages]);

    const { transfers, batchIndex, numberOfBatches, errors, currentState } = state;

    const renderFooter = (): React.ReactElement => {
        const isLastBatch = batchIndex === numberOfBatches - 1;
        const actionText = isLastBatch ? messages.standardUiButtons.continue : messages.standardUiButtons.next;
        const actionHandler = isLastBatch ? handleFinish : handleNext;
        return (
            <BatchNftTransferFooter
                testId={testId}
                batchNumber={batchIndex + 1}
                numberOfBatches={numberOfBatches}
                actionText={actionText}
                actionHandler={actionHandler}
            />
        );
    };

    switch (currentState) {
        case CurrentState.Loading:
            return <LoadingUi showLoading testId={`${testId}__loadingUi`} />;
        case CurrentState.BatchInvalid:
            return (
                <BatchNftTransferInvalidScreen testId={testId} transferDetails={transfers} footer={renderFooter()} />
            );
        case CurrentState.BatchError:
            return (
                <BatchNftTransferErrorScreen
                    testId={testId}
                    transferDetails={transfers}
                    footer={renderFooter()}
                    batchError={errors}
                />
            );
        case CurrentState.BatchComplete:
            return (
                <BatchNftTransferCompleteScreen testId={testId} transferDetails={transfers} footer={renderFooter()} />
            );
        case CurrentState.BatchConfirm:
            return (
                <BatchNftTransferConfirmScreen
                    testId={testId}
                    handleConfirm={submitBatchNftTransfer}
                    handleCancel={handleCancel}
                    transferDetails={transfers}
                    totalAssets={params.length}
                    batchIndex={batchIndex}
                    numberOfBatches={numberOfBatches}
                    isSubmitting={isSubmitting}
                />
            );
        default:
            return (
                <StandardLinkRouteContainer testId={`${testId}__batchNftTransfer__invalidState`}>
                    <ErrorMessage
                        parentWindow={parent}
                        heading="Invalid transfer state"
                        body={messages.generalError.body}
                    />
                </StandardLinkRouteContainer>
            );
    }
};
