import { BaseComponentPropTypes, FlexLayout, layoutHelpers } from '@imtbl/design-system';
import React from 'react';

export const BatchNftTransferHeader: React.FC<BaseComponentPropTypes> = ({ testId, children }) => {
    return (
        <FlexLayout
            testId={testId}
            paddingTop={layoutHelpers.gridUnits(7)}
            paddingRight={layoutHelpers.gridUnits(6)}
            paddingLeft={layoutHelpers.gridUnits(6)}
            flexDirection="column"
            flexGrow={0}
        >
            {children}
        </FlexLayout>
    );
};
