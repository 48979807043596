import { LinkParams, LocalStorageKeys, ProviderPreference, Routes } from '@imtbl/imx-sdk';
import { parse } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getProviderData } from './providers';

const useProviderPreference = () => {
    const [providerPreference, setProviderPreference] = useState<ProviderPreference>();
    const [providerTitle, setProviderTitle] = useState<string>();

    const location = useLocation();

    const setupParams = useMemo(() => parse(location.search) as LinkParams.Setup, [location]);

    useEffect(() => {
        if (location.pathname === `/${Routes.Setup}`) {
            setProviderPreference(setupParams.providerPreference);
            return;
        }

        setProviderPreference(window.localStorage.getItem(LocalStorageKeys.PROVIDER_PREFERENCE) as ProviderPreference);
    }, [location]);

    useEffect(() => {
        if (location.pathname === `/${Routes.Setup}`) {
            if (providerPreference) {
                window.localStorage.setItem(LocalStorageKeys.PROVIDER_PREFERENCE, providerPreference);
            } else {
                window.localStorage.removeItem(LocalStorageKeys.PROVIDER_PREFERENCE);
            }
        }

        const preference = getProviderData(providerPreference ?? ProviderPreference.NONE);
        setProviderTitle(preference === ProviderPreference.NONE ? ProviderPreference.NONE : preference.name);
    }, [providerPreference]);

    return { providerPreference, setProviderPreference, providerTitle };
};

export default useProviderPreference;
