import { ExternalProvider } from '@ethersproject/providers';

export type GamestopExternalProvider = ExternalProvider & {
    isGamestop: boolean;
};

export const useGamestopConnector = (_: ExternalProvider) => {
    const changeWallet = async () => {
        return null;

        // TODO: Uncomment this code when GME implement this method
        // return (provider as any).request({
        //     method: 'wallet_requestPermissions',
        //     params: [
        //         {
        //             eth_accounts: {},
        //         },
        //     ],
        // });
    };
    return { changeWallet };
};
