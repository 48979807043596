import { useEffect } from 'react';

import { getLocationSearch } from '../utils/location';

const SDK_VERSION_PARAM = 'sdkVersion';

export const useSetSdkVersion = () =>
    useEffect(() => {
        const queryString = getLocationSearch();
        const urlParams = new URLSearchParams(queryString);
        let sdkVersion;
        try {
            sdkVersion = urlParams.get(SDK_VERSION_PARAM);
        } catch (e) {
            sdkVersion = '';
        }
        // @ts-ignore
        window?.newrelic?.setCustomAttribute('client-sdk-version', sdkVersion);
    }, []);
