import {
    colors,
    ExplodingCircleWithTick,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

import { StandardLinkRouteButtonsFooter } from './StandardLinkUiContainers';

export type ResultProps = {
    heading: string;
    paragraph: string;
    subParagraph: string;
    finishButtonTitle?: string;
    onFinish: () => void;
};

export const ResultScreen = ({
    heading,
    paragraph,
    subParagraph,
    onFinish,
    finishButtonTitle = 'CONTINUE',
}: ResultProps) => {
    return (
        <>
            <ExplodingCircleWithTick />
            <VerticalSpace bottom="small">
                <SectionHeading testId="ResultScreen__heading">{heading}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small" testId="ResultScreen__paragraph">
                {paragraph}
            </ParagraphText>

            <VerticalSpace top="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small" testId="ResultScreen__subParagraph">
                    {subParagraph}
                </ParagraphText>
            </VerticalSpace>
            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large">
                    <StandardButton testId="ResultScreen__finishCta" buttonKind="ultimate-cta" onClick={onFinish}>
                        {finishButtonTitle}
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
