import {
    BoxAnalyticsEvent,
    BoxEventName,
    BoxEventProps,
    ButtonAnalyticsEvent,
    ButtonEventName,
    ButtonEventProps,
    EventType,
    ExchangeAnalyticsEvent,
    ExchangeEventName,
    ExchangeEventProps,
    FlowAnalyticsEvent,
    FlowEventName,
    FlowEventProps,
    LinkAnalyticsEvent,
    LinkEventName,
    LinkEventProps,
    LSUserId,
    ScreenAnalyticsEvent,
    ScreenEventName,
    ScreenEventProps,
    UTMParam,
    WalletAnalyticsEvent,
    WalletEventName,
} from './types';

const UTM_KEYS: string[] = Object.values(UTMParam);

export function createButtonEvent(name: ButtonEventName, props?: ButtonEventProps): ButtonAnalyticsEvent {
    return {
        eventName: name,
        eventType: EventType.button,
        eventProperties: props ?? {},
    };
}

export function createScreenEvent(name: ScreenEventName, props?: ScreenEventProps): ScreenAnalyticsEvent {
    return {
        eventName: name,
        eventType: EventType.screen,
        eventProperties: props ?? {},
    };
}

export function createFlowEvent(name: FlowEventName, props?: FlowEventProps): FlowAnalyticsEvent {
    return {
        eventName: name,
        eventType: EventType.flow,
        eventProperties: props ?? {},
    };
}

export function createLinkEvent(name: LinkEventName, props?: LinkEventProps): LinkAnalyticsEvent {
    return {
        eventName: name,
        eventType: EventType.link,
        eventProperties: props ?? {},
    };
}

export function createBoxEvent(name: BoxEventName, props?: BoxEventProps): BoxAnalyticsEvent {
    return {
        eventName: name,
        eventType: EventType.box,
        eventProperties: props ?? {},
    };
}

export function createExchangeEvent(name: ExchangeEventName, props: ExchangeEventProps): ExchangeAnalyticsEvent {
    return {
        eventName: name,
        eventType: EventType.exchange,
        eventProperties: props,
    };
}
export function createWalletEvent(name: WalletEventName): WalletAnalyticsEvent {
    return {
        eventName: name,
        eventType: EventType.wallet,
    };
}

export function isFuncValid(func: any) {
    return typeof func === 'function';
}

export function isStringValid(value: any) {
    return typeof value === 'string' && value.length > 0;
}

export function isObjectValid(obj: any) {
    if (!obj || Array.isArray(obj)) return false;
    return typeof obj === 'object' && Object.keys(obj).length > 0;
}

export function isStorageValid(storage: any) {
    const ls = storage as Storage | undefined;
    return !!ls && isFuncValid(ls?.setItem) && isFuncValid(ls?.getItem);
}

export function filterUTMParams(search: URLSearchParams) {
    const utmParams: Record<string, string> = {};

    try {
        search.forEach((value, key) => {
            if (isStringValid(value) && UTM_KEYS.includes(key)) {
                utmParams[key] = value;
            }
        });
    } catch (error) {
        console.error('Could not get UTM parameters', error);
    }

    return utmParams;
}

export function resetUTMParams() {
    UTM_KEYS.forEach((key) => localStorage.removeItem(key));
}

export function setUTMParams(params: Record<string, string>) {
    if (!isObjectValid(params) || !isStorageValid(localStorage)) {
        return;
    }

    Object.entries(params).forEach(([key, value]) => {
        if (isStringValid(value) && UTM_KEYS.includes(key)) {
            localStorage.setItem(key, value);
        }
    });
}

export function getUTMParams() {
    const utmParams: Record<string, string> = {};
    if (!isStorageValid(localStorage)) {
        return utmParams;
    }

    UTM_KEYS.forEach((key) => {
        const value = localStorage.getItem(key) as string;
        if (isStringValid(value)) utmParams[key] = value;
    });

    return utmParams;
}

export function getAppName() {
    const { hostname: name } = window?.location ?? {};
    if (typeof name !== 'string' || !name.length) {
        return undefined;
    }

    return name.split('.').reverse().join('.') as string;
}

export function getUserAgent() {
    const { userAgent: agent } = window?.navigator ?? {};
    if (typeof agent !== 'string' || !agent.length) {
        return undefined;
    }

    return agent;
}

export function setWalletAddressInLocalStorage(id: string, key?: LSUserId) {
    if (!isStringValid(id) || !isStorageValid(localStorage)) {
        return;
    }

    localStorage.setItem(key ?? LSUserId.walletId, id.toLowerCase());
}

export function removeWalletIdFromLocalStorage() {
    localStorage.removeItem(LSUserId.walletId);
}

export function getWalletAddressFromLocalStorage() {
    if (!isStorageValid(localStorage)) {
        return undefined;
    }

    return localStorage.getItem(LSUserId.walletId);
}
