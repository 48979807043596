import {
    AmountWithIcon,
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    SimpleText,
    SimpleTextSizes,
} from '@imtbl/design-system';
import { ERC20TokenType } from '@imtbl/imx-sdk';
import React, { useCallback } from 'react';

import styles from './index.module.css';

interface BalanceERC20Props extends Pick<BaseComponentPropTypes, 'testId'> {
    ticker: string;
    amount?: string;
    platform?: string;
    fillColor?: string;
    fontSize?: SimpleTextSizes;
    tokenImageUrl?: string;
}

/**
 * NOTE: There are lot of situations where supplied props aren't typed properly,
 * and comes through as 'undefined', so adding conditions here to check if they
 * exist, before using them, even though 'ticker', 'amount' are required props!
 */
function getAmountTextWithTicker(amount?: string, ticker?: string) {
    return amount ? `${amount}${ticker ? ` ${ticker}` : ''}` : '';
}

export const BalanceERC20 = ({
    ticker,
    amount,
    platform,
    fillColor = colors.light[100],
    fontSize = 'small',
    tokenImageUrl = '',
    testId = 'balanceERC20',
}: BalanceERC20Props) => {
    const amountTextWithTicker = getAmountTextWithTicker(amount, ticker);

    const showBalance = useCallback(() => {
        if (tokenImageUrl && amount) {
            return (
                <AmountWithIcon
                    amount={amount}
                    fillColor={fillColor}
                    fontWeight="bold"
                    fontSize={fontSize}
                    testId={`${testId}-erc20-price`}
                    icon={tokenImageUrl}
                />
            );
        }

        return (
            <SimpleText
                fontWeight="bold"
                fontSize={fontSize}
                fillColor={fillColor}
                className={styles.truncate}
                testId={`${testId}__amount`}
                title={amountTextWithTicker}
            >
                {amountTextWithTicker}
            </SimpleText>
        );
    }, [amount]);

    return (
        <FlexLayout flexDirection="column" testId={testId}>
            <FlexLayout>
                {amount ? (
                    showBalance()
                ) : (
                    <FlexLayout flexDirection="column">
                        <SimpleText
                            displayBlock
                            fontWeight="bold"
                            fontSize={fontSize}
                            fillColor={fillColor}
                            className={styles.truncate}
                            testId={`${testId}__erc20Label`}
                        >
                            {ERC20TokenType.ERC20}
                        </SimpleText>
                    </FlexLayout>
                )}
            </FlexLayout>

            {platform && (
                <SimpleText fontSize="tag" fillColor={colors.light[300]} testId={`${testId}__platform`}>
                    {platform}
                </SimpleText>
            )}
        </FlexLayout>
    );
};
