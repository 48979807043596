import { Web3Provider } from '@ethersproject/providers';
import { ERC721TokenType, LinkParamsF } from '@imtbl/imx-sdk';
import queryString from 'query-string';
import React from 'react';

import { Messages } from '../../i18n';
import { DispatchSetError, DispatchSetLoading, EthNetwork, LinkClientConfig, ParentWindow } from '../../lib';
import { search } from '../../utils/location';
import FixedDeposit from './FixedDeposit';
import FlexibleDeposit from './FlexibleDeposit';

export type DepositProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    setErrorLog: DispatchSetError;
    setLoading: DispatchSetLoading;
    ethNetwork: EthNetwork;
};

const isFlexible = (params: LinkParamsF.FlexibleDeposit): boolean =>
    !params || !('type' in params) || (!('amount' in params) && params.type !== ERC721TokenType.ERC721);

export const Deposit = ({ config, setErrorLog, ...restProps }: DepositProps) => {
    const props = { config, setErrorLog, ...restProps };
    const queryParams = queryString.parse(search) as LinkParamsF.FlexibleDeposit;

    if (isFlexible(queryParams)) {
        return <FlexibleDeposit {...props} />;
    }

    return <FixedDeposit {...props} />;
};
