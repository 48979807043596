// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XKTLDkbck531Ji63Wqo2.XKTLDkbck531Ji63Wqo2 {\n    margin-left: 6px;\n    align-self: center;\n}\n\n.rHBjRCGmWI0TnsGYN9yU {\n    border-top: 2px solid var(--colors_dark_300);\n    padding-top: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/CompleteWithdrawal/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,4CAA4C;IAC5C,iBAAiB;AACrB","sourcesContent":[".prepare.prepare {\n    margin-left: 6px;\n    align-self: center;\n}\n\n.summary {\n    border-top: 2px solid var(--colors_dark_300);\n    padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prepare": "XKTLDkbck531Ji63Wqo2",
	"summary": "rHBjRCGmWI0TnsGYN9yU"
};
export default ___CSS_LOADER_EXPORT___;
