/* eslint-disable @typescript-eslint/naming-convention */
import { Web3Provider } from '@ethersproject/providers';
import {
    assertEither,
    ERC20TokenType,
    ERC721TokenType,
    errorsToError,
    EthAddress,
    EthAddressBrand,
    ethToken,
    ETHTokenType,
    FlatToken,
    FlatTokenWithAmount,
    ImmutableMethodParams,
    ImmutableMethodResults,
    ImmutableXClient,
    NonNegativeBigNumber,
    PositiveBigNumber,
    PositiveNumberStringC,
    switchCase,
    taskEitherWithError,
    Token,
    TokenCodec,
    TokenTS,
    valueOrThrow,
} from '@imtbl/imx-sdk';
import BigNumberJs from 'bignumber.js';
import { BigNumber, utils } from 'ethers';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { Branded, Errors as IOErrors } from 'io-ts';

import { findTokenWithAddress, TokenDataType, TokensListType } from '../hooks/useTokensList.hook';
import { messagesIndex } from '../i18n';
import { DEFAULT_DECIMALS, DEFAULT_ETH_TOKEN_IMAGERY } from '../utils/constants';
import { TokenWithAmount, TokenWithAmountCodec, TokenWithDetails } from './types';

export * from './closeWindow';

const messages = messagesIndex.en;

export const parseAmount = (quantity: string, decimals?: number): BigNumber => {
    // NOTE: Check for '0'' decimals to prevent issue:
    // https://immutable.atlassian.net/browse/IMX-3556
    try {
        if (parseInt(decimals?.toString() || '0') > 0) {
            return utils.parseUnits(quantity, decimals);
        }

        return utils.parseEther(quantity);
    } catch {
        return BigNumber.from(0);
    }
};

/**
 * Calculates the minimum amount that can be represented
 * given a number of decimals and a quantum.
 *
 * The formula is: 1 / (10^decimals / quantum)
 *
 * @example
 *
 * Token with: { decimals: '6', quantum: '100' }
 * returns BigNumber: 0.0001 (4 digits)
 *
 */
export const calculateMinQuantisedAmount = (decimals: string, quantum: string): BigNumberJs => {
    return new BigNumberJs(1).div(new BigNumberJs(10).pow(decimals).div(quantum));
};

/**
 * Calculates the minimum quantised amount for a token.
 *
 * @see calculateMinQuantisedAmount
 */
export const calculateMinQuantisedTokenAmount = (token: TokenDataType | undefined): BigNumberJs => {
    if (!token) return new BigNumberJs(0);

    return calculateMinQuantisedAmount(token.decimals, token.quantum);
};

/**
 * Validates that the given amount (provided by the user)
 * satisfies various constraints.
 *
 * Returns an error message if the input is invalid or undefined if it's valid.
 *
 * @param amount The amount to be validated.
 * @param availableBalance The balance available in the selected token.
 * @param minAmount The minimum value that the amount can be.
 */
export const getAmountValidationErrorMessage = (
    amount: BigNumberJs | undefined,
    availableBalance: BigNumberJs | undefined,
    minAmount: BigNumberJs,
    // eslint-disable-next-line consistent-return
): string | undefined => {
    if (!amount) {
        return 'Input is invalid';
    }
    if (!availableBalance || amount.gt(availableBalance)) {
        return 'Amount is higher than available balance';
    }
    if (amount.lt(minAmount)) {
        return `Amount is less than minimum amount (${minAmount.toFixed()})`;
    }

    const maxDecimals = minAmount.decimalPlaces();
    if (amount.decimalPlaces() > maxDecimals) {
        return `Amount has too many decimals (max. ${maxDecimals})`;
    }
};

export function flatTokenToToken(t: FlatToken, decimals = 18): Token {
    if (t.type === ERC721TokenType.ERC721) {
        return {
            type: ERC721TokenType.ERC721,
            data: {
                tokenId: t.tokenId,
                tokenAddress: t.tokenAddress,
            },
        };
    }

    /**
     * @TODO: Find a way to extract the correct 'decimals' value using the '/tokens' API.
     * We set it to '0' for the time being, because the FE elsewhere handles '0' decimals
     * because of the issue: https://immutable.atlassian.net/browse/IMX-3556
     */
    if (t.type === ERC20TokenType.ERC20) {
        return {
            type: ERC20TokenType.ERC20,
            data: {
                decimals,
                symbol: t.symbol,
                tokenAddress: t.tokenAddress,
            },
        };
    }

    return ethToken;
}

export const formatAmount = (qty: PositiveBigNumber | NonNegativeBigNumber, dec?: number): string => {
    // NOTE: Check for '0'' decimals to prevent issue:
    // https://immutable.atlassian.net/browse/IMX-3556
    if (parseInt(dec?.toString() || '0') > 0) {
        return utils.formatUnits(qty, dec);
    }

    return utils.formatEther(qty);
};

export const tokenToTokenWithAmount =
    (quantity: PositiveBigNumber) =>
    (token: Token): E.Either<IOErrors, TokenWithAmount> => {
        switch (token.type) {
            case ERC721TokenType.ERC721: {
                return TokenWithAmountCodec.decode({
                    amount: valueOrThrow(PositiveNumberStringC.decode('1')),
                    quantity,
                    token,
                });
            }

            case ERC20TokenType.ERC20: {
                const { decimals, symbol, tokenAddress } = token.data;
                return TokenWithAmountCodec.decode({
                    amount: formatAmount(quantity, decimals),
                    quantity,
                    token: {
                        type: ERC20TokenType.ERC20,
                        data: {
                            symbol,
                            decimals,
                            tokenAddress,
                        },
                    },
                });
            }

            default: {
                return TokenWithAmountCodec.decode({
                    amount: formatAmount(quantity),
                    token: ethToken,
                    quantity,
                });
            }
        }
    };

export const getTokenAsset =
    (token: Token) =>
    (client: ImmutableXClient): TE.TaskEither<Error, ImmutableMethodResults.ImmutableAsset | undefined> =>
        token.type === ERC721TokenType.ERC721
            ? pipe(
                  ImmutableMethodParams.ImmutableGetAssetParamsCodec.decode({
                      address: token.data.tokenAddress,
                      id: token.data.tokenId,
                  }),
                  E.mapLeft(errorsToError),
                  TE.fromEither,
                  TE.chain(client.getAssetF),
              )
            : TE.of(undefined);

export const tokenToTokenWithDetails =
    (client: ImmutableXClient) =>
    (quantity: PositiveBigNumber) =>
    (token: Token): TE.TaskEither<Error, TokenWithDetails> => {
        return pipe(
            E.bindTo('tokenWithAmount')(tokenToTokenWithAmount(quantity)(token)),
            E.mapLeft(errorsToError),
            TE.fromEither,
            TE.bind('asset', () => getTokenAsset(token)(client)),
            TE.map(({ tokenWithAmount, asset }) => ({
                ...tokenWithAmount,
                toAddress: '',
                asset,
            })),
        );
    };

export function log<T>(input: T): T {
    console.log(input);
    return input;
}

export const getTokenWithDetails =
    (flatToken: FlatTokenWithAmount, decimals = 18) =>
    (client: ImmutableXClient): TE.TaskEither<Error, TokenWithDetails> =>
        pipe(
            E.bindTo('amount')(
                flatToken.type === ERC721TokenType.ERC721
                    ? PositiveBigNumber.decode(1)
                    : pipe(utils.parseUnits(flatToken.amount, BigNumber.from(decimals)), (amount) =>
                          PositiveBigNumber.decode(amount.toString()),
                      ),
            ),
            E.bind('token', () => E.right(flatTokenToToken(flatToken, decimals))),
            E.mapLeft(errorsToError),
            TE.fromEither,
            TE.chain(({ amount, token }) => tokenToTokenWithDetails(client)(amount)(token)),
        );

export const immutableTokenToToken = (token: ImmutableMethodResults.ImmutableToken): E.Either<IOErrors, Token> => {
    return switchCase(
        token.type,
        {
            [ERC721TokenType.ERC721]: () => {
                const t = token as ImmutableMethodResults.ImmutableERC721Token;
                const { token_id, token_address } = t.data ?? {};

                return pipe(
                    TokenCodec.decode({
                        type: ERC721TokenType.ERC721,
                        data: {
                            tokenId: token_id,
                            tokenAddress: token_address,
                        },
                    }),
                );
            },
            [ERC20TokenType.ERC20]: () => {
                const t = token as ImmutableMethodResults.ImmutableERC20Token;
                const { decimals, token_address } = t.data ?? {};

                // NOTE: Fallback value, required for 'decode'.
                const symbol = ERC20TokenType.ERC20 as string;

                return pipe(
                    TokenCodec.decode({
                        type: ERC20TokenType.ERC20,
                        data: {
                            symbol,
                            decimals,
                            tokenAddress: token_address,
                        },
                    }),
                );
            },
            [ETHTokenType.ETH]: () => E.of(ethToken),
        },
        () => TokenCodec.decode(undefined),
    );
};

export const immutableTokenToTokenWithDetails =
    (client: ImmutableXClient) =>
    (immutableToken: ImmutableMethodResults.ImmutableToken): TE.TaskEither<Error, TokenWithDetails> =>
        pipe(
            E.bindTo('token')(immutableTokenToToken(immutableToken)),
            E.mapLeft(errorsToError),
            TE.fromEither,
            TE.chain(({ token }) => {
                const { quantity } = immutableToken.data;
                return tokenToTokenWithDetails(client)(quantity)(token);
            }),
        );

export const checkSufficientTokenBalance = (
    quantity: PositiveBigNumber,
    imxBalance: NonNegativeBigNumber,
    errorMessage: string,
) => pipe(A.sequence(E.either)([assertEither(quantity.gt(imxBalance), errorMessage)]), TE.fromEither);

export const checkSufficientTokenBalanceTS = (quantity: PositiveBigNumber, imxBalance: NonNegativeBigNumber) =>
    quantity.lte(imxBalance);

export const waitForTransaction = (provider: Web3Provider) => (txHash: string) =>
    pipe(
        taskEitherWithError(() => provider.waitForTransaction(txHash)),
        TE.chain((receipt) => (receipt.status === 0 ? TE.left(new Error('Transaction failed')) : TE.right(receipt))),
    );

export const getAddress = (provider: Web3Provider) =>
    pipe(
        taskEitherWithError(async () => {
            try {
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                return address.toLowerCase();
            } catch {
                throw new Error(messages.failedToRetrieveWalletAddr);
            }
        }),
        TE.chain((address) => pipe(EthAddress.decode(address), E.mapLeft(errorsToError), TE.fromEither)),
    );

export const getBalanceForTokenType = (
    token: Token,
    client: ImmutableXClient,
    address: Branded<string, EthAddressBrand>,
): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetBalanceResult> => {
    if (token.type !== ERC721TokenType.ERC721) {
        const tokenAddress = token.type === ETHTokenType.ETH ? 'eth' : token.data.tokenAddress;
        return client.getBalanceF({ user: address, tokenAddress });
    }
    return TE.of({} as ImmutableMethodResults.ImmutableGetBalanceResult);
};

export const getBalanceForTokenTypeTS = (
    token: TokenTS,
    client: ImmutableXClient,
    address: string,
): Promise<ImmutableMethodResults.ImmutableGetBalanceResult> => {
    if (token.type !== ERC721TokenType.ERC721) {
        const tokenAddress = token.type === ETHTokenType.ETH ? 'eth' : token.data.tokenAddress;
        return client.getBalance({ user: address, tokenAddress });
    }
    return Promise.resolve({} as ImmutableMethodResults.ImmutableGetBalanceResult);
};

export const wasRequestRejected = (e: Error) => 'code' in e && (e as any).code === 4001;

export const isServiceUnavailable = (e: Error) => e.message.indexOf('This service is unavailable') >= 0;

export const registerUserAndWait = (client: ImmutableXClient, provider: Web3Provider) =>
    pipe(
        client.getSignableRegistrationF({
            etherKey: client.address,
            starkPublicKey: client.starkPublicKey,
        }),
        TE.chain((signableRegistrationResult) =>
            TE.of({
                etherKey: client.address,
                starkPublicKey: client.starkPublicKey,
                operatorSignature: signableRegistrationResult.operator_signature,
            }),
        ),
        TE.chain(client.registerStarkF),
        TE.chain((txHash) => taskEitherWithError(() => provider.waitForTransaction(txHash))),
    );

export const registerUserAsync = async (client: ImmutableXClient, provider: Web3Provider) => {
    const signableRegistrationResult = await client.getSignableRegistration({
        etherKey: client.address,
        starkPublicKey: client.starkPublicKey,
    });

    const txHash = await client.registerStark({
        etherKey: client.address,
        starkPublicKey: client.starkPublicKey,
        operatorSignature: signableRegistrationResult.operator_signature,
    });

    return provider.waitForTransaction(txHash);
};

export const requiresSeparateRegistrationStep = (
    isRegistered: boolean,
    separateRegisterAndWithdrawalEth: boolean,
    token: Token,
) => !isRegistered && (separateRegisterAndWithdrawalEth || token.type !== ETHTokenType.ETH);

export const isEthOrERC20TokenType = (tokenType: string) =>
    tokenType === ETHTokenType.ETH || tokenType === ERC20TokenType.ERC20;

export function getERC20TokenMetaData(details: TokenWithAmount, tokensList: TokensListType): TokenDataType {
    let tokenMetaData: TokenDataType;

    const defaultTokenMetaData = {
        token_address: '',
        image_url: DEFAULT_ETH_TOKEN_IMAGERY,
        name: '',
        decimals: '0',
        quantum: '',
        symbol: '',
    };

    switch (details.token.type) {
        case ERC20TokenType.ERC20: {
            const { tokenAddress } = details.token.data;
            tokenMetaData = findTokenWithAddress(tokensList, tokenAddress);
            break;
        }
        default:
            break;
    }

    return tokenMetaData || defaultTokenMetaData;
}

export const calculateTokenQuantity = (tokenDetail: TokenWithDetails): BigNumber =>
    utils.parseUnits(
        tokenDetail.amount,
        tokenDetail.token.type === ERC20TokenType.ERC20 ? tokenDetail.token.data.decimals : DEFAULT_DECIMALS,
    );
