import {
    BaseComponentPropTypes,
    colors,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import React, { useMemo } from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { closeWindow, ParentWindow } from '../../lib';
import { FullTransferInfoWithFinalStatus } from '../../types/SharedTransfer.types';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { TransferDetailsListing } from './common.components';

export interface TransferCompletePropTypes extends BaseComponentPropTypes {
    parent: ParentWindow;
    transferDetails?: FullTransferInfoWithFinalStatus[];
}

// @TODO: Alot of the below is a standard Link screen, so this should eventually be templatized in such a way
// that other components too, can use this exact same layout (albiet with their own actions etc)
export const TransferCompleteScreen = ({ parent, transferDetails, testId }: TransferCompletePropTypes) => {
    const textMessages = useI18nMessaging({});
    const onFinishCtaClick = closeWindow(parent);

    const failedTransfers = useMemo(() => {
        return transferDetails?.filter(
            (failedTransfer) =>
                failedTransfer.validationResult.status === 'error' ||
                failedTransfer?.transferTransactionStatus?.status === 'error',
        );
    }, [transferDetails]);
    const showFailureMessagingUi = failedTransfers?.length;

    return (
        <StandardLinkRouteContainer testId={`${testId}__transferCompleteContainer`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId={`${testId}__transferCompleteContainer__heading`}>
                    {showFailureMessagingUi
                        ? textMessages.transfer.title.partialDone
                        : textMessages.transfer.title.done}
                </SectionHeading>
            </VerticalSpace>
            <ParagraphText
                fillColor={colors.light[300]}
                fontSize="small"
                testId={`${testId}__transferCompleteContainer__intro`}
            >
                {showFailureMessagingUi
                    ? textMessages.transfer.bodyText.partialDone
                    : textMessages.transfer.bodyText.done}
            </ParagraphText>

            {showFailureMessagingUi && (
                <>
                    <VerticalSpace top="x-large">
                        <ParagraphText
                            fontWeight="bold"
                            fillColor={colors.yellow[100]}
                            fontSize="small"
                            testId={`${testId}__transferCompleteContainer__partialFailuresText`}
                        >
                            {textMessages.transfer.someItemsInvalid}
                        </ParagraphText>
                    </VerticalSpace>
                    <TransferDetailsListing
                        testId={`${testId}__transferCompleteContainer__unsuccessfulTransfers`}
                        transfers={failedTransfers}
                        displayErrorInformation
                    />
                </>
            )}

            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large">
                    <StandardButton
                        testId={`${testId}__transferCompleteContainer__finishCta`}
                        buttonKind="ultimate-cta"
                        onClick={onFinishCtaClick}
                    >
                        {textMessages.standardUiButtons.continue}
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
