import { Web3Provider } from '@ethersproject/providers';
import { ImmutableXClient, LinkResults, taskEitherWithError, Token } from '@imtbl/imx-sdk';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';

import { sendAnalytics } from './analytics/send-analytics';
import { FlowEventName, ScreenEventName } from './analytics/types';
import { createFlowEvent, createScreenEvent } from './analytics/utils';
import { NotifyMethod } from './types';
import { registerUserAndWait } from './utils';

export const completeWithdrawal = (
    client: ImmutableXClient,
    provider: Web3Provider,
    token: Token,
    requiresSeparateRegistrationStep: boolean,
    notify: NotifyMethod,
): TE.TaskEither<Error, LinkResults.CompleteWithdrawal> => {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.bind('starkExContractVersion', () => client.getStarkExContractMajorVersion()),
        TE.chain(({ starkExContractVersion }) =>
            requiresSeparateRegistrationStep && starkExContractVersion === 3
                ? registerUserAndWait(client, provider)
                : TE.of(null),
        ),
        TE.chainFirst(() => TE.fromIO(() => notify({ msg: 'Withdrawing On-Chain' }))),
        TE.bind('txHash', () =>
            client.registerAndCompleteWithdrawalF({ starkPublicKey: client.starkPublicKey, token }),
        ),
        TE.chainFirst(({ txHash }) => TE.fromIO(() => notify({ msg: 'Waiting for receipt', tx: txHash }))),
        TE.bind('receipt', ({ txHash }) => {
            return taskEitherWithError(async () => provider.waitForTransaction(txHash));
        }),
        TE.bind('result', ({ receipt, txHash }) => {
            return pipe(
                { transactionId: txHash },
                O.fromPredicate(() => receipt.status !== 0),
                TE.fromOption(() => new Error('Transaction failed')),
            );
        }),
        TE.chainFirst(() =>
            TE.fromIO(() => {
                sendAnalytics(
                    createScreenEvent(ScreenEventName.completeWdrawConfirmedOpened),
                    createFlowEvent(FlowEventName.completeWdrawSucceeded),
                );
            }),
        ),
        TE.map(({ result }) => result),
    );
};
