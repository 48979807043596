import { getProvider, ImmutableXClient, LinkResults } from '@imtbl/imx-sdk';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

import { ProvidersConfig } from '../utils/getProvidersConfig';
import { sendAnalytics } from './analytics/send-analytics';
import { FlowEventName, ScreenEventName } from './analytics/types';
import { createFlowEvent, createScreenEvent } from './analytics/utils';
import { LinkClientConfig, NotifyMethod, TokenWithAmount } from './types';

export function prepareWithdrawal(
    config: LinkClientConfig,
    params: TokenWithAmount,
    notify: NotifyMethod,
    providerOptions: ProvidersConfig,
): TE.TaskEither<Error, LinkResults.PrepareWithdrawal> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.chain(() => TE.bindTo('provider')(getProvider(providerOptions))),
        TE.bind('client', ({ provider }) =>
            ImmutableXClient.buildF({
                ...config,
                signer: provider.getSigner(),
            }),
        ),
        TE.chainFirst(() => TE.fromIO(() => notify({ msg: 'Preparing Withdrawal' }))),
        TE.bind('transfer', ({ client, client: { address } }) =>
            client.prepareWithdrawalF({
                user: address,
                token: params.token,
                quantity: params.quantity,
            }),
        ),
        TE.chainFirst(() =>
            TE.fromIO(() => {
                sendAnalytics(
                    // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                    createScreenEvent(ScreenEventName.prepareWdrawConfirmedOpened),
                    createFlowEvent(FlowEventName.prepareWdrawSucceeded),
                );
            }),
        ),
        TE.map(({ transfer: { withdrawal_id } }) => ({ withdrawalId: withdrawal_id })),
    );
}
