// NOTE: The values listed in this 'enum' should
// match the keys of the flags from 'config.json'.
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum FEATURE_FLAG {
    SHOW_REWARD_CLAIM_FLOW = 'imx3715_showImxRewardClaimFlow',
    SHOW_DAILYPOINT_SUPPORT_HELPER = 'imx2121_linkDailyPointsSupportArticle',
    CLAIM_US_SECOND_OPTION = 'imx3715_showImxRewardClaimFlow_US_second_option',
    SEPARATE_REGISTER_AND_WITHDRAWAL_ETH = 'imx_3735_SeparateRegisterAndWithdrawalEth',
    ERC20_LISTING = 'imx4595_erc20Listing',
    ENABLE_EXCHANGE = 'imx4307_enableExchange',
    ENABLE_BATCH_TRANSFER = 'cd572_enableTransfersV2',
    ENABLE_CRYPTO_TO_FIAT = 'main77_enable_crypto_to_fiat',
    ENABLE_HARDWARE_WALLET_INFO = 'wt22_enableHardwareWalletInformation',
    ENABLE_WALLETCONNECT_FOR_MOBILE_WALLET = 'imx410_enableWalletConnectForMobile',
    ENABLE_WALLETCONNECT_DEPRECATION_MESSAGE = 'wt1426_enable_wc_deprecation_message',
    ENABLE_ASYNC_SEND_ANALYTICS = 'imx735_enable_async_send_analytics',
    ENABLE_ASYNC_END_OF_DEPOSIT_FLOW_EVENTS = 'imx773_enable_async_analytics_at_end_of_deposit_flow',
    ENABLE_SEND_PROV_PREF_ANALYTICS_EVENT = 'imx760_enable_send_provider_pref_with_asset_purchase_analytics_event',
    ENABLE_RISK_ASSESSMENT = 'wt462_enable_risk_assessment',
    HIDE_BUY_CONFIRMATION_FOR_MAGIC_FLOW = 'wt441_hide_buy_confirmation_for_magic',
    ENABLE_GAMESTOP_WALLET = 'wt544_enable_gamestop_wallet',
    SHOW_GAMESTOP_WALLET_IN_LIST = 'wt544_show_gamestop_wallet_in_list',
    ENABLE_NFT_DIRECT_UI = 'wt616_enable_nft_direct_ui',
    ENABLE_NFT_SECONDARY_UI = 'wt752_enable_nft_secondary_flow_ui',
    ENABLE_OFFER_ROUTES = 'mp1159_enable_offer_routes',
    ENABLE_GAME_WALLETS = 'wt921_enable_game_wallets',
    ENABLE_GAME_WALLET_AUTO_DETECT = 'wt985-enable-game-wallet-auto-detect',
}
