import { ImmutableXClient } from '@imtbl/imx-sdk';
import { useEffect, useMemo, useState } from 'react';

import { LinkClientConfig } from '../lib';
import { useGetTransferParamsFromSearchParams } from './useGetTransferParamsFromSearchParams.hook';
import { useGetTransferTokenDetails } from './useGetTransferTokenDetails.hook';
import { useGetTransferTokenMetadata } from './useGetTransferTokenMetadata.hook';
import { useGetUserTokenBalances } from './useGetUserTokenBalances.hook';
import { useValidateTransfers } from './useValidateTransfers.hook';

export const useGetFullTransferDetails = ({
    client,
    ethAddress,
    config,
}: {
    client?: ImmutableXClient;
    ethAddress?: string;
    config: LinkClientConfig;
}) => {
    const [loading, setLoading] = useState(true);
    const params = useGetTransferParamsFromSearchParams();
    const transferDetailsWithTokenDetails = useGetTransferTokenDetails({ client, params, config });
    const transferDetailsWithMetadata = useGetTransferTokenMetadata({
        transferDetails: transferDetailsWithTokenDetails,
        config,
    });
    const tokens = useMemo(
        () => transferDetailsWithTokenDetails?.map((transfer) => ({ ...transfer.tokenDetails?.token })),
        [transferDetailsWithTokenDetails],
    );
    const userTokenBalances = useGetUserTokenBalances({
        tokens,
        client,
        ethAddress,
    });
    const transferDetailsWithMetadataAndUserBalances = useMemo(
        () =>
            transferDetailsWithMetadata?.map((transfer, index) => ({
                ...transfer,
                userBalance: userTokenBalances?.[index],
            })),
        [transferDetailsWithMetadata, userTokenBalances],
    );
    const validatedTransfers = useValidateTransfers({
        transferDetails: transferDetailsWithMetadataAndUserBalances,
        ethAddress,
    });
    useEffect(() => {
        if (validatedTransfers) setLoading(false);
    }, [validatedTransfers]);

    return { loading, transferDetails: validatedTransfers };
};
