import { BaseComponentPropTypes, FlexLayout, layoutHelpers, StandardButton, VerticalSpace } from '@imtbl/design-system';
import React from 'react';

import { BatchNftTransferWarning } from './BatchNftTransferWarning.component';

interface Props extends BaseComponentPropTypes {
    batchNumber: number;
    numberOfBatches: number;
    actionText: string;
    actionHandler: () => void;
}

export const BatchNftTransferFooter: React.FC<Props> = ({
    testId,
    batchNumber,
    numberOfBatches,
    actionText,
    actionHandler,
}) => {
    return (
        <FlexLayout
            marginTop="auto"
            marginBottom={layoutHelpers.gridUnits(1)}
            flexDirection="column"
            alignItems="stretch"
            testId={`${testId}__batchNftTransferFooter__container`}
        >
            <BatchNftTransferWarning testId={testId} batchNumber={batchNumber} numberOfBatches={numberOfBatches} />
            <FlexLayout
                marginTop="auto"
                marginBottom={layoutHelpers.gridUnits(1)}
                paddingRight={layoutHelpers.gridUnits(6)}
                paddingLeft={layoutHelpers.gridUnits(6)}
                paddingTop={layoutHelpers.gridUnits(4)}
                flexDirection="column"
                alignItems="stretch"
                testId={`${testId}__batchNftTransferFooter__buttonContainer`}
            >
                <VerticalSpace bottom="large">
                    <StandardButton
                        testId={`${testId}__batchNftTransferFooter__actionButton`}
                        buttonKind="ultimate-cta"
                        onClick={actionHandler}
                    >
                        {actionText}
                    </StandardButton>
                </VerticalSpace>
            </FlexLayout>
        </FlexLayout>
    );
};
