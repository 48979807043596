import { colors, FlexLayout, layoutHelpers, ParagraphText, StyledLink } from '@imtbl/design-system';
import React from 'react';

import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { LinkEventName } from '../../../lib/analytics/types';
import { createLinkEvent } from '../../../lib/analytics/utils';

const IMX_TERMS_OF_SERVICE =
    'https://support.immutable.com/en/articles/6393967-immutable-x-third-party-digital-wallets';
const IMX_PRIVACY_POLICY = 'https://www.immutable.com/privacy-policy';
const IMX_COLLECTION_STATEMENT = 'https://www.immutable.com/collection-statement';
const MAGIC_TERMS_OF_SERVICE = 'https://magic.link/legal/terms-of-service';
const MAGIC_PRIVACY_POLICY = 'https://magic.link/legal/privacy-policy';

export const TermsAndConditions = ({ showMagicLinks }: { showMagicLinks?: boolean }) => {
    const renderMagicTerms = () => (
        <>
            {' '}
            and Magic&apos;s{' '}
            <StyledLink
                testId="magic-terms-button"
                onClick={() => {
                    sendAnalytics(createLinkEvent(LinkEventName.setupKeyMagicTermsPressed));
                }}
                fontSize="tag"
                fontWeight="bold"
                underline
                href={MAGIC_TERMS_OF_SERVICE}
                target="_blank"
            >
                Terms of Service
            </StyledLink>
            {' & '}
            <StyledLink
                testId="magic-privacy-policy-button"
                onClick={() => {
                    sendAnalytics(createLinkEvent(LinkEventName.setupKeyMagicPrivacyPressed));
                }}
                fontSize="tag"
                fontWeight="bold"
                underline
                href={MAGIC_PRIVACY_POLICY}
                target="_blank"
            >
                Privacy Policy
            </StyledLink>
        </>
    );

    return (
        <FlexLayout marginBottom={layoutHelpers.gridUnits(1)} flexDirection="column">
            <ParagraphText fillColor={colors.light[300]} fontSize="tag" textAlign="center">
                By connecting you&apos;re agreeing to our
                <br />
                <StyledLink
                    testId="terms-and-conditions-button"
                    fillColor={colors.light[700]}
                    onClick={() => {
                        sendAnalytics(createLinkEvent(LinkEventName.setupKeyTermsPressed));
                    }}
                    fontSize="tag"
                    fontWeight="bold"
                    underline
                    href={IMX_TERMS_OF_SERVICE}
                    target="_blank"
                >
                    Terms of Service
                </StyledLink>
                {', '}
                <StyledLink
                    testId="privacy-policy-button"
                    fillColor={colors.light[700]}
                    onClick={() => {
                        sendAnalytics(createLinkEvent(LinkEventName.setupKeyPolicyPressed));
                    }}
                    fontSize="tag"
                    fontWeight="bold"
                    underline
                    href={IMX_PRIVACY_POLICY}
                    target="_blank"
                >
                    Privacy Policy
                </StyledLink>
                {' & '}
                <StyledLink
                    testId="collection-statement-button"
                    onClick={() => {
                        sendAnalytics(createLinkEvent(LinkEventName.setupKeyCollectionStatementPressed));
                    }}
                    fontSize="tag"
                    fontWeight="bold"
                    underline
                    href={IMX_COLLECTION_STATEMENT}
                    target="_blank"
                >
                    Collection Statement
                </StyledLink>
                {showMagicLinks ? renderMagicTerms() : null}.
            </ParagraphText>
        </FlexLayout>
    );
};
