import { colors, ParagraphText, SectionHeading, StandardButton, VerticalSpace } from '@imtbl/design-system';
import React, { useMemo } from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { removeSupportMessage, SupportLink } from '../ErrorMessage/SupportLink';
import { StandardLinkRouteButtonsFooter } from './StandardLinkUiContainers';

export type ErrorProps = {
    message: string;
    title?: string;
    withSupportMessage?: boolean;
    withTitle?: boolean;
    onFinish: () => void;
};

const useHeading = (title: string) => (
    <VerticalSpace bottom="small" top="x-large">
        <SectionHeading testId="heading" fillColor={colors.red[300]}>
            {title}
        </SectionHeading>
    </VerticalSpace>
);

const deduplicateSupportMessage = (message: string): string => removeSupportMessage(message);

const useSupportFooter = () => (
    <VerticalSpace top="x-large">
        <ParagraphText fillColor={colors.light[300]} fontSize="small" testId="ErrorMessage__supportMessage">
            <SupportLink />
        </ParagraphText>
    </VerticalSpace>
);

export const ErrorMessage = ({
    message,
    title = 'Error',
    withSupportMessage = false,
    withTitle = true,
    onFinish,
}: ErrorProps) => {
    const textMessages = useI18nMessaging({});

    const headingParagraph = useMemo(() => (withTitle ? useHeading(title) : null), [withTitle]);

    const messageParagraph = useMemo(
        () => (withSupportMessage ? deduplicateSupportMessage(message) : message),
        [withSupportMessage],
    );

    const supportParagraph = useMemo(() => (withSupportMessage ? useSupportFooter() : null), [withSupportMessage]);

    return (
        <>
            {headingParagraph}

            <ParagraphText fillColor={colors.light[300]} fontSize="small" testId="ErrorMessage__message">
                {messageParagraph}
            </ParagraphText>

            {supportParagraph}

            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large">
                    <StandardButton testId="ErrorMessage__finishCta" buttonKind="ultimate-cta" onClick={onFinish}>
                        {textMessages.standardUiButtons.continue}
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
