// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mNbHXXC59ye7Y6WI5glP:hover {\n    color: var(--colors_light_500)\n}", "",{"version":3,"sources":["webpack://./src/components/FooterLink/index.module.css"],"names":[],"mappings":"AAAA;IACI;AACJ","sourcesContent":[".footerLink:hover {\n    color: var(--colors_light_500)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerLink": "mNbHXXC59ye7Y6WI5glP"
};
export default ___CSS_LOADER_EXPORT___;
