import { colors, ParagraphText, VerticalSpace } from '@imtbl/design-system';
import React, { useEffect } from 'react';

import { EthNetwork } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ScreenEventName } from '../../lib/analytics/types';
import { createScreenEvent } from '../../lib/analytics/utils';
import { SupportLink } from './SupportLink';

interface ChangeNetworkProps {
    network: EthNetwork;
}

export const ChangeNetwork = ({ network }: ChangeNetworkProps) => {
    useEffect(() => {
        sendAnalytics(createScreenEvent(ScreenEventName.errorNetworkOpened));
    }, []);

    return (
        <>
            <VerticalSpace bottom="large" top="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Connect your wallet to <strong style={{ color: colors.light[100] }}>{network}</strong> network.
                </ParagraphText>
            </VerticalSpace>

            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                <SupportLink />
            </ParagraphText>
        </>
    );
};
