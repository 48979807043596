// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yaHKo8puSIzEdvbLs5Bi {\n  border-radius: 5px;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/components/HelperMessageBox/HelperMessageBox.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".helperMessageBox {\n  border-radius: 5px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helperMessageBox": "yaHKo8puSIzEdvbLs5Bi"
};
export default ___CSS_LOADER_EXPORT___;
