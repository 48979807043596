import { ERC20TokenType, EthAddress, TokenTS } from '@imtbl/imx-sdk';
import { useEffect, useState } from 'react';

import { LinkClientConfigTS } from '../lib';
import { findTokenWithAddress, useTokensList } from './useTokensList.hook';

export const useGetBuyTokenMetadata = ({
    config,
    buyTokens,
}: {
    config: LinkClientConfigTS;
    buyTokens?: TokenTS[];
}) => {
    const [tokensWithMetadata, setTokensWithMetadata] = useState<any[]>();
    const { tokens: tokensList } = useTokensList({ config });
    useEffect(() => {
        if (buyTokens?.length && tokensList.length) {
            const tokensWithPickedMetadata = buyTokens.map((buyToken) => {
                if (buyToken.type === ERC20TokenType.ERC20) {
                    const { tokenAddress } = buyToken.data;
                    const metadata = findTokenWithAddress(tokensList, tokenAddress as EthAddress);
                    return {
                        ...metadata,
                    };
                }
                return {};
            });
            setTokensWithMetadata(tokensWithPickedMetadata);
        }
    }, [buyTokens, tokensList]);

    return tokensWithMetadata;
};
