import { Web3Provider } from '@ethersproject/providers';
import { valueOrThrowTE } from '@imtbl/imx-sdk';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { getAddress } from '../lib';

export const useGetEthAddress = (provider: Web3Provider | undefined) => {
    const [ethAddress, setEthAddress] = useState<string>();
    const { setErrorLog } = useContext(LinkUiCoreContext);
    useEffect(() => {
        async function buildEthAddress() {
            try {
                const newEthAddress = await valueOrThrowTE(getAddress(provider as Web3Provider));
                setEthAddress(newEthAddress as string);
            } catch (err) {
                setErrorLog(err);
            }
        }

        if (provider) buildEthAddress();
    }, [provider, setErrorLog]);

    return ethAddress;
};
