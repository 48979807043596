import { colors, Icon, ParagraphText, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
import { isMobile } from '@walletconnect/browser-utils';
import React, { useEffect, useState } from 'react';

import gamestopLogo from '../../assets/images/providers/gamestop-logo-black.svg';
import magicLogo from '../../assets/images/providers/magic-logo.svg';
import metaMaskLogo from '../../assets/images/providers/metamask-logo.svg';
import walletConnectLogo from '../../assets/images/providers/wallet_connect-logo.svg';
import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { useShouldEnableMetamaskSelection } from '../../hooks/useShouldEnableMetamaskSelection.hook';
import { LinkConfig } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, ScreenEventName } from '../../lib/analytics/types';
import { createButtonEvent, createScreenEvent, removeWalletIdFromLocalStorage } from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { InternalRoutes } from '../../utils/internalRoutes';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import FooterLink from '../FooterLink';
import { TermsAndConditions } from '../Setup/TermsAndConditions';
import { GameWalletSelection } from './GameWalletSelection';
import { GameWalletsOption } from './GameWalletsOption';
import * as styles from './index.styles';
import { ProviderSelectionOption, ProviderSelectionOptionReactRouter } from './ProviderSelectionOption';

const HardwareWalletProviderSelectionOption = () => (
    <ProviderSelectionOptionReactRouter
        testId="provider--hardware-wallet"
        selection="setup-hardware-wallet"
        text="MetaMask (Hardware wallet)"
        providerLogo={metaMaskLogo}
        to={InternalRoutes.SETUP_HARDWARE_WALLET}
    />
);

const GamestopSelectionOption = ({ enableAsyncSendAnalytics }: { enableAsyncSendAnalytics: boolean }) => (
    <ProviderSelectionOption
        providerPreference={ProviderPreference.GAMESTOP}
        testId="provider--gamestop"
        selection="gamestop"
        providerLogo={gamestopLogo}
        text="Gamestop"
        enableAsyncSendAnalytics={enableAsyncSendAnalytics}
    />
);
interface ProviderSelectionProps {
    config: LinkConfig;
}
const ProviderSelection = ({ config }: ProviderSelectionProps) => {
    const flags = useLaunchDarklyFlags();
    const enableHardwareWalletInfo = flags[FEATURE_FLAG.ENABLE_HARDWARE_WALLET_INFO];
    const enableWalletConnectFeatureFlag = flags[FEATURE_FLAG.ENABLE_WALLETCONNECT_FOR_MOBILE_WALLET];
    const enableAsyncSendAnalytics = flags[FEATURE_FLAG.ENABLE_ASYNC_SEND_ANALYTICS];
    const { shouldEnableMetamaskSelection } = useShouldEnableMetamaskSelection();
    const showGamestopSelection = flags[FEATURE_FLAG.SHOW_GAMESTOP_WALLET_IN_LIST];
    const showGameWallets = flags[FEATURE_FLAG.ENABLE_GAME_WALLETS];
    const [gameWalletsSelection, setGameWalletsSelection] = useState(false);

    useEffect(() => {
        removeWalletIdFromLocalStorage();
        sendAnalytics(createScreenEvent(ScreenEventName.linkProviderPreferenceOpened));
    }, []);

    function handleGameWalletsClick() {
        sendAnalytics(createButtonEvent(ButtonEventName.setupGameWalletsPressed));
        setGameWalletsSelection(true);
    }

    if (gameWalletsSelection)
        return <GameWalletSelection config={config} onBackButtonClick={() => setGameWalletsSelection(false)} />;

    return (
        <StandardLinkRouteContainer>
            <VerticalSpace bottom="large">
                <SectionHeading>Connect your wallet</SectionHeading>
            </VerticalSpace>

            {enableWalletConnectFeatureFlag ? (
                <VerticalSpace bottom="large">
                    <ParagraphText fillColor={colors.light[300]} fontSize="small">
                        Connect or create your own wallet to access Immutable X.
                    </ParagraphText>
                </VerticalSpace>
            ) : (
                <>
                    <VerticalSpace bottom="large">
                        <ParagraphText fillColor={colors.light[300]} fontSize="small">
                            Welcome to <strong style={{ color: colors.light[100] }}>Immutable X Link</strong> - your
                            companion for all Immutable X powered marketplaces.
                        </ParagraphText>
                    </VerticalSpace>

                    <VerticalSpace bottom="large">
                        <ParagraphText fillColor={colors.light[300]} fontSize="small">
                            Connect your wallet or create a new one:
                        </ParagraphText>
                    </VerticalSpace>
                </>
            )}
            <div className={styles.ButtonsContainer}>
                {shouldEnableMetamaskSelection && (
                    <ProviderSelectionOption
                        providerPreference={ProviderPreference.METAMASK}
                        testId="provider--metaMask"
                        selection="metamask"
                        providerLogo={metaMaskLogo}
                        text="MetaMask"
                        enableAsyncSendAnalytics={enableAsyncSendAnalytics}
                    />
                )}
                {showGameWallets && (
                    <GameWalletsOption
                        providerIcon="logo_imx"
                        text="Game Wallets"
                        onGameWalletsClick={handleGameWalletsClick}
                        testId="provider--gameWallets"
                    />
                )}
                {enableHardwareWalletInfo && <HardwareWalletProviderSelectionOption />}
                {showGamestopSelection && (
                    <GamestopSelectionOption enableAsyncSendAnalytics={enableAsyncSendAnalytics} />
                )}

                <ProviderSelectionOption
                    providerPreference={ProviderPreference.MAGIC_LINK}
                    testId="provider--magic"
                    selection="magic"
                    providerLogo={magicLogo}
                    text="Email"
                    enableAsyncSendAnalytics={enableAsyncSendAnalytics}
                />
            </div>
            <StandardLinkRouteButtonsFooter testId="providerSelection__buttonsFooter">
                <FooterLink to={InternalRoutes.WALLET_DIFFERENCE} testId="what-difference-button">
                    What&apos;s the difference?
                </FooterLink>
                {enableWalletConnectFeatureFlag && <TermsAndConditions />}
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};

export default ProviderSelection;
