import { css } from '@emotion/css';
import { colors, StyledLink } from '@imtbl/design-system';
import React from 'react';

type CancelLinkProps = {
    title: string;
    onClick: () => void;
    fillColor?: string;
    testId?: string;
};

export const CancelLink: React.FC<CancelLinkProps> = ({
    title,
    onClick,
    fillColor = colors.light[700],
    testId = 'cancel',
}) => (
    <StyledLink
        testId={`${testId}__denyCta`}
        fontSize="small"
        fontWeight="bold"
        fillColor={fillColor}
        onClick={onClick}
        className={css`
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
        `}
    >
        {title}
    </StyledLink>
);
