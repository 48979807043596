import {
    assertNever,
    ERC20TokenType,
    ERC721TokenType,
    ETHTokenType,
    MintableERC20TokenType,
    MintableERC721TokenType,
} from '@imtbl/imx-sdk';
import { useEffect, useState } from 'react';

import { validateErc721Transfer, validateEthOrErc20Transfer } from '../lib/transfers';
import { FullTransferTokenInfo, TransferTokenInfoMinusValidation } from '../types/SharedTransfer.types';
import { useI18nMessaging } from './useI18nMessaging.hook';

export const useValidateTransfers = ({
    transferDetails,
    ethAddress,
}: {
    transferDetails?: TransferTokenInfoMinusValidation[];
    ethAddress?: string;
}) => {
    const [validatedTransfers, setValidatedTransfers] = useState<FullTransferTokenInfo[]>();
    const textMessages = useI18nMessaging();

    useEffect(() => {
        if (transferDetails?.length && ethAddress && transferDetails[0].userBalance) {
            const updatedTransferList = transferDetails.map((transfer) => {
                const modifiedTransfer = { ...transfer } as FullTransferTokenInfo;
                switch (transfer.tokenDetails?.token.type) {
                    case ERC721TokenType.ERC721:
                        modifiedTransfer.validationResult = validateErc721Transfer(transfer, textMessages, ethAddress);
                        break;

                    case ETHTokenType.ETH:
                    case ERC20TokenType.ERC20:
                        modifiedTransfer.validationResult = validateEthOrErc20Transfer(transfer, textMessages);
                        break;

                    case MintableERC721TokenType.MINTABLE_ERC721:
                    case MintableERC20TokenType.MINTABLE_ERC20:
                        throw new Error('Not implemented');

                    default:
                        assertNever(transfer.tokenDetails?.token);
                }

                return modifiedTransfer;
            });
            setValidatedTransfers(updatedTransferList);
        }
    }, [transferDetails, textMessages, ethAddress]);
    return validatedTransfers;
};
