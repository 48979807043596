import { Web3Provider } from '@ethersproject/providers';
import { ImmutableXClient, LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';

import { CancelOfferCancelOrderAPIError } from '../../components/Offers/CancelOffer/CancelOfferError';
import { SetErrorLog } from '../../context/App.context';
import { sendAnalytics } from '../analytics/send-analytics';
import { FlowEventName } from '../analytics/types';
import { createFlowEvent } from '../analytics/utils';
import { LinkClientConfig, ParentWindow } from '../types';

export interface CancelOfferInputs {
    config: LinkClientConfig;
    orderId: number;
    provider: Web3Provider;
    parent: ParentWindow;
    setErrorLog: SetErrorLog;
}

export async function cancelOffer({ config, orderId, provider, parent, setErrorLog }: CancelOfferInputs) {
    const client = await ImmutableXClient.build({ ...config, signer: provider.getSigner() });
    try {
        await client.cancelOrderV3(orderId);
        sendAnalytics(createFlowEvent(FlowEventName.cancelOfferSucceeded));
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.success }, '*');
    } catch (err) {
        console.error(err);
        sendAnalytics(createFlowEvent(FlowEventName.cancelOfferFailed));
        setErrorLog(CancelOfferCancelOrderAPIError);
    }
}
