// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ld8yA4aGfUwBQ1IaZmTz {\n    background-color: transparent;\n    color: #fff;\n    margin-top: 15px;\n}\n\n.SISEtD3WuTMc_TopMH5U {\n    text-decoration: none;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.FXDdPI8EoatYcMH_gyIC {\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    margin-bottom: 22px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/WalletDifference/index.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".provider {\n    background-color: transparent;\n    color: #fff;\n    margin-top: 15px;\n}\n\n.providerDescription {\n    text-decoration: none;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.providerInfo {\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    margin-bottom: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"provider": "ld8yA4aGfUwBQ1IaZmTz",
	"providerDescription": "SISEtD3WuTMc_TopMH5U",
	"providerInfo": "FXDdPI8EoatYcMH_gyIC"
};
export default ___CSS_LOADER_EXPORT___;
