import { BigNumber } from '@ethersproject/bignumber';

import { BUFFER_FEE } from '../../../utils/constants';

// 2^63 is the max amount that can be represented in a StarkEx transaction.
// 2^63 = 9 223 372 036 854 775 808
export const MAX_VAULT_AMOUNT = BigNumber.from('2').pow(63);

export const isAmountValid = (amount: string, quantum = '1'): boolean => {
    try {
        if (amount) {
            // The max amount here leaves a 10% buffer for fees to be added by the taker marketplace
            // on the other side of the trade.
            // More context in https://immutable.atlassian.net/browse/IMX-5053
            const quantisedAmount = BigNumber.from(amount).div(BigNumber.from(quantum));
            const bufferFee = quantisedAmount.mul(BigNumber.from(BUFFER_FEE)).div(BigNumber.from(100));
            const totalQuantisedAmountWithFee = quantisedAmount.add(bufferFee);

            return totalQuantisedAmountWithFee.lte(MAX_VAULT_AMOUNT);
        }

        return false;
    } catch (err) {
        console.error(err);

        return false;
    }
};
