import { StyledLink } from '@imtbl/design-system';
import React, { ReactNode } from 'react';

import { EthNetwork } from '../../lib';

export type EtherscanLinkProps = {
    children?: ReactNode;
    txId: string;
    ethNetwork: EthNetwork;
};

export const EtherscanLink = ({ ethNetwork, txId, children }: EtherscanLinkProps) => {
    const urlEthNetworkSegment = ethNetwork === EthNetwork.mainnet ? '' : `${ethNetwork}.`;
    return (
        <StyledLink
            fontSize="small"
            href={`https://${urlEthNetworkSegment}etherscan.io/tx/${txId}`}
            target="_blank"
            anchorDomProps={{ rel: 'noreferrer noopener' }}
        >
            {children}
        </StyledLink>
    );
};
