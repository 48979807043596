import { Web3Provider } from '@ethersproject/providers';
import { errorsToError, LINK_MESSAGE_TYPE, LinkParamsCodecs, messageTypes } from '@imtbl/imx-sdk';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import React, { useEffect, useState } from 'react';

import { BatchNftTransfer } from '../components/BatchNftTransfer';
import SignMessage from '../components/SignMessage';
import { DispatchSetError, LinkConfig, ParentWindow } from '../lib';

export const eventListenerCurried =
    ({
        config,
        parent,
        provider,
        setErrorLog,
        setMessagingComponent,
        testId,
    }: {
        config: LinkConfig;
        parent: ParentWindow;
        provider: Web3Provider;
        setErrorLog: DispatchSetError;
        setMessagingComponent: any;
        testId?: string;
    }) =>
    (event: MessageEvent) => {
        const { data } = event;
        if (data.type === LINK_MESSAGE_TYPE) {
            switch (data.message) {
                case messageTypes.batchNftTransfer: {
                    pipe(
                        LinkParamsCodecs.BatchNftTransfer.decode(data.payload),
                        E.mapLeft(errorsToError),
                        E.fold(setErrorLog, (params) => {
                            setMessagingComponent(
                                <BatchNftTransfer
                                    config={config.client}
                                    parent={parent}
                                    provider={provider}
                                    params={params}
                                    setErrorLog={setErrorLog}
                                    testId={testId}
                                />,
                            );
                        }),
                    );
                    break;
                }
                case messageTypes.sign: {
                    pipe(
                        LinkParamsCodecs.Sign.decode(data.payload),
                        E.mapLeft(errorsToError),
                        E.fold(setErrorLog, (params) => {
                            setMessagingComponent(
                                <SignMessage
                                    config={config}
                                    parent={parent}
                                    provider={provider}
                                    params={params}
                                    setErrorLog={setErrorLog}
                                />,
                            );
                        }),
                    );
                    break;
                }

                default: {
                    console.error('Unknown message', data);
                    break;
                }
            }
        }
    };

export const useGetMessagingComponent = ({
    config,
    parent,
    provider,
    setErrorLog,
    testId,
}: {
    config?: LinkConfig;
    parent?: ParentWindow;
    provider?: Web3Provider;
    setErrorLog: DispatchSetError;
    testId?: string;
}) => {
    const [messagingComponent, setMessagingComponent] = useState<JSX.Element | null>(null);
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
        if (window.location.pathname !== '/') {
            console.log('Messaging component not needed');
            return;
        }

        if (config && parent && provider && !initialised) {
            setInitialised(true);
            parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.ready }, '*');

            const eventListener = eventListenerCurried({
                config,
                parent,
                provider,
                setErrorLog,
                setMessagingComponent,
                testId,
            });
            window.addEventListener('message', eventListener, false);

            // eslint-disable-next-line consistent-return
            return () => {
                window.removeEventListener('message', eventListener);
            };
        }
    }, [config, parent, provider]);

    return messagingComponent;
};
