import { getProvider, ImmutableMethodResults, ImmutableXClient } from '@imtbl/imx-sdk';
import { constant, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

import { ProvidersConfig } from '../utils/getProvidersConfig';
import { sendAnalytics } from './analytics/send-analytics';
import { FlowEventName, ScreenEventName } from './analytics/types';
import { createFlowEvent, createScreenEvent } from './analytics/utils';
import { LinkClientConfig, NotifyMethod } from './types';

export function cancel(
    config: LinkClientConfig,
    order: ImmutableMethodResults.ImmutableGetOrderV3Result,
    notify: NotifyMethod,
    providerOptions: ProvidersConfig,
): TE.TaskEither<Error, ImmutableMethodResults.ImmutableCancelOrderResult> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.chain(() => TE.bindTo('provider')(getProvider(providerOptions))),
        TE.bind('client', ({ provider }) =>
            ImmutableXClient.buildF({
                ...config,
                signer: provider.getSigner(),
            }),
        ),
        TE.chainFirst(constant(TE.fromIO(() => notify({ msg: 'Cancelling order' })))),
        TE.chain(({ client }) => {
            return client.cancelOrderV3F(order.order_id);
        }),
        TE.chainFirst(() =>
            TE.fromIO(() => {
                sendAnalytics(
                    // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                    createScreenEvent(ScreenEventName.cancelSaleConfirmedOpened),
                    createFlowEvent(FlowEventName.cancelSaleSucceeded),
                );
            }),
        ),
    );
}
