import { ImmutableXClient } from '@imtbl/imx-sdk';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';

export const useBuildClient = (publicApiUrl: string) => {
    const [client, setClient] = useState<ImmutableXClient>();
    const { setErrorLog } = useContext(LinkUiCoreContext);
    useEffect(() => {
        async function buildClient() {
            try {
                const newClient = await ImmutableXClient.build({ publicApiUrl });
                setClient(newClient);
            } catch (err) {
                setErrorLog(err);
            }
        }

        buildClient();
    }, [publicApiUrl, setErrorLog]);

    return client;
};
