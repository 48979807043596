import { LINK_MESSAGE_TYPE, messageTypes, NFTTransactionStatus } from '@imtbl/imx-sdk';
import React, { useEffect, useState } from 'react';

import { useLaunchDarklyFlags } from '../../../context/LaunchDarkly';
import {
    closeWindow,
    createSecondaryTransaction,
    getSecondaryTransactionStatus,
    poll,
    SecondaryFlowStatusInfo,
} from '../../../lib';
import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { ExchangeEventName } from '../../../lib/analytics/types';
import { createExchangeEvent } from '../../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../../lib/featureFlags';
import { StandardLinkRouteContainer } from '../../common';
import { InProgressScreen } from '../../common/InProgressScreen';
import { ResultScreen } from '../../common/ResultScreen';
import { exchangeErrorMessage } from '../exchangeErrorMessage';
import { ExchangeProps } from '../Onramp';
import { showMoonpayExchangeIframe } from '../ProviderIframes/MoonPayExchangeIframe';

export type ProviderNFTCheckoutSecondaryProps = ExchangeProps & {
    providerName: string;
    orderId: string;
    userWalletAddress?: string;
};

enum NFTCheckoutSecondaryStages {
    Widget,
    InProcess,
    Result,
}

export const ProviderNFTCheckoutSecondary = ({
    parent,
    loading,
    config,
    provider,
    setErrorLog,
    messages,
    orderId,
    userWalletAddress,
    providerName = 'moonpay',
}: ProviderNFTCheckoutSecondaryProps) => {
    const flags = useLaunchDarklyFlags();
    const enableNFTDirect = flags[FEATURE_FLAG.ENABLE_NFT_DIRECT_UI];

    const [stage, setStage] = useState<NFTCheckoutSecondaryStages>(NFTCheckoutSecondaryStages.InProcess);
    const [providerUrl, setProviderUrl] = useState<string>();
    const [transactionId, setTransactionId] = useState<string>();
    const [transactionStatus, setTransactionStatus] = useState<NFTTransactionStatus>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const onClose = closeWindow(parent);

    useEffect(() => {
        if (errorMessage) {
            sendAnalytics(createExchangeEvent(ExchangeEventName.nftCheckoutSecondaryNetworkError, {}));

            setErrorLog(
                { message: errorMessage },
                exchangeErrorMessage(messages.nftCheckoutSecondary.title.error, errorMessage, () =>
                    parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*'),
                ),
            );
        }

        if (transactionStatus === NFTTransactionStatus.Failed) {
            sendAnalytics(
                createExchangeEvent(ExchangeEventName.nftCheckoutSecondaryFailed, {
                    transactionId,
                    provider: providerName,
                }),
            );
            setErrorLog(
                { message: messages.nftCheckoutSecondary.error.statusFail },
                exchangeErrorMessage(
                    messages.nftCheckoutSecondary.title.error,
                    messages.nftCheckoutSecondary.error.statusFail,
                    () => parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*'),
                ),
            );
        } else if (transactionStatus === NFTTransactionStatus.Completed) {
            setStage(NFTCheckoutSecondaryStages.Result);
            parent.postMessage(
                { type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: { transactionId } },
                '*',
            );
            sendAnalytics(
                createExchangeEvent(ExchangeEventName.nftCheckoutSecondaryCompleted, {
                    transactionId,
                    provider: providerName,
                }),
            );
        }
    }, [transactionStatus, errorMessage]);

    useEffect(() => {
        if (!enableNFTDirect || !userWalletAddress) {
            return;
        }
        setStage(NFTCheckoutSecondaryStages.InProcess);

        createSecondaryTransaction(config, provider, {
            orderId,
            userWalletAddress,
            provider: providerName,
        })
            .then((response) => {
                setTransactionId(response.transactionId);
                setProviderUrl(response.providerIframeSrc);
                setStage(NFTCheckoutSecondaryStages.Widget);
                sendAnalytics(
                    createExchangeEvent(ExchangeEventName.nftCheckoutSecondaryCreated, {
                        transactionId: response.transactionId,
                        provider: providerName,
                    }),
                );
            })
            .catch((e) => {
                console.error(e);
                setErrorMessage(messages.nftCheckoutSecondary.error.createTransactionError);
            });
    }, [enableNFTDirect]);

    useEffect(() => {
        if (!transactionId) {
            return;
        }
        // Polling
        const pollingStatusFunction = async () => getSecondaryTransactionStatus(config.client, transactionId);
        const shouldContinuePolling = (info: SecondaryFlowStatusInfo): boolean => {
            if (info?.status !== transactionStatus) {
                setTransactionStatus(info?.status);
            }

            return info.status !== NFTTransactionStatus.Completed && info.status !== NFTTransactionStatus.Failed;
        };

        poll<SecondaryFlowStatusInfo>(pollingStatusFunction, shouldContinuePolling, 1500)
            .then((info) => {
                setTransactionStatus(info?.status);
            })
            .catch((e) => {
                setErrorMessage(e.message);
            });
    }, [transactionId]);

    if (enableNFTDirect === undefined || loading) {
        return null;
    }

    if (!enableNFTDirect) {
        setErrorLog(
            { message: messages.nftCheckoutSecondary.error.featureNotYetSupported },
            exchangeErrorMessage(
                messages.nftCheckoutSecondary.title.error,
                messages.nftCheckoutSecondary.error.featureNotYetSupported,
                () => parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*'),
            ),
        );
        return null;
    }

    const stageElements = {
        [NFTCheckoutSecondaryStages.Widget]: showMoonpayExchangeIframe(providerUrl),
        [NFTCheckoutSecondaryStages.InProcess]: (
            <StandardLinkRouteContainer>
                <InProgressScreen
                    heading={messages.nftCheckoutSecondary.moonpay.inProgress.title}
                    paragraph={messages.nftCheckoutSecondary.moonpay.inProgress.text.description}
                />
            </StandardLinkRouteContainer>
        ),
        [NFTCheckoutSecondaryStages.Result]: (
            <StandardLinkRouteContainer>
                <ResultScreen
                    heading={messages.nftCheckoutSecondary.moonpay.result.title}
                    paragraph={messages.nftCheckoutSecondary.moonpay.result.text.description}
                    subParagraph={messages.nftCheckoutSecondary.moonpay.result.text.check}
                    onFinish={onClose}
                />
            </StandardLinkRouteContainer>
        ),
    };

    return <>{stageElements[stage]}</>;
};
