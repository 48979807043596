import { ImmutableXClient } from '@imtbl/imx-sdk';

import { LinkClientConfigTS } from './types';
import { removeVersionInPublicApiUrl } from './verifyOTP';

export const getAwaitingOrderStatus = async (config: LinkClientConfigTS, requestId: string) => {
    const client = await ImmutableXClient.build(removeVersionInPublicApiUrl(config));
    const { status } = await client.getAwaitingOrderStatus(requestId);

    return status;
};
