import { colors, ParagraphText, StyledLink, VerticalSpace } from '@imtbl/design-system';
import React, { useEffect } from 'react';

import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ScreenEventName } from '../../lib/analytics/types';
import { createScreenEvent } from '../../lib/analytics/utils';

export const ActionDenied = () => {
    useEffect(() => {
        sendAnalytics(createScreenEvent(ScreenEventName.errorDeniedOpened));
    }, []);

    return (
        <>
            <VerticalSpace top="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    You have <strong style={{ color: colors.light[100] }}>denied an action</strong> coming from
                    Immutable&nbsp;X.
                </ParagraphText>
            </VerticalSpace>

            <VerticalSpace bottom="large" top="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    If this was in error, please initiate the action again.
                </ParagraphText>
            </VerticalSpace>

            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                If you suspect fraudulent activity, contact us via our{' '}
                <StyledLink
                    href="https://support.immutable.com"
                    fillColor={colors.light[700]}
                    testId="supportLink"
                    fontWeight="bold"
                    target="_blank"
                    fontSize="small"
                >
                    support page
                </StyledLink>{' '}
                immediately.
            </ParagraphText>
        </>
    );
};
