import { colors, SectionHeading, StandardButton, VerticalSpace } from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import React, { useCallback } from 'react';

import { ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../lib/analytics/types';
import { createButtonEvent } from '../../lib/analytics/utils';
import { StandardLinkRouteButtonsFooter } from '../common';

export type ErrorMessageProps = {
    heading: string;
    body: JSX.Element;
};

export const ErrorMessage = ({
    heading,
    body,
    parentWindow,
}: ErrorMessageProps & { parentWindow: ParentWindow | undefined }) => {
    const handleCloseClick = useCallback(() => {
        sendAnalytics(createButtonEvent(ButtonEventName.errorPageClosePressed));
        parentWindow?.postMessage(
            {
                type: LINK_MESSAGE_TYPE,
                message: messageTypes.close,
            },
            '*',
        );
    }, [parentWindow]);

    return (
        <>
            <SectionHeading fillColor={colors.red[300]}>{heading}</SectionHeading>

            {body}

            {parentWindow && (
                <StandardLinkRouteButtonsFooter testId="errorMessage__buttonsFooter">
                    <VerticalSpace bottom="large" top="large">
                        <StandardButton
                            testId="errorMessage__closeButton"
                            buttonKind="primary"
                            onClick={handleCloseClick}
                        >
                            Close
                        </StandardButton>
                    </VerticalSpace>
                </StandardLinkRouteButtonsFooter>
            )}
        </>
    );
};
