import {
    colors,
    ExplodingCircleWithTick,
    FlexLayout,
    IndeterminateHorizontalLines,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    VerticalSpace,
} from '@imtbl/design-system';
import {
    assertEither,
    assertNever,
    ERC20TokenType,
    ERC721TokenType,
    ETHTokenType,
    ImmutableAssetStatus,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LinkError,
    LinkParamsCodecs,
    LinkParamsF,
    messageTypes,
    MintableERC20TokenType,
    MintableERC721TokenType,
    taskEitherWithError,
} from '@imtbl/imx-sdk';
import { utils } from 'ethers';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import React, { useCallback, useEffect, useRef } from 'react';

import { useTokensList } from '../../../hooks/useTokensList.hook';
import { deposit, getERC20TokenMetaData, getTokenWithDetails, NotifyMethod, TokenWithDetails } from '../../../lib';
import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createScreenEvent } from '../../../lib/analytics/utils';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../../utils/constants';
import { AccountActivationFee } from '../../AccountActivationFee';
import { Action } from '../../Action';
import { EtherscanLink } from '../../EtherscanLink';
import { HelperMessageType } from '../../HelperMessageBox';
import { TokenDetailsDisplayRow } from '../../TokenDetailsDisplayRow';
import { DepositProps } from '..';

export type DepositInput = {
    client: ImmutableXClient;
    token: TokenWithDetails;
};

const FixedDeposit = ({ config, parent, provider, messages, setErrorLog, setLoading, ethNetwork }: DepositProps) => {
    const { tokens, error } = useTokensList({ config });
    const hasTokensList = tokens.length > 0;

    useEffect(() => {
        if (!hasTokensList) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [hasTokensList, setLoading]);

    useEffect(() => {
        if (error) {
            setErrorLog(error, messages.generalErrorMessage([messages.failedToRetrieveTokenList(error.message)]));
            setLoading(false);
        }
    }, [error, setErrorLog, setLoading]);

    // TODO: Copy this and change from TaskEither to Promise (in the new flow, keep this unmodified)
    const loadInput = (params: LinkParamsF.Deposit) =>
        pipe(
            TE.bindTo('client')(
                ImmutableXClient.buildF({
                    ...config,
                    signer: provider.getSigner(),
                }),
            ),
            TE.bind('address', ({ client }) => TE.of(client.address)),
            TE.bind('tokenWithDecimals', ({ client }) =>
                pipe(
                    client.getTokenF(params.type === ERC20TokenType.ERC20 ? { tokenAddress: params.tokenAddress } : {}),
                    TE.mapLeft(() => E.toError(messages.deposit.invalidERC20Token)),
                ),
            ),
            TE.bind('token', ({ client, tokenWithDecimals }) =>
                getTokenWithDetails(params, parseInt(tokenWithDecimals.decimals))(client),
            ),
            TE.bind('balance', ({ address }) => taskEitherWithError(() => provider.getBalance(address))),
            TE.chainFirst(({ token, address, balance }) => {
                switch (token.token.type) {
                    case ERC721TokenType.ERC721:
                        return pipe(
                            token.asset,
                            E.fromNullable(new Error(messages.loadAssetError)),
                            E.chain((asset) =>
                                A.sequence(E.either)([
                                    assertEither(asset.user !== address, messages.userNotAssetOwner),
                                    assertEither(
                                        asset.status !== ImmutableAssetStatus.eth,
                                        messages.deposit.assetAlreadyDeposited,
                                    ),
                                ]),
                            ),
                            TE.fromEither,
                        );
                    case ETHTokenType.ETH:
                        return pipe(
                            A.sequence(E.either)([
                                assertEither(token.quantity.gt(balance), messages.deposit.insufficientFunds),
                                assertEither(
                                    token.quantity.lt(utils.parseEther('0.000001')),
                                    messages.deposit.minNumEth,
                                ),
                            ]),
                            TE.fromEither,
                        );
                    case ERC20TokenType.ERC20:
                        /* TODO: Check 'token.quantity' against balance and show 'insufficientFunds'. */
                        /* TODO: Check 'token.quantity' against minimum value and show 'minNumERC20'. */
                        return TE.of([]);
                    case MintableERC20TokenType.MINTABLE_ERC20:
                        return TE.left(new Error('Not implemented'));
                    case MintableERC721TokenType.MINTABLE_ERC721:
                        return TE.left(new Error('Not implemented'));
                    default:
                        return assertNever(token.token);
                }
            }),
            TE.map(({ token, client }) => ({
                token,
                client,
            })),
        );

    const execute = ({ token, client }: DepositInput, notify: NotifyMethod) => {
        sendAnalytics(createButtonEvent(ButtonEventName.depositNotifiedConfirmPressed));
        setLoading(true);
        return pipe(
            deposit(client, provider, token, notify),
            TE.bimap(
                (e) => {
                    setLoading(false);
                    return new LinkError(
                        messages.deposit.failedAPIRequest.code,
                        messages.deposit.failedAPIRequest.message(e.message),
                    );
                },
                () => {
                    setLoading(false);
                    parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: {} }, '*');
                },
            ),
        );
    };

    const showDetailsStart = ({ token: details }: DepositInput) => {
        const tokenMetaData = getERC20TokenMetaData(details, tokens);

        return (
            <>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Please confirm the assets for deposit:
                </ParagraphText>
                <OuterSpace top={measurements.SpacingTeeShirtAmounts.large}>
                    <TokenDetailsDisplayRow
                        testId="fixedDepositTokenDetails"
                        tokenType={details.token.type}
                        asset={details?.asset}
                        tokenDisplayAmount={details?.amount}
                        transactionCostTokenImage={tokenMetaData?.image_url || DEFAULT_ETH_TOKEN_IMAGERY}
                        tokenMetadata={tokenMetaData}
                        hideTokenIconImage
                    />
                </OuterSpace>
            </>
        );
    };

    // The 'showDetailsExecuting' function gets executed repeatedly
    // because of how the '<Action />' component has been structured.
    // This flag helps prevent analytics from being sent more than once!
    const wasSignInRequested = useRef(false);
    const showDetailsExecuting = (_: DepositInput) => {
        if (!wasSignInRequested.current) {
            wasSignInRequested.current = true;
            // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
            sendAnalytics(createScreenEvent(ScreenEventName.depositSignRequestOpened));
        }

        return (
            <>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    A transaction for the deposit has been sent to your Ethereum wallet.
                </ParagraphText>

                <VerticalSpace top="large" bottom="large">
                    <ParagraphText fillColor={colors.light[300]} fontSize="small">
                        <strong style={{ color: colors.yellow[100] }}>Sign the transaction</strong> to complete the
                        deposit.
                    </ParagraphText>
                </VerticalSpace>

                <ParagraphText fillColor={colors.light[100]} fontSize="small" fontWeight="bold">
                    This may take some time to complete.
                </ParagraphText>
            </>
        );
    };

    const showDetailsInProgress = (_: DepositInput, tx = 'test') => {
        return (
            <>
                <IndeterminateHorizontalLines />
                <VerticalSpace bottom="small">
                    <SectionHeading>{messages.deposit.title.inProgress}</SectionHeading>
                </VerticalSpace>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Your deposit is confirming with the Ethereum blockchain.
                </ParagraphText>

                <VerticalSpace top="large">
                    <ParagraphText fillColor={colors.light[100]} fontWeight="bold" fontSize="small">
                        This may take some time.
                    </ParagraphText>
                </VerticalSpace>

                <VerticalSpace top="large">
                    <EtherscanLink ethNetwork={ethNetwork} txId={tx}>
                        View on Etherscan
                    </EtherscanLink>
                </VerticalSpace>
            </>
        );
    };

    const showDetailsDone = (_: DepositInput) => {
        return (
            <>
                <ExplodingCircleWithTick />
                <VerticalSpace bottom="small">
                    <SectionHeading>{messages.deposit.title.done}</SectionHeading>
                </VerticalSpace>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Your deposit is on its way into your{' '}
                    <strong style={{ color: colors.light[100] }}>Immutable X Inventory</strong>.
                </ParagraphText>

                <VerticalSpace top="large">
                    <ParagraphText fillColor={colors.light[300]} fontSize="small">
                        You can check the status of the transaction via your Ethereum wallet.
                    </ParagraphText>
                </VerticalSpace>
            </>
        );
    };

    const onFinish = useCallback(() => {
        sendAnalytics(createButtonEvent(ButtonEventName.depositConfirmedFinishPressed));
    }, []);

    const onDeny = useCallback(() => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, []);

    if (!hasTokensList) {
        return null;
    }

    return (
        <Action
            type="deposit"
            parent={parent}
            title={messages.deposit.title}
            paramsDecoder={LinkParamsCodecs.Deposit.decode}
            loadInput={loadInput}
            execute={execute}
            helperMessageType={HelperMessageType.IS_CONFIRM}
            showDetails={{
                start: showDetailsStart,
                executing: showDetailsExecuting,
                inProgress: showDetailsInProgress,
                done: showDetailsDone,
            }}
            hasTitleInDetails={{
                start: false,
                executing: false,
                inProgress: true,
                done: true,
            }}
            setErrorLog={setErrorLog}
            onFinishClick={onFinish}
            onDenyClick={onDeny}
        />
    );
};

export default FixedDeposit;
