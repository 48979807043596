import { Web3Provider } from '@ethersproject/providers';
import { EthAddressBrand, FeeType, LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import { BigNumber } from 'ethers';
import { Branded } from 'io-ts';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { LinkUiCoreContext } from '../../../context/App.context';
import { useGetAsset } from '../../../hooks/useGetAsset.hook';
import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { useTokensList } from '../../../hooks/useTokensList.hook';
import { LinkClientConfig, ParentWindow } from '../../../lib';
import { sendAnalytics, sendAnalyticsAsync } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../../lib/analytics/utils';
import { getMakeOfferTokens, makeOffer } from '../../../lib/offers/makeOffer';
import { getFeesFromSearchParams } from '../../../utils/getFeesFromSearchParams';
import { getLocationSearch } from '../../../utils/location';
import { LoadingUi } from '../../LoadingUi/LoadingUi.component';
import { OfferFlowComplete } from '../OfferFlowComplete.component';
import { MakeOfferAssetError, MakeOfferInvalidParametersError, MakeOfferTokensListError } from './MakeOfferError';
import { MakeOfferPrompt } from './MakeOfferPrompt.component';

export interface MakeOfferInputParams {
    tokenId: string;
    tokenAddress: string;
    amount: string;
    currencyAddress: string;
    expirationTimestamp?: string;
    fees?: FeeType[];
}
export enum MakeOfferStages {
    Loading = 'loading',
    Prompt = 'prompt',
    Complete = 'complete',
}

export interface MakeOfferProps {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
}

export const MakeOffer = ({ config, parent, provider }: MakeOfferProps) => {
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [offerStage, setOfferStage] = useState(MakeOfferStages.Loading);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const textMessages = useI18nMessaging({});

    const makeOfferParamsRaw = queryString.parse(getLocationSearch());

    // Validation
    if (!makeOfferParamsRaw.tokenId || !makeOfferParamsRaw.tokenAddress || !makeOfferParamsRaw.amount) {
        setErrorLog(MakeOfferInvalidParametersError, textMessages.offers.makeOffer.invalidParameters);
        return null;
    }
    const feesFromParams = getFeesFromSearchParams();

    const makeOfferParams = {
        tokenId: makeOfferParamsRaw.tokenId,
        tokenAddress: makeOfferParamsRaw.tokenAddress,
        amount: makeOfferParamsRaw.amount,
        currencyAddress: makeOfferParamsRaw.currencyAddress || '', // defaults to ETH
        expirationTimestamp: (makeOfferParamsRaw.expirationTimestamp as string) || undefined,
        fees: feesFromParams,
    } as MakeOfferInputParams;

    // Get all token currencies so we can get the details of the offer currency
    const { tokens: availableTokens, error: getTokensError } = useTokensList({ config });
    const hasTokensList = useMemo(() => availableTokens.length > 0, [availableTokens]);

    // Get the details of the NFT so we can show the image preview
    const { asset, error: getAssetError } = useGetAsset({
        id: makeOfferParams.tokenId,
        address: makeOfferParams.tokenAddress as Branded<string, EthAddressBrand>,
        config,
    });

    // calculate total fee percentage royalty, protocol and maker fees
    const assetFees = asset?.fees || [];
    const sumOfFeePercentages = [...feesFromParams, ...assetFees].reduce((sum, fee) => sum + fee.percentage, 0);

    useEffect(() => {
        if (getAssetError) {
            setErrorLog(MakeOfferAssetError, textMessages.offers.apiError);
        }
    }, [getAssetError]);

    useEffect(() => {
        if (getTokensError) {
            setErrorLog(MakeOfferTokensListError, textMessages.offers.apiError);
        }
    }, [getTokensError]);

    useEffect(() => {
        if (hasTokensList && asset) {
            sendAnalytics(
                createScreenEvent(ScreenEventName.makeOfferPromptScreenOpened),
                createFlowEvent(FlowEventName.makeOfferStarted),
            );
            setOfferStage(MakeOfferStages.Prompt);
        }
    }, [hasTokensList, asset]);

    // Get token sell details (currency) from list of tokens
    const offerCurrencyToken = useMemo(
        () => availableTokens.find((token) => token.token_address === makeOfferParams.currencyAddress),
        [availableTokens, makeOfferParams],
    );

    const makeOfferTokens = useMemo(
        () => getMakeOfferTokens(offerCurrencyToken, makeOfferParams),
        [offerCurrencyToken, makeOfferParams],
    );

    const makeOfferTransaction = useCallback(async () => {
        if (makeOfferTokens) {
            setTransactionLoading(true);
            sendAnalytics(createButtonEvent(ButtonEventName.makeOfferPromptConfirmBtnPressed));
            await makeOffer({
                config,
                makeOfferTokens,
                fees: makeOfferParams.fees || [],
                provider,
                parent,
                setErrorLog,
            });
            setOfferStage(MakeOfferStages.Complete);
            setTransactionLoading(false);
        }
    }, [config, makeOfferTokens, makeOfferParams.fees, provider, parent, setErrorLog, setTransactionLoading]);

    const onMakeOfferCompleteClick = useCallback(async () => {
        await sendAnalyticsAsync(createButtonEvent(ButtonEventName.makeOfferCompleteContinueBtnPressed));
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent]);

    switch (offerStage) {
        case MakeOfferStages.Complete:
            return (
                <OfferFlowComplete
                    testId="makeOfferComplete"
                    offerMessages={textMessages.offers.makeOffer}
                    onContinueClick={onMakeOfferCompleteClick}
                />
            );
        case MakeOfferStages.Prompt:
            return (
                <>
                    {transactionLoading && <LoadingUi showLoading testId="makeOfferLoadingUi" />}
                    <MakeOfferPrompt
                        testId="makeOfferPrompt"
                        parent={parent}
                        makeOfferTransaction={makeOfferTransaction}
                        tokenBuy={makeOfferTokens?.tokenBuy}
                        tokenSell={makeOfferTokens?.tokenSell}
                        amountSell={makeOfferTokens?.amountSell || BigNumber.from(0)}
                        totalFeePercentage={sumOfFeePercentages}
                        offerCurrencyToken={offerCurrencyToken}
                        asset={asset}
                        loading={transactionLoading}
                    />
                </>
            );
        case MakeOfferStages.Loading:
        default:
            return <LoadingUi showLoading testId="makeOfferLoadingUi" />;
    }
};
