// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kPU4nKv_Hu_s6PpcpgF1 {\n    height: calc(100vh - var(--appHeaderHeight));\n}\n\n.eqnw1_SquwgTIvKwbYkd {\n    width: 100%;\n    height: 100%;\n}\n\n.wkC6ebznzW0dHOWnRZwi {\n    flex-grow: 1;\n}\n\n.e5GFYElqzZuwRMmuWau2 {\n    color: #c1c1c1;\n    height: var(--appHeaderHeight);\n    padding-top: 4px;\n}\n\n.DZ52JEPT2V8E5L6nWE6_ {\n    margin-top: -6px;\n    height: calc(var(--appHeaderHeight) / 2);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Exchange/Onramp/index.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;AAChD;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,wCAAwC;AAC5C","sourcesContent":[".exchangeRoot {\n    height: calc(100vh - var(--appHeaderHeight));\n}\n\n.moonpayIframeFilter {\n    width: 100%;\n    height: 100%;\n}\n\n.moonpayIframeContainer {\n    flex-grow: 1;\n}\n\n.moonpayFooter {\n    color: #c1c1c1;\n    height: var(--appHeaderHeight);\n    padding-top: 4px;\n}\n\n.moonpayLogo {\n    margin-top: -6px;\n    height: calc(var(--appHeaderHeight) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exchangeRoot": "kPU4nKv_Hu_s6PpcpgF1",
	"moonpayIframeFilter": "eqnw1_SquwgTIvKwbYkd",
	"moonpayIframeContainer": "wkC6ebznzW0dHOWnRZwi",
	"moonpayFooter": "e5GFYElqzZuwRMmuWau2",
	"moonpayLogo": "DZ52JEPT2V8E5L6nWE6_"
};
export default ___CSS_LOADER_EXPORT___;
