import { LinkParams, PARSE_SETTINGS } from '@imtbl/imx-sdk';
import { parse } from 'qs';

import { search } from '../utils/location';

export const useGetTransferParamsFromSearchParams = (): LinkParams.TransferV2 => {
    // @NOTE: apparently, query-string and qs both seem to struggle to parse an array
    // at the top level. The resulting format is always and object with 0 and 1 keys.
    // :shrug: eg: { 0: {}, 1: {} }, instead of [{}, {}]
    // Using Object.values here is a workaround
    const transferParams = Object.values(parse(search, PARSE_SETTINGS)) as any;
    return transferParams;
};
