import {
    colors,
    FlexLayout,
    layoutHelpers,
    measurements,
    OuterSpace,
    ParagraphText,
    SimpleText,
} from '@imtbl/design-system';
import React from 'react';

type OptionProps = {
    details: { title: string; text: string; comment?: string };
    name: string;
    onClick: () => void;
};

export const Option = ({ details, name, onClick }: OptionProps) => (
    <FlexLayout
        flexDirection="row"
        width="100%"
        testId="optionContainer"
        marginBottom={measurements.SpacingTeeShirtAmounts.large}
    >
        <FlexLayout flexDirection="column" flexGrow={1} testId="leftColumn">
            <ParagraphText fillColor={colors.light[300]} fontWeight="bold" fontSize="small">
                {details.title}
            </ParagraphText>
            <OuterSpace top={measurements.SpacingTeeShirtAmounts['2x-small']}>
                <SimpleText fontSize="small" fillColor={colors.light[300]}>
                    {details.text}
                </SimpleText>
            </OuterSpace>

            <ParagraphText fontSize="small" fontStyle="italic" fillColor={colors.light[700]}>
                {details.comment}
            </ParagraphText>
        </FlexLayout>
        <FlexLayout
            flexDirection="column"
            alignItems="flex-end"
            width={layoutHelpers.gridUnits(10)}
            testId="rightColumn"
        >
            <input type="radio" id="claim-type" name={name} onClick={onClick} />{' '}
        </FlexLayout>
    </FlexLayout>
);
