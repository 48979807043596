import { css } from '@emotion/css';
import { BaseComponentPropTypes, FlexLayout, layoutHelpers, useResizeObserver } from '@imtbl/design-system';
import React, { ReactNode, useEffect, useRef } from 'react';

export interface StandardLinkRouteButtonsFooterPropTypes extends BaseComponentPropTypes {
    children: ReactNode;
    marginBottom?: string;
}

const BottomFixedButtonFooter = css`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    backdrop-filter: blur(10px);
`;
/**
 * StandardLinkRouteButtonsFooter is used on most Link routes in order to make the CTA buttons and links
 * fixed to the bottom. This is so all CTA buttons are visible on mobile devices without
 * the user needing to scroll
 */
export const StandardLinkRouteButtonsFooter = ({
    testId,
    marginBottom,
    children,
}: StandardLinkRouteButtonsFooterPropTypes) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { height } = useResizeObserver(containerRef);
    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--standardLinkRouteFooterHeight', `${height}px`);
    }, [height]);
    return (
        <FlexLayout
            domRef={containerRef}
            marginBottom={marginBottom || layoutHelpers.gridUnits(1)}
            paddingLeft={layoutHelpers.gridUnits(6)}
            paddingRight={layoutHelpers.gridUnits(6)}
            flexDirection="column"
            alignItems="stretch"
            className={BottomFixedButtonFooter}
            testId={testId}
        >
            {children}
        </FlexLayout>
    );
};
