import { BaseComponentPropTypes, colors, ParagraphText, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import React, { useMemo } from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { FullTransferInfoWithFinalStatus } from '../../types/SharedTransfer.types';
import { BatchNftTransferHeader } from './BatchNftTransferHeader.component';
import { BatchTransferScrollViewInvalidTransfers } from './BatchNftTransferScrollViewInvalidTransfers.component';
import styles from './index.module.css';

export interface BatchNftTransferCompletePropTypes extends BaseComponentPropTypes {
    transferDetails?: FullTransferInfoWithFinalStatus[];
    footer: React.ReactElement;
}

// @TODO: Alot of the below is a standard Link screen, so this should eventually be templatized in such a way
// that other components too, can use this exact same layout (albeit with their own actions etc)
export const BatchNftTransferCompleteScreen = ({
    transferDetails,
    testId,
    footer,
}: BatchNftTransferCompletePropTypes) => {
    const textMessages = useI18nMessaging({});

    const failedTransfers = useMemo(() => {
        return transferDetails?.filter(
            (failedTransfer) =>
                failedTransfer.validationResult.status === 'error' ||
                failedTransfer?.transferTransactionStatus?.status === 'error',
        );
    }, [transferDetails]);
    const showFailureMessagingUi = failedTransfers?.length;

    return (
        <div className={styles.screenContainer}>
            <BatchNftTransferHeader testId={`${testId}__batchNftTransferComplete__header`}>
                <VerticalSpace bottom="small">
                    <SectionHeading testId={`${testId}__batchNftTransferComplete__heading`}>
                        {showFailureMessagingUi
                            ? textMessages.batchTransfer.title.partialDone
                            : textMessages.batchTransfer.title.done}
                    </SectionHeading>
                </VerticalSpace>
                <ParagraphText
                    fillColor={colors.light[300]}
                    fontSize="small"
                    testId={`${testId}__batchNftTransferComplete__intro`}
                >
                    {showFailureMessagingUi
                        ? textMessages.batchTransfer.bodyText.partialDone
                        : textMessages.batchTransfer.bodyText.done}
                </ParagraphText>
            </BatchNftTransferHeader>

            {showFailureMessagingUi && (
                <BatchTransferScrollViewInvalidTransfers
                    testId={testId}
                    transfers={failedTransfers}
                    invalidTransfersText={textMessages.batchTransfer.someItemsInvalid}
                />
            )}
            {footer}
        </div>
    );
};
