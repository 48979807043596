import { GameWalletProviderOptions, MagicProviderOptions } from '@imtbl/imx-sdk';
import { IWalletConnectProviderOptions } from '@walletconnect/types';
import { EthNetworkConfiguration } from 'magic-sdk';

import { LinkConfig } from '../lib';

export interface ProvidersConfig {
    walletConnectProviderOption: IWalletConnectProviderOptions;
    magicProviderOptions: MagicProviderOptions;
    gameWalletProviderOptions: GameWalletProviderOptions[];
}

export const getProvidersConfig = (config?: LinkConfig): ProvidersConfig => {
    if (!config) {
        throw Error('Unable to retrieve providers configuration');
    }

    return {
        walletConnectProviderOption: {
            ...config.providerOptions.walletConnectProviderOption,
            qrcodeModalOptions: { mobileLinks: ['metamask'] },
        },
        magicProviderOptions: {
            publishableKey: config.providerOptions.magicProviderOptions.publishableKey,
            // https://immutable.atlassian.net/browse/IMX-6899 A custom node is needed to goerli as Magic has not yet support goerli, it should be simply 'network: config.ethNetwork' once Magic provide node infra for goerli
            network: (config.providerOptions.magicProviderOptions.network ||
                config.ethNetwork) as EthNetworkConfiguration,
        },
        gameWalletProviderOptions: config.providerOptions.gameWalletOptions.map((gameWallet) => {
            return {
                gameTitle: gameWallet.gameTitle,
                gameIconUrl: gameWallet.gameIconUrl,
                publishableKey: gameWallet.publishableKey,
                providerPreference: gameWallet.providerPreference,
                network: (config.providerOptions.magicProviderOptions.network ||
                    config.ethNetwork) as EthNetworkConfiguration,
            } as GameWalletProviderOptions;
        }),
    };
};
