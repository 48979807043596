// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\n    @TODO: This class is used across more than one component,\n    perhaps it can be refactored into one location, component.\n*/\n.DsGo0Kuf1nUsHrTvxmh7 {\n    border-bottom: 1px solid var(--colors_dark_500);\n    padding-bottom: 18px;\n    margin-bottom: 10px;\n}\n\n/* @TODO: remove this after alpha! */\n.qj6uY9UD2LgvdYeXpVoA {\n    height: 19px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Buy/index.module.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;IACI,+CAA+C;IAC/C,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA,oCAAoC;AACpC;IACI,YAAY;AAChB","sourcesContent":["/*\n    @TODO: This class is used across more than one component,\n    perhaps it can be refactored into one location, component.\n*/\n.historyItem {\n    border-bottom: 1px solid var(--colors_dark_500);\n    padding-bottom: 18px;\n    margin-bottom: 10px;\n}\n\n/* @TODO: remove this after alpha! */\n.commissionFee {\n    height: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"historyItem": "DsGo0Kuf1nUsHrTvxmh7",
	"commissionFee": "qj6uY9UD2LgvdYeXpVoA"
};
export default ___CSS_LOADER_EXPORT___;
