// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HNFbtCMWy1PxsiTudu_D {\n    position: relative;\n    width: 100%;\n    left: 0;\n    top: 0;\n    height: var(--appHeaderHeight);\n    z-index: var(--appHeaderZIndex);\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.nexUEvkQRw7iLIP0_SDf {\n    position: absolute;\n    bottom: -3px;\n    height: 3px;\n    left: 0;\n    width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppHeaderBar/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,OAAO;IACP,MAAM;IACN,8BAA8B;IAC9B,+BAA+B;IAC/B,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,OAAO;IACP,WAAW;AACf","sourcesContent":[".header {\n    position: relative;\n    width: 100%;\n    left: 0;\n    top: 0;\n    height: var(--appHeaderHeight);\n    z-index: var(--appHeaderZIndex);\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.header__line {\n    position: absolute;\n    bottom: -3px;\n    height: 3px;\n    left: 0;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "HNFbtCMWy1PxsiTudu_D",
	"header__line": "nexUEvkQRw7iLIP0_SDf"
};
export default ___CSS_LOADER_EXPORT___;
