// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uyD2eiOl7DYoTGV5wxfC {\n    text-align: center;\n    border: 1px solid var(--colors_dark_300);\n}\n\n.We1O15BCjPGMWIyr7Ex4 {\n    display: block;\n    max-width: 100%;\n    max-height: 100%;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.ZxGVv2nFizieEW3Hcc3D {\n    padding: 2px 1px;\n}\n\n.HgAnm10hhJ4EvW3p28kG {\n    padding: 4px 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/BalanceItem/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IAEI,gBAAgB;AACpB;;AAEA;IAEI,gBAAgB;AACpB","sourcesContent":[".thumbnail {\n    text-align: center;\n    border: 1px solid var(--colors_dark_300);\n}\n\n.thumbnailImage {\n    display: block;\n    max-width: 100%;\n    max-height: 100%;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.imxdIcon {\n    composes: thumbnailImage;\n    padding: 2px 1px;\n}\n\n.customIcon {\n    composes: thumbnailImage;\n    padding: 4px 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": "uyD2eiOl7DYoTGV5wxfC",
	"thumbnailImage": "We1O15BCjPGMWIyr7Ex4",
	"imxdIcon": "ZxGVv2nFizieEW3Hcc3D We1O15BCjPGMWIyr7Ex4",
	"customIcon": "HgAnm10hhJ4EvW3p28kG We1O15BCjPGMWIyr7Ex4"
};
export default ___CSS_LOADER_EXPORT___;
