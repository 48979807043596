import './index.global.css';
import './utils/init-new-relic';

import { TooltipProvider, TooltipSystem } from '@imtbl/design-system';
import { errorsToError, taskEitherWithError } from '@imtbl/imx-sdk';
import Axios from 'axios';
import * as E from 'fp-ts/Either';
import { constant, identity, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { LinkConfig, LinkConfigCodec } from './lib';
import { loadSegment } from './lib/analytics/segment';
import * as serviceWorker from './serviceWorker';

(async () => {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line global-require
        const { worker } = require('./tests/mocks/browser'); // eslint-disable-line @typescript-eslint/no-var-requires
        console.log('worker', worker);
        worker.start();
    }

    const config = await pipe(
        taskEitherWithError(() => Axios.get('/config.json')),
        TE.mapLeft(constant(new Error('Unable to decode config'))),
        TE.bindTo('configJson'),
        TE.bind('config', ({ configJson }) =>
            pipe(LinkConfigCodec.decode(configJson.data), E.mapLeft(errorsToError), TE.fromEither),
        ),
        // eslint-disable-next-line @typescript-eslint/no-shadow
        TE.chainFirst((config) => TE.of(config)),
        TE.map(identity),
        TE.fold(
            (error) => {
                console.error(error);
                return T.of({} as LinkConfig);
            },
            (res) => T.of(res.config),
        ),
    )();

    const LaunchDarklyProvider = await asyncWithLDProvider({
        clientSideID: config.ldClientId,
        user: {
            key: 'anonymous',
        },
    });

    loadSegment(config.segment.apiKey);

    ReactDOM.render(
        <StrictMode>
            <BrowserRouter basename="/">
                <LaunchDarklyProvider>
                    <TooltipProvider>
                        <App config={config} />
                        <TooltipSystem portalMountPoint={document.getElementById('tooltip') as HTMLElement} />
                    </TooltipProvider>
                </LaunchDarklyProvider>
            </BrowserRouter>
        </StrictMode>,
        document.getElementById('root'),
    );

    serviceWorker.unregister();
})();
