import { Box, FlexLayout, SimpleText } from '@imtbl/design-system';
import React from 'react';

import moonpayLogo from '../../../assets/images/exchanges/moonpay-logo.svg';
import styles from '../Onramp/index.module.css';

export function showMoonpayExchangeIframe(moonpayUrl: string | undefined) {
    // Moonpay does not allow us to customize their widget to dark mode just
    // yet, so we make do with a absurd sequence of CSS filters.
    // There are two goals to these filters: make the background as close to
    // #192235 as possible and keep colors as much as possible around the same
    // hue as they started out.
    // We then choose a highlight color such that after going through the filters
    // it will come out as close to #17B5CB as possible.
    //
    // This script can be used to figure out a good color match for a given filter pipeline:
    // https://gist.github.com/felipetavares/1afabc9d5b2e679310827694a13f777e
    return (
        <FlexLayout className={styles.exchangeRoot} justifyContent="center" alignItems="stretch" flexDirection="column">
            <Box className={styles.moonpayIframeContainer}>
                <Box className={styles.moonpayIframeFilter}>
                    <iframe
                        data-testid="providerIframe"
                        className={styles.moonpayIframeFilter}
                        title="Moonpay"
                        allow="accelerometer; autoplay; camera; gyroscope; payment"
                        frameBorder="0"
                        src={moonpayUrl}
                    >
                        <p>Your browser does not support iframes.</p>
                    </iframe>
                </Box>
            </Box>

            <FlexLayout
                className={styles.moonpayFooter}
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
            >
                <SimpleText>Powered by &nbsp;</SimpleText>
                <img className={styles.moonpayLogo} src={moonpayLogo} alt="Moonpay logo" />
            </FlexLayout>
        </FlexLayout>
    );
}
