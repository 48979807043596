/* eslint-disable react/prop-types */
import { cx } from '@emotion/css';
import { Box, colors } from '@imtbl/design-system';
import React, { FC, memo } from 'react';

import styles from './index.module.css';
import { FullWidthBorderLinePropTypes } from './types';

const FullWidthBorderLine: FC<FullWidthBorderLinePropTypes> = ({ className }) => {
    const defaultLineLayoutProps = {
        className: cx(styles.line, className),
        backgroundColor: colors.dark[300],
        width: '100%',
        height: '2px',
    };

    return <Box {...defaultLineLayoutProps} />;
};

export default memo(FullWidthBorderLine);
