import { ExternalProvider } from '@ethersproject/providers/lib/web3-provider';
import {
    colors,
    ExplodingCreditCard,
    FlexLayout,
    IndeterminateHorizontalLines,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    TextPrettyInput,
    VerticalSpace,
} from '@imtbl/design-system';
import {
    EthAddress,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LocalStorageKeys,
    messageTypes,
    ProviderPreference,
} from '@imtbl/imx-sdk';
import { EthNetworkConfiguration } from 'magic-sdk';
import React, { FormEvent, MouseEvent, useCallback, useEffect, useState } from 'react';

import magicLogo from '../../assets/images/providers/magic-logo-horizontal.svg';
import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { LinkConfig, ParentWindow } from '../../lib';
import { sendAnalytics, sendAnalyticsAsync } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../lib/analytics/types';
import {
    createFlowEvent,
    createScreenEvent,
    removeWalletIdFromLocalStorage,
    setWalletAddressInLocalStorage,
} from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import useStoreUserEmail from '../../lib/useStoreUserEmail';
import { getProvidersConfig } from '../../utils/getProvidersConfig';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { getGameIconByPreference } from '../ProviderSelection/utils';
import { TermsAndConditions } from '../Setup/TermsAndConditions';
import buildImxClient from '../SetupMagicWallet/buildImxClient';
import buildMagicClient from '../SetupMagicWallet/buildMagicClient';
import DifferentWalletButton from '../SetupMagicWallet/DifferentWalletButton';
import styles from '../SetupMagicWallet/index.module.css';

export type Steps = 'connectWallet' | 'waitingMagicConfirmation' | 'waitingImxConfirmation' | 'complete';

export interface SetupMagicWalletProps {
    parent: ParentWindow;
    config: LinkConfig;
    overrideInitialStep?: Steps;
    gameWalletProviderKey: string;
    nextRoute?: string;
}

const SetupGameWallet = ({
    parent,
    config,
    overrideInitialStep,
    gameWalletProviderKey,
    nextRoute,
}: SetupMagicWalletProps) => {
    const [email, setEmail] = useState('');
    // @NOTE: Allow tests to render any of these screens - without having to click through them
    const [step, setStep] = useState<Steps>(overrideInitialStep || 'connectWallet');
    const flags = useLaunchDarklyFlags();
    const providerConfig = getProvidersConfig(config);

    const gameWalletProviderOptions = providerConfig.gameWalletProviderOptions.find(
        (gameWalletOption) => gameWalletOption.providerPreference === gameWalletProviderKey,
    );

    const magic = buildMagicClient(
        gameWalletProviderOptions || { publishableKey: '', network: config.ethNetwork as EthNetworkConfiguration },
    );
    const gameIcon = gameWalletProviderOptions
        ? getGameIconByPreference(
              gameWalletProviderOptions.providerPreference as ProviderPreference,
              gameWalletProviderOptions.gameIconUrl,
          )
        : undefined;
    const { setUserEmail } = useStoreUserEmail();
    const enableAsyncSendAnalytics = flags[FEATURE_FLAG.ENABLE_ASYNC_SEND_ANALYTICS];

    useEffect(() => {
        removeWalletIdFromLocalStorage();

        sendAnalytics(createScreenEvent(ScreenEventName.linkProviderMagicOpened));
    }, []);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        sendAnalytics(createFlowEvent(FlowEventName.magicSignInStarted));

        try {
            setStep('waitingMagicConfirmation');

            await magic.auth.loginWithMagicLink({
                email,
                showUI: false,
            });

            setUserEmail(email);

            setStep('waitingImxConfirmation');

            const imxClient = await buildImxClient(magic.rpcProvider as ExternalProvider, config.client);

            const userMetaData = await magic.user.getMetadata();

            const publicAddress = userMetaData.publicAddress?.toLowerCase() as EthAddress;

            setWalletAddressInLocalStorage(publicAddress);

            const isRegistered = await imxClient.isRegistered({ user: publicAddress });

            if (isRegistered) {
                // messageTypes.info will not resolve the promise in the SDK hence we can proceed to the nextRoute without closing Link
                returnUserDetails(publicAddress, imxClient, nextRoute ? messageTypes.info : messageTypes.result);

                return;
            }

            await registerUser(publicAddress, imxClient);
        } catch (err) {
            console.error(err);

            sendAnalytics(createFlowEvent(FlowEventName.magicSignInFailure));

            removeWalletIdFromLocalStorage();

            setUserEmail('');
        }
    };

    const registerUser = async (publicAddress: EthAddress, imxClient: ImmutableXClient) => {
        await imxClient?.registerImx({
            etherKey: publicAddress,
            starkPublicKey: imxClient.starkPublicKey,
            email,
        });

        returnUserDetails(publicAddress, imxClient, nextRoute ? messageTypes.info : messageTypes.success);

        setStep('complete');
    };

    const returnUserDetails = async (publicAddress: EthAddress, imxClient: ImmutableXClient, message: any) => {
        if (enableAsyncSendAnalytics) {
            await sendAnalyticsAsync(createFlowEvent(FlowEventName.magicSignInSucceeded));
        } else {
            sendAnalytics(createFlowEvent(FlowEventName.magicSignInSucceeded));
        }

        parent.postMessage(
            {
                type: LINK_MESSAGE_TYPE,
                message,
                data: {
                    address: publicAddress,
                    starkPublicKey: imxClient?.starkPublicKey,
                    providerPreference: gameWalletProviderOptions?.providerPreference || ProviderPreference.NONE,
                    ethNetwork: config.ethNetwork,
                    email,
                },
            },
            '*',
        );

        // On success of connecting a new wallet set the new provider preference in local storage
        window.localStorage.setItem(
            LocalStorageKeys.PROVIDER_PREFERENCE,
            gameWalletProviderOptions?.providerPreference || ProviderPreference.NONE,
        );

        if (nextRoute) {
            window.location.replace(nextRoute);
        }
    };

    const validateEmail = (emailValue: string) => {
        return /^\S+@\S+$/.test(emailValue);
    };

    const closeWindow = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();
            parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
        },
        [parent],
    );

    return (
        <form onSubmit={handleSubmit}>
            <StandardLinkRouteContainer>
                {step === 'waitingMagicConfirmation' && (
                    <>
                        <SectionHeading>Check your email</SectionHeading>
                        <VerticalSpace top="medium">
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                We&apos;ve emailed{' '}
                                <SimpleText fontSize="small" fillColor={colors.light[100]} fontWeight="bold">
                                    {email}
                                </SimpleText>{' '}
                                with a link to confirm your account
                            </ParagraphText>
                            <br />
                            <ParagraphText fontSize="small" fillColor={colors.light[100]} fontWeight="bold">
                                Please do not close this screen.
                            </ParagraphText>
                        </VerticalSpace>
                    </>
                )}
                {step === 'waitingImxConfirmation' && (
                    <div>
                        <IndeterminateHorizontalLines />
                        <SectionHeading>Setup in progress...</SectionHeading>
                        <VerticalSpace top="medium" bottom="medium">
                            <ParagraphText
                                fillColor={colors.light[300]}
                                fontSize="small"
                                style={{ wordBreak: 'break-word' }}
                            >
                                Retrieving your account details, this may take some time.
                            </ParagraphText>
                        </VerticalSpace>
                        <ParagraphText
                            fillColor={colors.light[300]}
                            fontSize="small"
                            style={{ wordBreak: 'break-word' }}
                        >
                            <strong>Please, do not close this screen.</strong>
                        </ParagraphText>
                    </div>
                )}
                {step === 'complete' && (
                    <>
                        <div>
                            <ExplodingCreditCard />
                            <VerticalSpace bottom="large">
                                <SectionHeading>Success!</SectionHeading>
                            </VerticalSpace>
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                You can now buy, sell, and trade assets via Immutable X!
                            </ParagraphText>
                        </div>
                        <VerticalSpace bottom="large">
                            <StandardButton testId="wallet-setup-complete" buttonKind="primary" onClick={closeWindow}>
                                Close
                            </StandardButton>
                        </VerticalSpace>
                    </>
                )}
                {step === 'connectWallet' && (
                    <>
                        <div>
                            <VerticalSpace bottom="large" responsiveSpacing={[{ breakpoint: 'medium', top: 'large' }]}>
                                <SectionHeading>
                                    <FlexLayout flexDirection="row" justifyContent="flex-start" alignItems="center">
                                        {gameWalletProviderOptions && gameIcon && (
                                            <img
                                                alt={gameWalletProviderOptions.gameTitle}
                                                src={gameIcon}
                                                height="30px"
                                                width="30px"
                                                style={{ marginRight: '15px' }}
                                            />
                                        )}
                                        Connect your {gameWalletProviderOptions?.gameTitle || ''} wallet
                                    </FlexLayout>
                                </SectionHeading>
                            </VerticalSpace>
                            <VerticalSpace bottom="large">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    Create a wallet and authenticate using your email.
                                </ParagraphText>
                            </VerticalSpace>
                            <TextPrettyInput
                                labelText="Enter your email address"
                                onChange={(value) => setEmail(value)}
                                initialIcon="misc_email"
                            />
                            <VerticalSpace top="large">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    <span className={styles.poweredByMagic}>
                                        Powered by
                                        <a href="https://magic.link/" target="_blank" rel="noreferrer">
                                            <img src={magicLogo} alt="Magic logo" />
                                        </a>
                                    </span>
                                </ParagraphText>
                            </VerticalSpace>
                        </div>

                        <StandardLinkRouteButtonsFooter>
                            <TermsAndConditions showMagicLinks />
                            <VerticalSpace top="large" bottom="small">
                                <StandardButton buttonKind="ultimate-cta" disabled={!validateEmail(email)}>
                                    Connect
                                </StandardButton>
                            </VerticalSpace>
                            {!nextRoute && (
                                <DifferentWalletButton analyticsEvent={ButtonEventName.custodialMagicChangePressed} />
                            )}
                        </StandardLinkRouteButtonsFooter>
                    </>
                )}
            </StandardLinkRouteContainer>
        </form>
    );
};

export default SetupGameWallet;
