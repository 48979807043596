import {
    colors,
    ExplodingCircleWithTick,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

import { useI18nMessaging } from '../../../../hooks/useI18nMessaging.hook';
import { useScrollToTop } from '../../../../hooks/useScrollToTop.hook';
import { Messages } from '../../../../i18n';
import { StandardLinkRouteButtonsFooter } from '../../../common/StandardLinkUiContainers';

type ResultProps = {
    messages: Messages;
    onFinish: () => void;
};

export const ResultScreen = ({ messages, onFinish }: ResultProps) => {
    const textMessages = useI18nMessaging({});
    useScrollToTop();
    return (
        <>
            <ExplodingCircleWithTick />
            <VerticalSpace bottom="small">
                <SectionHeading>{messages.deposit.title.done}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your deposit is on its way into your{' '}
                <strong style={{ color: colors.light[100] }}>Immutable X Inventory</strong>.
            </ParagraphText>

            <VerticalSpace top="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    You can check the status of the transaction via your Ethereum wallet.
                </ParagraphText>
            </VerticalSpace>
            <StandardLinkRouteButtonsFooter>
                <VerticalSpace bottom="large">
                    <StandardButton testId="deposit__Container__nextCta" buttonKind="ultimate-cta" onClick={onFinish}>
                        {textMessages.standardUiButtons.continue}
                    </StandardButton>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
