import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import React, { createContext, useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LaunchDarklyContext = createContext<LDFlagSet>({});
LaunchDarklyContext.displayName = 'LaunchDarklyContext';

interface LaunchDarklyProviderPropTypes {
    children: JSX.Element | JSX.Element[];
    flags?: LDFlagSet;
}

export function LaunchDarklyProvider({ children, flags }: LaunchDarklyProviderPropTypes) {
    return <LaunchDarklyContext.Provider value={flags || {}}>{children}</LaunchDarklyContext.Provider>;
}

export function useLaunchDarklyFlags() {
    const context = useContext(LaunchDarklyContext);

    if (!Object.keys(context).length) {
        console.error('useLaunchDarklyFlags must be used within a LaunchDarklyProvider');
    }

    return context;
}
