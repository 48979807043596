import {
    colors,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

import { useScrollToTop } from '../../../../hooks/useScrollToTop.hook';
import { TokenDataType } from '../../../../hooks/useTokensList.hook';
import { sendAnalytics } from '../../../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../../../lib/analytics/types';
import { createButtonEvent } from '../../../../lib/analytics/utils';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../../../utils/constants';
import { CancelLink } from '../../../common';
import { StandardLinkRouteButtonsFooter } from '../../../common/StandardLinkUiContainers';
import { TokenDetailsDisplayRow } from '../../../TokenDetailsDisplayRow';
import { tokenToFlatToken } from '../utils';

type ConfirmScreenProps = {
    token: TokenDataType;
    amount: string;
    onConfirm: () => void;
    onCancel: () => void;
};

export const ConfirmScreen = ({ token, amount, onConfirm, onCancel }: ConfirmScreenProps) => {
    useScrollToTop();
    const flatToken = tokenToFlatToken(token);

    const handleOnCancel = () => {
        sendAnalytics(createButtonEvent(ButtonEventName.flexibleDepositCancelBtnPressed));
        onCancel();
    };

    return (
        <>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">Confirm deposit</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Confirm your deposit details below:
            </ParagraphText>
            <OuterSpace top={measurements.SpacingTeeShirtAmounts.large}>
                <TokenDetailsDisplayRow
                    testId="confirmDepositTokenDetails"
                    tokenType={flatToken.type}
                    tokenDisplayAmount={amount}
                    transactionCostTokenImage={token?.image_url || DEFAULT_ETH_TOKEN_IMAGERY}
                    tokenMetadata={token}
                />
            </OuterSpace>
            <StandardLinkRouteButtonsFooter>
                <StandardButton testId="deposit__Container__nextCta" buttonKind="ultimate-cta" onClick={onConfirm}>
                    CONFIRM
                </StandardButton>
                <CancelLink title="Cancel" onClick={handleOnCancel} />
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
