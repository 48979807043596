import { BaseComponentPropTypes, colors, ParagraphText, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import React from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { FullTransferInfoWithFinalStatus } from '../../types/SharedTransfer.types';
import { BatchNftTransferHeader } from './BatchNftTransferHeader.component';
import { BatchTransferScrollViewInvalidTransfers } from './BatchNftTransferScrollViewInvalidTransfers.component';
import styles from './index.module.css';
import { getInvalidTransfers } from './lib';

export interface BatchNftTransferInvalidPropTypes extends BaseComponentPropTypes {
    transferDetails: FullTransferInfoWithFinalStatus[];
    footer: React.ReactElement;
}

// @TODO: Alot of the below is a standard Link screen, so this should eventually be templatized in such a way
// that other components too, can use this exact same layout (albiet with their own actions etc)
export const BatchNftTransferInvalidScreen = ({
    transferDetails,
    testId,
    footer,
}: BatchNftTransferInvalidPropTypes) => {
    const textMessages = useI18nMessaging({});
    const invalidTransfers = getInvalidTransfers(transferDetails);

    return (
        <div className={styles.screenContainer}>
            <BatchNftTransferHeader testId={`${testId}__batchNftTransferInvalid__header`}>
                <VerticalSpace bottom="small">
                    <SectionHeading testId={`${testId}__batchNftTransferInvalid__heading`}>
                        {textMessages.batchTransfer.title.invalid}
                    </SectionHeading>
                </VerticalSpace>
                <ParagraphText
                    fillColor={colors.light[300]}
                    fontSize="small"
                    testId={`${testId}__batchNftTransferInvalid__intro`}
                >
                    {textMessages.batchTransfer.bodyText.invalid}
                </ParagraphText>
            </BatchNftTransferHeader>

            <BatchTransferScrollViewInvalidTransfers
                testId={testId}
                transfers={invalidTransfers}
                invalidTransfersText={textMessages.batchTransfer.itemsInvalid}
            />
            {footer}
        </div>
    );
};
