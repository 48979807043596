import { LDClient } from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { LinkConfig } from './types';

export function getFlags(config: LinkConfig): LDFlagSet {
    const client: LDClient | undefined = useLDClient();
    const ldFlags = client?.allFlags();
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
        return { ...ldFlags, ...config.flags };
    }

    return ldFlags || {};
}
