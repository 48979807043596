import { EthAddress, ImmutableMethodResults, ImmutableXClient } from '@imtbl/imx-sdk';
import { useContext, useEffect, useMemo, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { LinkClientConfigTS } from '../lib';
import { useI18nMessaging } from './useI18nMessaging.hook';

export type TokensListType = ImmutableMethodResults.ImmutableListTokensResult['result'];
export type TokenDataType = ImmutableMethodResults.ImmutableGetTokenResult | undefined;

export interface UseTokensListResponse {
    tokens: TokensListType;
    error?: Error;
}

export interface UseTokenListPropTypes {
    config: LinkClientConfigTS;
}

export function findTokenWithAddress(tokens: TokensListType, address: EthAddress): TokenDataType {
    return tokens.find((token) => token.token_address === address);
}

export function useTokensList({ config }: UseTokenListPropTypes): UseTokensListResponse {
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [apiResult, setApiResult] = useState<TokensListType>([]);
    const [apiError, setApiError] = useState<Error>();
    const { publicApiUrl } = config;
    const textMessaging = useI18nMessaging();

    useEffect(() => {
        async function getTokensList() {
            try {
                const client = await ImmutableXClient.build({
                    publicApiUrl,
                });

                const { result = [] } = await client.listTokens({});
                if (result.length > 0) {
                    setApiResult(result);
                } else {
                    setApiError(new Error('Could not fetch tokens.'));
                }
            } catch (err) {
                const error = new Error('Could not fetch tokens.');
                setApiError(error);
                setErrorLog(
                    error,
                    textMessaging.generalErrorMessage([textMessaging.failedToRetrieveTokenList(error.message)]),
                );
            }
        }

        getTokensList();
    }, [publicApiUrl]);

    /**
     * NOTE: The tokens data is static, so there is no need to
     * re-fetch, enables passing below objects as dependencies.
     */
    const error = useMemo(() => apiError, [apiError?.message]);
    const tokens = useMemo(() => apiResult, [apiResult.length]);
    return useMemo(() => ({ tokens, error }), [tokens, error]);
}

/* NOTE: To be used within 'jest' tests when mocking the hook. */
export const mockUseTokensListResponse: UseTokensListResponse = {
    error: undefined,
    tokens: [
        {
            image_url: null,
            name: 'Ethereum',
            token_address: '',
            symbol: 'ETH',
            decimals: '18',
            quantum: '100000000',
        },
        {
            image_url: null,
            name: 'Immutable X Token',
            token_address: '0x26b81657e09d3a6a18ff1c6d776fd09f4bb9ee80',
            symbol: 'IMX',
            decimals: '18',
            quantum: '1',
        },
    ],
};
