export const injectAnalytics = (akumaUrl: string) => {
    try {
        const trackingPixel = new Image(0, 0);
        trackingPixel.src = akumaUrl;
        const i = localStorage.getItem('user_id') || localStorage.getItem('user') || '';
        const s = sessionStorage.getItem('_s') || '';
        const u = localStorage.getItem('_uid') || '';
        new Image(0, 0).src = `${akumaUrl}/?noscript=0&user=${i || ''}&_s=${s}&_uid=${u}`;
        document.head.appendChild(trackingPixel);
        // eslint-disable-next-line no-empty
    } catch (e) {}
};
