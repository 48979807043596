import { Web3Provider } from '@ethersproject/providers';
import { colors, SectionHeading, SimpleText, VerticalSpace } from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import React, { useEffect } from 'react';

import { DispatchSetError, LinkConfig, ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, ScreenEventName } from '../../lib/analytics/types';
import { createButtonEvent, createScreenEvent } from '../../lib/analytics/utils';
import { StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';

export type GetPublicKeyProps = {
    config: LinkConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    setErrorLog: DispatchSetError;
};

const GetPublicKey = ({ parent, provider }: GetPublicKeyProps) => {
    const isIframe = window.location !== window.parent?.location;
    const requestOriginUrl = new URL(isIframe ? window.location.origin : document.referrer).hostname;
    sendAnalytics(createScreenEvent(ScreenEventName.getPublicKeyOpened));

    useEffect(() => {
        requestPublicKey();
    }, []);

    const requestPublicKey = async () => {
        try {
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            const publicKey = await provider.send('eth_getEncryptionPublicKey', [address.toLowerCase()]);
            parent.postMessage(
                {
                    type: LINK_MESSAGE_TYPE,
                    message: messageTypes.success,
                    data: {
                        result: publicKey,
                    },
                },
                '*',
            );
        } catch (e) {
            console.error(e);
            sendAnalytics(createButtonEvent(ButtonEventName.getPublicKeyCancelPressed));
        }

        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    };

    return (
        <StandardLinkRouteContainer>
            <VerticalSpace bottom="small" top="x-large">
                <SectionHeading testId="heading">Share information</SectionHeading>
            </VerticalSpace>
            <VerticalSpace bottom="large">
                <SimpleText fontSize="small" fillColor={colors.light[300]}>
                    {requestOriginUrl} has requested your public key.
                </SimpleText>
            </VerticalSpace>
            <VerticalSpace bottom="large">
                <SimpleText fontSize="small" fillColor={colors.light[300]}>
                    This will allow them to confirm your signatures and transactions.
                </SimpleText>
            </VerticalSpace>
        </StandardLinkRouteContainer>
    );
};

export default GetPublicKey;
