import { colors, measurements, OuterSpace, ParagraphText } from '@imtbl/design-system';
import React from 'react';

import { useI18nMessaging as i18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { ErrorMessageProps } from '../ErrorMessage';

export const COUNTRY_CODE_US = 'US';

// @TODO: these strings should not exist here. If they are needed,
// they belong inside of: src/i18n/messages.tsx
export const ERROR_MSG_DEFAULT = 'Something has gone wrong.';
export const ERROR_MSG_IP_ADDRESS = 'Could not get your IP address.';

export const URL_IP_SERVICE_CHECK = 'https://api.ipstack.com/check';
export const KEY_IP_SERVICE_ACCESS = '34917f98f86ae4006448a87191afa054';

const textMessages = i18nMessaging({});
export const GENERIC_CLAIM_ERROR_SCREEN: ErrorMessageProps = {
    heading: textMessages.claim.error.genericClaimErrorScreenTitle,
    body: (
        <>
            {textMessages.claim.error.genericClaimErrorScreenBodyText.map((paragraph) => (
                <OuterSpace bottom={measurements.SpacingTeeShirtAmounts.large}>
                    <ParagraphText fillColor={colors.light[300]} fontSize="small">
                        {paragraph}
                    </ParagraphText>
                </OuterSpace>
            ))}
        </>
    ),
};
