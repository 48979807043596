import { BaseComponentPropTypes, colors, ParagraphText, VerticalSpace } from '@imtbl/design-system';
import React from 'react';

import { FullTransferInfoWithFinalStatus } from '../../types/SharedTransfer.types';
import { TransferDetailsListing } from '../TransferV2/common.components';
import { BatchNftTransferScrollView } from './BatchNftTransferScrollView.component';

export interface BatchTransferScrollViewInvalidTransfersPropTypes extends BaseComponentPropTypes {
    transfers?: FullTransferInfoWithFinalStatus[];
    invalidTransfersText: string;
}

export const BatchTransferScrollViewInvalidTransfers = ({
    testId,
    transfers,
    invalidTransfersText,
}: BatchTransferScrollViewInvalidTransfersPropTypes) => {
    return (
        <BatchNftTransferScrollView>
            <VerticalSpace top="x-large">
                <ParagraphText
                    fontWeight="bold"
                    fillColor={colors.yellow[100]}
                    fontSize="small"
                    testId={`${testId}__batchNftTransferInvalid__invalidTransfersText`}
                >
                    {invalidTransfersText}
                </ParagraphText>
            </VerticalSpace>
            <TransferDetailsListing
                testId={`${testId}__batchNftTransferInvalid__invalidTransfers`}
                transfers={transfers}
                displayErrorInformation
            />
        </BatchNftTransferScrollView>
    );
};
