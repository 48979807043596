import { ImmutableXClient } from '@imtbl/imx-sdk';
import { useEffect, useMemo, useState } from 'react';

import { LinkClientConfigTS } from '../lib';
import { useGetBuyTokenMetadata } from './useGetBuyTokenMetadata.hook';
import { useGetOrderDetails } from './useGetOrderDetails.hook';
import { useGetOrderIdsFromSearchParams } from './useGetOrderIdsFromSearchParams.hook';
import { useGetOrderTokenDetails } from './useGetOrderTokenDetails.hook';
import { useGetUserTokenBalances } from './useGetUserTokenBalances.hook';
import { useValidateOrders } from './useValidateOrders.hook';

export const useGetFullOrderDetails = ({
    client,
    ethAddress,
    config,
}: {
    client?: ImmutableXClient;
    ethAddress?: string;
    config: LinkClientConfigTS;
}) => {
    const [loading, setLoading] = useState(true);
    const orderIds = useGetOrderIdsFromSearchParams();
    const orderDetailsOnly = useGetOrderDetails({ client, orderIds });
    const ordersDetailsWithTokenInfo = useGetOrderTokenDetails({ orderDetails: orderDetailsOnly, client });
    const buyTokens = useMemo(
        () => ordersDetailsWithTokenInfo?.map((order) => ({ ...order.tokenBuyDetails.token })),
        [ordersDetailsWithTokenInfo],
    );
    const buyTokenMetadata = useGetBuyTokenMetadata({ config, buyTokens });
    const userTokenBalances = useGetUserTokenBalances({
        tokens: buyTokens,
        client,
        ethAddress,
    });
    const ordersWithTokenBalancesAndMetadata = useMemo(
        () =>
            ordersDetailsWithTokenInfo?.map((order, index) => ({
                ...order,
                userBalance: userTokenBalances?.[index],
                tokenBuyMetadata: buyTokenMetadata?.[index],
            })),
        [ordersDetailsWithTokenInfo, userTokenBalances, buyTokenMetadata],
    );
    const validatedOrders = useValidateOrders({
        orderDetails: ordersWithTokenBalancesAndMetadata,
        ethAddress,
    });
    useEffect(() => {
        if (validatedOrders?.length) {
            setLoading(false);
        }
    }, [validatedOrders]);

    return { loading, orderDetails: validatedOrders };
};
