import { BaseComponentPropTypes, NumberPrettyInput } from '@imtbl/design-system';
import BigNumber from 'bignumber.js';
import React from 'react';

import { TokenDataType } from '../../hooks/useTokensList.hook';

interface EnterPriceProps extends Pick<BaseComponentPropTypes, 'testId'> {
    token: TokenDataType;
    minPrice: any;
    onEnter: (value: BigNumber) => void;
    isValid: boolean;
    price?: any;
    className?: string;
    disabled?: boolean;
    displayHelp?: boolean;
    errorMsg?: string;
}

export const PriceInput = ({
    token,
    minPrice,
    onEnter,
    isValid,
    price,
    className,
    disabled = false,
    displayHelp = true,
    errorMsg = 'Invalid maximum price',
}: EnterPriceProps) => {
    return (
        <NumberPrettyInput
            inputId="listForSalePrice"
            testId="listForSalePriceInput"
            initialIcon={token?.image_url || ''}
            validation={(value) => [
                { check: () => value >= minPrice },
                { check: () => value >= minPrice && isValid, errorMsg },
            ]}
            fieldIsRequired
            valueOveride={price}
            initialHelpText={displayHelp && `Minimum list price of ${minPrice}`}
            centeredAndLarge
            disabled={disabled}
            onChange={(value) => {
                onEnter(value);
            }}
            className={className}
        />
    );
};
