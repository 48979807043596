// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ab59O8bVYlxSeMQJGcOu {\n    left: 0;\n    position: absolute;\n    margin-top: calc(0px - var(--measurements_medium) - 2px);\n}\n", "",{"version":3,"sources":["webpack://./src/components/FullWidthBorderLine/index.module.css"],"names":[],"mappings":"AAAA;IACI,OAAO;IACP,kBAAkB;IAClB,wDAAwD;AAC5D","sourcesContent":[".line {\n    left: 0;\n    position: absolute;\n    margin-top: calc(0px - var(--measurements_medium) - 2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": "Ab59O8bVYlxSeMQJGcOu"
};
export default ___CSS_LOADER_EXPORT___;
