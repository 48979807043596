import {
    AmountWithIcon,
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    Icon,
    SimpleText,
    SimpleTextSizes,
    VerticalSpace,
} from '@imtbl/design-system';
import { ERC20TokenType, ETHTokenType } from '@imtbl/imx-sdk';
import React from 'react';

import styles from './index.module.css';

interface BalanceEthProps extends Pick<BaseComponentPropTypes, 'testId'> {
    type: string;
    amount: string;
    platform?: string;
    fillColor?: string;
    tokenImage?: string;
    fontSize?: SimpleTextSizes;
}

export const Balance = ({
    type,
    amount,
    platform,
    tokenImage = '',
    fillColor = colors.light[100],
    fontSize = 'small',
    testId = 'balance',
}: BalanceEthProps) => {
    const isTypeETH = type === ETHTokenType.ETH;
    const isTypeERC20 = type === ERC20TokenType.ERC20;

    return (
        <FlexLayout flexDirection="column" testId={testId}>
            <FlexLayout>
                <FlexLayout flexDirection="column" alignItems="center" justifyContent="center">
                    {isTypeETH && (
                        <Icon
                            iconSize={fontSize}
                            fillColor={fillColor}
                            ligature="currency_eth"
                            testId={`${testId}__ethIcon`}
                        />
                    )}
                </FlexLayout>
                {isTypeETH && (
                    <SimpleText
                        title={amount}
                        fontWeight="bold"
                        fontSize={fontSize}
                        fillColor={fillColor}
                        className={styles.truncate}
                        testId={`${testId}__amount`}
                    >
                        {amount}
                    </SimpleText>
                )}
                {isTypeERC20 && (
                    <FlexLayout flexDirection="column">
                        {!tokenImage ? (
                            <SimpleText
                                title={amount}
                                fontWeight="bold"
                                fontSize={fontSize}
                                fillColor={fillColor}
                                className={styles.truncate}
                                testId={`${testId}-erc20-price`}
                            >
                                {amount}
                            </SimpleText>
                        ) : (
                            <AmountWithIcon
                                amount={amount}
                                fillColor={colors.light[100]}
                                fontWeight="bold"
                                fontSize={fontSize}
                                testId={`${testId}-erc20-price`}
                                icon={tokenImage}
                            />
                        )}
                    </FlexLayout>
                )}
                {!isTypeETH && !isTypeERC20 && (
                    <SimpleText
                        title={type}
                        fontWeight="bold"
                        fontSize={fontSize}
                        fillColor={fillColor}
                        className={styles.truncate}
                        testId={`${testId}__type`}
                    >
                        {type}
                    </SimpleText>
                )}
            </FlexLayout>

            {platform && (
                <VerticalSpace top="2x-small">
                    <SimpleText fontSize="tag" fillColor={colors.light[300]} testId={`${testId}__platform`}>
                        {platform}
                    </SimpleText>
                </VerticalSpace>
            )}
        </FlexLayout>
    );
};
