import { colors, Icon } from '@imtbl/design-system';
import React from 'react';

const EthImage = () => (
    <Icon
        style={{
            lineHeight: '35px',
        }}
        ligature="currency_eth"
        fillColor={colors.light[100]}
        iconSize="x-large"
        textAlign="center"
    />
);

export default EthImage;
