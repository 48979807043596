import { ProviderPreference } from '@imtbl/imx-sdk';

import crossTheAgesLogo from '../assets/images/providers/cross-the-ages.svg';
import gamestopLogo from '../assets/images/providers/gamestop-logo-black.svg';
import kyoLogo from '../assets/images/providers/kyo.svg';
import magicLogo from '../assets/images/providers/magic-logo.svg';
import metamaskLogo from '../assets/images/providers/metamask-logo.svg';
import newGanymedeLogo from '../assets/images/providers/new-ganymede.svg';

const providers = {
    [ProviderPreference.GAMESTOP]: {
        name: 'Gamestop',
        iconUri: gamestopLogo,
        iconSize: 28,
    },
    [ProviderPreference.METAMASK]: {
        name: 'MetaMask',
        iconUri: metamaskLogo,
        iconSize: 28,
    },
    [ProviderPreference.MAGIC_LINK]: {
        name: 'Magic link',
        iconUri: magicLogo,
        iconSize: 28,
    },
    [ProviderPreference.CROSS_THE_AGES]: {
        name: 'Cross the Ages',
        iconUri: crossTheAgesLogo,
        iconSize: 38,
    },
    [ProviderPreference.KYO]: {
        name: 'KYO',
        iconUri: kyoLogo,
        iconSize: 38,
    },
    [ProviderPreference.NEW_GANYMEDE]: {
        name: 'New Ganymede',
        iconUri: newGanymedeLogo,
        iconSize: 38,
    },
};

export const getProviderData = (provider: ProviderPreference) => {
    if (provider === ProviderPreference.NONE) return ProviderPreference.NONE;
    return providers[provider] ? providers[provider] : ProviderPreference.NONE;
};
