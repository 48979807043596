import { css, cx } from '@emotion/css';
import { colors, FlexLayout, ParagraphText, SectionHeading, StandardButton, VerticalSpace } from '@imtbl/design-system';
import { ERC721TokenType } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import { parseUnits } from 'ethers/lib/utils';
import React, { useCallback, useState } from 'react';

import { Asset } from '../../../../hooks/useGetAsset.hook';
import { TokenDataType, TokensListType } from '../../../../hooks/useTokensList.hook';
import { CancelLink, CurrencySelectBox, PriceInput, StandardLinkRouteButtonsFooter } from '../../../common';
import { TokenDetailsDisplayRow } from '../../../TokenDetailsDisplayRow';
import { isAmountValid } from '../validation';

type ConfirmProps = {
    availableTokens: TokensListType;
    token: TokenDataType;
    sellerReceiveAmount: BigNumber | null;
    price: BigNumber | null;
    minPrice: number;
    asset: Asset;
    onConfirm: (amount: BigNumber | null, token: TokenDataType) => void;
    onCancel: () => void;
    testId?: string;
};

export const ListingAsset = ({
    availableTokens,
    token,
    sellerReceiveAmount,
    price,
    asset,
    onConfirm,
    onCancel,
    minPrice,
    testId = 'listingAsset',
}: ConfirmProps) => {
    const [selectedToken, setSelectedToken] = useState(token);
    const [amount, setAmount] = useState<BigNumber | null>(price);
    // NOTE: https://immutable.atlassian.net/browse/IMX-5053
    // totalTokenAmount represents the quantity (including fees) in the smallest unit of the token.
    const isPriceValid = useCallback(() => {
        let parsedAmount: any = 0.0;

        try {
            parsedAmount = amount ? parseUnits(amount.toString(), selectedToken?.decimals || 18) : '';
        } catch (error) {
            return false;
        }

        return isAmountValid(parsedAmount.toString(), selectedToken?.quantum);
    }, [amount, selectedToken]);

    return (
        <>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">List for sale</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                What price would you like to list this for?
            </ParagraphText>
            <VerticalSpace top="large">
                <FlexLayout justifyContent="flex-start" flexDirection="column" flexGrow={1}>
                    <TokenDetailsDisplayRow
                        testId="sellTokenDetails"
                        tokenType={ERC721TokenType.ERC721}
                        asset={asset}
                    />
                    <VerticalSpace top="large" bottom="medium">
                        <CurrencySelectBox
                            selectedToken={token?.token_address || undefined}
                            tokens={availableTokens}
                            onSelect={setSelectedToken}
                            className={cx(
                                css`
                                    margin-bottom: 16px;

                                    div[role='menu'] {
                                        overflow-y: auto;
                                        max-height: 220px;
                                    }
                                `,
                            )}
                            disabled={!!token?.token_address}
                        />
                        <PriceInput
                            token={selectedToken}
                            price={sellerReceiveAmount || price || undefined}
                            minPrice={minPrice}
                            onEnter={(value) => {
                                setAmount(value >= new BigNumber(minPrice) ? value : null);
                            }}
                            isValid={isPriceValid()}
                            disabled={!selectedToken || Boolean(price)}
                        />
                    </VerticalSpace>
                </FlexLayout>
            </VerticalSpace>

            <StandardLinkRouteButtonsFooter testId={`${testId}__buttonsFooter`}>
                <StandardButton
                    testId="listAsset__listContainer__finishCta"
                    buttonKind="ultimate-cta"
                    disabled={!selectedToken || !isPriceValid()}
                    onClick={async () => {
                        await onConfirm(amount, selectedToken);
                    }}
                >
                    CONFIRM
                </StandardButton>
                <CancelLink title="Cancel" testId={testId} onClick={onCancel} />
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
