import { ERC20TokenType, PositiveBigNumber } from '@imtbl/imx-sdk';

import { Messages } from '../../i18n';
import { ResultStatus } from '../../types/SharedOrder.types';
import { TransferTokenInfoMinusValidation } from '../../types/SharedTransfer.types';
import { checkSufficientTokenBalanceTS } from '..';
import { ERROR_VALIDATION_STATUS, SUCCESS_VALIDATION_STATUS } from './transfers.types';

export const validateEthOrErc20Transfer = (
    transfer: TransferTokenInfoMinusValidation,
    textMessages: Messages,
): ResultStatus => {
    const validationResult: ResultStatus = { status: SUCCESS_VALIDATION_STATUS };
    const {
        tokenDetails: { token, quantity },
        userBalance: { balance },
    } = transfer;
    if (!checkSufficientTokenBalanceTS(quantity as PositiveBigNumber, balance)) {
        validationResult.status = ERROR_VALIDATION_STATUS;
        validationResult.message =
            token.type === ERC20TokenType.ERC20
                ? textMessages.insufficientERC20Funds(token.data.symbol)
                : textMessages.insufficientEthFunds;
    }
    return validationResult;
};
