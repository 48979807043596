import { Web3Provider } from '@ethersproject/providers';
import { ImmutableXClient, MFAOption, VerifyOTPParams, VerifyOTPResponse } from '@imtbl/imx-sdk';

import { LinkClientConfigTS } from './types';

export async function verifyOTP(
    config: LinkClientConfigTS,
    provider: Web3Provider,
    { request_id, code }: Partial<VerifyOTPParams>,
): Promise<VerifyOTPResponse> {
    const signer = provider.getSigner();
    const client = await ImmutableXClient.build(removeVersionInPublicApiUrl(config));
    const address = await signer.getAddress();

    return client.verifyOTP({
        mfa_option: MFAOption.Email,
        ether_key: address,
        request_id,
        code,
    });
}

// NOTE: this method is modifying the public api url removing version
// use path /mfa/verification instead of /v1//mfa/verification
export function removeVersionInPublicApiUrl(config: LinkClientConfigTS) {
    return {
        ...config,
        publicApiUrl: config.publicApiUrl.replace(/\/v\d+$/, ''),
    };
}
