import { Web3Provider } from '@ethersproject/providers';
import { ImmutableXClient, valueOrThrowTE } from '@imtbl/imx-sdk';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import { CLAIM_CONFIG } from '../components/Claim/config';
import { ClaimResponse, ListClaimsResponse } from '../components/Claim/types';
import { LinkClientConfig } from '.';
import { sendAnalytics } from './analytics/send-analytics';
import { FlowEventName, ScreenEventName } from './analytics/types';
import { createFlowEvent, createScreenEvent } from './analytics/utils';

export async function claim(url: string, option: string, eth_signature: string) {
    return pipe(
        TE.fromIO<Error, void>(() => {
            createScreenEvent(ScreenEventName.claimNonUSConfirmedOpened);
            createFlowEvent(FlowEventName.claimNonUSStarted);
        }),
        TE.chainFirst(() =>
            TE.tryCatch<Error, ClaimResponse>(
                () => axios.post(url, { option, eth_signature }),
                (err) => new Error(String(err)),
            ),
        ),
        TE.chainFirst(() =>
            TE.fromIO(async () => {
                sendAnalytics(createFlowEvent(FlowEventName.claimNonUSSucceeded));
            }),
        ),
        TE.fold(
            () => T.of(false),
            () => T.of(true),
        ),
    );
}

export const signOption = async (config: LinkClientConfig, provider: Web3Provider, option: string) => {
    return valueOrThrowTE(
        pipe(
            TE.bindTo('client')(
                ImmutableXClient.buildF({
                    ...config,
                    signer: provider.getSigner(),
                }),
            ),
            TE.bind('ethSignature', ({ client }) => client.signMessage(option)),
            TE.map(({ ethSignature }) => ethSignature),
        ),
    );
};

export const getClaim = async (url: string): Promise<ListClaimsResponse> => {
    try {
        return (await axios.get(url)).data as ListClaimsResponse;
    } catch (err) {
        throw new Error(String(err));
    }
};

export const calculateNumbers = async (
    config: LinkClientConfig,
    userPoints: BigNumber,
    unquantizedImxAmount: BigNumber,
) => {
    const client = await ImmutableXClient.build({ ...config });
    const { result } = await client.listTokens({ symbols: ['IMX'] });
    const decimals = Number(result[0].decimals);

    /**
     * Retro campaign = earn IMX if you participate in our marketplace.
     * There was 100k imx allocated to this campaign.
     * 100k / amount of people who participated in our marketplace = IMX earned as part
     * of retro campaign = retro claim.
     *
     * Alpha campaign = earn IMX based on how many GU NFTs you purchased and the quality
     * and rarity of those NFTs. The rarer the card and the higher the quality,
     * the more points you earned.
     *
     * Points earned by user / total points = percentage of total points
     * Percentage * 40M imx = total imx earned by user for alpha campaign
     *
     * Engine db stores claims with retro type and alpha type.
     * Endpoint returns combination of both retro and alpha campaign rewards.
     */

    const totalPoints = new BigNumber(CLAIM_CONFIG.TOTAL_POINTS);
    const userPointsShare = userPoints.dividedBy(totalPoints);

    const userImxAmount = unquantizedImxAmount.dividedBy(10 ** decimals);

    const totalAlphaImxAmount = new BigNumber(CLAIM_CONFIG.ALPHA_CAMPAIGN_TOTAL_IMX);
    const userAlphaImxAmount = totalAlphaImxAmount.multipliedBy(userPointsShare);
    const userRetroImxAmount = userImxAmount.minus(userAlphaImxAmount);

    // human readable formats:
    const percent = userPointsShare.multipliedBy(100).toFixed(10).toString();
    const imxTotal = userImxAmount.toFormat();
    const usdcTotal = userImxAmount.dividedBy(10).toFixed(10).toString();
    const imxRetro = userRetroImxAmount.isGreaterThan(0) ? userRetroImxAmount.toFormat() : '0';

    return {
        percent,
        imxTotal,
        imxRetro,
        usdcTotal,
    };
};
