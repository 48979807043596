import { css, cx } from '@emotion/css';
import { BaseComponentPropTypes, Box, colors, FlexLayout, Icon, SimpleText, StyledLink } from '@imtbl/design-system';
import React from 'react';

import * as styles from './index.styles';

export interface GameWalletsOptionProps extends BaseComponentPropTypes {
    providerIcon?: string;
    text: string;
    onGameWalletsClick: () => void;
}

export const GameWalletsOption = ({ providerIcon, text, onGameWalletsClick }: GameWalletsOptionProps) => {
    return (
        <StyledLink
            key={text}
            className={cx(
                styles.ProviderButton,
                css`
                    justify-content: space-between;
                `,
            )}
            fontSize="small"
            fontWeight="bold"
            testId="provider--gameWallets"
            onClick={onGameWalletsClick}
        >
            <FlexLayout flexDirection="row" justifyContent="space-between">
                <Box className={styles.ProviderButton_logo_gameWallet_background}>
                    {providerIcon && (
                        <Icon ligature={providerIcon} iconSize="x-large" className={styles.ProviderButtonX_logo} />
                    )}
                </Box>
                <FlexLayout flexDirection="column" justifyContent="center">
                    {text}
                </FlexLayout>
            </FlexLayout>

            <Icon ligature="action_forward" iconSize="normal" />
        </StyledLink>
    );
};
