import {
    colors,
    IndeterminateHorizontalLines,
    ParagraphText,
    SectionHeading,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

import { useScrollToTop } from '../../../../hooks/useScrollToTop.hook';
import { Messages } from '../../../../i18n';
import { EthNetwork } from '../../../../lib';
import { EtherscanLink } from '../../../EtherscanLink';

type InProgressProps = {
    messages: Messages;
    transactionId: string;
    ethNetwork: EthNetwork;
};

export const InProgressScreen = ({ messages, transactionId, ethNetwork }: InProgressProps) => {
    useScrollToTop();
    return (
        <>
            <IndeterminateHorizontalLines />
            <VerticalSpace bottom="small">
                <SectionHeading>{messages.deposit.title.inProgress}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your deposit is confirming with the Ethereum blockchain.
            </ParagraphText>

            <VerticalSpace top="large">
                <ParagraphText fillColor={colors.light[100]} fontWeight="bold" fontSize="small">
                    This may take some time.
                </ParagraphText>
            </VerticalSpace>

            <VerticalSpace top="large">
                <EtherscanLink ethNetwork={ethNetwork} txId={transactionId}>
                    View on Etherscan
                </EtherscanLink>
            </VerticalSpace>
        </>
    );
};
