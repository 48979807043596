import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber';
import {
    ERC20TokenType,
    ERC721TokenType,
    ETHTokenType,
    ImmutableMethodResults,
    ImmutableXClient,
    LinkParamsF,
    PositiveBigNumber,
    valueOrThrow,
} from '@imtbl/imx-sdk';
import BigNumberJs from 'bignumber.js';

import { TokenDataType } from '../../../hooks/useTokensList.hook';
import { parseAmount } from '../../../lib';
import { getFeesFromSearchParams } from '../../../utils/getFeesFromSearchParams';

export const listAsset = (
    client: ImmutableXClient,
    address: string,
    amount: BigNumberJs,
    token: TokenDataType,
    params: LinkParamsF.Sell,
): Promise<ImmutableMethodResults.ImmutableCreateOrderResult> => {
    const tokenSell =
        token?.symbol && token.symbol !== ETHTokenType.ETH
            ? {
                  type: ERC20TokenType.ERC20,
                  data: {
                      decimals: parseInt(token.decimals) || 8,
                      tokenAddress: token.token_address,
                      symbol: token.symbol,
                  },
              }
            : {
                  type: ETHTokenType.ETH,
                  data: {
                      decimals: token?.decimals ? parseInt(token.decimals) : 18,
                  },
              };

    const sellInfo = {
        token: tokenSell,
        quantity: amount,
    };

    const assetInfo = {
        token: {
            type: ERC721TokenType.ERC721,
            data: {
                tokenId: params.tokenId,
                tokenAddress: params.tokenAddress,
            },
        },
        quantity: EthersBigNumber.from(1),
    };

    const fees = getFeesFromSearchParams();

    const finalAmount = parseAmount(amount.toFixed(tokenSell.data.decimals).toString(), tokenSell.data.decimals);

    const expirationTimestamp = params.expirationTimestamp ? parseInt(params.expirationTimestamp) : undefined;

    return client.createOrderV3({
        user: address,
        tokenSell: assetInfo.token,
        amountSell: valueOrThrow(PositiveBigNumber.decode(EthersBigNumber.from(1))),
        tokenBuy: sellInfo.token,
        amountBuy: valueOrThrow(PositiveBigNumber.decode(finalAmount)),
        fees,
        // Split fees = true: forces fee calculation to be split in the "v3" way.
        // https://immutable.atlassian.net/wiki/spaces/TRAD/pages/2101051579/DACI+-+GetSignableDetails+v3+public-api+changes
        splitFees: true,
        expiration_timestamp: expirationTimestamp,
    });
};
