import { colors, FlexLayout, SimpleText } from '@imtbl/design-system';
import { ERC20TokenType, ERC721TokenType, ETHTokenType, ImmutableMethodResults, Token } from '@imtbl/imx-sdk';
import React from 'react';

import { BalanceItem } from '../BalanceItem';
import styles from './index.module.css';

/**
 * NOTE: If custom 'imageUrl' and 'symbol' are not passed as props, then the component
 * falls back to 'details.asset?.image_url' and 'details.token.data.symbol' as default.
 */
export type TokenDetailsProps = {
    imageUrl?: string | null;
    className?: string;
    symbol?: string;
    details: {
        token: Token;
        amount?: string;
        asset?: ImmutableMethodResults.ImmutableAsset;
    };
};

export const TokenDetails = ({ className, imageUrl, symbol, details }: TokenDetailsProps) => {
    switch (details.token.type) {
        case ETHTokenType.ETH:
            return (
                /**
                 *  NOTE: Since the non-ETH tokens don't use <FlexLayout />,
                 * we're not passing down the 'className' property to them,
                 * but setting height manually via 'styles.token' instead!
                 */
                <FlexLayout className={styles.token}>
                    <BalanceItem
                        amount={details.amount}
                        type={details.token.type}
                        imageUrl={imageUrl || details.asset?.image_url}
                    />
                </FlexLayout>
            );

        case ERC721TokenType.ERC721:
            return (
                <SimpleText fillColor={colors.light[300]} fontSize="small" className={className}>
                    ERC721 Token {details.token.data.tokenId} ({details.token.data.tokenAddress})
                </SimpleText>
            );

        case ERC20TokenType.ERC20:
            return (
                <FlexLayout className={styles.token}>
                    <BalanceItem
                        amount={details.amount}
                        type={details.token.type}
                        symbol={symbol || details.token.data.symbol}
                        imageUrl={imageUrl || details.asset?.image_url}
                    />
                </FlexLayout>
            );
        default:
            return (
                <SimpleText fillColor={colors.light[300]} fontSize="small" className={className}>
                    Unknown token type
                </SimpleText>
            );
    }
};
