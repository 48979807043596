import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber';
import { Web3Provider } from '@ethersproject/providers';
import {
    EthAddress,
    ExchangeProvider,
    ExchangeType,
    ImmutableCreateExchangeV3Params,
    ImmutableExchangeTypeV3,
    ImmutableMethodParams,
    ImmutableXClient,
    NFTTransactionStatus,
    Token,
} from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import { Branded, IntBrand } from 'io-ts';

import { LinkClientConfig, LinkConfig } from './types';

export type ProviderExchangeStartupInfo = {
    exchangeId: number;
    providerIframeSrc: string;
};

export type CreateNFTPrimaryTransactionResponse = {
    transactionId: string;
    providerIframeSrc: string;
};

export type ConnectionError = null;
const exchangeStatuses = [
    'received',
    'waitingPayment',
    'pending',
    'waitingAuthorization',
    'transacting',
    'completed',
    'failed',
] as const;
export type ExchangeStatus = typeof exchangeStatuses[number];
export type ExchangeInfo = {
    status: ExchangeStatus | undefined;
    providerWalletAddress: string | undefined;
    amount: string | undefined;
    currency: string | undefined;
};

export interface BaseTransferInfo {
    contractAddress: string;
    offerId: string;
    userWalletAddress: string;
    provider: string;
}

export interface CreateSecondaryFlowTransaction {
    orderId: string;
    userWalletAddress: string;
    provider: string;
}

export interface TransferInfo extends BaseTransferInfo {
    transactionId: string;
    sellerWalletAddress: string;
    status: NFTTransactionStatus;
}

export interface SecondaryFlowStatusInfo {
    transactionId: string;
    status: NFTTransactionStatus;
}

export enum ProviderWidget {
    BUY,
    SELL,
}

async function buildClientAndGetAddress(
    config: LinkConfig,
    provider: Web3Provider,
): Promise<{
    client: ImmutableXClient;
    address: string;
}> {
    const signer = provider.getSigner();
    const client = await ImmutableXClient.build(config.client);
    const address = await signer.getAddress();

    const isWalletRegistered = await client.isRegistered({ user: address });
    if (!isWalletRegistered) {
        const clientWithStark = await ImmutableXClient.build({ ...config.client, signer });

        await clientWithStark.registerImx({
            etherKey: address,
            starkPublicKey: clientWithStark.starkPublicKey,
        });
    }

    return { client, address };
}

export async function createExchangeAndGetProviderUrl({
    config,
    provider,
    supportedCurrencies = [],
    providerWidget = ProviderWidget.BUY,
    providerName = 'moonpay',
    amount,
}: {
    config: LinkConfig;
    provider: Web3Provider;
    supportedCurrencies?: string[];
    providerWidget?: ProviderWidget;
    providerName?: string;
    amount?: string;
}): Promise<ProviderExchangeStartupInfo> {
    const { client, address } = await buildClientAndGetAddress(config, provider);
    let widgetParams: ImmutableCreateExchangeV3Params['widget'] = {
        theme: 'dark',
        amount,
    };

    if (supportedCurrencies && supportedCurrencies.length > 0) {
        widgetParams = {
            ...widgetParams,
            supported_currencies: supportedCurrencies,
        };
    }

    const { id, url } = await client.createExchangeV3({
        wallet_address: address,
        provider: providerName,
        type: providerWidget === ProviderWidget.SELL ? ExchangeType.OFFRAMP : ExchangeType.ONRAMP,
        widget: widgetParams,
    });

    return {
        exchangeId: id,
        providerIframeSrc: url,
    };
}

export async function createExchangeTransfer(
    config: LinkConfig,
    provider: Web3Provider,
    exchangeId: number,
    token: Token,
    quantity: EthersBigNumber,
    providerWalletAddress: string,
): Promise<Branded<number, IntBrand>> {
    const signer = provider.getSigner();
    const client = await ImmutableXClient.build({
        ...config.client,
        signer,
    });
    const address = await signer.getAddress();

    const transaction = {
        sender: address as EthAddress,
        token,
        quantity,
        receiver: providerWalletAddress as EthAddress,
    };

    if (ImmutableMethodParams.ImmutableTransferParamsCodec.is(transaction)) {
        const transfer = await client.createExchangeTransfer(exchangeId, transaction);

        return transfer.transfer_id;
    }

    throw new Error('Incorrect transaction format');
}

export async function createPrimaryTransaction(
    config: LinkConfig,
    web3provider: Web3Provider,
    { contractAddress, offerId, userWalletAddress, provider }: BaseTransferInfo,
): Promise<CreateNFTPrimaryTransactionResponse> {
    const { client } = await buildClientAndGetAddress(config, web3provider);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { transaction_id, url } = await client.nftCheckoutPrimary({
        contract_address: contractAddress,
        offer_id: offerId,
        user_wallet_address: userWalletAddress,
        provider,
    });

    return {
        transactionId: transaction_id,
        providerIframeSrc: url,
    };
}

export async function createSecondaryTransaction(
    config: LinkConfig,
    web3provider: Web3Provider,
    { orderId, userWalletAddress, provider }: CreateSecondaryFlowTransaction,
): Promise<CreateNFTPrimaryTransactionResponse> {
    const { client } = await buildClientAndGetAddress(config, web3provider);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { transaction_id, url } = await client.nftCheckoutSecondary({
        order_id: parseInt(orderId, 10),
        user_wallet_address: userWalletAddress,
        provider,
    });

    return {
        transactionId: transaction_id,
        providerIframeSrc: url,
    };
}

export const getExchangeStatus = async (config: LinkClientConfig, id: number): Promise<ExchangeStatus | null> => {
    const client = await ImmutableXClient.build({ publicApiUrl: config.publicApiUrl });
    return (await client.getExchange(id)).status as ExchangeStatus;
};

export const getExchangeInfo = async (config: LinkClientConfig, id: number): Promise<ExchangeInfo | null> => {
    const client = await ImmutableXClient.build({ publicApiUrl: config.publicApiUrl });
    const response = await client.getExchange(id);

    return {
        status: response.status as ExchangeStatus,
        providerWalletAddress: response?.data?.provider_wallet_address,
        amount: response?.data?.from_amount?.toString(),
        currency: response?.data?.from_currency,
    } as ExchangeInfo;
};

export const getPrimaryTransactionStatus = async (
    config: LinkClientConfig,
    transactionId: string,
): Promise<TransferInfo> => {
    const client = await ImmutableXClient.build({ publicApiUrl: config.publicApiUrl });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { transaction_id, contract_address, offer_id, user_wallet_address, seller_wallet_address, provider, status } =
        await client.getNFTPrimaryTransactionStatus(transactionId);
    return {
        transactionId: transaction_id,
        contractAddress: contract_address,
        offerId: offer_id,
        userWalletAddress: user_wallet_address,
        sellerWalletAddress: seller_wallet_address,
        provider,
        status: status as NFTTransactionStatus,
    };
};

export const getSecondaryTransactionStatus = async (
    config: LinkClientConfig,
    transactionId: string,
): Promise<SecondaryFlowStatusInfo> => {
    const client = await ImmutableXClient.build({ publicApiUrl: config.publicApiUrl });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { transaction_id, status } = await client.getNFTSecondaryTransactionStatus(transactionId);
    return {
        transactionId: transaction_id,
        status: status as NFTTransactionStatus,
    };
};

export type ExchangeCurrencyLimits = {
    min?: BigNumber;
    max?: BigNumber;
};

export async function getExchangeCurrencyLimits(
    provider: ExchangeProvider,
    kind: ImmutableExchangeTypeV3,
    config: LinkConfig,
): Promise<Record<string, ExchangeCurrencyLimits>> {
    const client = await ImmutableXClient.build({ publicApiUrl: config.client.publicApiUrl });
    const currencies = await client.getCurrenciesV3(kind, provider, true);

    const map: Record<string, ExchangeCurrencyLimits> = {};

    currencies.forEach((currency) => {
        const limit = currency.limits;

        map[currency.symbol.toLowerCase()] = {
            min: limit?.min_amount ? new BigNumber(limit.min_amount) : undefined,
            max: limit?.max_amount ? new BigNumber(limit.max_amount) : undefined,
        };
    });

    return map;
}

export enum AmountValidationError {
    Zero,
    UnsupportedCurrency,
    BelowQuantum,
    BelowProviderMinimum,
    AboveBalance,
    AboveProviderMaximum,
}

export type AmountValidationResult = {
    error: AmountValidationError;
    message: string;
};

export async function asyncValidateAmount(
    provider: ExchangeProvider,
    kind: ImmutableExchangeTypeV3,
    config: LinkConfig,
    amount: BigNumber,
    currency: string,
    balance: BigNumber,
): Promise<AmountValidationResult[]> {
    const limits = await getExchangeCurrencyLimits(provider, kind, config);

    return syncValidateAmount(limits, amount, currency, balance);
}

export function syncValidateAmount(
    limitMap: Record<string, ExchangeCurrencyLimits>,
    amount: BigNumber,
    currency: string,
    balance: BigNumber,
): AmountValidationResult[] {
    const limits = limitMap[currency.toLowerCase()];

    if (limits === undefined) {
        return [
            {
                error: AmountValidationError.UnsupportedCurrency,
                message: `${currency.toUpperCase} is not supported`,
            },
        ];
    }

    const fixedMin = new BigNumber('0.000001');
    const min = limits.min && limits.min.gte(fixedMin) ? limits.min : fixedMin;

    const validations = [
        {
            error: AmountValidationError.Zero,
            message: 'You must enter an amount',
            validation: () => amount.gt(0),
        },
        {
            error: AmountValidationError.AboveBalance,
            message: 'Insufficient funds',
            validation: () => amount.lte(balance),
        },
        {
            error: AmountValidationError.BelowProviderMinimum,
            message: `Must be over ${min.toString()} ${currency.toUpperCase()}`,
            validation: () => amount.gte(min),
        },
    ];

    if (limits.max) {
        validations.push({
            error: AmountValidationError.AboveProviderMaximum,
            message: `Must be below ${limits.max.toString()} ${currency.toUpperCase()}`,
            validation: () => (limits.max ? amount.lte(limits.max) : true),
        });
    }

    return validations
        .map(({ error, message, validation }) => (validation() ? undefined : { error, message }))
        .filter((result) => result !== undefined) as AmountValidationResult[];
}
