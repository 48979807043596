import { FlexLayout, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
import React from 'react';

import { LinkConfig } from '../../lib';
import { InternalRoutes } from '../../utils/internalRoutes';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import FooterLink from '../FooterLink';
import { GameWalletProviderSelectionOption } from './GameWalletProviderSelectionOption';
import { getGameIconByPreference } from './utils';

export interface GameWalletSelectionProps {
    config: LinkConfig;
    onBackButtonClick: () => void;
}

export const GameWalletSelection = ({ config, onBackButtonClick }: GameWalletSelectionProps) => {
    const {
        providerOptions: { gameWalletOptions },
    } = config;

    return (
        <StandardLinkRouteContainer>
            <VerticalSpace bottom="large">
                <SectionHeading>Select your game wallet</SectionHeading>
            </VerticalSpace>
            <FlexLayout flexDirection="column" testId="gameWalletsList">
                {gameWalletOptions.map((gameWallet) => {
                    const { providerPreference } = gameWallet;
                    return (
                        <GameWalletProviderSelectionOption
                            key={gameWallet.gameTitle}
                            providerPreference={providerPreference as ProviderPreference}
                            testId={`provider--${gameWallet.providerPreference}`}
                            selection={gameWallet.gameTitle}
                            providerLogo={getGameIconByPreference(
                                providerPreference as ProviderPreference,
                                gameWallet.gameIconUrl,
                            )}
                            text={gameWallet.gameTitle}
                            enableAsyncSendAnalytics
                        />
                    );
                })}
            </FlexLayout>
            <StandardLinkRouteButtonsFooter>
                <VerticalSpace top="large">
                    <FooterLink onClick={() => onBackButtonClick()} to={InternalRoutes.MISSING_WALLET}>
                        Use a different wallet
                    </FooterLink>
                </VerticalSpace>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
