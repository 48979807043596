import {
    colors,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import { LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import React from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName } from '../../lib/analytics/types';
import { createButtonEvent, createFlowEvent } from '../../lib/analytics/utils';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { CongratsProps } from './types';

export function Congrats({ parent }: CongratsProps) {
    const textMessages = useI18nMessaging({});

    const onFinish = () => {
        sendAnalytics(createButtonEvent(ButtonEventName.claimFinishPressed));
        sendAnalytics(createFlowEvent(FlowEventName.claimNonUSSucceeded));
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    };

    return (
        <StandardLinkRouteContainer>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">{textMessages.claim.congrats.title}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.claim.congrats.text}
            </ParagraphText>

            <StandardLinkRouteButtonsFooter>
                <OuterSpace bottom={measurements.SpacingTeeShirtAmounts['2x-large']}>
                    <StandardButton onClick={onFinish} buttonKind="ultimate-cta">
                        {textMessages.standardUiButtons.continue}
                    </StandardButton>
                </OuterSpace>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
}
