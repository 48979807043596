import {
    colors,
    FlexLayout,
    Icon,
    layoutHelpers,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    StyledLink,
    Tickbox,
    VerticalSpace,
} from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
import React, { useState } from 'react';

import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { BoxEventName, ButtonEventName } from '../../lib/analytics/types';
import { createBoxEvent } from '../../lib/analytics/utils';
import { StandardLinkRouteButtonsFooter } from '../common/StandardLinkUiContainers';
import DifferentWalletButton from '../SetupMagicWallet/DifferentWalletButton';
import styles from './index.module.css';

const HardwareWalletStep = () => {
    const [setupConfirmation, setSetupConfirmation] = useState(false);

    return (
        <FlexLayout
            paddingTop={layoutHelpers.gridUnits(1)}
            paddingLeft={layoutHelpers.gridUnits(6)}
            paddingRight={layoutHelpers.gridUnits(6)}
            flexGrow={1}
            flexDirection="column"
            justifyContent="space-between"
        >
            <FlexLayout
                justifyContent="space-around"
                flexDirection="column"
                paddingTop={layoutHelpers.gridUnits(5)}
                paddingBottom={layoutHelpers.gridUnits(5)}
            >
                <VerticalSpace bottom="large">
                    <SectionHeading>Hardware wallet</SectionHeading>
                </VerticalSpace>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Immutable X supports hardware wallets, when they are connected via{' '}
                    <strong className={styles.boldText}>MetaMask</strong>.
                </ParagraphText>
                <VerticalSpace top="large" bottom="large">
                    <StyledLink
                        href="https://metamask.zendesk.com/hc/en-us/articles/360020394612-How-to-connect-a-Trezor-or-Ledger-Hardware-Wallet"
                        target="_blank"
                        fontSize="small"
                        fontWeight="bold"
                        className={styles.learnMoreLink}
                    >
                        Learn more about how to set this up.
                    </StyledLink>
                </VerticalSpace>

                <VerticalSpace bottom="large">
                    <hr className={styles.separator} />
                </VerticalSpace>

                <ParagraphText
                    fillColor={colors.yellow[300]}
                    fontSize="small"
                    style={{
                        display: 'flex',
                        marginBottom: '20px',
                    }}
                >
                    <Icon
                        ligature="misc_alert"
                        fillColor={colors.yellow[300]}
                        iconSize="x-large"
                        style={{ marginRight: '6px' }}
                    />
                    <strong>GridPlus Lattice1 users please note:</strong>
                </ParagraphText>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    You <strong className={styles.boldText}>MUST</strong> be upgraded to{' '}
                    <strong className={styles.boldText}>firmware v0.12.0</strong> for your Immutable X Key to be
                    generated correctly.
                </ParagraphText>
                <VerticalSpace top="large">
                    <ParagraphText fillColor={colors.red[300]} fontSize="small">
                        <strong>If you are not, the key that is created may not be able to access your assets.</strong>
                    </ParagraphText>
                </VerticalSpace>
                <VerticalSpace top="large" bottom="large">
                    <StyledLink
                        href="https://docs.gridplus.io/lattice1/lattice1-guides/how-to-upgrade-the-firmware"
                        target="_blank"
                        fontSize="small"
                        fontWeight="bold"
                        className={styles.learnMoreLink}
                    >
                        Learn how about how to check your Lattice1 firmware version.
                    </StyledLink>
                </VerticalSpace>
            </FlexLayout>

            <StandardLinkRouteButtonsFooter>
                <Tickbox
                    testId="confirmationCheckbox"
                    labelText={
                        <VerticalSpace bottom="large">
                            <SimpleText fillColor={colors.white} fontSize="tag" fontWeight="bold">
                                My hardware wallet is setup correctly.
                            </SimpleText>
                        </VerticalSpace>
                    }
                    onChange={(value: boolean) => {
                        const eventProps = { value: value ? 'yes' : 'no' };
                        setSetupConfirmation(value);
                        sendAnalytics(createBoxEvent(BoxEventName.hardwareWalletSetupSelected, eventProps));
                    }}
                    tickedOveride={setupConfirmation}
                />

                <StandardButton
                    href={`/setup?providerPreference=${ProviderPreference.METAMASK}`}
                    testId="submit-hardware-wallet-confirmation"
                    buttonKind="ultimate-cta"
                    disabled={!setupConfirmation}
                >
                    Next
                </StandardButton>
                <DifferentWalletButton analyticsEvent={ButtonEventName.selfCustodialMetaMaskChangePressed} />
            </StandardLinkRouteButtonsFooter>
        </FlexLayout>
    );
};

export default HardwareWalletStep;
