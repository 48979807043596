import { BaseComponentPropTypes, ImxdTokenImage } from '@imtbl/design-system';
import { ERC20TokenType, ETHTokenType } from '@imtbl/imx-sdk';
import React from 'react';

import EthImage from './EthImage';
import styles from './index.module.css';

interface TokenIconProps extends BaseComponentPropTypes {
    imageUrl?: string | null;
    type: string;
}

const TokenIcon = ({ type, imageUrl }: TokenIconProps) => {
    const hasImageUrl = !!imageUrl && imageUrl.length > 0;

    switch (type) {
        case ETHTokenType.ETH:
            return <EthImage />;

        case ERC20TokenType.ERC20:
            return !hasImageUrl ? (
                <ImxdTokenImage className={styles.imxdIcon} />
            ) : (
                <img className={styles.customIcon} src={imageUrl as string} alt="Custom Token Icon" />
            );

        default:
            return !hasImageUrl ? (
                <img className={styles.thumbnailImage} src="images/missing.png" alt="No Asset thumbnail" />
            ) : (
                <img className={styles.thumbnailImage} src={imageUrl as string} alt="Custom Asset thumbnail" />
            );
    }
};

export default TokenIcon;
