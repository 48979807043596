import { formatUnits } from '@ethersproject/units';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';

import { LinkClientConfig } from '../lib';
import { useBuildClient } from './useBuildClient.hook';

export const useL2Balance = (
    config: LinkClientConfig,
    user: string | undefined,
    address: string | undefined,
    decimals: string | undefined,
): BigNumber | undefined => {
    const client = useBuildClient(config.publicApiUrl);
    const [balance, setBalance] = useState<BigNumber>();

    useEffect(() => {
        if (client !== undefined && user !== undefined && address !== undefined && decimals !== undefined) {
            client.getBalance({ user, tokenAddress: address || 'ETH' }).then((newBalance) => {
                setBalance(new BigNumber(formatUnits(newBalance.balance, decimals)));
            });
        }
    }, [client, user, address, decimals]);

    return balance;
};
