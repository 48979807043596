import { colors, StyledLink } from '@imtbl/design-system';
import React from 'react';

export const removeSupportMessage = (message: string): string =>
    message.replace(/\bfor further assistance\s+(.*)$/gi, '').trim();

export const SupportLink = () => (
    <>
        For further assistance, please visit our{' '}
        <StyledLink
            href="https://support.immutable.com"
            fillColor={colors.light[700]}
            testId="supportLink"
            fontWeight="bold"
            target="_blank"
            fontSize="small"
        >
            support page
        </StyledLink>
        .
    </>
);
