export type ExchangeCurrency = {
    symbol: string;
    currency_code: string;
    provider: string;
    limits?: {
        max_amount: number | null;
        min_amount: number | null;
    };
};

export enum ExchangeProvider {
    moonpay = 'moonpay',
}
