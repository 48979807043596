import { css } from '@emotion/css';
import {
    colors,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import { Routes } from '@imtbl/imx-sdk';
import React from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { RrHyperLink, StyledReactRouterLink } from '../DsConnectorButtons/index';
import { ClaimProps } from './types';

export function ClaimUSOverTime({ testId }: ClaimProps) {
    const textMessages = useI18nMessaging({});

    return (
        <StandardLinkRouteContainer testId={`${testId}__ClaimUSContainer`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">{textMessages.claim.US.overTime.title}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.claim.US.overTime.text.requesting}
                <SimpleText fontSize="small" fontWeight="bold">
                    {textMessages.claim.US.overTime.text.exchanged}
                </SimpleText>
            </ParagraphText>
            <OuterSpace
                top={measurements.SpacingTeeShirtAmounts.medium}
                bottom={measurements.SpacingTeeShirtAmounts.medium}
            >
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    {textMessages.claim.US.overTime.text.exposure}
                    <SimpleText fontSize="small" fontWeight="bold" fillColor={colors.yellow[100]}>
                        {textMessages.claim.US.overTime.text.glt}
                    </SimpleText>
                    {textMessages.claim.US.overTime.text.estimates}
                </ParagraphText>
            </OuterSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.claim.US.overTime.text.period}
                <SimpleText fontSize="small" fontWeight="bold">
                    {textMessages.claim.US.overTime.text.imxBalance}
                </SimpleText>
                {textMessages.claim.US.overTime.text.usdc}
            </ParagraphText>

            <StandardLinkRouteButtonsFooter>
                <StandardButton testId={`${testId}__beginCta`} buttonKind="ultimate-cta">
                    <RrHyperLink to={Routes.ClaimUSOverTimeDetails}>{textMessages.standardUiButtons.next}</RrHyperLink>
                </StandardButton>

                <StyledReactRouterLink
                    to={`/${Routes.Claim}`}
                    testId={`${testId}__denyCta`}
                    fontSize="small"
                    fontWeight="bold"
                    fillColor={colors.light[300]}
                    className={css`
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    {textMessages.standardUiButtons.back}
                </StyledReactRouterLink>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
}
