import { colors, ParagraphText, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import React from 'react';

export const LoadingMessage = () => (
    <>
        <VerticalSpace bottom="small" top="x-large">
            <SectionHeading testId="heading">Processing...</SectionHeading>
        </VerticalSpace>
        <ParagraphText fillColor={colors.light[300]} fontSize="small" testId="loading">
            Please wait
        </ParagraphText>
    </>
);
