import {
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    measurements,
    OuterSpace,
    ParagraphText,
} from '@imtbl/design-system';
import React, { useMemo } from 'react';

import { FullTransferInfoWithFinalStatus } from '../../types/SharedTransfer.types';
import { TransferDetailsListing } from './common.components';

export interface TransferCriticalFailureDetailsScreenPropTypes extends BaseComponentPropTypes {
    transferDetails?: FullTransferInfoWithFinalStatus[];
    firstParagraph: string;
}

export const TransferCriticalErrorDetailsScreen = ({
    transferDetails,
    firstParagraph,
    testId = 'criticalErrorDetailsScreen',
}: TransferCriticalFailureDetailsScreenPropTypes) => {
    const failedTransfers = useMemo(() => {
        return transferDetails?.filter(
            (failedTransfer) =>
                failedTransfer.validationResult.status === 'error' ||
                failedTransfer?.transferTransactionStatus?.status === 'error',
        );
    }, [transferDetails]);

    return (
        <FlexLayout testId={testId} flexDirection="column">
            <OuterSpace
                top={measurements.SpacingTeeShirtAmounts.large}
                bottom={measurements.SpacingTeeShirtAmounts.large}
            >
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    {firstParagraph}
                </ParagraphText>
            </OuterSpace>

            <TransferDetailsListing
                testId={`${testId}__unsuccessfulTransfers`}
                transfers={failedTransfers}
                displayErrorInformation
            />
        </FlexLayout>
    );
};
