import { css } from '@emotion/css';
import {
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    layoutHelpers,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    StandardButton,
    StyledLink,
} from '@imtbl/design-system';
import pluralize from 'pluralize';
import React from 'react';

import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { FullTransferTokenInfo } from '../../types/SharedTransfer.types';
import { AnimatedLoadingIcon } from '../AnimatedLoadingIcon';
import { TransferDetailsListing } from '../TransferV2/common.components';
import { BatchNftTransferHeader } from './BatchNftTransferHeader.component';
import { BatchNftTransferScrollView } from './BatchNftTransferScrollView.component';
import { BatchNftTransferWarning } from './BatchNftTransferWarning.component';
import styles from './index.module.css';

export interface BatchNftTransferConfirmScreenPropTypes extends BaseComponentPropTypes {
    handleConfirm: () => void;
    handleCancel: () => void;
    transferDetails: FullTransferTokenInfo[];
    numberOfBatches: number;
    totalAssets: number;
    batchIndex: number;
    isSubmitting: boolean;
}

// @TODO: Alot of the below is a standard Link screen, so this should eventually be templatized in such a way
// that other components too, can use this exact same layout (albeit with their own actions etc)
export const BatchNftTransferConfirmScreen = ({
    handleConfirm,
    handleCancel,
    testId,
    transferDetails,
    numberOfBatches,
    totalAssets,
    batchIndex,
    isSubmitting,
}: BatchNftTransferConfirmScreenPropTypes) => {
    const textMessages = useI18nMessaging({});

    const description = `Your transfer of ${totalAssets} ${pluralize(
        'asset',
        totalAssets,
    )} is split into ${numberOfBatches}  ${pluralize('batch', numberOfBatches)}. Confirm details of ${
        transferDetails.length
    } ${pluralize('asset', transferDetails.length)} below:`;

    return (
        <div className={styles.screenContainer}>
            <BatchNftTransferHeader testId={`${testId}__batchNftTransferConfirm__header`}>
                <SectionHeading testId="heading">{textMessages.batchTransfer.title.start}</SectionHeading>
                <OuterSpace top={measurements.SpacingTeeShirtAmounts.medium}>
                    <ParagraphText
                        fillColor={colors.light[300]}
                        fontSize="small"
                        testId={`${testId}__batchNftTransferConfirm_description`}
                    >
                        {description}
                    </ParagraphText>
                </OuterSpace>
            </BatchNftTransferHeader>

            <BatchNftTransferScrollView>
                <TransferDetailsListing
                    testId={`${testId}__batchNftTransferConfirm__transferDetails`}
                    transfers={transferDetails}
                />
            </BatchNftTransferScrollView>

            <FlexLayout
                marginTop="auto"
                marginBottom={layoutHelpers.gridUnits(1)}
                flexDirection="column"
                alignItems="stretch"
                testId={`${testId}__batchNftTransferConfirm__footerContainer`}
            >
                <BatchNftTransferWarning
                    testId={testId}
                    batchNumber={batchIndex + 1}
                    numberOfBatches={numberOfBatches}
                />
                <FlexLayout
                    marginTop="auto"
                    marginBottom={layoutHelpers.gridUnits(1)}
                    paddingRight={layoutHelpers.gridUnits(6)}
                    paddingLeft={layoutHelpers.gridUnits(6)}
                    paddingTop={layoutHelpers.gridUnits(4)}
                    flexDirection="column"
                    alignItems="stretch"
                    testId={`${testId}__batchNftTransferConfirm__buttonContainer`}
                >
                    <StandardButton testId={`${testId}__beginCta`} buttonKind="ultimate-cta" onClick={handleConfirm}>
                        {isSubmitting ? (
                            <>
                                <AnimatedLoadingIcon />
                            </>
                        ) : (
                            textMessages.standardUiButtons.confirm
                        )}
                    </StandardButton>
                    <StyledLink
                        testId={`${testId}__batchNftTransferConfirm__cancelButton`}
                        fontSize="small"
                        fontWeight="bold"
                        fillColor={colors.light[700]}
                        onClick={handleCancel}
                        className={css`
                            height: 52px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        {textMessages.standardUiButtons.cancel}
                    </StyledLink>
                </FlexLayout>
            </FlexLayout>
        </div>
    );
};
