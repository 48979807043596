import { ERC721TokenType } from '@imtbl/imx-sdk';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';

import { FullOrderInfo, PartialOrderInfo } from '../types/SharedOrder.types';
import { useI18nMessaging } from './useI18nMessaging.hook';

export function getOrdersTotalEthValue(ordersDetails?: PartialOrderInfo[]) {
    if (!ordersDetails) return BigNumber.from(0);
    const total = ordersDetails.reduce((accum, current) => {
        return current?.tokenBuyDetails && current?.tokenBuyDetails.token.type !== ERC721TokenType.ERC721
            ? accum.add(current?.tokenBuyDetails?.quantity)
            : accum;
    }, BigNumber.from(0));
    return total;
}

export const useValidateOrders = ({
    orderDetails,
    ethAddress,
}: {
    orderDetails?: PartialOrderInfo[];
    ethAddress?: string;
}) => {
    const [validatedOrders, setValidatedOrders] = useState<FullOrderInfo[]>();
    const textMessages = useI18nMessaging();

    useEffect(() => {
        if (!!orderDetails && ethAddress) {
            const updatedOrders = orderDetails?.map((order) => {
                const modifiedOrder = { ...order };
                if (order.details?.status !== 'active') {
                    modifiedOrder.validationResult = { status: 'error', message: textMessages.buy.orderNotAvailable };
                } else if (ethAddress && order.details?.user === ethAddress) {
                    modifiedOrder.validationResult = { status: 'error', message: textMessages.buy.ownOrder };
                } else if (
                    order.userBalance?.balance &&
                    getOrdersTotalEthValue(orderDetails).gt(order.userBalance?.balance)
                ) {
                    modifiedOrder.validationResult = { status: 'error', message: textMessages.insufficientFunds };
                } else {
                    modifiedOrder.validationResult = { status: 'success' };
                }
                return modifiedOrder;
            });
            setValidatedOrders(updatedOrders as FullOrderInfo[]);
        }
    }, [orderDetails, textMessages, ethAddress]);

    return validatedOrders;
};
