import { css } from '@emotion/css';
import {
    colors,
    FlexLayout,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    StyledLink,
    Tickbox,
} from '@imtbl/design-system';
import { Routes } from '@imtbl/imx-sdk';
import React, { useContext, useState } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { FlowEventName } from '../../lib/analytics/types';
import { claim, signOption } from '../../lib/claimImxTokens';
import { BalanceItem } from '../BalanceItem';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { StyledReactRouterLink } from '../DsConnectorButtons/index';
import { CLAIM_CONFIG } from './config';
import { Congrats } from './Congrats';
import { GENERIC_CLAIM_ERROR_SCREEN } from './constants';
import { useGetImxFinancialNumbers } from './hooks/useGetImxFinancialNumbers.hook';
import * as txt from './textProps';
import { ClaimProps } from './types';

const option = 'usdc';

export function ClaimUSInstant({ config, parent, provider, testId }: ClaimProps) {
    const textMessages = useI18nMessaging({});
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const { publicApiUrl } = config;
    const [ctaDisabled, setCtaDisabled] = useState(true);
    const [isClaimed, setIsClaimed] = useState(false);
    const imxFinancialDetails = useGetImxFinancialNumbers({ config, provider, parent });

    const execute = async () => {
        const { ethSignature } = await signOption(config, provider, option);
        const res = await (await claim(CLAIM_CONFIG.ENDPOINT(publicApiUrl), option, ethSignature))();
        setIsClaimed(res);
        // error handling:
        // we catching actual error in the claim() to avoid clash with nrWrapper, that will crash otherwise.
        res || setErrorLog(FlowEventName.claimUSInstantFailed, GENERIC_CLAIM_ERROR_SCREEN);
    };

    return isClaimed ? (
        <Congrats parent={parent} />
    ) : (
        <StandardLinkRouteContainer testId={`${testId}__ClaimUSInstantContainer`}>
            <OuterSpace bottom={measurements.SpacingTeeShirtAmounts.small}>
                <SectionHeading testId="heading">{textMessages.claim.US.instant.title}</SectionHeading>
            </OuterSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.claim.US.instant.text.your}
                <SimpleText fontSize="small" fontWeight="bold">
                    {imxFinancialDetails.points} {Number(imxFinancialDetails.points) > 1 ? 'points' : 'point'}
                </SimpleText>
                {textMessages.claim.US.instant.text.accounted}
                <SimpleText fontSize="small" fontWeight="bold">
                    {imxFinancialDetails.percent}%
                </SimpleText>
                {textMessages.claim.US.instant.text.allPoints}
                <SimpleText fontSize="small" fontWeight="bold">
                    {imxFinancialDetails.imxTotal} IMX!
                </SimpleText>{' '}
            </ParagraphText>
            <OuterSpace
                top={measurements.SpacingTeeShirtAmounts.medium}
                bottom={measurements.SpacingTeeShirtAmounts.medium}
            >
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    {textMessages.claim.US.instant.text.receive}
                    <SimpleText fontSize="small" fontWeight="bold">
                        {textMessages.claim.US.instant.text.rate}
                    </SimpleText>
                </ParagraphText>
            </OuterSpace>
            {imxFinancialDetails.imxRetro !== '0' && (
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    <SimpleText fontSize="small" fontWeight="bold" fontStyle="italic" fillColor={colors.yellow[100]}>
                        {textMessages.claim.US.instant.text.note}
                    </SimpleText>
                    {textMessages.claim.US.instant.text.retro}
                    <SimpleText fontSize="small" fontWeight="bold">
                        ({imxFinancialDetails.imxRetro} IMX)
                    </SimpleText>
                    {textMessages.claim.US.instant.text.included}
                </ParagraphText>
            )}

            <FlexLayout flexDirection="column" flexGrow={1}>
                <OuterSpace
                    top={measurements.SpacingTeeShirtAmounts['x-large']}
                    bottom={measurements.SpacingTeeShirtAmounts['x-large']}
                >
                    <FlexLayout justifyContent="space-between">
                        <BalanceItem
                            amount={imxFinancialDetails.usdcTotal}
                            platform="Immutable X token"
                            type="USDC"
                            imageUrl="/images/usdc-logo.png"
                        />
                        <ParagraphText {...txt.defaultTextProps} textAlign="right">
                            <SimpleText {...txt.priceProps}>+{imxFinancialDetails.usdcTotal}</SimpleText>
                        </ParagraphText>
                    </FlexLayout>
                </OuterSpace>

                <FlexLayout flexDirection="row" justifyContent="center">
                    <OuterSpace
                        top={measurements.SpacingTeeShirtAmounts['x-small']}
                        left={measurements.SpacingTeeShirtAmounts.small}
                    >
                        <ParagraphText
                            fillColor={colors.light[700]}
                            textAlign="center"
                            fontSize="tag"
                            fontStyle="italic"
                        >
                            IMX to USDC (10 IMX = 1 USDC)
                        </ParagraphText>
                    </OuterSpace>
                </FlexLayout>
                <OuterSpace bottom={measurements.SpacingTeeShirtAmounts.medium}>
                    {imxFinancialDetails.imxTotal !== '0' && (
                        <Tickbox
                            className={css`
                                margin-top: auto;
                            `}
                            labelText={
                                <SimpleText fillColor={colors.light[300]} fontSize="tag">
                                    {textMessages.claim.US.instant.text.agree}
                                    <StyledLink
                                        fillColor={colors.light[700]}
                                        fontWeight="bold"
                                        fontSize="tag"
                                        onClick={() => {
                                            window.open(CLAIM_CONFIG.TERMS, '_blank');
                                        }}
                                    >
                                        {textMessages.claim.US.instant.text.terms}
                                    </StyledLink>
                                </SimpleText>
                            }
                            onChange={() => setCtaDisabled(!ctaDisabled)}
                        />
                    )}
                </OuterSpace>
            </FlexLayout>

            <StandardLinkRouteButtonsFooter>
                <StandardButton
                    testId={`${testId}__beginCta`}
                    onClick={execute}
                    buttonKind="ultimate-cta"
                    disabled={ctaDisabled}
                >
                    {textMessages.standardUiButtons.confirm}
                </StandardButton>

                <StyledReactRouterLink
                    to={`/${Routes.Claim}`}
                    testId={`${testId}__backCta`}
                    fontSize="small"
                    fontWeight="bold"
                    fillColor={colors.light[300]}
                    className={css`
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    {textMessages.standardUiButtons.back}
                </StyledReactRouterLink>
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
}
