import { css, cx } from '@emotion/css';
import { BaseComponentPropTypes, StyledLink, StyledLinkPropTypes } from '@imtbl/design-system';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface RRHyperLinkPropTypes
    extends BaseComponentPropTypes,
        DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
        LinkProps {
    domRef?: any;
}

interface StyledReactRouterLinkPropTypes
    extends Omit<StyledLinkPropTypes, 'href' | 'target'>,
        Omit<RRHyperLinkPropTypes, 'children' | 'onClick'> {
    domRef?: any;
}

export const RrHyperLink = ({ testId, children, className, onClick, domRef, ...props }: RRHyperLinkPropTypes) => {
    return (
        <Link {...props} ref={domRef} data-testid={testId} className={className} onClick={onClick}>
            {children}
        </Link>
    );
};

export const StyledReactRouterLink = ({
    to,
    onClick,
    className,
    children,
    underline,
    ...props
}: StyledReactRouterLinkPropTypes) => {
    return (
        <StyledLink
            {...props}
            className={cx(
                css`
                    text-decoration: ${underline ? 'underline' : 'none'};
                `,
                className,
            )}
        >
            <RrHyperLink to={to} onClick={onClick}>
                {children}
            </RrHyperLink>
        </StyledLink>
    );
};
