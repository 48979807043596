import { ImxTransactionKinds, PriceEffect } from '@imtbl/design-system';
import {
    ERC20Token,
    ERC20TokenType,
    ERC721TokenType,
    EthAddress,
    ImmutableMethodResults,
    ImmutableOrderMakerTakerType,
    ImmutableOrderStatus,
    ImmutableTransactionStatus,
    ImmutableXClient,
} from '@imtbl/imx-sdk';
import { getUnixTime } from 'date-fns';
import { formatUnits } from 'ethers/lib/utils';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as ORD from 'fp-ts/Ord';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as t from 'io-ts';
import { useCallback, useEffect, useState } from 'react';

import { findTokenWithAddress, TokensListType, useTokensList } from '../../hooks/useTokensList.hook';
import { messagesIndex } from '../../i18n';
import { getAddress, immutableTokenToTokenWithDetails, TokenWithDetails } from '../../lib';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../utils/constants';
import { convertToEllipsizedText } from '../../utils/convertToEllipsizedText';
import { HistoryProps } from '.';

const messages = messagesIndex.en;
type Exchange = ImmutableMethodResults.ImmutableGetExchangeHistoryResult['result'][number];

export type HistoryItem = {
    transactionType: string;
    date: Date;
    txId: number | string;
    transactionLink?: string;
    amount?: string;
    primaryText: string;
    secondaryText?: string;
    assetImageUrl?: string;
    tokenImageUrl?: string;
    isCurrency: boolean;
    priceEffect: PriceEffect;
    tokenType: string;
    toAddress?: string;
    fromAddress?: string;
};

export type PrimaryTransactionHistoryItem = {
    contract_address: string;
    offer_id: string;
    user_wallet_address: string;
    seller_wallet_address: string;
    provider: string;
    transaction_id: string;
    status: string;
    from_amount?: number;
    from_currency?: string;
    fees_amount?: number;
    created_at?: string;
};

export type SecondaryTransactionHistoryItem = {
    order_id: string;
    user_wallet_address: string;
    provider: string;
    transaction_id: string;
    status: string;
    from_amount?: number;
    from_currency?: string;
    fees_amount?: number;
    created_at?: string;
};

export type HistoryData = {
    items: HistoryItem[];
    address: EthAddress;
};

function pickPriceEffect(transactionType: ImxTransactionKinds) {
    return /Deposit|Sold|Exchange|Transfer\sin/.test(transactionType) ? 'positive' : 'negative';
}

function getToAndFromAddresses(result: any) {
    return {
        fromAddress: result.sender || result.user,
        toAddress: result.receiver || result.user,
    };
}

function chooseExchangeTokenImage(
    tokens: TokensListType,
    moonpayToCurrency: string | undefined,
): string | null | undefined {
    if (moonpayToCurrency === undefined) return null;

    const matchedToken = tokens?.find((token) => {
        return `${token.symbol}_IMMUTABLE` === moonpayToCurrency.toUpperCase();
    });

    return matchedToken?.image_url;
}

const exchangeItem = (
    tokens: TokensListType,
    exchange: Exchange,
    primaryText: string,
    secondaryText: string,
    priceEffect: PriceEffect,
    currency: string | undefined,
): HistoryItem => ({
    tokenType: 'EXCHANGE',
    transactionType: 'Exchange',
    date: new Date(exchange.created_at),
    txId: exchange.id,
    transactionLink: `https://immutascan.io/tx/${exchange.id}`,
    primaryText,
    secondaryText,
    isCurrency: true,
    priceEffect,
    tokenImageUrl: chooseExchangeTokenImage(tokens, currency) || '',
    assetImageUrl: chooseExchangeTokenImage(tokens, currency) || '',
});

const onrampItem = (tokens: TokensListType, exchange: Exchange): HistoryItem => {
    const toCurrency = exchange.data?.to_currency.split('_')[0].toUpperCase();
    const finalPrice = (exchange.data?.from_amount || 0) + (exchange.data?.fees_amount || 0);

    return exchangeItem(
        tokens,
        exchange,
        `Credit card payment - ${exchange.status}`,
        `Billed amount: ${exchange.data?.from_currency.toUpperCase()} $${finalPrice.toFixed(2)} incl. $${
            exchange.data?.fees_amount
        } fees for ${toCurrency} ${exchange.data?.to_amount}`,
        'positive',
        exchange.data?.to_currency,
    );
};

const offrampItem = (tokens: TokensListType, exchange: Exchange): HistoryItem => {
    const statusMap: Record<string, string> = {
        waitingPayment: 'sent for processing',
        pending: 'received by MoonPay',
    };

    const fromCurrency = exchange.data?.from_currency.split('_')[0].toUpperCase();
    const finalPrice = (exchange.data?.to_amount || 0) + (exchange.data?.fees_amount || 0);

    return exchangeItem(
        tokens,
        exchange,
        `Withdraw ${statusMap[exchange.status] || exchange.status}`,
        `Withdraw amount: ${exchange.data?.to_currency.toUpperCase()} $${finalPrice.toFixed(2)} incl. $${
            exchange.data?.fees_amount
        } fees for ${fromCurrency} ${exchange.data?.from_amount}`,
        'negative',
        exchange.data?.from_currency,
    );
};

const primaryTransactionToHistoryItem = ({
    transaction_id,
    created_at,
    status,
    from_currency,
    from_amount,
    fees_amount,
    offer_id,
    provider,
}: PrimaryTransactionHistoryItem): HistoryItem => {
    const finalPrice = (from_amount || 0) + (fees_amount || 0);
    const ellipsizedTransactionId = convertToEllipsizedText(transaction_id);
    return {
        tokenType: 'EXCHANGE',
        transactionType: `Mint by ${provider.toUpperCase()}`,
        date: created_at && new Date(created_at),
        txId: ellipsizedTransactionId,
        transactionLink: `https://immutascan.io/tx/${transaction_id}`,
        primaryText: `Credit card payment - ${status}`,
        secondaryText: `Billed amount: ${from_currency?.toUpperCase()} $${finalPrice.toFixed(
            2,
        )} incl. $${fees_amount} fees for NFT ${offer_id}`,
        isCurrency: false,
        priceEffect: 'positive',
        tokenImageUrl: undefined,
        assetImageUrl: undefined,
    } as HistoryItem;
};

const secondaryTransactionToHistoryItem = ({
    transaction_id,
    created_at,
    status,
    from_currency,
    from_amount,
    fees_amount,
    order_id,
}: SecondaryTransactionHistoryItem): HistoryItem => {
    const finalPrice = (from_amount || 0) + (fees_amount || 0);
    const ellipsizedTransactionId = convertToEllipsizedText(transaction_id);
    return {
        tokenType: 'EXCHANGE',
        transactionType: 'Order',
        date: created_at && new Date(created_at),
        txId: ellipsizedTransactionId,
        transactionLink: `https://immutascan.io/tx/${transaction_id}`,
        primaryText: `Credit card payment - ${status}`,
        secondaryText: `Billed amount: ${from_currency?.toUpperCase()} $${finalPrice.toFixed(
            2,
        )} incl. $${fees_amount} fees for Order ${order_id}`,
        isCurrency: false,
        priceEffect: 'positive',
        tokenImageUrl: undefined,
        assetImageUrl: undefined,
    } as HistoryItem;
};

export const useHistory = ({ config, provider, setErrorLog, setLoading, flags }: HistoryProps) => {
    const { tokens, error } = useTokensList({ config });
    const [data, setData] = useState<HistoryData>();
    const hasTokensList = tokens.length > 0;
    const enableNFTDirect = flags?.[FEATURE_FLAG.ENABLE_NFT_DIRECT_UI];
    const enableNFTSecondary = flags?.[FEATURE_FLAG.ENABLE_NFT_SECONDARY_UI];

    const getTokenInfo = (address: EthAddress) => {
        return findTokenWithAddress(tokens, address);
    };

    const getTokenInfoCallback = useCallback(getTokenInfo, [tokens.length]);

    useEffect(() => {
        if (!data) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [data, setLoading]);

    useEffect(() => {
        if (error) {
            setErrorLog(error);
            setLoading(false);
        }
    }, [error, setErrorLog, setLoading]);

    useEffect(() => {
        if (!hasTokensList) {
            return;
        }

        pipe(
            TE.fromIO<Error, void>(() => {
                setLoading(true);
            }),
            TE.bind('address', () => {
                return getAddress(provider);
            }),
            TE.bind('client', () =>
                ImmutableXClient.buildF({
                    publicApiUrl: config.publicApiUrl,
                }),
            ),
            TE.bind('items', ({ client, address }) => {
                type TransactionResult =
                    | ImmutableMethodResults.ImmutableDeposit
                    | ImmutableMethodResults.ImmutableWithdrawal
                    | ImmutableMethodResults.ImmutableTransfer;

                const resultToItem = (type: ImxTransactionKinds) => (result: TransactionResult) =>
                    pipe(
                        immutableTokenToTokenWithDetails(client)(result.token),
                        TE.map((details: TokenWithDetails): HistoryItem => {
                            return pipe(
                                details.token.type,
                                O.fromPredicate((tokenType) => tokenType !== ERC721TokenType.ERC721),
                                O.fold(
                                    // @NOTE: ERC721 ITEMS here:
                                    (): HistoryItem => {
                                        return {
                                            transactionType: type,
                                            tokenType: details.token.type,
                                            date: result.timestamp,
                                            txId: result.transaction_id,
                                            transactionLink: `https://immutascan.io/tx/${result.transaction_id}`,
                                            primaryText: details.asset?.name || '',
                                            secondaryText: details.asset?.collection.name,
                                            assetImageUrl: details.asset?.image_url || undefined,
                                            isCurrency: false,
                                            priceEffect: pickPriceEffect(type),
                                            ...getToAndFromAddresses(result),
                                        };
                                    },
                                    (tokenType): HistoryItem => {
                                        // @NOTE: ERC20 and ETH ITEMS here:
                                        let tokenImageUrl: string | undefined;
                                        let primaryText = '';
                                        let secondaryText = '';
                                        if (tokenType === ERC20TokenType.ERC20) {
                                            const { tokenAddress } = (details.token as ERC20Token).data;
                                            const tokenInfo = getTokenInfoCallback(tokenAddress);
                                            tokenImageUrl = tokenInfo?.image_url || undefined;
                                            primaryText = `${tokenInfo?.symbol} Token`;
                                            secondaryText = tokenInfo?.name || '';
                                        } else {
                                            tokenImageUrl = DEFAULT_ETH_TOKEN_IMAGERY;
                                            primaryText = 'ETH Token';
                                            secondaryText = 'Ethereum';
                                        }

                                        return {
                                            transactionType: type,
                                            tokenType,
                                            date: result.timestamp,
                                            txId: result.transaction_id,
                                            transactionLink: `https://immutascan.io/tx/${result.transaction_id}`,
                                            primaryText,
                                            secondaryText,
                                            amount: details.amount,
                                            tokenImageUrl,
                                            assetImageUrl: tokenImageUrl,
                                            isCurrency: true,
                                            priceEffect: pickPriceEffect(type),
                                            ...getToAndFromAddresses(result),
                                        };
                                    },
                                ),
                            );
                        }),
                    );

                const depositResult = pipe(
                    client.getPaginatedResults(
                        {
                            user: address,
                            status: ImmutableTransactionStatus.success,
                        },
                        client.getDepositsF,
                    ),
                    TE.chain(A.traverse(TE.taskEither)(resultToItem('Deposit'))),
                );

                const withdrawalResult = pipe(
                    client.getPaginatedResults(
                        { user: address, status: ImmutableTransactionStatus.success },
                        client.getWithdrawalsF,
                    ),
                    TE.chain(A.traverse(TE.taskEither)(resultToItem('Withdrawal Prepared'))),
                );

                const transferSendResult = pipe(
                    client.getPaginatedResults(
                        { user: address, status: ImmutableTransactionStatus.success },
                        client.getTransfersF,
                    ),
                    TE.chain(A.traverse(TE.taskEither)(resultToItem('Transfer out'))),
                );

                const transferReceiveResult = pipe(
                    client.getPaginatedResults(
                        { receiver: address, status: ImmutableTransactionStatus.success },
                        client.getTransfersF,
                    ),
                    TE.chain(A.traverse(TE.taskEither)(resultToItem('Transfer in'))),
                );

                const allowedExchangeStatuses = ['failed', 'completed', 'pending'];

                // TODO: 'fiatToCrypto' and 'cryptoToFiat' are deprecated
                const mapExchangeStatuses = {
                    fiatToCrypto: allowedExchangeStatuses,
                    onramp: allowedExchangeStatuses,
                    cryptoToFiat: [...allowedExchangeStatuses, 'waitingPayment'],
                    offramp: [...allowedExchangeStatuses, 'waitingPayment'],
                };

                const exchangeToHistoryItem = (exchange: Exchange): HistoryItem =>
                    // TODO: 'fiatToCrypto' is deprecated and needs to be removed when API is ready
                    exchange.type === 'fiatToCrypto' || exchange.type === 'onramp'
                        ? onrampItem(tokens, exchange)
                        : offrampItem(tokens, exchange);

                const exchangesResult = async () =>
                    pipe(
                        client.getPaginatedResults({ wallet_address: address }, client.getExchangesF),
                        TE.map((result): HistoryItem[] =>
                            result
                                .filter(
                                    (exchange: Exchange) =>
                                        exchange.data !== null &&
                                        mapExchangeStatuses[exchange.type].includes(exchange.status),
                                )
                                .map(exchangeToHistoryItem),
                        ),
                    )();

                const ordersResult = pipe(
                    client.getPaginatedResults(
                        {
                            user: address,
                            status: ImmutableOrderStatus.filled,
                            include_fees: true,
                            order_by: 'updated_at',
                        },
                        client.getOrdersV3F,
                    ),
                    TE.chain((orders) =>
                        A.traverse(TE.taskEither)((order: ImmutableMethodResults.ImmutableGetOrderV3Result) =>
                            pipe(
                                TE.bindTo('buyToken')(immutableTokenToTokenWithDetails(client)(order.buy)),
                                TE.bind('sellToken', () => immutableTokenToTokenWithDetails(client)(order.sell)),
                                TE.map(({ buyToken, sellToken }) => {
                                    // @NOTE:
                                    // if buyToken.token.type === ERC721TokenType.ERC721 then its a "PURCHASE" item,
                                    // Otherwise it's a "SOLD" item (and the sellToken.token.type will be ERC721TokenType.ERC721)
                                    // Either way, ATM we assume every "order" is to do with the exchange of
                                    // ownership of ONLY ERC721 tokens
                                    const transactionItem: Partial<HistoryItem> = {
                                        isCurrency: false,
                                        date: order.timestamp,
                                        txId: order.order_id,
                                        transactionLink: `https://immutascan.io/order/${order.order_id}`,
                                        tokenType: ERC721TokenType.ERC721,
                                    };
                                    if (buyToken.token.type === ERC721TokenType.ERC721) {
                                        // buyOrder taker = purchased listing
                                        // buyOrder maker = accepted bid
                                        const priceWithFees =
                                            order.maker_taker_type === ImmutableOrderMakerTakerType.taker
                                                ? formatUnits(
                                                      order.taker_fees.quantity_with_fees,
                                                      order.taker_fees.decimals,
                                                  )
                                                : formatUnits(
                                                      order.maker_fees.quantity_with_fees,
                                                      order.maker_fees.decimals,
                                                  );

                                        transactionItem.transactionType = 'Purchase';
                                        transactionItem.priceEffect = 'negative';
                                        transactionItem.primaryText = buyToken.asset?.name || '';
                                        transactionItem.secondaryText = buyToken.asset?.collection.name || '';
                                        transactionItem.assetImageUrl = buyToken.asset?.image_url || '';
                                        transactionItem.amount = priceWithFees;
                                        transactionItem.tokenImageUrl =
                                            findTokenWithAddress(tokens, (sellToken.token.data as any).tokenAddress)
                                                ?.image_url || DEFAULT_ETH_TOKEN_IMAGERY;
                                    } else {
                                        // sellOrder taker = order that accepted a bid
                                        // sellOrder maker = listing successfully sold
                                        // Note: we want what the seller received, not the purchase price
                                        const receivedAmount =
                                            order.maker_taker_type === ImmutableOrderMakerTakerType.taker
                                                ? formatUnits(
                                                      order.taker_fees.quantity_with_fees,
                                                      order.taker_fees.decimals,
                                                  )
                                                : formatUnits(
                                                      order.maker_fees.quantity_with_fees,
                                                      order.maker_fees.decimals,
                                                  );

                                        transactionItem.transactionType = 'Sold';
                                        transactionItem.priceEffect = 'positive';
                                        transactionItem.primaryText = sellToken.asset?.name || '';
                                        transactionItem.secondaryText = sellToken.asset?.collection.name || '';
                                        transactionItem.assetImageUrl = sellToken.asset?.image_url || '';
                                        transactionItem.amount = receivedAmount;
                                        transactionItem.tokenImageUrl =
                                            findTokenWithAddress(tokens, (buyToken.token.data as any).tokenAddress)
                                                ?.image_url || DEFAULT_ETH_TOKEN_IMAGERY;
                                    }
                                    return transactionItem as HistoryItem;
                                }),
                            ),
                        )(orders),
                    ),
                );

                let primaryTransactionsResult: TE.TaskEither<Error, HistoryItem[]> = TE.of([]);
                if (enableNFTDirect) {
                    primaryTransactionsResult = async () =>
                        pipe(
                            client.getPaginatedResults(
                                { user_wallet_address: address },
                                client.getPrimaryTransactionsHistoryF,
                            ),
                            TE.map((result) => result.map(primaryTransactionToHistoryItem)),
                        )();
                }

                let secondaryTransactionsResult: TE.TaskEither<Error, HistoryItem[]> = TE.of([]);
                if (enableNFTSecondary) {
                    secondaryTransactionsResult = async () =>
                        pipe(
                            client.getPaginatedResults(
                                { user_wallet_address: address },
                                client.getSecondaryTransactionsHistoryF,
                            ),
                            TE.map((result) => result.map(secondaryTransactionToHistoryItem)),
                        )();
                }

                return pipe(
                    // Request data in sequence.
                    A.sequence(TE.taskEither)([
                        depositResult,
                        withdrawalResult,
                        transferSendResult,
                        transferReceiveResult,
                        ordersResult,
                        primaryTransactionsResult,
                        secondaryTransactionsResult,
                        exchangesResult,
                    ]),
                    TE.map(A.flatten),
                    // Sort by date in reverse.
                    TE.map(A.sortBy([ORD.ord.contramap(ORD.ordNumber, (i: HistoryItem) => -getUnixTime(i.date))])),
                );
            }),
            TE.fold(
                (e) =>
                    T.fromIO(() => {
                        setErrorLog(e, messages.history.retrieveTransactionsFailed(e.message));
                    }),
                ({ items, address }) =>
                    T.fromIO(() => {
                        setData({ items, address });
                    }),
            ),
        )();
    }, [hasTokensList, config.publicApiUrl, provider, setErrorLog, setLoading, getTokenInfoCallback]);

    return { data };
};
