/*
  process.env.NODE_ENV is automatically defined (through Craco) as follows:
  'npm start' ------> process.env.NODE_ENV = 'development'
  'npm run test' ---> process.env.NODE_ENV = 'test'
  'npm run build' --> process.env.NODE_ENV = 'production'
  source: https://create-react-app.dev/docs/adding-custom-environment-variables

  When running 'npm run build' in the pipeline, is it possible to build for:
  'dev' ------> process.env.REACT_APP_BUILD_ENV = 'dev'
  'ropsten' --> process.env.REACT_APP_BUILD_ENV = 'ropsten'
  'prod' -----> process.env.REACT_APP_BUILD_ENV = 'prod'
*/

const compileEnv = {
    development: process.env.NODE_ENV === 'development',
    test: process.env.NODE_ENV === 'test',
    production: process.env.NODE_ENV === 'production',
};

const runtimeEnv = {
    development: process.env.REACT_APP_BUILD_ENV === 'dev',
    staging: process.env.REACT_APP_BUILD_ENV === 'ropsten',
    production: process.env.REACT_APP_BUILD_ENV === 'prod',
};

export const isLocalEnv = compileEnv.development;

export const isTestEnv = compileEnv.test;

export const isDevEnv = isLocalEnv || (compileEnv.production && runtimeEnv.development);

export const isStagingEnv = compileEnv.production && runtimeEnv.staging;

export const isProdEnv = compileEnv.production && runtimeEnv.production;
