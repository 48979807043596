import { versions } from '../version';
import { AnalyticsClient } from './analytics-client';
import { AnyAnalyticsEvent } from './types';

export const analyticsClient = new AnalyticsClient({ version: versions.linkVersion });

// TODO: This Analytics service should likely reside outside the Analytics library boundary

export const sendAnalytics = (...events: AnyAnalyticsEvent[]) => {
    events.forEach((event) => analyticsClient.publishEvent(event));
};

export const sendAnalyticsAsync = async (...events: AnyAnalyticsEvent[]) => {
    const options = { requestType: 'async' };

    const promises = events.flatMap((event) => analyticsClient.publishEvent(event, options));

    return Promise.allSettled(promises);
};
