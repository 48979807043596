import { BaseComponentPropTypes } from '@imtbl/design-system';
import { ERC20TokenType } from '@imtbl/imx-sdk';
import React from 'react';

import { formatAmount } from '../../lib';
import { FullOrderInfo } from '../../types/SharedOrder.types';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../utils/constants';
import { TokenDetailsDisplayRow } from '../TokenDetailsDisplayRow';

interface OrderSummaryPropTypes extends BaseComponentPropTypes {
    order: FullOrderInfo;
}

export const OrderSummaryListItem = ({ order, testId }: OrderSummaryPropTypes) => {
    const takerFeesAmount = formatAmount(
        order.details.taker_fees.quantity_with_fees,
        order.details.taker_fees.decimals,
    );
    return (
        <TokenDetailsDisplayRow
            testId={testId}
            tokenType={order.tokenSellDetails.token.type}
            asset={order?.tokenSellDetails?.asset}
            transactionCostTokenImage={
                order.tokenBuyDetails?.token.type === ERC20TokenType.ERC20 && order.tokenBuyMetadata?.image_url
                    ? order?.tokenBuyMetadata?.image_url
                    : DEFAULT_ETH_TOKEN_IMAGERY
            }
            tokenDisplayAmount={takerFeesAmount}
        />
    );
};
