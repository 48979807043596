import { ImmutableAssetStatus } from '@imtbl/imx-sdk';

import { Messages } from '../../i18n';
import { ResultStatus } from '../../types/SharedOrder.types';
import { TransferTokenInfoMinusValidation } from '../../types/SharedTransfer.types';
import { ERROR_VALIDATION_STATUS, SUCCESS_VALIDATION_STATUS } from './transfers.types';

export const validateErc721Transfer = (
    transfer: TransferTokenInfoMinusValidation,
    textMessages: Messages,
    userEthAddress: string,
): ResultStatus => {
    const validationResult: ResultStatus = { status: SUCCESS_VALIDATION_STATUS };
    if (!transfer.tokenDetails.asset) {
        validationResult.status = ERROR_VALIDATION_STATUS;
        validationResult.message = textMessages.loadAssetError;
        return validationResult;
    }
    if (transfer.tokenDetails.asset?.user !== userEthAddress) {
        validationResult.status = ERROR_VALIDATION_STATUS;
        validationResult.message = textMessages.userNotAssetOwner;
        return validationResult;
    }
    if (transfer.tokenDetails.asset?.user === transfer.originalParams.toAddress) {
        validationResult.status = ERROR_VALIDATION_STATUS;
        validationResult.message = textMessages.transfer.ownAsset;
        return validationResult;
    }
    if (transfer.tokenDetails.asset?.status !== ImmutableAssetStatus.imx) {
        validationResult.status = ERROR_VALIDATION_STATUS;
        validationResult.message = textMessages.assetNotDeposited;
        return validationResult;
    }

    return validationResult;
};
