import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import {
    BaseComponentPropTypes,
    colors,
    FlexLayout,
    ParagraphText,
    SectionHeading,
    StandardButton,
    VerticalSpace,
} from '@imtbl/design-system';
import {
    ERC20Token,
    ERC20TokenType,
    ERC721Token,
    ERC721TokenType,
    ETHToken,
    LINK_MESSAGE_TYPE,
    messageTypes,
} from '@imtbl/imx-sdk';
import React, { useCallback } from 'react';

import { Asset } from '../../../hooks/useGetAsset.hook';
import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { useScrollToTop } from '../../../hooks/useScrollToTop.hook';
import { TokenDataType } from '../../../hooks/useTokensList.hook';
import { ParentWindow } from '../../../lib';
import { sendAnalyticsAsync } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent } from '../../../lib/analytics/utils';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../../utils/constants';
import { CancelLink, StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../../common';
import { TokenDetailsDisplayRow } from '../../TokenDetailsDisplayRow';

export interface MakeOfferPromptScreenPropTypes extends BaseComponentPropTypes {
    parent: ParentWindow;
    makeOfferTransaction: () => void;
    tokenSell?: ETHToken | ERC20Token;
    tokenBuy?: ERC721Token;
    offerCurrencyToken: TokenDataType;
    amountSell: BigNumber;
    totalFeePercentage: number;
    loading: boolean;
    asset?: Asset;
}

export const MakeOfferPrompt = ({
    parent,
    makeOfferTransaction,
    tokenSell,
    tokenBuy,
    offerCurrencyToken,
    amountSell,
    totalFeePercentage,
    asset,
    loading,
    testId,
}: MakeOfferPromptScreenPropTypes) => {
    useScrollToTop();
    const textMessages = useI18nMessaging({});
    const onCancelClick = useCallback(async () => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
        await sendAnalyticsAsync(
            createButtonEvent(ButtonEventName.makeOfferPromptCancelBtnPressed),
            createFlowEvent(FlowEventName.makeOfferFailed),
        );
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, [parent, textMessages]);

    // calculation so that the amountSell here should include all fees. Must convert up by 10000 and divide by 10000
    // as fee percentages can be specified to 2 decimal places. To avoid BigNumber overflow
    const amountSellWithFees = amountSell
        .mul(BigNumber.from(10000 + totalFeePercentage * 100))
        .div(BigNumber.from(10000));
    const tokenDisplayAmount = formatUnits(amountSellWithFees, offerCurrencyToken?.decimals || 18);
    return (
        <StandardLinkRouteContainer testId={`${testId}__Container`}>
            <VerticalSpace bottom="small">
                <SectionHeading testId="heading">{textMessages.offers.makeOffer.title.start}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                {textMessages.offers.makeOffer.bodyText.start}
            </ParagraphText>

            <VerticalSpace top="large">
                <FlexLayout flexGrow={1} flexDirection="column">
                    <TokenDetailsDisplayRow
                        testId={`${testId}__tokenDetails`}
                        tokenType={tokenBuy?.type || ERC721TokenType.ERC721}
                        asset={asset}
                        transactionCostTokenImage={
                            tokenSell?.type === ERC20TokenType.ERC20 && offerCurrencyToken?.image_url
                                ? offerCurrencyToken?.image_url
                                : DEFAULT_ETH_TOKEN_IMAGERY
                        }
                        tokenDisplayAmount={tokenDisplayAmount}
                    />
                </FlexLayout>
            </VerticalSpace>

            <StandardLinkRouteButtonsFooter testId={`${testId}__buttonsFooter`}>
                <StandardButton
                    testId={`${testId}__buttonsFooter__confirmCta`}
                    buttonKind="ultimate-cta"
                    onClick={makeOfferTransaction}
                    disabled={loading}
                >
                    {textMessages.offers.makeOffer.buttons.confirm}
                </StandardButton>
                <CancelLink
                    title={textMessages.offers.makeOffer.buttons.cancel}
                    onClick={onCancelClick}
                    testId={`${testId}__buttonsFooter__cancel`}
                />
            </StandardLinkRouteButtonsFooter>
        </StandardLinkRouteContainer>
    );
};
