import { ERC20TokenType, EthAddress } from '@imtbl/imx-sdk';
import { useEffect, useState } from 'react';

import { LinkClientConfig } from '../lib';
import { TransferInfoWithTokenInfo, TransferInfoWithTokenInfoAndMetadata } from '../types/SharedTransfer.types';
import { findTokenWithAddress, useTokensList } from './useTokensList.hook';

export const useGetTransferTokenMetadata = ({
    config,
    transferDetails,
}: {
    config: LinkClientConfig;
    transferDetails?: TransferInfoWithTokenInfo[];
}) => {
    const [transferTokensWithMetadata, setTransferTokensWithMetadata] =
        useState<TransferInfoWithTokenInfoAndMetadata[]>();
    const { tokens: tokensList } = useTokensList({ config });
    useEffect(() => {
        if (transferDetails?.length && tokensList.length) {
            const tokensWithPickedMetadata = transferDetails.map((transfer) => {
                if (transfer.tokenDetails?.token.type === ERC20TokenType.ERC20) {
                    const { tokenAddress } = transfer.tokenDetails.token.data;
                    const metadata = findTokenWithAddress(tokensList, tokenAddress as EthAddress);
                    return {
                        ...transfer,
                        tokenMetadata: metadata,
                    };
                }
                return { ...transfer };
            });
            setTransferTokensWithMetadata(tokensWithPickedMetadata);
        }
    }, [transferDetails, tokensList]);

    return transferTokensWithMetadata;
};
