import { Web3Provider } from '@ethersproject/providers';
import { colors, FlexLayout, layoutHelpers, ParagraphText, SimpleText, VerticalSpace } from '@imtbl/design-system';
import {
    assertEither,
    ERC20TokenType,
    errorsToError,
    ImmutableMethodResults,
    ImmutableOrderStatus,
    ImmutableXClient,
    LinkError,
    LinkParamsCodecs,
    LinkParamsF,
} from '@imtbl/imx-sdk';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as t from 'io-ts';
import React, { useCallback, useContext } from 'react';

import { LinkCoreContext } from '../../context/App.context';
import { Messages } from '../../i18n';
import {
    DispatchSetError,
    formatAmount,
    getAddress,
    getBalanceForTokenType,
    immutableTokenToTokenWithDetails,
    LinkClientConfig,
    NotifyMethod,
    ParentWindow,
    TokenWithDetails,
} from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../lib/analytics/utils';
import { buy } from '../../lib/trades/buy';
import { getProvidersConfig } from '../../utils/getProvidersConfig';
import { Action } from '../Action';
import { Balance } from '../Balance';
import { BalanceItem } from '../BalanceItem';
import FullWidthBorderLine from '../FullWidthBorderLine';
import { HelperMessageType } from '../HelperMessageBox';
import styles from './index.module.css';

export type BuyProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    setErrorLog: DispatchSetError;
};

export type BuyInput = {
    order: ImmutableMethodResults.ImmutableGetOrderV3Result;
    tokenSell: TokenWithDetails;
    tokenBuy: TokenWithDetails;
    takerFees: ImmutableMethodResults.ImmutableOrderMakerTakerFeesType & { amount: string };
    tokenBuyInfo: ImmutableMethodResults.ImmutableGetTokenResult;
};

export const Buy = ({ config, parent, provider, messages, setErrorLog }: BuyProps) => {
    const { config: linkConfig } = useContext(LinkCoreContext);
    const providerOptions = getProvidersConfig(linkConfig);

    const loadInput = (params: LinkParamsF.Buy) =>
        pipe(
            // @NOTE: factory function, we stil' nedd to do this
            // const client = await ImmutableXClient.build({ publicApiUrl: config.publicApiUrl });
            TE.bindTo('client')(ImmutableXClient.buildF({ publicApiUrl: config.publicApiUrl })),

            // @NOTE: this gets the users eth address from MM
            // const address = await valueOrThrowTE(getAddress(provider));
            TE.bind('address', () => getAddress(provider)),

            // @NOTE: use queryString.parse(window.location.search) to get params (eg orderId)
            TE.bind('parsedOrderId', () =>
                pipe(t.Int.decode(parseInt(params.orderId)), E.mapLeft(errorsToError), TE.fromEither),
            ),

            // @NOTE: gets the details of a specific order
            // const order = await client.getOrder({ orderId: parsedOrderId }));
            TE.bind('order', ({ client, parsedOrderId }) => client.getOrderV3F({ orderId: parsedOrderId })),

            // @NOTE: these 2 commands populate all order details (for display in UI)
            // const tokenSellDetails = await valueOrThrowTE(immutableTokenToTokenWithDetails(client)(order.sell))
            // const tokenBuyDetails = await valueOrThrowTE(immutableTokenToTokenWithDetails(client)(order.buy))
            TE.bind('tokenSell', ({ order, client }) => immutableTokenToTokenWithDetails(client)(order.sell)),
            TE.bind('tokenBuy', ({ order, client }) => immutableTokenToTokenWithDetails(client)(order.buy)),
            TE.bind('takerFees', ({ order }) =>
                TE.right({
                    ...order.taker_fees,
                    amount: formatAmount(order.taker_fees.quantity_with_fees, order.taker_fees.decimals),
                }),
            ),

            TE.bind('tokenBuyInfo', ({ tokenBuy, client }) => {
                return client.getTokenF(
                    tokenBuy.token.type === ERC20TokenType.ERC20
                        ? { tokenAddress: tokenBuy.token.data.tokenAddress }
                        : {},
                );
            }),

            // @NOTE: we get the user's balance here, so that we can check they have enough funds...
            // const balance = await valueOrThrowTE(getBalanceForTokenType(tokenBuy.token, client, address));
            TE.bind('balance', ({ client, address, tokenBuy }) =>
                getBalanceForTokenType(tokenBuy.token, client, address),
            ),

            // @NOTE: just doing a bunch of if checks
            // if (order.status !== 'active') throw new Error(messages.buy.orderNotAvailable);
            // ... as above for this check
            // ... as above for this check
            TE.chainFirst(({ order, address, takerFees, balance }) => {
                return pipe(
                    A.sequence(E.either)([
                        assertEither(order.status !== ImmutableOrderStatus.active, messages.buy.orderNotAvailable),
                        assertEither(order.user === address, messages.buy.ownOrder),
                        assertEither(takerFees.quantity_with_fees.gt(balance.balance), messages.insufficientFunds),
                    ]),
                    TE.fromEither,
                );
            }),
            TE.chainFirst(() =>
                TE.fromIO(() => {
                    // @NOTE: this should be run, once all loading operations are complete
                    sendAnalytics(
                        createScreenEvent(ScreenEventName.buyNowNotifiedOpened),
                        createFlowEvent(FlowEventName.assetBuyNowStarted),
                    );
                }),
            ),
        );

    const showDetailsStart = ({ tokenSell, tokenBuy, takerFees, tokenBuyInfo }: BuyInput) => {
        return (
            <>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Please confirm the assets to purchase:
                </ParagraphText>
                <VerticalSpace top="large">
                    <FlexLayout flexGrow={1} flexDirection="column">
                        <FlexLayout justifyContent="space-between" className={styles.historyItem}>
                            <BalanceItem
                                testId="buy-balance-item"
                                amount={takerFees.amount}
                                platform={tokenSell.asset?.collection.name}
                                type={tokenSell.asset?.name || tokenSell.token.type}
                                imageUrl={tokenSell.asset?.image_url}
                            />

                            <Balance
                                testId="buy-balance"
                                amount={takerFees.amount}
                                type={tokenBuy.token.type}
                                tokenImage={tokenBuyInfo.image_url || ''}
                            />
                        </FlexLayout>
                    </FlexLayout>
                </VerticalSpace>

                <FlexLayout justifyContent="space-between" paddingTop={layoutHelpers.gridUnits(3)}>
                    <FlexLayout flexDirection="column">
                        <FullWidthBorderLine />
                        <SimpleText fontSize="large" fontWeight="bold" fillColor={colors.light[100]}>
                            Total
                        </SimpleText>
                        <SimpleText fillColor={colors.light[700]} fontSize="small" className={styles.commissionFee}>
                            {/* Inc. 5% commission fee */}
                        </SimpleText>
                    </FlexLayout>
                    <Balance
                        fontSize="large"
                        testId="total-balance"
                        fillColor={colors.blue[300]}
                        type={tokenBuy.token.type}
                        amount={tokenBuy.amount}
                        tokenImage={tokenBuyInfo.image_url || ''}
                    />
                </FlexLayout>
            </>
        );
    };

    const showDetailsDone = (_: BuyInput) => {
        return (
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your purchase has been completed.
            </ParagraphText>
        );
    };

    // @NOTE when they click the button, this code runs:
    const execute = ({ tokenSell, tokenBuy, order }: BuyInput, notify: NotifyMethod) => {
        sendAnalytics(createButtonEvent(ButtonEventName.buyNowNotifiedConfirmPressed));
        return pipe(
            buy(config, order, tokenSell, tokenBuy, notify, providerOptions),
            TE.bimap(
                (error) => {
                    return new LinkError(
                        messages.buy.failedAPIRequest.code,
                        messages.buy.failedAPIRequest.message(error.message),
                    );
                },
                (orderId) => orderId,
            ),
        );
    };

    const onFinish = useCallback(() => {
        sendAnalytics(createButtonEvent(ButtonEventName.buyNowConfirmedFinishPressed));
    }, []);

    const onCancel = useCallback(() => {
        sendAnalytics(
            createButtonEvent(ButtonEventName.buyNowNotifiedCancelPressed),
            createFlowEvent(FlowEventName.assetBuyNowFailed),
        );
    }, []);

    return (
        <Action
            parent={parent}
            title={messages.buy.title}
            paramsDecoder={LinkParamsCodecs.Buy.decode}
            loadInput={loadInput}
            execute={execute}
            helperMessageType={HelperMessageType.IS_CONFIRM}
            showDetails={{
                start: showDetailsStart,
                done: showDetailsDone,
            }}
            setErrorLog={setErrorLog}
            onFinishClick={onFinish}
            onDenyClick={onCancel}
        />
    );
};
