// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ab7AGe7oVYTSJAXs_CBI {\n    align-items: center;\n    display: flex;\n}\n\n.Ab7AGe7oVYTSJAXs_CBI a {\n    display: flex;\n}\n\n.Ab7AGe7oVYTSJAXs_CBI img {\n    margin-left: 5px;\n    width: 55px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SetupMagicWallet/index.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".poweredByMagic {\n    align-items: center;\n    display: flex;\n}\n\n.poweredByMagic a {\n    display: flex;\n}\n\n.poweredByMagic img {\n    margin-left: 5px;\n    width: 55px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poweredByMagic": "Ab7AGe7oVYTSJAXs_CBI"
};
export default ___CSS_LOADER_EXPORT___;
