import {
    colors,
    IndeterminateHorizontalLines,
    ParagraphText,
    SectionHeading,
    VerticalSpace,
} from '@imtbl/design-system';
import React from 'react';

export type InProgressProps = {
    heading: string;
    paragraph: string;
    waitMessage?: string;
};

export const InProgressScreen = ({ heading, paragraph, waitMessage = 'Please wait...' }: InProgressProps) => (
    <>
        <IndeterminateHorizontalLines />
        <VerticalSpace bottom="small">
            <SectionHeading testId="InProgressScreen__heading">{heading}</SectionHeading>
        </VerticalSpace>
        <ParagraphText fillColor={colors.light[300]} fontSize="small" testId="InProgressScreen__paragraph">
            {paragraph}
        </ParagraphText>

        <VerticalSpace top="large">
            <ParagraphText
                fillColor={colors.light[100]}
                fontWeight="bold"
                fontSize="small"
                testId="InProgressScreen__waitMessage"
            >
                {waitMessage}
            </ParagraphText>
        </VerticalSpace>
    </>
);
