import { ApiErrorCodec } from './api';

// https://github.com/gcanti/io-ts/issues/442
export function isApiError(error: unknown): boolean {
    let parsedError;
    try {
        parsedError = JSON.parse((error as Error).message);
    } catch (e) {
        console.log('Unable to parse error', e);
        return false;
    }
    return ApiErrorCodec.is(parsedError);
}
