import { LinkParams } from '@imtbl/imx-sdk';
import queryString from 'query-string';
import React, { useEffect } from 'react';

import { useGetEthAddress } from '../../../hooks/useGetAddress.hook';
import { LinkConfig } from '../../../lib';
import { search } from '../../../utils/location';
import { LoadingMessage, StandardLinkRouteContainer } from '../../common';
import { ExchangeProps } from '../Onramp';
import { ProviderNFTCheckoutPrimary } from './ProviderNFTCheckoutPrimary';

export type NFTCheckoutPrimaryProps = ExchangeProps & {
    config: LinkConfig;
};

export const NFTCheckoutPrimary = (props: NFTCheckoutPrimaryProps) => {
    const { provider: providerName, ...restParams } = queryString.parse(
        search,
    ) as unknown as LinkParams.NFTCheckoutPrimary;
    const { provider, loading, setLoading } = props;
    const walletAddress = useGetEthAddress(provider);

    useEffect(() => {
        setLoading(!walletAddress);
    }, [walletAddress]);

    return (
        <>
            {loading ? (
                <StandardLinkRouteContainer>
                    <LoadingMessage />
                </StandardLinkRouteContainer>
            ) : (
                <ProviderNFTCheckoutPrimary
                    {...props}
                    {...restParams}
                    providerName={providerName}
                    userWalletAddress={walletAddress}
                />
            )}
        </>
    );
};
