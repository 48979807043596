import { createContext } from 'react';

import { ErrorMessageProps } from '../components';
import { LinkConfig, ParentWindow } from '../lib';

export type SetErrorLog = (e: any, ui?: ErrorMessageProps) => void;

export interface LinkUiCoreContextPropTypes {
    setErrorLog: SetErrorLog;
    imageResizerServiceUrl: string;
}

export const linkUiCoreContextInitialState = {
    setErrorLog: () => ({}),
    imageResizerServiceUrl: 'this field gets set via env config',
};

export interface LinkCoreContextPropTypes {
    parent: ParentWindow | undefined;
    config: LinkConfig | undefined;
}

export const linkCoreContextInitialState = {
    parent: undefined,
    config: undefined,
};

/*
    @TODO: I think these should be setup inside a shared context, so that any
    child below can choose to use them without the need to have these props
    be prop-drilled all the way down to it.
    IMO these vars could all benefit from this change:
    -   parent
    -   provider
    -   setErrorLog
    -   config
    -   flags
 */
export const LinkUiCoreContext = createContext<LinkUiCoreContextPropTypes>({
    ...linkUiCoreContextInitialState,
});

export const LinkCoreContext = createContext<LinkCoreContextPropTypes>({
    ...linkCoreContextInitialState,
});
