import { Web3Provider } from '@ethersproject/providers';
import { formatUnits } from '@ethersproject/units';
import { ERC20TokenType, ETHTokenType, ImmutableXClient } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';

import { TokenDataType, TokensListType } from '../../../hooks/useTokensList.hook';
import { parseAmount, registerUserAsync } from '../../../lib';
import { DEFAULT_DECIMALS } from '../../../utils/constants';

export const tokenToFlatToken = (token: TokenDataType): any => {
    if (!token) return {};

    return token.symbol && token.symbol !== ETHTokenType.ETH
        ? {
              type: ERC20TokenType.ERC20,
              data: {
                  decimals: parseInt(token.decimals) || DEFAULT_DECIMALS,
                  tokenAddress: token.token_address,
                  symbol: token.symbol,
              },
          }
        : {
              type: ETHTokenType.ETH,
              data: {
                  decimals: token?.decimals ? parseInt(token.decimals) : DEFAULT_DECIMALS,
              },
          };
};

export const getTokenToAddress = (query: any): string | undefined => {
    if (!query.type && !query.amount) return undefined;

    return 'tokenAddress' in query ? query.tokenAddress : '';
};

export const addressToToken = (address: string, tokens: TokensListType): TokenDataType =>
    tokens && tokens.find((t) => t.token_address === address);

export const isEnoughBalance = async (provider: Web3Provider, user: string, amount: BigNumber): Promise<boolean> => {
    const balance = await provider.getBalance(user);
    const parsedBalance = new BigNumber(formatUnits(balance, DEFAULT_DECIMALS));

    return amount.lte(parsedBalance);
};

export const registerIfNotExist = async (
    client: ImmutableXClient,
    provider: Web3Provider,
    token: TokenDataType,
): Promise<void> => {
    const isRegistered = await client.isRegisteredStark(client.starkPublicKey);
    if (!isRegistered && token?.symbol !== ETHTokenType.ETH) {
        await registerUserAsync(client, provider);
    }
};

export const deposit = async (
    provider: Web3Provider,
    client: ImmutableXClient,
    token: TokenDataType,
    amount: string,
): Promise<string> => {
    const tokenDeposit = tokenToFlatToken(token);
    const finalAmount = parseAmount(amount, tokenDeposit.data.decimals);
    if (tokenDeposit.type === ERC20TokenType.ERC20) {
        const isEnoughTokens = await client.hasERC20Allowance(
            tokenDeposit.data.tokenAddress,
            finalAmount,
            client.address,
        );

        if (!isEnoughTokens) {
            const transactionId = await client.approveERC20({
                tokenAddress: tokenDeposit.data.tokenAddress,
                amount: finalAmount,
            });

            await provider.waitForTransaction(transactionId);
        }
    }

    const transactionId = await client.deposit({
        user: client.address,
        token: tokenDeposit,
        quantity: finalAmount,
    });
    return transactionId;
};
