import { Web3Provider } from '@ethersproject/providers';
import { ImmutableXClient, LINK_MESSAGE_TYPE, messageTypes, ProviderPreference, valueOrThrowTE } from '@imtbl/imx-sdk';
import { useCallback, useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { useLaunchDarklyFlags } from '../context/LaunchDarkly';
import { isServiceUnavailable, LinkClientConfigTS, NotifyMethod, ParentWindow } from '../lib';
import { sendAnalytics } from '../lib/analytics/send-analytics';
import { ButtonEventName } from '../lib/analytics/types';
import { createButtonEvent } from '../lib/analytics/utils';
import { FEATURE_FLAG } from '../lib/featureFlags';
import { buyV3 } from '../lib/trades/buy';
import { BuyResults, FullOrderInfo, FullOrderInfoWithFinalStatus, SuccessOrError } from '../types/SharedOrder.types';
import { getFeesFromSearchParams } from '../utils/getFeesFromSearchParams';
import { useI18nMessaging } from './useI18nMessaging.hook';

export type UseMakeBuyTransactionPropTypes = {
    config?: LinkClientConfigTS;
    orderDetails?: FullOrderInfo[];
    notify?: NotifyMethod;
    parent: ParentWindow;
    provider: Web3Provider;
};

export const useMakeBuyTransaction = ({
    provider,
    parent,
    config,
    orderDetails,
    notify = () => ({}),
}: UseMakeBuyTransactionPropTypes) => {
    const [buyResults, setBuyResults] = useState<BuyResults>();
    const [finalConsolidatedOrders, setFinalConsolidatedOrders] = useState<FullOrderInfoWithFinalStatus[]>();
    const [transacting, setTransacting] = useState(false);
    const [sendAnalyticsCallback] = useState<() => void>();
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const buyTextMessaging = useI18nMessaging().buy;
    const takerFees = getFeesFromSearchParams();

    const flags = useLaunchDarklyFlags();
    const enableRiskAssessment = flags[FEATURE_FLAG.ENABLE_RISK_ASSESSMENT];
    const enableSendProviderPrefWithAssetPurchase = flags[FEATURE_FLAG.ENABLE_SEND_PROV_PREF_ANALYTICS_EVENT];

    const beginBuyTransaction = useCallback(async () => {
        if (config && orderDetails) {
            setTransacting(true);
            parent.postMessage(
                {
                    type: LINK_MESSAGE_TYPE,
                    message: messageTypes.inProgress,
                },
                '*',
            );
            sendAnalytics(createButtonEvent(ButtonEventName.buyNowNotifiedConfirmPressed));

            try {
                // @NOTE: only send off the validated orders, the rest can just be messaged as
                // failed in the final buy2 screen and promise response object
                const validOrders = orderDetails.filter((order) => order.validationResult.status === 'success');
                const invalidOrders: FullOrderInfoWithFinalStatus[] = orderDetails
                    .filter((order) => order.validationResult.status !== 'success')
                    .map((order) => ({
                        ...order,
                        buyTransactionStatus: {
                            status: 'error',
                            message: order.validationResult.message,
                        },
                    }));

                const noValidOrders = validOrders.length === 0;
                const insufficientFunds = !!invalidOrders.find(
                    (invalidOrder) => invalidOrder.validationResult.message === useI18nMessaging().insufficientFunds,
                );

                if (noValidOrders && insufficientFunds) {
                    throw Error(buyTextMessaging.insufficientFunds);
                }
                if (noValidOrders && !insufficientFunds) {
                    throw Error(buyTextMessaging.noValidOrders);
                }

                const client = await ImmutableXClient.build({
                    ...config,
                    signer: provider.getSigner(),
                });
                const buyResponse: FullOrderInfoWithFinalStatus[] = await Promise.all(
                    validOrders.map(async (order) => {
                        let requestId: string | undefined;
                        try {
                            await valueOrThrowTE(
                                buyV3(
                                    client,
                                    order.details,
                                    order.tokenSellDetails,
                                    notify,
                                    takerFees,
                                    enableSendProviderPrefWithAssetPurchase,
                                ),
                            );
                            return {
                                ...order,
                                buyTransactionStatus: {
                                    status: 'success' as SuccessOrError,
                                    requestId,
                                },
                            };
                        } catch (err) {
                            const error = err instanceof Error ? err : new Error(String(err));

                            // @NOTE: if the service is unavailable it doesn't make sense to try others more
                            if (isServiceUnavailable(error)) throw error;

                            return Promise.resolve({
                                ...order,
                                buyTransactionStatus: {
                                    status: 'error' as SuccessOrError,
                                    message: error.message,
                                },
                            });
                        }
                    }),
                );

                const finalBuyResult: FullOrderInfoWithFinalStatus[] = [...buyResponse, ...invalidOrders];
                setFinalConsolidatedOrders(finalBuyResult);

                // @NOTE: create a very simple report of which orders failed and which ones passed:
                const simplifiedBuyReport: BuyResults = {};
                finalBuyResult.forEach((order) => {
                    simplifiedBuyReport[order.details.order_id] = {
                        status: order.buyTransactionStatus.status,
                        message: order.buyTransactionStatus?.message,
                        requestId: order.buyTransactionStatus.requestId,
                    };
                });

                // @NOTE: If we submitted any orders to API, and they ALL happened to fail
                // Then we treat this as a critical failure, and message accordingly:
                const completeFailure = !finalBuyResult.find(
                    (order) => order.buyTransactionStatus?.status === 'success',
                );
                if (completeFailure) throw Error(buyTextMessaging.allAttemptsFailed);

                setBuyResults(simplifiedBuyReport);
            } catch (err) {
                parent.postMessage(
                    {
                        type: LINK_MESSAGE_TYPE,
                        message: messageTypes.fail,
                    },
                    '*',
                );
                setErrorLog(
                    err,
                    useI18nMessaging().generalErrorMessage([buyTextMessaging.failedAPIRequest.message(err.message)]),
                );
            }
        }
    }, [config, orderDetails, buyTextMessaging, provider, notify, setErrorLog]);

    useEffect(() => {
        if (buyResults && !enableRiskAssessment) {
            parent.postMessage(
                {
                    type: LINK_MESSAGE_TYPE,
                    message: messageTypes.success,
                    data: { result: buyResults },
                },
                '*',
            );
            setTransacting(false);
        }
    }, [buyResults, enableRiskAssessment, parent]);

    return {
        buyResults,
        transacting,
        beginBuyTransaction,
        finalConsolidatedOrders,
        sendAnalyticsCallback,
    };
};
