import { useEffect, useState } from 'react';

export const USER_EMAIL_STORAGE_KEY = 'userEmail';

const useStoreUserEmail = () => {
    const [userEmail, setUserEmail] = useState<string>(window.localStorage.getItem(USER_EMAIL_STORAGE_KEY) || '');

    useEffect(() => {
        if (userEmail !== window.localStorage.getItem(USER_EMAIL_STORAGE_KEY)) {
            window.localStorage.setItem(USER_EMAIL_STORAGE_KEY, userEmail);
        }
    }, [userEmail]);

    return { email: userEmail, setUserEmail };
};

export default useStoreUserEmail;
