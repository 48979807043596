import { ImmutableXClient, LINK_MESSAGE_TYPE, LinkParams, messageTypes } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import { useLaunchDarklyFlags } from '../../../context/LaunchDarkly';
import { LinkError } from '../../../errors';
import { useGetEthAddress } from '../../../hooks/useGetAddress.hook';
import { useI18nMessaging } from '../../../hooks/useI18nMessaging.hook';
import { TokenDataType, useTokensList } from '../../../hooks/useTokensList.hook';
import { closeWindow } from '../../../lib';
import { sendAnalytics, sendAnalyticsAsync } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, FlowEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createFlowEvent, createScreenEvent } from '../../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../../lib/featureFlags';
import { search } from '../../../utils/location';
import { LoadingMessage, StandardLinkRouteContainer } from '../../common';
import { DepositProps } from '..';
import {
    ConfirmScreen,
    DepositBalanceProvider,
    DepositForm,
    InProgressScreen,
    ResultScreen,
    SignInScreen,
} from './components';
import { addressToToken, deposit, getTokenToAddress, isEnoughBalance } from './utils';

enum DepositStages {
    Loading,
    Deposit,
    Confirm,
    SignIn,
    InProgress,
    Result,
}

const FlexibleDeposit = ({ config, parent, setLoading, messages, provider, ethNetwork, setErrorLog }: DepositProps) => {
    const queryParams = queryString.parse(search) as LinkParams.FlexibleDeposit;
    const { tokens: availableTokens, error: errorRetrievingTokens } = useTokensList({ config });
    const [stage, setStage] = useState<DepositStages>(DepositStages.Loading);
    const [token, setToken] = useState<TokenDataType>();
    const [amount, setAmount] = useState<BigNumber>(new BigNumber(0));
    const [client, setClient] = useState<ImmutableXClient>();
    const [transactionId, setTransactionId] = useState<string>('test');
    const hasTokensList = availableTokens.length > 0;
    const user = useGetEthAddress(provider);
    const textMessages = useI18nMessaging({});

    const flags = useLaunchDarklyFlags();
    const enableAsyncEventAtEndOfDepositFlow = flags[FEATURE_FLAG.ENABLE_ASYNC_END_OF_DEPOSIT_FLOW_EVENTS];

    const onClose = closeWindow(parent);

    const imxClient = useCallback(async () => {
        return ImmutableXClient.build({
            ...config,
            signer: provider.getSigner(),
        });
    }, [config]);

    useEffect(() => {
        if (errorRetrievingTokens) {
            setErrorLog(
                errorRetrievingTokens,
                messages.generalErrorMessage([messages.failedToRetrieveTokenList(errorRetrievingTokens.message)]),
            );
            setLoading(false);
        }
    }, [errorRetrievingTokens, setErrorLog, setLoading]);

    useEffect(() => {
        imxClient()
            .then((result) => {
                if (result && !result.address)
                    throw new LinkError(
                        textMessages.deposit.walletAddressRetrievingFailed.code,
                        textMessages.deposit.walletAddressRetrievingFailed.message,
                    );

                return setClient(result);
            })
            .catch((err) => {
                if (err instanceof LinkError) {
                    switch (err.code) {
                        case textMessages.deposit.walletAddressRetrievingFailed.code:
                            return setErrorLog(err, messages.generalErrorMessage([err.message]));
                        default:
                            break;
                    }
                }

                return setErrorLog(err, textMessages.generalError);
            });
    }, []);

    useEffect(() => {
        if (hasTokensList) {
            setStage(DepositStages.Deposit);
            validateRequestAndSetStage();
            setLoading(false);
        } else {
            setStage(DepositStages.Loading);
            setLoading(true);
        }
    }, [hasTokensList, setLoading]);

    useEffect(() => {
        (async () => {
            switch (stage) {
                case DepositStages.SignIn:
                    try {
                        if (!client) return;
                        sendAnalytics(createScreenEvent(ScreenEventName.depositSignRequestOpened));
                        const depositTransactionId = await deposit(provider, client, token, amount.toFixed());
                        setTransactionId(depositTransactionId);
                        setStage(DepositStages.InProgress);
                    } catch (err: any) {
                        setErrorLog(
                            err,
                            textMessages.generalErrorMessage([
                                textMessages.deposit.failedAPIRequest.message(err.message),
                            ]),
                        );
                    }
                    break;
                case DepositStages.InProgress:
                    try {
                        sendAnalytics(createScreenEvent(ScreenEventName.depositInProgressScreenOpened));
                        await provider.waitForTransaction(transactionId);
                        setStage(DepositStages.Result);
                    } catch (err: any) {
                        const message = `Cannot check transaction ${transactionId}.`;
                        setErrorLog(err, messages.generalErrorMessage([message]));
                    }
                    break;
                case DepositStages.Result:
                    if (enableAsyncEventAtEndOfDepositFlow) {
                        await sendAnalyticsAsync(
                            createFlowEvent(FlowEventName.depositSucceeded),
                            createScreenEvent(ScreenEventName.despositResultScreenOpened),
                        );
                    } else {
                        sendAnalytics(
                            createFlowEvent(FlowEventName.depositSucceeded),
                            createScreenEvent(ScreenEventName.despositResultScreenOpened),
                        );
                    }
                    setLoading(false);
                    parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: {} }, '*');
                    break;
                default:
                    break;
            }
        })();
    }, [stage]);

    useEffect(() => {
        sendAnalytics(createScreenEvent(ScreenEventName.flexibleDepositFormScreenOpened));
    }, []);

    const validateRequestAndSetStage = () => {
        if (queryParams) {
            if ('tokenAddress' in queryParams && !addressToToken(queryParams.tokenAddress, availableTokens)) {
                setErrorLog(
                    { message: messages.deposit.invalidERC20Token },
                    messages.generalErrorMessage([messages.deposit.invalidERC20Token]),
                );
            }

            if ('amount' in queryParams && !('tokenAddress' in queryParams)) {
                const parsedAmount = new BigNumber(queryParams.amount);
                setAmount(parsedAmount);
                setToken(addressToToken('', availableTokens));

                isEnoughBalance(provider, user || '', parsedAmount).then((result) => {
                    if (result) {
                        setStage(DepositStages.Confirm);
                    } else {
                        setErrorLog(
                            { message: textMessages.deposit.insufficientFunds },
                            messages.generalErrorMessage([textMessages.deposit.insufficientFunds]),
                        );
                    }
                });
            }
        }
    };

    const onSubmitForm = (selectedAmount: any, selectedToken: TokenDataType) => {
        setAmount(selectedAmount);
        setToken(selectedToken);
        setStage(DepositStages.Confirm);

        const parsedAmount = new BigNumber(selectedAmount).toFormat();

        sendAnalytics(
            createButtonEvent(ButtonEventName.flexibleDepositNextBtnPressed, {
                currency: selectedToken?.symbol,
                amount: parsedAmount,
            }),
        );
    };

    const onConfirm = async () => {
        setStage(DepositStages.Loading);
        setLoading(true);
        sendAnalytics(createButtonEvent(ButtonEventName.depositNotifiedConfirmPressed));

        if (client) {
            setStage(DepositStages.SignIn);
        }
    };

    const stageElements = {
        [DepositStages.Loading]: <LoadingMessage />,
        [DepositStages.Deposit]: (
            <DepositForm
                availableTokens={availableTokens}
                token={getTokenToAddress(queryParams)}
                onConfirm={onSubmitForm}
                onCancel={onClose}
                user={user || ''}
            />
        ),
        [DepositStages.Confirm]: (
            <ConfirmScreen token={token} amount={amount.toFixed()} onConfirm={onConfirm} onCancel={onClose} />
        ),
        [DepositStages.SignIn]: <SignInScreen />,
        [DepositStages.InProgress]: (
            <InProgressScreen messages={messages} ethNetwork={ethNetwork} transactionId={transactionId} />
        ),
        [DepositStages.Result]: <ResultScreen messages={messages} onFinish={onClose} />,
    };

    if (!hasTokensList || !client) {
        return null;
    }

    return (
        <DepositBalanceProvider>
            <StandardLinkRouteContainer>{stageElements[stage]}</StandardLinkRouteContainer>
        </DepositBalanceProvider>
    );
};

export default FlexibleDeposit;
