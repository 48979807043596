import { colors, ParagraphText, SectionHeading, StandardButton, VerticalSpace } from '@imtbl/design-system';
import { ERC20TokenType, ExchangeProvider, ImmutableExchangeTypeV3 } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';
import React, { useEffect, useState } from 'react';

import { useL2Balance } from '../../../hooks/useL2Balance.hook';
import { TokenDataType, TokensListType } from '../../../hooks/useTokensList.hook';
import {
    DispatchSetLoading,
    ExchangeCurrencyLimits,
    getExchangeCurrencyLimits,
    LinkConfig,
    syncValidateAmount,
} from '../../../lib';
import { Balance } from '../../Balance';
import { CancelLink, CurrencySelectBox, PriceInput, StandardLinkRouteButtonsFooter } from '../../common';

export type OfframpFormProps = {
    config: LinkConfig;
    availableTokens: TokensListType;
    selectedToken?: TokenDataType;
    user: string;
    onConfirm: (amount: BigNumber, token: TokenDataType) => void;
    onCancel: () => void;
    loading: boolean;
    setLoading: DispatchSetLoading;
    providerName: ExchangeProvider;
};

export const OfframpForm = ({
    availableTokens,
    onConfirm,
    onCancel,
    user,
    config,
    loading,
    selectedToken,
    providerName,
}: OfframpFormProps) => {
    const [currencyLimits, setCurrencyLimits] = useState<Record<string, ExchangeCurrencyLimits>>();
    const [token, setToken] = useState<TokenDataType>(selectedToken);
    const [amount, setAmount] = useState<BigNumber>(new BigNumber(0));
    const [nextAllowed, setNextAllowed] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const balance = useL2Balance(config.client, user, token?.token_address, token?.decimals);

    const onChangeToken = (newToken: TokenDataType) => {
        setToken(newToken);
    };

    useEffect(() => {
        getExchangeCurrencyLimits(providerName, ImmutableExchangeTypeV3.offramp, config)
            .then(setCurrencyLimits)
            .catch(() => {
                setErrorMessage('Network error');
            });
    }, []);

    useEffect(() => {
        if (currencyLimits && token && balance) {
            const errors = syncValidateAmount(currencyLimits, amount, token.symbol, balance);

            setNextAllowed(errors.length === 0);

            if (errors.length > 0) {
                setErrorMessage(errors[0].message);
            }
        }
    }, [currencyLimits, amount, token, balance]);

    return (
        <>
            <VerticalSpace bottom="small" top="x-large">
                <SectionHeading testId="heading">Withdraw</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Withdraw your funds into your bank account
            </ParagraphText>
            <VerticalSpace top="large">
                <CurrencySelectBox
                    testId="selectedToken"
                    tokens={availableTokens}
                    selectedToken={selectedToken?.token_address}
                    onSelect={onChangeToken}
                    disabled={typeof selectedToken !== 'undefined'}
                />
                <VerticalSpace top="large">
                    <ParagraphText fillColor={colors.light[700]} fontSize="small" fontWeight="bold">
                        Available balance:
                    </ParagraphText>
                    <Balance
                        testId="totalAmount"
                        fontSize="normal"
                        type={ERC20TokenType.ERC20}
                        amount={balance ? balance.toString() : '-'}
                        tokenImage={token?.image_url || ''}
                    />
                </VerticalSpace>
                <VerticalSpace top="large">
                    <PriceInput
                        token={token}
                        onEnter={setAmount}
                        minPrice={0}
                        disabled={!token || loading}
                        displayHelp={false}
                        errorMsg={errorMessage}
                        isValid={nextAllowed}
                    />
                </VerticalSpace>
            </VerticalSpace>
            <StandardLinkRouteButtonsFooter>
                <StandardButton
                    testId="buttonNext"
                    buttonKind="ultimate-cta"
                    onClick={() => onConfirm(amount, token)}
                    disabled={!nextAllowed || loading}
                >
                    NEXT
                </StandardButton>
                <CancelLink title="Cancel" onClick={onCancel} />
            </StandardLinkRouteButtonsFooter>
        </>
    );
};
