export const NUMBER_OF_SIGNIFICANT_DIGITS = 18;
export const DEFAULT_DECIMALS = 18;

export const BurnEthAddress = '0x0000000000000000000000000000000000000000';
export const DEFAULT_ETH_TOKEN_IMAGERY = 'https://design-system.immutable.com/currency_icons/currency--eth.svg';
export const DEFAULT_ERC20_TOKEN_IMAGERY = 'https://design-system.immutable.com/currency_icons/currency--erc20.svg';
export const DEFAULT_IMX_TOKEN_IMAGERY = 'https://design-system.immutable.com/currency_icons/currency--imx.svg';
export const DEFAULT_USDC_TOKEN_IMAGERY = 'https://design-system.immutable.com/currency_icons/currency--usdc.svg';
export const DEFAULT_GODS_TOKEN_IMAGERY = 'https://design-system.immutable.com/currency_icons/currency--gods.svg';

export const BUFFER_FEE = 10;
