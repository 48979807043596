/* eslint-disable @typescript-eslint/no-shadow */
import { colors, FlexLayout, ParagraphText, SectionHeading, StandardButton, VerticalSpace } from '@imtbl/design-system';
import { errorsToError, LINK_MESSAGE_TYPE, messageTypes } from '@imtbl/imx-sdk';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { Errors } from 'io-ts';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { ErrorWithCode } from '../../errors';
import { messagesIndex } from '../../i18n';
import { DispatchSetError, NotifyMethod, ParentWindow } from '../../lib';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { getLocationSearch } from '../../utils/location';
import { CancelLink, StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import { HelperMessageBox, HelperMessageType } from '../HelperMessageBox';

const messages = messagesIndex.en;

export type ActionProps<P, I, O> = {
    type?: string;
    parent: ParentWindow;
    title: {
        start: string;
        executing?: string;
        inProgress?: string;
        done: string;
    };
    paramsDecoder: (x: any) => E.Either<Errors, P>;
    loadInput: (params: P) => TE.TaskEither<Error, I>;
    execute: (input: I, notify: NotifyMethod) => TE.TaskEither<ErrorWithCode, O>;
    showDetails: {
        start: (input: I) => JSX.Element;
        executing?: (input: I) => JSX.Element;
        inProgress?: (input: I, tx: string) => JSX.Element;
        done: (input: I) => JSX.Element;
    };
    helperMessageType?: HelperMessageType;
    // NOTE: Below is to indicate that the markup in 'showDetails' contains
    // nested 'SectionHeading'. This skips the 'title' prop from being used.
    hasTitleInDetails?: {
        start?: boolean;
        executing?: boolean;
        inProgress?: boolean;
        done?: boolean;
    };
    setErrorLog: DispatchSetError;
    // Callback function triggered, when the user clicks on the 'Finish' button.
    // NOTE: 'window.close()' is triggered right after this callback is executed!
    onFinishClick?: () => void;
    // Callback function triggered, when the user clicks on the 'Deny' button.
    // NOTE: 'setErrorLog()' is triggered right after this callback is executed!
    onDenyClick?: () => void;
};

export const actionDeniedError = new Error('Action denied');

export function Action<P, I, O>({
    parent,
    title,
    loadInput,
    execute,
    showDetails,
    hasTitleInDetails,
    paramsDecoder,
    type,
    setErrorLog,
    onDenyClick,
    onFinishClick,
    helperMessageType,
}: ActionProps<P, I, O>) {
    const [input, setInput] = useState<I>();

    const [progress, setProgress] = useState<{ msg: string; tx?: string }>({ msg: '' });
    const [result, setResult] = useState<boolean>(false);

    const loadInputC = useCallback(loadInput, []); // eslint-disable-line react-hooks/exhaustive-deps
    const executeC = useCallback(execute, []); // eslint-disable-line react-hooks/exhaustive-deps
    const showDetailsStartC = useCallback(showDetails.start, []); // eslint-disable-line react-hooks/exhaustive-deps
    const showDetailsExecutingC = useCallback(showDetails.executing || showDetails.start, []); // eslint-disable-line react-hooks/exhaustive-deps
    const showDetailsInProgressC = useCallback(showDetails.inProgress || showDetails.done, []); // eslint-disable-line react-hooks/exhaustive-deps
    const showDetailsDoneC = useCallback(showDetails.done, []); // eslint-disable-line react-hooks/exhaustive-deps
    const paramsDecoderC = useCallback(paramsDecoder, []); // eslint-disable-line react-hooks/exhaustive-deps

    const flags = useLaunchDarklyFlags();
    const showDailyPointsHelperBox = flags[FEATURE_FLAG.SHOW_DAILYPOINT_SUPPORT_HELPER];

    const helperMessageTypeIsConfirm = helperMessageType === HelperMessageType.IS_CONFIRM;
    const helperMessageTypeIsCancel = helperMessageType === HelperMessageType.IS_CANCEL;
    const onFinishClickCallback = useCallback(() => {
        if (onFinishClick) {
            onFinishClick();
        }

        // NOTE: Need to prevent the window from closing so,
        // as the triggered analytics events can be tested!
        // (This is only an issue in the test environment).
        if (process.env.NODE_ENV !== 'test') {
            parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
        }
    }, [onFinishClick, parent]);
    const onDenyClickCallback = useCallback(() => {
        if (onDenyClick) {
            onDenyClick();
        }

        setErrorLog(actionDeniedError, messages.actionDenied);
    }, [onDenyClick, setErrorLog]);

    useEffect(() => {
        const run = pipe(
            queryString.parse(getLocationSearch()),
            paramsDecoderC,
            E.mapLeft(errorsToError),
            TE.fromEither,
            TE.chain(loadInputC),
            TE.fold(
                (e) =>
                    T.fromIO(() => {
                        setErrorLog(e, messages.generalErrorMessage([e.message]));
                    }),
                (input) => T.fromIO(() => setInput(input)),
            ),
        );

        run();
    }, [paramsDecoderC, loadInputC, setErrorLog]);

    const pickError = (code?: string | number, message = 'Something went wrong') => {
        let error;
        switch (code) {
            case 'INSUFFICIENT_FUNDS':
                error = messages.generalInsufficientFunds;
                break;
            case 4001:
                error = messages.actionDenied;
                break;
            case messages.deposit.failedAPIRequest.code:
            case messages.buy.failedAPIRequest.code:
            case messages.sell.failedAPIRequest.code:
            case messages.transfer.failedAPIRequest.code:
                error = messages.generalErrorMessage([message]);
                break;
            default:
        }
        return error;
    };

    const handleExecute = async (input: I) => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.inProgress }, '*');
        await pipe(
            executeC(input, setProgress),
            TE.fold(
                (e) => {
                    return T.fromIO(() => {
                        setErrorLog(e, pickError(e.code, e.message));
                        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
                    });
                },
                (response) =>
                    T.fromIO(() => {
                        setResult(true);
                        const obj = JSON.parse(
                            JSON.stringify({ type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: response }),
                        );
                        parent.postMessage(obj, '*');
                    }),
            ),
        )();
    };

    const Body = () => {
        if (result && input)
            return (
                <>
                    {!hasTitleInDetails?.done && (
                        <VerticalSpace bottom="small">
                            <SectionHeading testId="heading">{title.done}</SectionHeading>
                        </VerticalSpace>
                    )}
                    {showDetailsDoneC(input)}
                    <StandardLinkRouteButtonsFooter>
                        {helperMessageTypeIsConfirm && showDailyPointsHelperBox && (
                            <VerticalSpace bottom="small">
                                <FlexLayout flexDirection="column">
                                    <HelperMessageBox helperMessageType={helperMessageType} />
                                </FlexLayout>
                            </VerticalSpace>
                        )}
                        <VerticalSpace bottom="small">
                            <StandardButton testId="close" buttonKind="ultimate-cta" onClick={onFinishClickCallback}>
                                {messages.standardUiButtons.continue}
                            </StandardButton>
                        </VerticalSpace>
                    </StandardLinkRouteButtonsFooter>
                </>
            );
        if (progress.msg === 'Waiting for receipt' && progress.tx && input)
            return (
                <>
                    {!hasTitleInDetails?.inProgress && (
                        <VerticalSpace bottom="small">
                            <SectionHeading testId="heading">{title.inProgress || title.done}</SectionHeading>
                        </VerticalSpace>
                    )}
                    {showDetailsInProgressC(input, progress.tx)}
                </>
            );
        if (progress.msg && input)
            return (
                <>
                    {!hasTitleInDetails?.executing && (
                        <VerticalSpace bottom="small">
                            <SectionHeading testId="heading">{title.executing || title.start}</SectionHeading>
                        </VerticalSpace>
                    )}
                    {showDetailsExecutingC(input)}
                    <StandardLinkRouteButtonsFooter>
                        {type === 'deposit' ? (
                            <></>
                        ) : (
                            <>
                                <StandardButton
                                    testId="action"
                                    buttonKind="ultimate-cta"
                                    onClick={() => handleExecute(input)}
                                    disabled
                                >
                                    CONFIRM
                                </StandardButton>
                                <CancelLink testId="deny" onClick={onDenyClickCallback} title="Cancel" />
                            </>
                        )}
                    </StandardLinkRouteButtonsFooter>
                </>
            );
        if (input)
            return (
                <>
                    {!hasTitleInDetails?.start && (
                        <VerticalSpace bottom="small">
                            <SectionHeading testId="heading">{title.start}</SectionHeading>
                        </VerticalSpace>
                    )}
                    {showDetailsStartC(input)}
                    {(helperMessageTypeIsConfirm || helperMessageTypeIsCancel) && showDailyPointsHelperBox && (
                        <VerticalSpace bottom="small">
                            <FlexLayout flexDirection="column">
                                <HelperMessageBox helperMessageType={helperMessageType} />
                            </FlexLayout>
                        </VerticalSpace>
                    )}
                    <StandardLinkRouteButtonsFooter>
                        <StandardButton testId="action" buttonKind="ultimate-cta" onClick={() => handleExecute(input)}>
                            {messages.standardUiButtons.confirm}
                        </StandardButton>
                        <CancelLink testId="deny" onClick={onDenyClickCallback} title="Cancel" />
                    </StandardLinkRouteButtonsFooter>
                </>
            );
        return (
            <VerticalSpace bottom="small" top="2x-large">
                <ParagraphText fillColor={colors.light[300]}>Loading...</ParagraphText>
            </VerticalSpace>
        );
    };

    return (
        <StandardLinkRouteContainer>
            <Body />
        </StandardLinkRouteContainer>
    );
}
