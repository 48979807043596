import {
    CardShapedWalletStatus,
    colors,
    ParagraphText,
    SectionHeading,
    StandardButton,
    StyledLink,
    VerticalSpace,
} from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
import React, { useEffect } from 'react';

import { sendAnalytics } from '../../../lib/analytics/send-analytics';
import { ButtonEventName, ScreenEventName } from '../../../lib/analytics/types';
import { createButtonEvent, createScreenEvent, removeWalletIdFromLocalStorage } from '../../../lib/analytics/utils';
import useProviderPreference from '../../../lib/useProviderPreference';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../../common';
import DifferentWalletButton from '../../SetupMagicWallet/DifferentWalletButton';

export const METAMASK_URL_HREF = 'https://metamask.io';
export const GAMESTOP_URL_HREF = 'https://wallet.gamestop.com/';

type WalletLinkProps = {
    title: string;
    url: string;
    testId: string;
};

function WalletLink({ title, url, testId }: WalletLinkProps) {
    return (
        <StandardLinkRouteButtonsFooter>
            <StandardButton
                testId={testId}
                buttonKind="ultimate-cta"
                onClick={() => {
                    // NOTE: Tests are not required for non-critical analytics events.
                    sendAnalytics(createButtonEvent(ButtonEventName.setupNoneGamestopPressed));
                    window.open(url, '_blank');
                }}
            >
                {title}
            </StandardButton>
            <DifferentWalletButton analyticsEvent={ButtonEventName.selfCustodialGamestopChangePressed} />
        </StandardLinkRouteButtonsFooter>
    );
}

export const MissingWallet = () => {
    const { providerPreference } = useProviderPreference();
    useEffect(() => {
        removeWalletIdFromLocalStorage();
        // NOTE: Tests are not required for non-critical analytics events.
        sendAnalytics(createScreenEvent(ScreenEventName.setupNoneOpened));
    }, []);

    return (
        <StandardLinkRouteContainer>
            <CardShapedWalletStatus testId="missing-wallet" />

            <VerticalSpace bottom="large">
                <SectionHeading>Create a wallet</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]}>
                You&apos;ll need an <strong style={{ color: colors.light[100] }}>Ethereum wallet</strong> to trade on
                Immutable&nbsp;X.
            </ParagraphText>
            <VerticalSpace top="large" bottom="large">
                <StyledLink
                    fillColor={colors.light[700]}
                    fontWeight="bold"
                    href="https://support.immutable.com/hc/en-us/articles/360062010414"
                    target="_blank"
                >
                    Learn more about how to create one.
                </StyledLink>
            </VerticalSpace>

            {providerPreference === ProviderPreference.GAMESTOP ? (
                <WalletLink title="VISIT GAMESTOP" url={GAMESTOP_URL_HREF} testId="gamestop-button" />
            ) : (
                <WalletLink title="VISIT METAMASK.IO" url={METAMASK_URL_HREF} testId="metamask-button" />
            )}
        </StandardLinkRouteContainer>
    );
};
