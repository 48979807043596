import { BaseComponentPropTypes } from '@imtbl/design-system';
import { LocalStorageKeys, ProviderPreference } from '@imtbl/imx-sdk';
import React from 'react';

import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../lib/analytics/types';
import { createButtonEvent } from '../../lib/analytics/utils';
import { InternalRoutes } from '../../utils/internalRoutes';
import FooterLink from '../FooterLink';

const DifferentWalletButton = ({
    analyticsEvent,
    testId,
}: { analyticsEvent: ButtonEventName } & Pick<BaseComponentPropTypes, 'testId'>) => {
    const handleClick = () => {
        window.localStorage.setItem(LocalStorageKeys.PROVIDER_PREFERENCE, ProviderPreference.NONE);
        sendAnalytics(createButtonEvent(analyticsEvent));
    };

    return (
        <FooterLink testId={testId} onClick={handleClick} to={InternalRoutes.MISSING_WALLET}>
            Use a different wallet
        </FooterLink>
    );
};

export default DifferentWalletButton;
