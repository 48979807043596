import { Web3Provider } from '@ethersproject/providers';
import {
    ERC20TokenType,
    ERC721TokenType,
    errorsToError,
    ImmutableMethodParams,
    ImmutableXClient,
    taskEitherWithError,
} from '@imtbl/imx-sdk';
import * as E from 'fp-ts/Either';
import { constVoid, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

import { sendAnalytics } from './analytics/send-analytics';
import { FlowEventName, ScreenEventName } from './analytics/types';
import { createFlowEvent, createScreenEvent } from './analytics/utils';
import { NotifyMethod, TokenWithAmount } from './types';
import { waitForTransaction } from './utils';

export function deposit(
    client: ImmutableXClient,
    provider: Web3Provider,
    params: TokenWithAmount,
    notify: NotifyMethod,
): TE.TaskEither<Error, void> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.chainFirst(() => {
            const { token } = params;
            return token.type === ERC721TokenType.ERC721
                ? pipe(
                      TE.fromIO<Error, void>(() => notify({ msg: 'Preparing NFT for deposit' })),
                      TE.chain(() =>
                          pipe(
                              ImmutableMethodParams.ImmutableStarkApproveNFTParamsCodec.decode({
                                  tokenAddress: token.data.tokenAddress,
                                  tokenId: token.data.tokenId,
                              }),
                              E.mapLeft(errorsToError),
                              TE.fromEither,
                          ),
                      ),
                      TE.chain(client.approveNFTF),
                      TE.chain(waitForTransaction(provider)),
                  )
                : token.type === ERC20TokenType.ERC20
                ? pipe(
                      TE.fromIO<Error, void>(() => notify({ msg: 'Preparing ERC20 for deposit' })),
                      TE.bind('hasAllowance', () =>
                          taskEitherWithError(() =>
                              client.hasERC20Allowance(token.data.tokenAddress, params.quantity, client.address),
                          ),
                      ),
                      TE.chain(({ hasAllowance }) =>
                          !hasAllowance
                              ? pipe(
                                    pipe(
                                        ImmutableMethodParams.ImmutableStarkApproveERC20ParamsCodec.decode({
                                            tokenAddress: token.data.tokenAddress,
                                            amount: params.quantity,
                                        }),
                                        E.mapLeft(errorsToError),
                                        TE.fromEither,
                                    ),
                                    TE.chain(client.approveERC20F),
                                    TE.chain(waitForTransaction(provider)),
                                )
                              : TE.of<Error, undefined>(undefined),
                      ),
                  )
                : TE.of<Error, undefined>(undefined);
        }),
        TE.chainFirst(() => TE.fromIO(() => notify({ msg: 'Getting signable deposit' }))),
        TE.bind('txHash', () =>
            client.depositF({
                user: client.address,
                token: params.token,
                quantity: params.quantity,
            }),
        ),
        TE.chainFirst(({ txHash }) =>
            TE.fromIO(() => {
                notify({ msg: 'Waiting for receipt', tx: txHash });
            }),
        ),
        TE.bind('receipt', ({ txHash }) => waitForTransaction(provider)(txHash)),
        TE.chainFirst(() =>
            TE.fromIO(() => {
                sendAnalytics(
                    // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
                    createScreenEvent(ScreenEventName.depositConfirmedOpened),
                    createFlowEvent(FlowEventName.depositSucceeded),
                );
            }),
        ),
        TE.map(constVoid),
    );
}
