import { EthAddress, ImmutableMethodResults, ImmutableXClient } from '@imtbl/imx-sdk';
import { useContext, useEffect, useMemo, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { LinkClientConfig } from '../lib';

export type Asset = ImmutableMethodResults.ImmutableAsset | undefined;

export interface UseTokensListResponse {
    asset: Asset;
    error?: Error;
}

export interface UseGetAssetPropTypes {
    config: LinkClientConfig;
    id: string;
    address: EthAddress;
}

export function useGetAsset({ id, address, config }: UseGetAssetPropTypes): UseTokensListResponse {
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const [apiResult, setApiResult] = useState<Asset>();
    const [apiError, setApiError] = useState<Error>();
    const { publicApiUrl } = config;

    useEffect(() => {
        async function getAsset() {
            try {
                const client = await ImmutableXClient.build({
                    publicApiUrl,
                });

                const asset = await client.getAsset({ id, address, include_fees: true });
                if (asset) {
                    setApiResult(asset);
                } else {
                    setApiError(new Error('Could not get asset.'));
                }
            } catch (err) {
                console.error('API error! Could not get asset. :(', err);
                setApiError(new Error('Could not get asset.'));
                setErrorLog(err);
            }
        }

        getAsset();
    }, [publicApiUrl]);

    /**
     * NOTE: The tokens data is static, so there is no need to
     * re-fetch, enables passing below objects as dependencies.
     */
    const error = useMemo(() => apiError, [apiError?.message]);
    const asset = useMemo(() => apiResult, [apiResult]);
    return useMemo(() => ({ asset, error }), [asset, error]);
}
