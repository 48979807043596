import { FlatTokenWithAmountAndToAddress, ImmutableXClient, LinkParams, valueOrThrowTE } from '@imtbl/imx-sdk';
import { useContext, useEffect, useState } from 'react';

import { LinkUiCoreContext } from '../context/App.context';
import { getTokenWithDetails } from '../lib';
import { TransferInfoWithTokenInfo } from '../types/SharedTransfer.types';

export const useGetBatchNftTransferTokenDetails = ({
    client,
    params,
}: {
    client?: ImmutableXClient;
    params?: LinkParams.BatchNftTransfer;
}) => {
    const [transferDetails, setTransferDetails] = useState<TransferInfoWithTokenInfo[]>();
    const { setErrorLog } = useContext(LinkUiCoreContext);

    // @NOTE: For each order, get all the token details:
    useEffect(() => {
        async function getAllOrdersTokenInfo() {
            if (!client || !params) {
                return;
            }
            try {
                const transferTokenDetailsResponse = await Promise.all(
                    [
                        ...params.map((token) => {
                            return [
                                valueOrThrowTE(getTokenWithDetails(token as FlatTokenWithAmountAndToAddress)(client)),
                            ];
                        }),
                    ].flat(),
                );
                const mergeResponseWithParams = transferTokenDetailsResponse.map((token, index) => ({
                    tokenDetails: {
                        ...params[index],
                        ...token,
                    },
                    originalParams: params[index],
                }));
                setTransferDetails(mergeResponseWithParams);
            } catch (err) {
                setErrorLog(err);
            }
        }

        getAllOrdersTokenInfo();
    }, [params, client, setErrorLog]);

    return transferDetails;
};
