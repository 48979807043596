import { Web3Provider } from '@ethersproject/providers';
import { ImmutableXClient, LINK_MESSAGE_TYPE, messageTypes, valueOrThrowTE } from '@imtbl/imx-sdk';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { TransferCriticalErrorDetailsScreen } from '../components/TransferV2/TransferCriticalFailureDetailsScreen.component';
import { LinkUiCoreContext } from '../context/App.context';
import { isServiceUnavailable, LinkClientConfig, NotifyMethod, ParentWindow, transferV2 } from '../lib';
import { SuccessOrError } from '../types/SharedOrder.types';
import { FullTransferInfoWithFinalStatus, FullTransferTokenInfo, TransferResults } from '../types/SharedTransfer.types';
import { useI18nMessaging } from './useI18nMessaging.hook';

export type UseMakeTransferTransactionPropTypes = {
    config?: LinkClientConfig;
    transferDetails?: FullTransferTokenInfo[];
    notify?: NotifyMethod;
    parent: ParentWindow;
    provider: Web3Provider;
};

export const useMakeTransferTransaction = ({
    provider,
    parent,
    config,
    transferDetails,
    notify = () => ({}),
}: UseMakeTransferTransactionPropTypes) => {
    const [transferResults, setTransferResults] = useState<TransferResults>([]);
    const [finalConsolidatedTransfers, setFinalConsolidatedTransfers] = useState<FullTransferInfoWithFinalStatus[]>([]);
    const [transacting, setTransacting] = useState(false);
    const { setErrorLog } = useContext(LinkUiCoreContext);
    const transferTextMessaging = useI18nMessaging().transfer;
    const textMessages = useI18nMessaging();

    const beginTransferTransaction = useCallback(async () => {
        if (config && transferDetails) {
            setTransacting(true);
            parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.inProgress }, '*');
            try {
                // @NOTE: only send off the validated transfers, the rest can just be messaged as
                // failed in the final transfer2 screen and promise response object
                const validTransfers = transferDetails.filter(
                    (transfer) => transfer.validationResult.status === 'success',
                );

                // @NOTE: If there are zero valid transfers, then we treat this as a critical failure,
                // and message accordingly:
                if (validTransfers.length === 0) {
                    setErrorLog(new Error(transferTextMessaging.noValidTransfers), {
                        heading: transferTextMessaging.validationFailedErrorContent.heading,
                        body: (
                            <TransferCriticalErrorDetailsScreen
                                firstParagraph={transferTextMessaging.validationFailedErrorContent.introParagraph}
                                transferDetails={transferDetails}
                            />
                        ),
                    });
                    parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
                    return;
                }
                const invalidTransfers: FullTransferTokenInfo[] = transferDetails.filter(
                    (transfer) => transfer.validationResult.status !== 'success',
                );
                const client = await ImmutableXClient.build({
                    ...config,
                    signer: provider.getSigner(),
                });
                const transferResponse: FullTransferInfoWithFinalStatus[] = await Promise.all(
                    validTransfers.map(async (transfer) => {
                        try {
                            const transaction = await valueOrThrowTE(transferV2(client, transfer, notify));
                            return await Promise.resolve({
                                ...transfer,
                                transferTransactionStatus: {
                                    status: 'success' as SuccessOrError,
                                    txId: transaction.transfer_id,
                                },
                            });
                        } catch (err) {
                            const error = err instanceof Error ? err : new Error(String(err));

                            // @NOTE: if the service is unavailable it doesn't make sense to try others more
                            if (isServiceUnavailable(error)) throw error;

                            return Promise.resolve({
                                ...transfer,
                                transferTransactionStatus: {
                                    status: 'error' as SuccessOrError,
                                    message: error.message,
                                },
                            });
                        }
                    }),
                );
                const finalTransferResults: FullTransferInfoWithFinalStatus[] = [
                    ...transferResponse,
                    ...invalidTransfers,
                ];
                setFinalConsolidatedTransfers(finalTransferResults);

                // @NOTE: create a very simple report describing which transfers failed / passed:
                const simplifiedTransferReport: TransferResults = finalTransferResults.map((transfer) => ({
                    ...transfer.originalParams,
                    status:
                        (transfer.transferTransactionStatus?.status as SuccessOrError) ||
                        transfer.validationResult.status,
                    ...(transfer.transferTransactionStatus?.status === 'success'
                        ? { txId: transfer.transferTransactionStatus?.txId }
                        : {
                              message:
                                  transfer.transferTransactionStatus?.message || transfer.validationResult?.message,
                          }),
                }));

                // @NOTE: If we submitted any transfers to API, and they ALL happened to fail
                // Then we treat this as a critical failure, and message accordingly:
                const completeFailure = !finalTransferResults.find(
                    (transfer) => transfer.transferTransactionStatus?.status === 'success',
                );
                if (completeFailure) {
                    setErrorLog(new Error(transferTextMessaging.allAttemptsFailed), {
                        heading: transferTextMessaging.criticalApiFailureErrorContent.heading,
                        body: (
                            <TransferCriticalErrorDetailsScreen
                                firstParagraph={transferTextMessaging.criticalApiFailureErrorContent.introParagraph}
                                transferDetails={finalTransferResults}
                            />
                        ),
                    });
                    parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
                    return;
                }
                setTransferResults(simplifiedTransferReport);
            } catch (err) {
                parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.fail }, '*');
                setErrorLog(
                    err,
                    textMessages.generalErrorMessage([textMessages.transfer.failedAPIRequest.message(err.message)]),
                );
            }
        }
    }, [config, transferDetails, transferTextMessaging, provider, setErrorLog]);

    useEffect(() => {
        if (transferResults.length) {
            parent.postMessage(
                { type: LINK_MESSAGE_TYPE, message: messageTypes.success, data: { result: transferResults } },
                '*',
            );
            setTransacting(false);
        }
    }, [transferResults, parent]);

    return { transferResults, transacting, beginTransferTransaction, finalConsolidatedTransfers };
};
