import { BaseComponentPropTypes, ImxHorizontalCondensedAssetTile, PriceEffect } from '@imtbl/design-system';
import {
    ERC20TokenType,
    ERC721TokenType,
    ETHTokenType,
    MintableERC20TokenType,
    MintableERC721TokenType,
} from '@imtbl/imx-sdk';
import React, { ReactNode, useContext } from 'react';

import { LinkUiCoreContext } from '../../context/App.context';
import { TokenDataType } from '../../hooks/useTokensList.hook';
import { TokenAsset } from '../../lib';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../utils/constants';

type TokenDetailDisplayRowPropTypes = BaseComponentPropTypes & {
    tokenType: ETHTokenType | ERC20TokenType | ERC721TokenType | MintableERC20TokenType | MintableERC721TokenType;
    asset?: TokenAsset;
    tokenMetadata?: TokenDataType;
    tokenDisplayAmount?: string;
    transactionCostTokenImage?: string;
    tokenDisplayPriceEffect?: PriceEffect;
    additionalNoteText?: ReactNode;
    hideTokenIconImage?: boolean;
};

export const TokenDetailsDisplayRow = ({
    className,
    testId,
    tokenType,
    asset,
    tokenMetadata,
    tokenDisplayAmount,
    tokenDisplayPriceEffect,
    additionalNoteText,
    transactionCostTokenImage = DEFAULT_ETH_TOKEN_IMAGERY,
    hideTokenIconImage = false,
}: TokenDetailDisplayRowPropTypes) => {
    const { setErrorLog, imageResizerServiceUrl } = useContext(LinkUiCoreContext);

    switch (tokenType) {
        case ETHTokenType.ETH:
            return (
                <ImxHorizontalCondensedAssetTile
                    className={className}
                    testId={testId}
                    transactionPrice={tokenDisplayAmount}
                    primaryText="ETH Token"
                    secondaryText="Ethereum"
                    priceEffect={tokenDisplayPriceEffect}
                    additionalNote={additionalNoteText}
                    assetImagery={DEFAULT_ETH_TOKEN_IMAGERY}
                    transactionCostTokenImage={transactionCostTokenImage}
                    imageResizeServiceUrl={imageResizerServiceUrl}
                    isCurrency
                />
            );

        case ERC20TokenType.ERC20:
            return (
                <ImxHorizontalCondensedAssetTile
                    testId={testId}
                    className={className}
                    transactionPrice={tokenDisplayAmount}
                    primaryText={`${tokenMetadata?.symbol} Token`}
                    secondaryText={tokenMetadata?.name || undefined}
                    priceEffect={tokenDisplayPriceEffect}
                    additionalNote={additionalNoteText}
                    assetImagery={tokenMetadata?.image_url || undefined}
                    transactionCostTokenImage={transactionCostTokenImage}
                    imageResizeServiceUrl={imageResizerServiceUrl}
                    isCurrency
                />
            );

        case ERC721TokenType.ERC721:
            return (
                <ImxHorizontalCondensedAssetTile
                    className={className}
                    transactionCostTokenImage={transactionCostTokenImage}
                    testId={testId}
                    assetImagery={asset?.image_url || undefined}
                    transactionPrice={tokenDisplayAmount}
                    primaryText={asset?.name || ''}
                    secondaryText={asset?.collection.name}
                    additionalNote={additionalNoteText}
                    imageResizeServiceUrl={imageResizerServiceUrl}
                    hideTransactionCostImage={hideTokenIconImage}
                />
            );

        default:
            setErrorLog(`The token type: ${tokenType} is not currently implemented`);
            return null;
    }
};
