import { BaseComponentPropTypes, colors, ParagraphText, SectionHeading, VerticalSpace } from '@imtbl/design-system';
import React, { useContext } from 'react';

import { LinkCoreContext } from '../../context/App.context';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { FullTransferInfoWithFinalStatus } from '../../types/SharedTransfer.types';
import { StandardLinkRouteContainer } from '../common/StandardLinkUiContainers';
import { ErrorMessage } from '../ErrorMessage';
import { TransferDetailsListing } from '../TransferV2/common.components';
import { BatchNftTransferHeader } from './BatchNftTransferHeader.component';
import { BatchNftTransferScrollView } from './BatchNftTransferScrollView.component';
import styles from './index.module.css';
import { getPositionsFromApiError, isApiError } from './lib';
import { ApiError, ApiErrorCode } from './lib/api';

export interface BatchNftTransferErrorPropTypes extends BaseComponentPropTypes {
    transferDetails: FullTransferInfoWithFinalStatus[];
    batchError: any;
    footer: React.ReactElement;
}

// @TODO: Alot of the below is a standard Link screen, so this should eventually be templatized in such a way
// that other components too, can use this exact same layout (albeit with their own actions etc)
export const BatchNftTransferErrorScreen = ({
    transferDetails,
    testId,
    batchError,
    footer,
}: BatchNftTransferErrorPropTypes) => {
    const textMessages = useI18nMessaging({});
    const { parent } = useContext(LinkCoreContext);

    if (!isApiError(batchError)) {
        // If there is an unexpected err, display default critical screen. Don't map errors.
        // This can happen if the API returns an unexpected error as a string like "method not allowed"
        return (
            <StandardLinkRouteContainer testId={`${testId}__batchNftTransferError__unexpectedError`}>
                <ErrorMessage
                    parentWindow={parent}
                    heading={textMessages.batchTransfer.title.error}
                    body={
                        <>
                            {textMessages.generalError.body}
                            {footer}
                        </>
                    }
                />
            </StandardLinkRouteContainer>
        );
    }

    const apiError = JSON.parse((batchError as ApiError).message);

    if (apiError.code === ApiErrorCode.InternalServerError) {
        return (
            <StandardLinkRouteContainer testId={`${testId}__batchNftTransferError__internalError`}>
                <ErrorMessage
                    parentWindow={parent}
                    heading={textMessages.batchTransfer.title.error}
                    body={
                        <>
                            {textMessages.generalError.body}
                            {footer}
                        </>
                    }
                />
            </StandardLinkRouteContainer>
        );
    }

    const errorPositions = getPositionsFromApiError(apiError);
    const errorTransfers = transferDetails.filter((_, index) => errorPositions.includes(index));
    return (
        <div className={styles.screenContainer}>
            <BatchNftTransferHeader testId={`${testId}__batchNftTransferError__header`}>
                <VerticalSpace bottom="small">
                    <SectionHeading fillColor={colors.red[300]} testId={`${testId}__batchNftTransferError__heading`}>
                        {textMessages.batchTransfer.title.error}
                    </SectionHeading>
                </VerticalSpace>
                <ParagraphText
                    fillColor={colors.light[300]}
                    fontSize="small"
                    testId={`${testId}__batchNftTransferError__intro`}
                >
                    {`${textMessages.batchTransfer.bodyText.error} ${apiError.message}`}
                </ParagraphText>
                {errorPositions.length > 0 && (
                    <VerticalSpace top="x-large">
                        <ParagraphText
                            fontWeight="bold"
                            fillColor={colors.yellow[100]}
                            fontSize="small"
                            testId={`${testId}__batchNftTransferError__errorItemsText`}
                        >
                            {textMessages.batchTransfer.errorItems}
                        </ParagraphText>
                    </VerticalSpace>
                )}
            </BatchNftTransferHeader>

            {errorPositions.length > 0 && (
                <BatchNftTransferScrollView>
                    <TransferDetailsListing
                        testId={`${testId}__batchNftTransferError__errorItems`}
                        transfers={errorTransfers}
                    />
                </BatchNftTransferScrollView>
            )}
            {footer}
        </div>
    );
};
