import React from 'react';

import { ErrorMessageProps } from '../components';
import { ActionDenied } from '../components/ErrorMessage/ActionDenied';
import { ChangeNetwork } from '../components/ErrorMessage/ChangeNetwork';
import { GeneralError } from '../components/ErrorMessage/GeneralError';
import { OfferMessages } from '../components/Offers/OfferFlowComplete.component';
import { EthNetwork } from '../lib';
import { Language } from './languages';

const generalErrorMessageEN = (messages: string[]): ErrorMessageProps => ({
    heading: 'Error',
    body: <GeneralError messages={messages} />,
});

const claimErrorMsg =
    'Your $IMX claim was temporarily unsuccessful due to the high volume of token claims. Your $IMX' +
    " is safe and you'll be able to claim it shortly - you can safely continue to retry until you are successful!";

const messagesEn = (generalErrorMessage: (messages: string[]) => ErrorMessageProps) => ({
    generalErrorMessage,
    failedToInstantiateSdkImxClient: 'Code 1000 - Something went wrong.',
    failedToRetrieveTokenList: (apiError?: string) =>
        `Code 1001 - Something went wrong retrieving your tokens list: ${apiError}`,
    failedToRetrieveWalletAddr:
        'Code 1002 - Something went wrong when retrieving your wallet address. Check with your wallet provider.',
    linkClosed: {
        code: 1003,
        message: 'Code 1003 - Link window closed.', // Used into the IMX SDK
    },
    storageUnavailable: {
        code: 1004,
        message:
            'Code 1004 - There is no storage available. This is usually related to a 3rd party cookie-blocking policy.',
    },
    /**
     * @deprecated Whitelist check removed since version prod-0.0.180
     */
    iFrameUnauthorized: {
        code: 1005,
        message: (address: string) =>
            `Code 1005 - Only whitelisted partners can currently embed link using an iframe. Please contact support@immutable.com and quote referrer "${address}" for information.`,
    },
    noMetamaskError: generalErrorMessage(['Ethereum provider not found, please install MetaMask']),
    walletUnavailableError: generalErrorMessage([
        'Seems like your wallet is not available. Please check your wallet extension.',
    ]),
    initializingMetamaskError: generalErrorMessage(['Problem initializing MetaMask']),
    routeNotFound: generalErrorMessage(['Route not found']),
    featureNotYetSupported: generalErrorMessage(['The feature you are trying to use is not yet supported']),
    notSupportedCurrency: generalErrorMessage(['Wrong currency parameter sent']),
    generalError: generalErrorMessage([
        'Something has gone wrong.',
        "Please check your wallet's connection and transaction status.",
    ]),
    userNotAssetOwner: 'Current user is not the owner of this asset',
    loadAssetError: 'Problem loading asset',
    insufficientEthFunds: 'Insufficient ETH funds',
    generalInsufficientFunds: generalErrorMessage(['Insufficient funds']),
    insufficientERC20Funds: (symbol?: string) => `Insufficient ERC20 ${symbol} funds`,
    insufficientFunds: 'Insufficient funds in Immutable X',
    assetNotDeposited: 'Asset not deposited to Immutable X',
    missingTokenData: (orderId?: any) => `Error: missing token data for orderId: ${orderId}`,
    unsupportedTokenType: (type: any) => `Unsupported token type: ${type}`,
    changeNetwork: (network: EthNetwork) => ({
        heading: 'Change network',
        body: <ChangeNetwork network={network} />,
    }),
    actionDenied: {
        heading: 'Action was denied',
        body: <ActionDenied />,
    },
    deposit: {
        title: {
            start: 'Deposit',
            executing: 'Sign the transaction...',
            inProgress: 'Deposit in Progress...',
            done: 'Deposit is on its way!',
        },
        assetAlreadyDeposited: 'Asset already deposited in Immutable X',
        minNumEth: 'Minimum deposit is 0.000001 ETH',
        minNumERC20: 'Minimum ERC20 deposit is 0.000001',
        walletAddressRetrievingFailed: {
            code: 2000,
            message:
                'Code 2000 - Something went wrong when retrieving your wallet address. Check with your wallet provider.',
        },
        invalidERC20Token: 'Code 2001 - Token not available in IMX.',
        insufficientFunds: 'Code 2002 - Insufficient funds.',
        failedAPIRequest: {
            code: 2003,
            message: (details: string) => `Code 2003 - The API rejected the deposit: ${details}`,
        },
    },
    signMessage: {
        title: 'Sign message',
        description: 'requested your signature:',
        errors: {
            title: 'Error',
            messages: {
                invalidMessage: 'Sorry, we cannot sign this message.',
            },
        },
    },
    transfer: {
        title: {
            start: 'Confirm transfer',
            partialDone: 'Transfer incomplete',
            done: 'Transfer complete',
        },
        bodyText: {
            start: 'Please confirm the assets to transfer:',
            partialDone: 'Only some of your items were transferred successfully.',
            done: 'Your transfer has been completed.',
        },
        assetAlreadyTransferred: 'Asset already transferred in Immutable X',
        ownAsset: 'Cannot transfer this asset becuase the toAddress already owns this asset',
        someItemsInvalid: 'The item(s) below could not be transferred:',
        noValidTransfers: 'There are no valid transfers to be transferred',
        assetsSentToLabel: 'Assets will be sent to',
        burn: {
            burnBodyTextMessage1: 'You are transferring assets to a ',
            burnTextLabel: 'burn address (0x00...0000).',
            burnBodyTextMessage2: 'Once transferred, these assets cannot be recovered.',
            burnTooltip:
                'A burn address is not owned by any user, and is used to permanently remove tokens from circulation',
        },
        allAttemptsFailed: 'All transfer attempts have failed',
        criticalApiFailureErrorContent: {
            heading: 'All transfers have failed by API',
            introParagraph:
                'While attempting to process these transfer request(s), we have found the following API errors.',
        },
        failedRetrievingTokenDetails: `Code 7000 - Something went wrong retrieving token details.`,
        failedRetrievingTokenBalance: (tokenType: string) =>
            `Code 7001 - Something went wrong retrieving your ${tokenType} token balance.`,
        validationFailedErrorContent: {
            heading: 'Code 7002 - Transfers have failed validation.',
            introParagraph: 'The following validation errors were identified:',
        },
        failedAPIRequest: {
            code: 7003,
            message: (details: string) => `Code 7003 - The API rejected the transfer: ${details}`,
        },
    },
    batchTransfer: {
        title: {
            start: 'Confirm transfer batch',
            invalid: 'Transfer batch invalid',
            partialDone: 'Transfer batch incomplete',
            done: 'Transfer batch complete!',
            error: 'Transfer batch error',
        },
        bodyText: {
            start: 'Please confirm the assets to transfer:',
            invalid: 'Batch unable to be processed.',
            partialDone: 'Only some of your items were transferred successfully.',
            done: 'Your transfer was successfully completed.',
            error: 'There was a problem with your transfer batch:',
        },
        assetAlreadyTransferred: 'Asset already transferred in Immutable X',
        ownAsset: 'Cannot transfer this asset becuase the toAddress already owns this asset',
        itemsInvalid: 'The item(s) below are invalid:',
        someItemsInvalid: 'The item(s) below could not be transferred:',
        errorItems: 'This error was caused by the following item(s):',
        noValidTransfers: 'There are no valid transfers to be transferred',
        assetsSentToLabel: 'Assets will be sent to',
        burn: {
            burnBodyTextMessage1: 'You are transferring assets to a ',
            burnTextLabel: 'burn address (0x00...0000).',
            burnBodyTextMessage2: 'Once transferred, these assets cannot be recovered.',
            burnTooltip:
                'A burn address is not owned by any user, and is used to permanently remove tokens from circulation',
        },
        allAttemptsFailed: 'All transfer attempts have failed',
        validationFailedErrorContent: {
            heading: 'All transfers have failed validation',
            introParagraph:
                'Before attempting to process these transfer request(s), we have found the following validation errors.',
        },
        criticalApiFailureErrorContent: {
            heading: 'All transfers have failed by API',
            introParagraph:
                'While attempting to process these transfer request(s), we have found the following API errors.',
        },
    },
    sell: {
        title: {
            start: 'List for sale',
            done: 'Listing confirmed',
        },
        minNum: 'Minimum sales is 0.000001 ETH',
        unableToRetrieveAssetDetails: 'Code 6000 - Something went wrong.',
        unableListAsset: 'Code 6001 - Unable to list asset.',
        unavailableAsset: 'Code 6002 - Asset is unavailable.',
        unknownCurrency: 'Code 6003 - Unknown currency.',
        belowMinPrice: 'Code 6004 - Price should be at least ',
        failedAPIRequest: {
            code: 6005,
            message: (details: string) => `Code 6005 - The API rejected the listing: ${details}`,
        },
    },
    setup: {
        error: {
            featureNotYetSupported: generalErrorMessage(['Code 11000 - This feature is not available yet.']),
        },
    },
    offramp: {
        title: {
            error: 'Error',
        },
        error: {
            featureNotYetSupported: generalErrorMessage(['Code 11000 - Something went wrong.']),
            statusFail: 'Code 11001 - Something went wrong.',
            connectionError: 'Code 11002 - Connection Error.',
            couldNotGetCurrencies: 'Code 11003 - Something went wrong.',
            unavailableCurrencies: generalErrorMessage(['Code 11004 - Currencies not available.']),
            invalidCurrency: generalErrorMessage(['Code 11005 - Invalid currency amount.']),
            incorrectTransactionFormat: 'Code 11006 - Something went wrong.',
        },
        moonpay: {
            inProgress: {
                title: 'Withdraw in progress',
                text: {
                    transfer: 'We are transferring your funds to Moonpay.',
                    wait: 'Please wait...',
                },
            },
            result: {
                title: 'Funds are on the way!',
                text: {
                    funds: 'Moonpay is processing your funds, they should appear in your bank account within three business days.',
                    check: 'You can check the status in your transaction list.',
                },
            },
        },
        layerswap: {
            inProgress: {
                title: 'Withdraw in progress',
                text: {
                    transfer: 'We are transferring your funds to Layerswap.',
                    wait: 'Please wait...',
                },
            },
            result: {
                title: 'Funds are on the way!',
                text: {
                    funds: 'Layerswap is processing your funds, they should appear in your account within three business days.',
                    check: 'You can check the status in your transaction list.',
                },
            },
        },
    },
    nftCheckoutPrimary: {
        title: {
            error: 'Error',
        },
        error: {
            featureNotYetSupported: 'Code 12000 - NFT Primary sale is not supported.',
            createTransactionError: 'Code 12001 - Cannot create a transaction.',
            connectionError: 'Code 12002 - Connection Error.',
            pollingError: 'Code 12003 - Cannot retrieve status.',
            statusFail: 'Code 12004 - Transaction failed.',
        },
        moonpay: {
            inProgress: {
                title: 'In progress',
                text: {
                    description: 'We’re retrieving funds from MoonPay and purchasing your item/s.',
                    wait: 'Please wait...',
                },
            },
            result: {
                title: 'Congratulations!',
                text: {
                    description: 'Your purchase has been completed.',
                    check: '',
                },
            },
        },
    },
    nftCheckoutSecondary: {
        title: {
            error: 'Error',
        },
        error: {
            featureNotYetSupported: 'Code 12000 - NFT Secondary sale is not supported.',
            createTransactionError: 'Code 12001 - Cannot create a transaction.',
            connectionError: 'Code 12002 - Connection Error.',
            pollingError: 'Code 12003 - Cannot retrieve status.',
            statusFail: 'Code 12004 - Transaction failed.',
        },
        moonpay: {
            inProgress: {
                title: 'In progress',
                text: {
                    description: 'We’re retrieving funds from MoonPay and purchasing your item/s.',
                    wait: 'Please wait...',
                },
            },
            result: {
                title: 'Congratulations!',
                text: {
                    description: 'Your purchase has been completed.',
                    check: '',
                },
            },
        },
    },
    onramp: {
        title: {
            done: 'Transfer is on its way!',
            error: 'Error',
            inProgress: 'Transfer in Progress...',
        },
        text: {
            featureNotYetSupported: generalErrorMessage(['Code 10000 - Something went wrong.']),
            error: 'Code 10001 - Something went wrong.',
            connectionError: 'Code 10002 - Connection Error.',
            invalidCurrencies: 'Code 10003 - Invalid currencies.',
            couldNotGetCurrencies: generalErrorMessage(['10004 - Something went wrong.']),
        },
        success: {
            layerswap: {
                title: 'Success',
                description: 'This may take a moment to appear in your funds.',
                buttonTitle: 'Done',
            },
        },
        error: {
            layerswap: {
                title: 'Failed',
                description: 'We were unable to add funds this time.',
            },
        },
    },
    completeWithdrawal: {
        nothingToWithdraw: 'Nothing to withdraw',
        title: {
            start: 'Ready to collect',
            inProgress: 'Withdrawal in progress...',
            done: 'Withdrawal is on its way',
        },
        walletAddressRetrievingFailed:
            'Code 4000 - Something went wrong when retrieving your wallet address. Check with your wallet provider.',
    },
    buy: {
        total: 'Total',
        title: { start: 'Confirm purchase', done: 'Purchase complete!', otp: 'Enter your code' },
        orderNotAvailable: 'Order not available for purchase',
        ownOrder: 'Cannot purchase own order',
        badOrderId: (orderId?: string) => `Error, bad orderId: ${orderId}`,
        noValidOrders: 'There are no valid orders to be bought',
        insufficientFunds: 'Insufficient funds for the purchase',
        allAttemptsFailed: 'All purchase attempts have failed',
        someItemsInvalid: `Some items could not be purchased:`,
        bodyText: {
            start: 'Please confirm the asset(s) to purchase:',
            done: 'Your purchase has been completed.',
        },
        failedRetrieveOrderDetails: (errorDetails?: string) => `Code 5000 - Something went wrong. - ${errorDetails} `,
        failedRetrieveAssetDetails: (errorDetails?: string) => `Code 5001 - Something went wrong. - ${errorDetails} `,
        failedTradeRequest: (errorDetails?: string) => `Code 5002 - Something went wrong. - ${errorDetails} `,
        failedAPIRequest: {
            code: 5003,
            message: (details: string) => `Code 5003 - The API rejected the purchase: ${details}`,
        },
    },
    offers: {
        makeOffer: {
            title: { start: 'Confirm Offer', done: 'Congratulations!' },
            bodyText: {
                start: 'Please review and confirm the offer you are making.',
                done: 'Your offer has been sent to the asset owner successfully.',
            },
            buttons: {
                done: 'Done',
                confirm: 'Make offer',
                cancel: 'Close',
            },
            invalidParameters: generalErrorMessage(['Invalid make offer parameters']),
        } as OfferMessages,
        cancelOffer: {
            title: { start: 'Cancel offer', done: 'Offer cancelled' },
            bodyText: {
                start: 'Please review and confirm the offer you are about to cancel.',
                done: 'Your offer has been cancelled successfully and no longer exists for this asset.',
            },
            buttons: {
                done: 'Done',
                confirm: 'Cancel offer',
                cancel: 'Close',
            },
            invalidParameters: generalErrorMessage(['Invalid cancel offer parameters']),
        } as OfferMessages,
        acceptOffer: {
            title: { start: 'Accept offer', done: 'Congratulations!' },
            bodyText: {
                start: 'Please review and confirm the offer you are about to accept.',
                done: 'You have successfully accepted this offer and your funds will arrive into your wallet soon.',
            },
            buttons: {
                done: 'Done',
                confirm: 'Accept offer',
                cancel: 'Close',
            },
            invalidParameters: generalErrorMessage(['Invalid accept offer parameters']),
        } as OfferMessages,
        apiError: {
            heading: 'Something went wrong',
            body: (
                <GeneralError
                    messages={[
                        'We have encountered an issue with our APIs while processing this request. Please try again.',
                    ]}
                />
            ),
        } as ErrorMessageProps,
    },
    prepareWithdrawal: {
        title: {
            start: 'Prepare withdrawal',
            done: 'Preparing withdrawal...',
        },
        assetAlreadyPreparingWithdraw: 'Asset is already preparing to withdraw from Immutable X',
        assetNotAvailableToWithdraw: 'Asset is not available to withdraw from Immutable X',
    },
    cancel: {
        title: {
            start: 'Cancel listing',
            done: 'Listing cancelled',
        },
        orderNotCancellable: 'This order is not cancellable',
        failedToRetrieveOrderDetails: 'Code 9000 - Something went wrong retrieving the order details.',
        failedToRetrieveToken: 'Code 9001 - Something went wrong retrieving the token details.',
    },
    claim: {
        error: {
            getClaimError: 'No claims available',
            cantClaim: 'Cannot claim for this address',
            genericClaimErrorScreenTitle: 'Claim failed',
            genericClaimErrorScreenBodyText: [claimErrorMsg, 'For further assistance, please visit our support page.'],
        },
        nonUS: {
            title: {
                start: 'Play to Earn rewards',
                done: 'Congratulations',
            },
            text: {
                congrats: 'Congratulations, you’ve earned IMX rewards for your participation in the ',
                playToEarn: 'Play to Earn',
                campaign: ' campaign!',
                your: 'Your ',
                accounted: ' accounted for ',
                allPoints: ' of all user points, earning you ',
                receive: ' You’ll receive your IMX reward as USDC at a ',
                rate: 'fixed exchange rate.',
                note: 'Note:',
                retro: ' A retrospective reward of (',
                included: ') has been included in the total to be exchanged.',
                comment: 'Available until 03 Feb 2022',
                terms: 'Terms & Conditions',
                agree: 'I agree to the ',
            },
        },
        US: {
            detection: {
                title: 'U.S. region detected',
                text: {
                    ipBasedLocation:
                        "We've detected you are a U.S. citizen based on your IP. If this is incorrect, select ",
                    cancel: 'Cancel',
                    ensure: '; ensure you have ',
                    noVPN: 'no active VPN',
                    try: ' and try again.',
                    regulations: 'Due to trade regulations imposed on U.S. citizens, ',
                    playToEarn: 'Play to Earn',
                    cant: ' rewards cannot be distributed as IMX tokens. Please select an alternative below:',
                },
                options: {
                    instant: {
                        title: 'Exchange and receive USDC instantly',
                        text: 'Reward exchanged at 10 IMX = 1 USDC',
                        comment: 'Available until 03 Feb 2022',
                    },
                    overTime: {
                        title: 'Exchange reward to USDC within 4 weeks',
                        text: "Allow your IMX to be exchanged on your behalf to USDC. This may take 4 weeks at most. This will provide you with some exposure to IMX's market value",
                        comment: 'Only available until 12 November 2021',
                    },
                },
            },
            instant: {
                title: 'Exchange instantly',
                text: {
                    your: 'Your ',
                    accounted: ' accounted for ',
                    allPoints: ' of all user points, earning you ',
                    receive: ' You’ll receive your IMX reward as USDC at a ',
                    rate: 'fixed exchange rate.',
                    note: 'Note:',
                    retro: ' A retrospective reward of (',
                    included: ') has been included in the total to be exchanged.',
                    terms: 'Terms & Conditions',
                    agree: 'I agree to the ',
                },
            },
            overTime: {
                title: 'Exchange over 4 weeks',
                text: {
                    requesting: 'You are requesting to have your IMX reward ',
                    exchanged: 'exchanged into USDC over a 4 week period. ',
                    exposure:
                        "This will provide you with exposure to the potential upside and downside of IMX's market value against USDC. As such, your final reward may be ",
                    glt: 'greater or less',
                    estimates: ' than any estimates previously provided to you.',
                    period: 'At the end of the 4 week period, your total reward will be credited to your ',
                    imxBalance: 'Immutable X balance',
                    usdc: ' in the form of USDC.',
                },
            },
            overTimeDetails: {
                title: 'Confirm request',
                text: {
                    imx: 'Your IMX will be ',
                    weeks: 'exchanged into USDC over 4 weeks',
                    end: ', with the total rewarded at the end.',
                    your: 'Your ',
                    points: ' points',
                    accounted: ' accounted for ',
                    allPoints: ' of all user points, earning you ',
                    note: 'Note:',
                    retro: ' A retrospective reward of (',
                    included: ') has been included in the total to be exchanged.',
                    irreversible: 'This request is irreversible once confirmed',
                    terms: 'Terms & Conditions',
                    agree: 'I agree to the ',
                },
            },
            confirmOverTime: { title: 'Confirm request', text: '' },
        },
        congrats: {
            title: 'Congratulations',
            text: `That's it! Your reward should appear in your Immutable X balance shortly.`,
        },
    },
    helperMessage: {
        confirm: {
            begin: 'You’ll earn',
            end: 'when this item sells',
        },
        cancel: {
            begin: 'You’ll miss',
            end: 'if this were to sell',
        },
        link: 'https://google.com.au',
        name: 'daily points',
    },
    standardUiButtons: {
        confirm: 'CONFIRM',
        next: 'NEXT',
        back: 'BACK',
        cancel: 'CANCEL',
        deny: 'Deny',
        continue: 'CONTINUE',
    },
    history: {
        retrieveTransactionsFailed: (details: string) =>
            generalErrorMessage([`Code 8000 - Something went wrong retrieving the transactions: ${details}`]),
    },
});

const en = messagesEn(generalErrorMessageEN);

export type Messages = typeof en;
export { generalErrorMessageEN as createErrorMessageUi };
export const messagesIndex: Record<Language, Messages> = {
    en,
};
